import React from "react";
import moment from "moment";

import logoImg from "../../../_assets/images/reusablesLogo2.png";
import impactLogoIcon from "../../../_assets/images/logoGray.png";
import impactContainerIcon from "../../../_assets/images/containerIcon.png";
import impactCO2Icon from "../../../_assets/images/co2.png";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Line,
  Svg,
} from "@react-pdf/renderer";

const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: "column",
    display: "flex",
    width: "100%",
    padding: "50",
    border: "1px solid black",
    position: "relative",
  },
  footer: {
    textAlign: "center",
    borderTop: "1px solid #2b5b6c",
    fontSize: "10",
    fontFamily: "Helvetica",
    position: "absolute",
    bottom: "20",
    left: "50",
    width: "100%",
    paddingTop: "10",
  },
  view: {
    minHeight: "100vh",
    minWidth: "30vw",
  },
  smallHeader: {
    width: "100%",
    border: "1px solid red",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "space-between",
  },
  img: {
    display: "flex",
    marginBottom: "auto",
    marginTop: "8",
  },
  headerBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    flexGrow: "1",
  },
  impactViewContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  impactView: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    flexGrow: "1",
    padding: "5px",
  },
  invoiceInfo: {
    marginTop: "15",
    marginBottom: "15",
  },
  transactionsTable: {
    fontSize: 10,
    fontFamily: "Helvetica",
    paddingTop: "20",
    marginTop: "10",
    marginBottom: "20",
  },
  transactionsHeader: {
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid #2b5b6c",
    paddingBottom: "8px",
  },
  transactionsRow: {
    fontSize: 10,
    fontFamily: "Helvetica",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid #aaaaaa",
    paddingBottom: "8px",
    paddingTop: "8px",
  },
  text14: {
    fontSize: "14",
    fontFamily: "Helvetica",
  },
  text10: {
    fontSize: "10",
    fontFamily: "Helvetica",
    marginBottom: "5",
  },
  text12: {
    fontSize: "12",
    fontFamily: "Helvetica",
  },
  text12bold: {
    fontSize: "12",
    fontFamily: "Helvetica-Bold",
  },
  text14bold: {
    fontSize: "14",
    fontFamily: "Helvetica-Bold",
    marginBottom: "5px",
  },
  text14margin: {
    fontSize: "14",
    fontFamily: "Helvetica",
    marginBottom: "5px",
  },
  text16: {
    fontFamily: "Helvetica",
    fontSize: "16",
    textAlign: "center",
    marginBottom: "8px",
  },
  text16bold: {
    fontFamily: "Helvetica-Bold",
    fontSize: "16",
    textAlign: "center",
    marginBottom: "8px",
    marginTop: "8px",
  },
  priceView: {
    width: "100%",
  },
  priceRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "30%",
    marginLeft: "auto",
  },
  priceRowTotal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "30%",
    marginTop: "5",
    paddingTop: "7",
    borderTop: "1px solid #aaaaaa",
    marginLeft: "auto",
  },
  infoRow: {
    fontSize: 12,
    fontFamily: "Helvetica",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: "15",
    paddingBottom: "15",
  },
  paymentTerms: { marginTop: "20" },
});

const InvoiceDoc = ({ invoice }) => {
  const subtotal =
    invoice.preTaxAmount ||
    invoice.transactionList.reduce((prev, curr) => prev + curr.amount, 0);
  const transactionList = [];
  invoice.transactionList.forEach((transaction) => {
    let index = transactionList.findIndex(
      (item) =>
        item.containerTypeId === transaction.containerTypeId &&
        item.price === transaction.price &&
        item.name === transaction.name
    );
    if (index !== -1) {
      transactionList[index].quantity += transaction.quantity;
    } else {
      transactionList.push({ ...transaction });
    }
  });

  transactionList.sort((a, b) => {
    return a.name - b.name;
  });

  const addressLines = invoice.user.location.split(",");
  let address = [];
  if (addressLines.length > 2) {
    const length = addressLines.length;
    address = addressLines.slice(0, length - 3);
    address.push(addressLines[length - 3] + "," + addressLines[length - 2]);
    address.push(addressLines[length - 1]);
  } else {
    address = addressLines;
  }

  const mainHeader = (
    <View style={pdfStyles.header}>
      <View style={pdfStyles.img}>
        <Image style={{ height: "30" }} source={logoImg} />
      </View>
      <View style={pdfStyles.headerBox}>
        <Text
          style={{
            color: "#2b5b6c",
            fontSize: "36",
            fontFamily: "Helvetica-Bold",
          }}
        >
          {invoice.paymentIntentId || invoice.chargedManually
            ? "STATEMENT"
            : "INVOICE"}
        </Text>
        <Text style={pdfStyles.text12}>
          {moment
            .utc(invoice.createdAt)
            .subtract(1, "months")
            .format("MMMM YYYY")}
        </Text>
      </View>
    </View>
  );
  const smallHeader = (
    <View>
      <View style={{ marginBottom: "10" }}>
        <View
          style={{ display: "flex", alignItems: "flex-end", flexGrow: "1" }}
        >
          <Text
            style={{
              color: "#2b5b6c",
              fontSize: "18",
              fontFamily: "Helvetica-Bold",
            }}
          >
            {invoice.paymentIntentId || invoice.chargedManually
              ? "STATEMENT"
              : "INVOICE"}
          </Text>
        </View>
      </View>
      <Svg
        height={1}
        style={{
          marginTop: "10",
          marginBottom: "10",
        }}
      >
        <Line
          x1={0}
          x2={window.innerWidth}
          y1={1}
          y2={1}
          strokeWidth={"1"}
          stroke={"#2b5b6c"}
        />
      </Svg>
    </View>
  );

  const invoiceInfo = (
    <View style={pdfStyles.invoiceInfo}>
      <Svg height={1}>
        <Line
          x1={0}
          x2={window.innerWidth}
          y1={1}
          y2={1}
          strokeWidth={"1"}
          stroke={"#2b5b6c"}
        />
      </Svg>
      <View style={pdfStyles.infoRow}>
        <View style={pdfStyles.infoCol}>
          <Text>To:</Text>
          <Text style={pdfStyles.text12bold}>{invoice.user.name}</Text>
          {address.map((addressLine, ind) => (
            <Text key={ind}>{addressLine.trim()}</Text>
          ))}
        </View>
        <View style={pdfStyles.infoCol}>
          <Text>
            {invoice.paymentIntentId || invoice.chargedManuallyInvoice
              ? "Statement "
              : "Invoice "}
            Date
          </Text>
          <Text style={pdfStyles.text12bold}>
            {moment(invoice.createdAt).format("MMMM DD, YYYY")}
          </Text>
          <Text> </Text>
          <Text>
            {invoice.paymentIntentId || invoice.chargedManuallyInvoice
              ? "Statement "
              : "Invoice "}
            Number
          </Text>
          <Text style={pdfStyles.text12bold}>{invoice.invoiceNumber}</Text>
        </View>
        <View style={pdfStyles.infoCol}>
          <Text>From:</Text>
          <Text style={pdfStyles.text12bold}>Circular Solutions Inc.</Text>
          <Text>6th Floor</Text>
          <Text>905 W Pender St Building</Text>
          <Text>Vancouver, BC V6C 1L6</Text>
        </View>
      </View>
      <Svg height={1}>
        <Line
          x1={0}
          x2={window.innerWidth}
          y1={1}
          y2={1}
          strokeWidth={"1"}
          stroke={"#2b5b6c"}
        />
      </Svg>
    </View>
  );
  const impactInfo = (
    <>
      <View>
        <Text style={pdfStyles.text16bold}>Thank you for using Reusables!</Text>
        <Text style={pdfStyles.text16}>Your monthly impact is</Text>
      </View>
      <View style={pdfStyles.impactViewContainer}>
        <View style={{ ...pdfStyles.impactView }}>
          <Image
            source={impactContainerIcon}
            style={{ height: "20px", marginBottom: "5px" }}
          />
          <Text style={pdfStyles.text14bold}>{invoice.quantity}</Text>
          <Text style={pdfStyles.text14}>Reusables Used</Text>
        </View>
        <View style={{ ...pdfStyles.impactView, width: "35%" }}>
          <Image
            source={impactLogoIcon}
            style={{ height: "20px", marginBottom: "5px" }}
          />
          <Text style={pdfStyles.text14bold}>
            {(invoice.quantity * 0.03).toFixed(1)}
          </Text>
          <Text style={pdfStyles.text14}>kgs of packaging waste</Text>
          <Text style={pdfStyles.text14}>diverted from waste streams</Text>
        </View>
        <View style={{ ...pdfStyles.impactView, width: "25%" }}>
          <Image
            source={impactCO2Icon}
            style={{ height: "20px", marginBottom: "5px" }}
          />
          <Text style={pdfStyles.text14bold}>
            {" "}
            {(invoice.quantity * 0.05).toFixed(1)}
          </Text>
          <Text style={pdfStyles.text14}>kgs of CO2 equivalent</Text>
          <Text style={pdfStyles.text14}>saved</Text>
        </View>
      </View>
    </>
  );
  const transactionsTable = (
    <View style={pdfStyles.transactionsTable}>
      <View style={pdfStyles.transactionsHeader}>
        <Text style={{ width: "45%" }}>Item</Text>
        <Text style={{ width: "25%", textAlign: "center" }}>Quantity</Text>
        <Text style={{ width: "15%" }}>Price</Text>
        <Text style={{ width: "15%", textAlign: "right" }}>Total</Text>
      </View>
      {transactionList.map((transaction, ind) => (
        <View key={ind} style={pdfStyles.transactionsRow}>
          <Text style={{ width: "45%" }}>{transaction.name}</Text>
          <Text
            style={{
              width: "25%",
              textAlign: "center",
            }}
          >
            {transaction.quantity}
          </Text>
          <Text style={{ width: "15%" }}>
            ${Number(transaction.price).toFixed(2)}
          </Text>
          <Text
            style={{
              width: "15%",
              textAlign: "right",
            }}
          >
            ${Number(transaction.price * transaction.quantity).toFixed(2)}
          </Text>
        </View>
      ))}
    </View>
  );
  const pricing = (
    <View style={pdfStyles.priceView} wrap={false}>
      <View style={pdfStyles.priceRow}>
        <Text style={pdfStyles.text10}>Subtotal:</Text>
        <Text style={pdfStyles.text10}>${Number(subtotal).toFixed(2)}</Text>
      </View>
      {Number(invoice.amount) != Number(subtotal) && (
        <View style={pdfStyles.priceRow}>
          <Text style={pdfStyles.text10}>Tax(GST):</Text>
          <Text style={pdfStyles.text10}>
            ${Number(subtotal * 0.05).toFixed(2)}
          </Text>
        </View>
      )}
      {invoice.pst && (
        <View style={pdfStyles.priceRow}>
          <Text style={pdfStyles.text10}>Tax(PST):</Text>
          <Text style={pdfStyles.text10}>
            ${Number((subtotal * invoice.pst) / 100).toFixed(2)}
          </Text>
        </View>
      )}
      <View style={pdfStyles.priceRowTotal}>
        <Text style={pdfStyles.text12bold}>Total:</Text>
        <Text style={pdfStyles.text12bold}>
          CA${Number(invoice.amount).toFixed(2)}
        </Text>
      </View>
    </View>
  );
  const paymentTerms = (
    <View style={pdfStyles.paymentTerms} wrap={false}>
      <Text style={pdfStyles.text14bold}>Payment Terms</Text>
      <Text style={pdfStyles.text14margin}>
        Due Date:{" "}
        {moment(invoice.createdAt).add(30, "days").format("MMMM DD, YYYY")}
      </Text>
      <Text style={pdfStyles.text14margin}>
        Send e-transfers to accounting@reusables.com
      </Text>
      <Text style={pdfStyles.text14margin}>
        Cheques should be made out to Circular Solutions Inc. and sent to:
      </Text>
      <Text style={{ ...pdfStyles.text14, marginLeft: "10" }}>
        Circular Solutions Inc.
      </Text>
      <Text style={{ ...pdfStyles.text14, marginLeft: "10" }}>6th Floor</Text>
      <Text style={{ ...pdfStyles.text14, marginLeft: "10" }}>
        905 W Pender St Building
      </Text>
      <Text style={{ ...pdfStyles.text14, marginLeft: "10" }}>
        Vancouver, BC V6C 1L6
      </Text>
    </View>
  );
  return (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <View
          style={{ maxHeight: "35" }}
          render={({ pageNumber }) => pageNumber !== 1 && smallHeader}
          fixed
        />
        {mainHeader}
        {invoiceInfo}
        {impactInfo}
        {transactionsTable}
        {pricing}
        {!invoice.paymentIntentId && !invoice.chargedManually && paymentTerms}
        <Text
          style={pdfStyles.footer}
          render={({ pageNumber }) => `${pageNumber}`}
          fixed
        />
      </Page>
    </Document>
  );
};

export default InvoiceDoc;
