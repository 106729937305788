import React from "react";
import DatePicker from "react-datepicker";
import "./datePicker.scss";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerComp = ({ selected, onChangeDate, imgLeft, disabled }) => {
  const CustomInput = ({ value, onClick, imgLeft }) => (
    <div>
      {imgLeft ? (
        <div
          className={
            disabled ? "distribution-wrapper-disabled" : "distribution-wrapper"
          }
          onClick={onClick}
        >
          {value ? (
            value
          ) : (
            <div>
              <img src={imgLeft} alt="Add date" />
              <span className="distribution-text">Add a Date</span>
            </div>
          )}
        </div>
      ) : (
        <div className="cleaning-wrapper">
          <span className="label">Insert batch name</span>
          <div className="cleaning" onClick={onClick}>
            {value}
          </div>
        </div>
      )}
    </div>
  );
  return (
    <div className="date-picker-wrapper">
      <DatePicker
        placeholderText="03/03/2021"
        selected={selected}
        onChange={onChangeDate}
        customInput={<CustomInput imgLeft={imgLeft} />}
        dateFormat="MM/dd/yyyy"
        disabled={disabled}
      />
    </div>
  );
};

export default DatePickerComp;
