import React, { useContext, useEffect, useState } from "react";
import styles from "./transfer.module.scss";
import TransferForm from "./form";
import TransferHistory from "./history";
import Button from "../../button";
import t from "../../../_config/constants";
import Selector from "../../selector";
import Input from "../../input";
import ConfirmModal from "../../modal/confirmModal";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import { DistributionContext } from "../../../_pages/distribution";
import toastService from "../../../_services/toast.service";
import ScanContainer from "./scanContainer";
import close from "./../../../_assets/icons/close.svg";
import Checkbox from "../../checkbox";
import { makeSelectMap } from "../../../_utils/utils";
const Transfer = ({ onTransferClick, onReplenishmentClick, activeTab }) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [scannedContainers, setScannedContainers] = useState([]);
  const [hideLeftSide, setHideLeftSide] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [transferClean, setTransferClean] = useState(true);
  const [data, setData] = useState({
    fromStoreId: "",
    fromStoreName: "",
    toStoreId: "",
    toStoreName: "",
    containers: [],
    state: "clean",
  });
  const [fromStoreOptions, setFromStoreOptions] = useState();
  const [toStoreOptions, setToStoreOptions] = useState();
  const [locations, setLocations] = useState([]);
  const [restaurants, restaurantOptions, containerTypes] =
    useContext(DistributionContext);
  const [errorMsg, setErrorMsg] = useState(false);
  const [restaurantError, setRestaurantError] = useState(false);
  const [error, setError] = useState({
    fromStore: false,
    toStore: false,
    containers: false,
  });

  useEffect(() => {
    const getLocations = async (regionId) => {
      let filter = [{ isDraft: { $ne: true } }];
      if (regionId && regionId !== "*") {
        filter.push({
          regionId: regionId,
        });
      }
      let data = await dispatch(
        mainActions.run("data", "location", "all", { filter })
      );
      if (data) {
        setLocations(data);
        let fromLocations = data.filter(
          (item) => item.isRestaurant && !item.isCleaning
        );
        let toLocations = data.filter(
          (item) => item.isRestaurant && item.isCleaning
        );
        setFromStoreOptions(makeSelectMap(fromLocations));
        setToStoreOptions(makeSelectMap(toLocations));
      }
    };
    if (store?.auth?.user?.regionId) {
      getLocations(store.auth.user.regionId);
    }
  }, [store.auth && store.auth.user]);
  useEffect(() => {
    handleSelectRestaurant(null, "fromStore");
    handleSelectRestaurant(null, "toStore");
    setData((prev) => ({ ...prev, state: transferClean ? "clean" : "dirty" }));
  }, [transferClean]);
  const handleView = (transferData) => {
    setData(transferData);
    setShowForm(true);
    setAddNew(false);
    setHideLeftSide(true);
  };

  const clearData = () => {
    setData({
      fromStoreId: "",
      fromStoreName: "",
      toStoreId: "",
      toStoreName: "",
      containers: [],
      state: "clean",
    });
    setError({
      fromStore: false,
      toStore: false,
      containers: false,
    });
    setShowForm(false);
    setAddNew(false);
    setScannedContainers([]);
  };
  const handleCloseForm = () => {
    clearData();
    setHideLeftSide(false);
  };
  const validateForm = () => {
    setError({
      fromStore: !data.fromStoreId,
      toStore: !data.toStoreId,
      containers: !data.containers.length,
    });
    if (data.fromStoreId === data.toStoreId && data.fromStoreId !== "") {
      setRestaurantError(true);
      return false;
    }
    let amount = true;
    data.containers.forEach((container) => {
      if (!container.amount || container.amount < 0) {
        setError((prev) => ({ ...prev, containers: true }));
        amount = false;
      }
    });
    return (
      data.fromStoreId && data.toStoreId && data.containers.length > 0 && amount
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let response = await dispatch(
        mainActions.run("data", "transferForm", "new", data)
      );
      if (response) {
        toastService.show("success", t.transfer.text_successfully_transferred);
        clearData();
      }
    } else {
      toastService.show("error", `${t.common.text_toast_all_fields_required}`);

      setErrorMsg(true);
    }
  };
  const handleSelectRestaurant = (e, name) => {
    setRestaurantError(false);
    setErrorMsg(false);
    const store =
      name === "toStore"
        ? { id: "toStoreId", name: "toStoreName" }
        : { id: "fromStoreId", name: "fromStoreName" };
    setError((prev) => ({ ...prev, [name]: false }));

    if (e) {
      let restaurant = transferClean
        ? restaurants.find((item) => item._id === e.value)
        : locations.find((item) => item._id === e.value);
      setData((data) => ({
        ...data,
        [store.id]: restaurant._id,
        [store.name]: restaurant.name,
      }));
    } else {
      setData((data) => ({
        ...data,
        [store.id]: "",
        [store.name]: "",
        containers: [],
      }));
      setScannedContainers([]);
    }
  };
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const handleInputNumber = (e) => {
    const numbers = "0123456789";
    if (!numbers.includes(e.key)) {
      e.preventDefault();
    }
  };
  const handleChange = (e) => {
    setErrorMsg(false);
    setError((prev) => ({ ...prev, containers: false }));
    const { name, value } = e.target;
    let amount = value;
    while (amount[0] == 0) {
      amount = amount.slice(1);
    }
    let containersList = [...scannedContainers];
    let container = containersList?.find((item) => item.name === name);
    container.amount = amount;
    setData((prev) => ({ ...prev, containers: containersList }));
  };
  const handleClose = (item) => {
    setErrorMsg(false);

    const scanned = scannedContainers.filter(
      (container) => container.name !== item.name
    );
    setScannedContainers(scanned);
    const containersData = data.containers.filter(
      (container) => container.containerTypeId !== item.containerTypeId
    );
    setData((data) => ({ ...data, containers: containersData }));
  };
  const handleDelete = async (answer) => {
    if (answer) {
      let response = await dispatch(
        mainActions.run("data", "transferForm", "delete", {
          _id: data._id,
        })
      );
      if (response) {
        toastService.show("success", t.transfer.text_successfully_canceled);
        clearData();
        setHideLeftSide(false);
        setShowModalDelete(false);
      }
    } else {
      setShowModalDelete(false);
    }
  };
  return (
    <div className={styles.wrapper}>
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={handleDelete}
          modalData={{}}
          question={t.transfer.text_cancel_transfer_modal}
        />
      )}
      {!hideLeftSide && (
        <div className={styles.left}>
          <div className={styles.header}>
            <p
              className={
                activeTab === "replenishment"
                  ? styles.tabActive
                  : styles.tabInactive
              }
              onClick={onReplenishmentClick}
            >
              Create Replenishment Form
            </p>
            <p
              className={
                activeTab === "transfer" ? styles.tabActive : styles.tabInactive
              }
              onClick={onTransferClick}
            >
              Transfer Containers
            </p>
          </div>
          <div className={styles.row}>
            <Button
              btnClass="btnNormalCasal"
              label={t.button.text_create_transfer}
              onClick={() => {
                setShowForm(true);
                setAddNew(true);
              }}
              disabled={showForm}
            />
            <div className={styles.checkboxes}>
              <Checkbox
                customClass="checkbox"
                label="Clean containers"
                checked={transferClean}
                onChange={() => setTransferClean((prev) => !prev)}
                disabled={!showForm}
              />
              <Checkbox
                customClass="checkbox"
                label="Dirty containers"
                checked={!transferClean}
                onChange={() => setTransferClean((prev) => !prev)}
                disabled={!showForm}
              />
            </div>
          </div>

          <div className={styles.restaurants}>
            <Selector
              placeholder={t.transfer.text_store_name}
              selectClassname="selectSecondaryWhite"
              label={"From"}
              options={transferClean ? restaurantOptions : fromStoreOptions}
              multiselect={false}
              selectedOption={getSelectedValue(
                transferClean ? restaurantOptions : fromStoreOptions,
                data.fromStoreId
              )}
              handleChangeSelect={(e) => handleSelectRestaurant(e, "fromStore")}
              disabled={!showForm}
              error={error.fromStore}
            />
            <Selector
              placeholder={t.transfer.text_store_name}
              selectClassname="selectSecondaryWhite"
              label={"To"}
              options={transferClean ? restaurantOptions : toStoreOptions}
              multiselect={false}
              selectedOption={getSelectedValue(
                transferClean ? restaurantOptions : toStoreOptions,
                data.toStoreId
              )}
              handleChangeSelect={(e) => handleSelectRestaurant(e, "toStore")}
              disabled={!showForm}
              error={
                error.toStore ||
                (data.toStoreId === data.fromStoreId && data.toStoreId !== "")
              }
            />
          </div>
          {restaurantError ? (
            <div className={styles.error}>
              <p>{t.transfer.text_select_different}</p>
            </div>
          ) : null}
          <ScanContainer
            scannedContainers={scannedContainers}
            setScannedContainers={setScannedContainers}
            enableScan={addNew}
            setErrorMsg={setErrorMsg}
          />

          <div className={styles.containers}>
            {scannedContainers?.map((item) => {
              return (
                <div key={item.containerTypeId} className={styles.item}>
                  <div className={styles.label}>
                    {item.name}
                    <img src={close} onClick={() => handleClose(item)}></img>
                  </div>
                  <div>
                    <Input
                      placeholder={t.common.text_th_amount}
                      name={item.name}
                      inputClass="inputBorder"
                      type="number"
                      onChange={handleChange}
                      onKeyPress={handleInputNumber}
                      error={error.containers}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          {errorMsg ? (
            <div className={styles.error}>
              <p>{t.common.text_all_fields_required}</p>
            </div>
          ) : null}
        </div>
      )}
      <div className={hideLeftSide ? styles.fullScreen : styles.right}>
        {showForm && (
          <TransferForm
            data={data}
            handleClose={handleCloseForm}
            onSubmit={handleSubmit}
            cancelDelivery={() => {
              setShowModalDelete(true);
            }}
          />
        )}
        {!showForm && <TransferHistory handleView={handleView} />}
      </div>
    </div>
  );
};

export default Transfer;
