import React, { useEffect, useState } from "react";
import styles from "./navbar.module.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import notificationImg from "../../_assets/icons/notificationNav.svg";
import userDefault from "../../_assets/icons/user.svg";
import { useDispatch, useSelector } from "react-redux";
import Selector from "../../_components/selector";
import { mainActions } from "../../_store/actions/main.actions";
import { isObjectEmpty, makeSelectMap } from "../../_utils/utils";
import { userActions } from "../../_store/actions/user.actions";

const Navbar = () => {
  const history = useHistory();
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [regionOptions, setRegionOptions] = useState([]);
  const [selectedRegionOption, setSelectedRegionOption] = useState({});
  const [selectedSubregionOption, setSelectedSubregionOption] = useState([]);

  const [selectedRegion, setSelectedRegion] = useState([]);
  const [subregionOptions, setSubregionOptions] = useState([]);
  const [unread, setUnread] = useState(false);

  useEffect(() => {
    if (store.auth && store.auth.user) {
      setUser(store.auth.user);
    }
  }, [store.auth && store.auth.user && store.auth.user.regionId]);

  useEffect(() => {
    if (user && (user.type === "master" || user.type === "admin")) {
      fetchRegions();
      fetchSubregions(user.regionId);
    }
  }, [user]);

  const fetchRegions = async () => {
    let response = await dispatch(mainActions.run("data", "region", "all", {}));
    let arr = makeSelectMap(response);
    arr.unshift({ label: "All", value: "*" });
    setRegionOptions(arr);
  };
  const fetchSubregions = async (regionId) => {
    let arr = [];
    if (regionId && regionId !== "*") {
      let response = await dispatch(
        mainActions.run("data", "subregion", "all", {
          filter: { regionId },
        })
      );
      arr = makeSelectMap(response);
    }
    arr.unshift({ label: "All", value: "*" });
    setSubregionOptions(arr);
  };
  useEffect(() => {
    if (regionOptions && regionOptions.length > 0 && user) {
      getSelectedValue(regionOptions, user.regionId);
      getSelectedValue(subregionOptions, user.subregionId, "subregion");

      fetchSubregions(user.regionId);
    }
  }, [
    store.auth && store.auth.user,
    regionOptions && regionOptions.length,
    subregionOptions && subregionOptions.length,
  ]);

  const getSelectedValue = (arr, val, type = "region") => {
    if (!val) {
      let obj = { label: "All", value: "*" };
      type === "region"
        ? setSelectedRegionOption(obj)
        : setSelectedSubregionOption(obj);
      return;
    }
    arr &&
      arr.forEach((obj) => {
        if (obj.value === val) {
          type === "region"
            ? setSelectedRegionOption(obj)
            : setSelectedSubregionOption(obj);
        }
      });
  };

  const handleNotification = () => {
    history.push("/notifications");
  };

  const handleSelectRegion = async (e) => {
    let data = { ...user };
    data.regionId = e.value;
    data.subregionId = "*";
    setUser(data);
    delete data.phone;
    await dispatch(userActions.updateUser(data));
  };

  const handleSelectSubregion = async (e) => {
    let data = { ...user };
    data.subregionId = e.value;
    setUser(data);
    delete data.phone;
    await dispatch(userActions.updateUser(data));
  };
  const getNotifications = async (id) => {
    setUnread(false);
    let filter = [];
    if (id && id !== "*") {
      filter.push({
        regionId: id,
        seen: false,
      });
    } else {
      filter.push({
        seen: false,
      });
    }
    let data = await dispatch(
      mainActions.run("notifications", "notification", "get", { filter })
    );
    if (!isObjectEmpty(data)) {
      setUnread(true);
    }
  };

  useEffect(() => {
    if (store.auth && store.auth.user)
      getNotifications(store.auth?.user?.regionId);
  }, [
    store.auth && store.auth.user,
    store.main.notifications &&
      store.main.notifications.notification &&
      store.main.notifications.notification.list,
  ]);

  return (
    <nav>
      {user && user.type === "master" && (
        <div className={styles.region}>
          <Selector
            multiselect={false}
            options={regionOptions}
            selectedOption={selectedRegionOption}
            handleChangeSelect={(e) => handleSelectRegion(e)}
            selectClassname="selectPrimary"
            label={"Region"}
          />
        </div>
      )}
      {user &&
        (user.type === "admin" || user.type === "master") &&
        user.subType !== "subregionAdmin" && (
          <div className={styles.region}>
            <Selector
              multiselect={false}
              options={subregionOptions}
              selectedOption={selectedSubregionOption}
              handleChangeSelect={(e) => handleSelectSubregion(e)}
              selectClassname="selectPrimary"
              disabled={
                !selectedRegionOption.value || selectedRegionOption.value == "*"
              }
              label={"Subregion"}
            />
          </div>
        )}
      <div className={styles.content}>
        <div className={styles.notification} onClick={handleNotification}>
          <img src={notificationImg} />
          {unread && <span className={styles.redDot} />}
        </div>
        <div className={styles.border} />
        <div className={styles.userInfo}>
          <span>{user.fullName}</span>
          <img src={userDefault} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
