import React, { useEffect, useState } from "react";
import Button from "../../button";
import Input from "../../input";
import styles from "./single.module.scss";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import ConfirmModal from "../../modal/confirmModal";
import toastService from "../../../_services/toast.service";
import t from "../../../_config/constants";
import Avatar from "../../avatar";
const SingleArchivedStore = ({ restaurantId, getAllRestaurants }) => {
  const [restaurant, setRestaurant] = useState("");
  const [showModalArchive, setShowModalArchive] = useState(false);
  const [loadingImg, setLoadingImg] = useState(true);
  const dispatch = useDispatch();

  const getSelectedRestaurant = async (id) => {
    const response = await dispatch(
      mainActions.run("profiles", "restaurant", "id", id)
    );
    setRestaurant(response);
    if (!response.avatarURL) setLoadingImg(false);
  };

  useEffect(() => {
    if (restaurantId) {
      getSelectedRestaurant(restaurantId);
      setLoadingImg(true);
    }
  }, [restaurantId]);

  const handleUnarchive = async (answer) => {
    if (answer) {
      try {
        const response = await dispatch(
          mainActions.run("profiles", "restaurant", "update", {
            _id: restaurantId,
            archived: false,
          })
        );

        if (response) {
          toastService.show(
            "success",
            t.restaurant.text_successfully_unarchived
          );
          getAllRestaurants();
          setRestaurant("");
          setShowModalArchive(false);
        }
      } catch (err) {
        setShowModalArchive(false);
      }
    } else {
      setShowModalArchive(false);
    }
  };
  return (
    <>
      {showModalArchive && (
        <ConfirmModal
          show={showModalArchive}
          handleYesNoAnswer={handleUnarchive}
          question={t.restaurant.text_modal_unarchive_restaurant}
          confirmLabel="Yes"
        />
      )}
      {!restaurant && (
        <div className={styles.noData}>
          <span>{t.restaurant.text_select_guideline}</span>
        </div>
      )}

      {restaurant && (
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Button
              label={"Reactivate"}
              btnClass="btnWhiteBackground"
              disabled={false}
              onClick={() => {
                setShowModalArchive(true);
              }}
            />
          </div>
          <div className={styles.restaurantInfos}>
            <Avatar
              imgUrl={restaurant.avatarURL}
              loadingImg={loadingImg}
              setLoadingImg={setLoadingImg}
            />
            <h2>
              {restaurant.isDraft && (
                <div className={styles.draft}>[Draft]</div>
              )}
              {restaurant.name || ""}
            </h2>
            <p>{t.common.text_profile_info}</p>
            <form>
              <div className={styles.inputGroup}>
                <Input
                  label={t.common.input.text_input_email_address}
                  name="email"
                  inputClass="inputForm"
                  type="text"
                  value={restaurant.email || ""}
                  disabled={true}
                />{" "}
                <Input
                  label={t.common.input.text_input_phone_number}
                  name="phone"
                  inputClass="inputForm"
                  type="text"
                  value={restaurant.phone || ""}
                  disabled={true}
                />
              </div>
              {restaurant.billingEmail && (
                <div className={styles.inputGroup}>
                  <Input
                    label={"Billing Email Address"}
                    name="billingEmail"
                    inputClass="inputForm"
                    type="text"
                    value={restaurant.billingEmail || ""}
                    disabled={true}
                  />{" "}
                </div>
              )}
              <div className={styles.inputGroup}>
                <Input
                  label={t.restaurant.text_input_owner}
                  name="fullName"
                  inputClass="inputForm"
                  type="text"
                  value={restaurant.fullName || ""}
                  disabled={true}
                />
              </div>
              <div className={styles.inputGroup}>
                <Input
                  label={t.common.input.text_input_address}
                  name="location"
                  inputClass="inputForm"
                  type="text"
                  value={restaurant.location || ""}
                  disabled={true}
                />
              </div>
              <div className={styles.inputGroup}>
                <Input
                  label={t.restaurant.text_input_secureCode}
                  name="secureCode"
                  inputClass="inputForm"
                  type="text"
                  value={restaurant.secureCode || "N/A"}
                  disabled={true}
                  showCopyToClipboard={true}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleArchivedStore;
