import React, { useEffect } from "react";
import styles from "./redirect.module.scss";
import Loading from "../../_components/loading";
import publicService from "../../_services/public.service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import queryString from "query-string";

const RedirectURL = () => {
  const history = useHistory();
  const { batch } = queryString.parse(history.location.search);
  useEffect(() => {
    const fetchUrl = async () => {
      try {
        const response = await publicService.getQRRedirectUrl({});
        if (response) {
          let redirectUrl = "";
          if (response.redirectURLS && response.redirectURLS[batch]) {
            redirectUrl = response.redirectURLS[batch];
          } else {
            redirectUrl = response.defaultRedirectUrl + "";
          }
          window.location.replace(redirectUrl);
        } else {
          window.location.replace("https://reusables.com");
        }
      } catch (err) {
        window.location.replace("https://reusables.com");
      }
    };
    fetchUrl();
  }, []);

  return (
    <div className={styles.wrapper}>
      <Loading width={70} height={70} />
      <p>Redirecting...</p>
    </div>
  );
};
export default RedirectURL;
