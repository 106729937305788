import React, { useEffect } from "react";
import styles from "./../usersTable/table.module.scss";
import Loading from "../../../loading";
import Button from "../../../button";
import arrowUp from "../../../../_assets/icons/arrowUp.svg";
import arrowDown from "../../../../_assets/icons/arrowDown.svg";
import upDownArrow from "../../../../_assets/icons/upDownArrow.svg";
import useSortableData from "../../../../_utils/useSortableData";
import t from "../../../../_config/constants";
import moment from "moment";
const ReusersCardsTable = ({
  data,
  handleSelectUser,
  selectedUser,
  loadingData,
  changeSortConfig,
}) => {
  const { handleSort, sortConfig } = useSortableData(data);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return upDownArrow;
    }
    return sortConfig.key === name
      ? sortConfig.direction === "ascending"
        ? arrowUp
        : arrowDown
      : upDownArrow;
  };
  useEffect(() => {
    changeSortConfig(sortConfig);
  }, [sortConfig]);

  return (
    <div className={styles.wrapper}>
      <table>
        <thead>
          <tr>
            <th
              onClick={() =>
                loadingData ? null : handleSort("uniqueReuserCardId")
              }
            >
              Unique Id
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("uniqueReuserCardId")}
              />
            </th>
            <th onClick={() => (loadingData ? null : handleSort("createdAt"))}>
              Created
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("createdAt")}
              />
            </th>
            <th
              onClick={() => (loadingData ? null : handleSort("inPossession"))}
            >
              {t.user.text_th_in_possession}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("inPossession")}
              />
            </th>
            <th onClick={() => (loadingData ? null : handleSort("overdue"))}>
              {t.user.text_overdue}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("overdue")}
              />
            </th>
            <th onClick={() => (loadingData ? null : handleSort("dueDate"))}>
              {t.user.text_th_dueDate}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("dueDate")}
              />
            </th>
            <th
              onClick={() =>
                loadingData ? null : handleSort("totalContainersUsed")
              }
            >
              {t.user.text_containers_used}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("totalContainersUsed")}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {!loadingData &&
            data &&
            data.length > 0 &&
            data.map((item) => (
              <tr
                className={selectedUser === item._id ? styles.active : ""}
                key={item._id}
                onClick={() => handleSelectUser(item._id)}
              >
                <td>{item.uniqueReuserCardId}</td>
                <td>{moment(item.createdAt).format("MM/DD/YYYY")}</td>
                <td>{item.inPossession}</td>
                <td>{item.overdue}</td>
                <td>{item.dueDate}</td>
                <td>{item.totalContainersUsed}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {!loadingData && !data.length && (
        <p className={styles.noData}>{t.user.text_no_reuser_cards}</p>
      )}
      {loadingData && (
        <div className={styles.loading}>
          <Loading width={50} height={50} />
        </div>
      )}
    </div>
  );
};

export default ReusersCardsTable;
