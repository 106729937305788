import React, { useState, useEffect } from "react";
import styles from "../auth.module.scss";
import userService from "../../../_services/user.service";

import logo from "../../../_assets/images/logo.svg";
import logoAuth from "../../../_assets/images/logoAuth.svg";
import toastService from "../../../_services/toast.service";
import { isObjectEmpty } from "../../../_utils/utils";
import { Link } from "react-router-dom";
import t from "../../../_config/constants";
import Loading from "../../loading";
const ConfirmMail = (props) => {
  const confirmEmailData = props.match.params ? props.match.params : {};
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const handleConfirmEmail = async (data) => {
      try {
        let response = await userService.confirmChangeEmail(data);
        if (response) {
          toastService.show("success", "Successfully confirmed email");
          setSuccess(true);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    if (!isObjectEmpty(confirmEmailData)) {
      handleConfirmEmail(confirmEmailData);
    } else {
      setLoading(false);
      toastService.show("warning", t.common.text_something_went_wrong);
    }
  }, [confirmEmailData]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img src={logoAuth} alt="Logo" />
      </div>
      <div className={styles.container}>
        <div>
          <div className={styles.logo}>
            <img src={logo} alt="Logo" />
          </div>
          <h2>Confirm Email Address</h2>
          {loading && <Loading width={50} height={50} />}
          {!loading && success && (
            <div>Successfully confirmed email address.</div>
          )}
          {!loading && !success && (
            <div>Failed to confirm your email address. Please try again.</div>
          )}
          {!loading && (
            <div className={styles.backToLoginDiv}>
              <div className={styles.backToLogin}>
                <Link to="/login">{t.auth.text_back_to_login}</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ConfirmMail;
