import React, { useEffect, useState } from "react";
import styles from "./list.module.scss";
import SearchBar from "../../searchBar/index";

import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import useDebounce from "../../../_utils/debounce";
import { termRegex } from "../../../_utils/utils";
import t from "../../../_config/constants";
import Pagination from "../../pagination";
import ArchivedStoresTable from "../archivedTable";
import DownloadData from "../../restaurant/restaurantsList/downloadData";
const ArchivedStoresList = ({
  refreshList,
  onSelectRestaurant,
  selectedRestaurantId,
  setActiveTab,
  activeTab,
}) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [numberOfRestaurants, setNumberOfRestaurants] = useState(0);
  const [sortConfig, setSortConfig] = useState({});
  const [filterData, setFilterData] = useState({
    searchTerm: "",
    selectedValue: "",
  });

  const debouncedSearchTerm = useDebounce(filterData.searchTerm, 500);

  const onChangeTerm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterData((prevState) => ({ ...prevState, [name]: value }));
  };

  const getFilteredRestaurants = async (
    term,
    id,
    subregionId,
    pageNumber,
    sortConfig = { createdAt: -1 }
  ) => {
    setLoadingData(true);
    let filter = [{ archived: true }];

    if (id && id !== "*") {
      filter.push({
        regionId: id,
      });
    }
    if (subregionId && subregionId !== "*") {
      filter.push({ subregionId });
    }
    if (term) {
      filter.push({
        $or: [
          { name: { $regex: `${termRegex(term)}`, $options: "i" } },
          { email: { $regex: `${termRegex(term)}`, $options: "i" } },
          { fullName: { $regex: `${termRegex(term)}`, $options: "i" } },
        ],
      });
    }
    let data = await dispatch(
      mainActions.run("profiles", "restaurant", "page", {
        filter,
        pageSize,
        pageNumber,
        sort: sortConfig,
      })
    );
    setFilteredRestaurants(data.rows);
    setNumberOfRestaurants(data.total);
    setLoadingData(false);
  };
  useEffect(() => {
    setPageNumber(0);
  }, [debouncedSearchTerm, store.auth && store.auth.user]);
  useEffect(() => {
    getFilteredRestaurants(
      debouncedSearchTerm,
      store.auth.user.regionId,
      store.auth.user.subregionId,
      pageNumber,
      sortConfig
    );
  }, [
    debouncedSearchTerm,
    store.auth && store.auth.user,
    refreshList,
    pageNumber,
    sortConfig,
  ]);

  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };
  const changeSortConfig = (data) => {
    if (data) {
      const { key, direction } = data;
      setSortConfig({ [key]: direction === "ascending" ? 1 : -1 });
    }
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.tabs}>
        <p
          className={
            activeTab === "single" ? styles.tabActive : styles.tabInactive
          }
          onClick={() => setActiveTab("single")}
        >
          {t.restaurant.text_restaurats}
        </p>
        <p
          className={
            activeTab === "groups" ? styles.tabActive : styles.tabInactive
          }
          onClick={() => setActiveTab("groups")}
        >
          {t.restaurant.text_groups}
        </p>
        <p
          className={
            activeTab === "archived" ? styles.tabActive : styles.tabInactive
          }
          onClick={() => setActiveTab("archived")}
        >
          {t.restaurant.text_archived_stores}
        </p>
      </div>
      <h2>{t.restaurant.text_archived_stores}</h2>
      <div className={styles.searchAndFilter}>
        <SearchBar
          customClass="restaurantSearch"
          placeholder={t.restaurant.text_search_restaurants}
          name="searchTerm"
          onChange={onChangeTerm}
          disabled={loadingData}
        />
        <DownloadData
          disabled={!numberOfRestaurants}
          archived={true}
          regionId={store.auth.user.regionId}
        />
      </div>
      <div className={styles.tableWrapper}>
        <ArchivedStoresTable
          restaurants={filteredRestaurants}
          onSelectRestaurant={onSelectRestaurant}
          selectedRestaurantId={selectedRestaurantId}
          loadingData={loadingData}
          changeSortConfig={changeSortConfig}
        />
      </div>
      <div className={styles.pagination}>
        <Pagination
          handlePageClick={onPageChange}
          pageCount={Math.ceil(numberOfRestaurants / pageSize)}
          forcePage={pageNumber}
          loading={loadingData}
        />
      </div>
    </div>
  );
};

export default ArchivedStoresList;
