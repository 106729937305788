export const localStorageService = {
  destroy(type) {
    localStorage.removeItem(type);
  },

  set(type, data) {
    localStorage.setItem(type, JSON.stringify(data));
  },
  get(type) {
    const data = localStorage.getItem(type);
    if (!data) return null;
    const res = JSON.parse(data);
    return res;
  },
  getSessionToken() {
    let data = localStorage.getItem("user");
    if (!data) return null;
    let session = JSON.parse(data);
    return session && session.token ? session.token : null;
  },
  getSessionRefreshToken() {
    let data = localStorage.getItem("user");
    if (!data) return null;
    let session = JSON.parse(data);
    return session && session.refreshToken ? session.refreshToken : null;
  },
  isAuth() {
    const data = localStorage.getItem("user");
    if (!data) {
      return false;
    } else {
      const session = JSON.parse(data);
      return session;
    }
  },
  isMaster() {
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);
    if (!data) {
      return false;
    } else if (data.type == "master") {
      return data;
    } else {
      return false;
    }
  },
  isAdmin() {
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);
    if (!data) {
      return false;
    } else if (
      ["admin", "master"].includes(data.type) &&
      data.subType !== "subregionAdmin"
    ) {
      return data;
    } else {
      return false;
    }
  },
  isSubregionAdmin() {
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);
    if (!data) {
      return false;
    } else if (["subregionAdmin"].includes(data.subType)) {
      return data;
    } else {
      return false;
    }
  },
  isRestaurant() {
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);
    if (!data) {
      return false;
    } else if (data.type == "restaurant") {
      return data;
    } else {
      return false;
    }
  },
  isCleaner() {
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);
    if (!data) {
      return false;
    } else if (data.type == "cleaner") {
      return data;
    } else {
      return false;
    }
  },
  updateUser(newData) {
    localStorage.setItem("user", JSON.stringify(newData));
  },
};
