import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./containerDetails.module.scss";
import Button from "../../../button/index";
import leftArrow from "../../../../_assets/icons/leftArrow.svg";
import { mainActions } from "../../../../_store/actions/main.actions";
import toastService from "../../../../_services/toast.service";
import ConfirmModal from "../../../modal/confirmModal";
import BorrowedList from "./borrowed";
import ReturnedList from "./returned";
import t from "../../../../_config/constants";
import PurchasedList from "./purchased";

const ContainerDetails = ({ id, goBack, handleUpdate }) => {
  const dispatch = useDispatch();

  const [tab, setTab] = useState("borrowed");
  const [showDeleteContainers, setShowDeleteContainers] = useState(false);
  const [selectedContainers, setSelectedContainers] = useState([]);
  const [update, setUpdate] = useState(1);

  const onUpdate = () => {
    setUpdate((prevState) => (prevState === 1 ? 2 : 1));
  };
  const openDeleteContainer = () => {
    setShowDeleteContainers(true);
  };
  const handleActive = (term) => {
    setTab(term);
    setSelectedContainers([]);
  };
  const handleCheckedContainers = (container) => {
    if (selectedContainers.includes(container)) {
      let filtered = selectedContainers.filter((item) => item !== container);
      setSelectedContainers(filtered);
    } else {
      setSelectedContainers((prevState) => [...prevState, container]);
    }
  };
  const mapContainersBatch = (containers) => {
    let map = {};
    containers.map((container) => {
      if (!map[container.batchId]) {
        map[container.batchId] = [];
      }
      map[container.batchId] = [...map[container.batchId], container._id];
    });
    return map;
  };
  const deleteContainers = async (answer, data) => {
    if (answer) {
      let batchMap = mapContainersBatch(data);
      if (batchMap) {
        let showSuccessMsg = true;
        for (let batchId in batchMap) {
          let response = await dispatch(
            mainActions.run("data", "container", "deleteMulti", {
              ids: batchMap[batchId],
              type: "container",
              batchId: batchId,
            })
          );
          if (!response.success) {
            showSuccessMsg = false;
          }
        }
        if (showSuccessMsg) {
          toastService.show(
            "success",
            t.containerManagement.add.text_successfully_deleted_containers
          );
          setShowDeleteContainers(false);
          onUpdate();
          handleUpdate();
          setSelectedContainers([]);
        } else {
          toastService.show("warning", t.common.text_something_went_wrong);
        }
      }
    } else {
      setShowDeleteContainers(false);
    }
  };
  useEffect(() => {
    setSelectedContainers([]);
  }, [id, update]);
  return (
    <div className={styles.wrapper}>
      {showDeleteContainers && (
        <ConfirmModal
          show={showDeleteContainers}
          handleYesNoAnswer={deleteContainers}
          modalData={selectedContainers}
          question={t.containerManagement.add.text_modal_delete_containers}
          confirmLabel="Retire"
        />
      )}

      <div className={styles.buttonsWrapper}>
        <Button
          label={t.button.text_back}
          btnClass="btnBack"
          iconLeft={leftArrow}
          onClick={goBack}
        />
      </div>
      <h1 className={styles.paymentTitle}>{t.common.text_container_details}</h1>
      <div className={styles.containerButtons}>
        <Button
          label={t.common.text_borrowed}
          btnClass={
            tab === "borrowed" ? "tabButtonActive" : "tabButtonInactive"
          }
          onClick={() => handleActive("borrowed")}
        />
        <Button
          label={t.common.text_returned}
          btnClass={
            tab === "returned" ? "tabButtonActive" : "tabButtonInactive"
          }
          onClick={() => handleActive("returned")}
        />
        <Button
          label={t.common.text_th_charged}
          btnClass={
            tab === "purchased" ? "tabButtonActive" : "tabButtonInactive"
          }
          onClick={() => handleActive("purchased")}
        />
      </div>

      {tab === "borrowed" && (
        <BorrowedList
          id={id}
          handleUpdate={handleUpdate}
          update={update}
          handleCheckedContainers={handleCheckedContainers}
          selectedContainers={selectedContainers}
          openDeleteContainer={openDeleteContainer}
          handleNoSelectedContainers={() => setSelectedContainers([])}
        />
      )}
      {tab === "returned" && <ReturnedList id={id} update={update} />}
      {tab === "purchased" && <PurchasedList id={id} />}
    </div>
  );
};

export default ContainerDetails;
