import React, { useRef, useEffect, useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import styles from "./geoinput.module.scss";
import Input from "../../input";

const GeoInput = ({
  onChangeLocation,
  editLocation,
  inputVal,
  name,
  inputClass = "inputForm",
  onSave,
  disabled = false,
  label,
  error = false,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    setIsEdit(editLocation ? true : false);
    if (editLocation) {
      setValue("");
    }
  }, [editLocation]);

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const node = useRef();

  useEffect(() => {
    if (editLocation) {
      setIsEdit(true);
    }
    setValue("");
  }, [onSave]);

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          onChangeLocation(description, { lat, lng });
        })
        .catch((error) => {});
    };

  const handleClickOutside = (e) => {
    if (!node.current.contains(e.target)) {
      clearSuggestions();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEditLocation = () => {
    setIsEdit(false);
    onChangeLocation("", {});
  };

  return (
    <div ref={node} className={styles.wrapper}>
      {!isEdit && (
        <>
          <Input
            label={label ? label : name}
            name={name}
            error={error}
            inputClass={inputClass}
            type="text"
            value={value}
            placeholder="Address"
            onChange={handleInput}
            autofocus={editLocation}
            title="Start typing and select address from dropdown"
            errorMsg="You must select address from dropdown"
          />
          {status === "OK" && (
            <div className={styles.container}>
              {data.map((suggestion) => {
                const {
                  place_id,
                  structured_formatting: { main_text, secondary_text },
                } = suggestion;
                return (
                  <div
                    key={place_id}
                    onClick={handleSelect(suggestion)}
                    className={styles.content}
                  >
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
      {isEdit && (
        <Input
          label={label ? label : name}
          name={name}
          error={error}
          inputClass={inputClass}
          type="text"
          value={inputVal}
          placeholder="Address"
          onChange={handleEditLocation}
          disabled={disabled}
        />
      )}
    </div>
  );
};
export default GeoInput;
