import React, { useState } from "react";
import Button from "../../../../button";
import Input from "../../../../input";
import styles from "./operatorEmails.module.scss";
import t from "../../../../../_config/constants";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../../../_store/actions/main.actions";
import toastService from "../../../../../_services/toast.service";
import { modalActions } from "../../../../../_store/actions/modal.actions";
import trash from "../../../../../_assets/icons/trash.svg";
import validateEmail from "../../../../../_utils/validateEmail";

const OperatorEmails = ({
  locationId,
  locationName,
  currentEmails,
  setRefresh,
}) => {
  const dispatch = useDispatch();
  const [operatorEmailsArr, setOperatorEmailsArr] = useState(
    currentEmails || []
  );
  const [activeEmail, setActiveEmail] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [newChanges, setNewChanges] = useState(false);
  const [error, setError] = useState(false);
  const handleChange = (e) => {
    const { value } = e.target;
    setActiveEmail(value);
    setError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    const response = await dispatch(
      mainActions.run("data", "location", "update", {
        _id: locationId,
        operatorEmails: operatorEmailsArr || [],
      })
    );
    setRefresh((prev) => !prev);
    if (response) {
      toastService.show(
        "success",
        `Successfully updated location operator email list.`
      );
      dispatch(modalActions.closeModal());
    }

    setDisabled(false);
  };

  const removeEmailFromList = (email) => {
    const emails = operatorEmailsArr.filter((e) => e !== email);
    setOperatorEmailsArr(emails);
    setNewChanges(true);
  };

  const addNewOperatorEmail = (email) => {
    const isValid = validateEmail(email);
    if (!isValid) {
      setError(true);
      return;
    }
    setOperatorEmailsArr((prev) => [...prev, email]);
    setActiveEmail("");
    setNewChanges(true);
  };

  return (
    <div className={styles.wrapper}>
      <h2>{locationName} Operator Emails</h2>
      <form onSubmit={handleSubmit}>
        {operatorEmailsArr.map((email, ind) => (
          <div className={styles.addedEmails}>
            <div>
              <span>{ind + 1}.</span> {email}
            </div>
            <img
              src={trash}
              alt="Remove email"
              className={styles.deleteButton}
              onClick={() => removeEmailFromList(email)}
            />
          </div>
        ))}
        <div className={styles.newEmail}>
          {" "}
          <Input
            label={"Add new email address"}
            name="email"
            inputClass="inputForm"
            type="email"
            value={activeEmail.trim()}
            placeholder={t.common.input.text_input_email_address}
            onChange={handleChange}
            error={error}
            errorMsg={"Invalid email address"}
          />{" "}
          <Button
            btnClass="btnWhiteBackground"
            label={t.button.text_add}
            type="button"
            onClick={() => {
              addNewOperatorEmail(activeEmail);
            }}
            disabled={disabled || !activeEmail}
          />
        </div>

        <div className={styles.buttons}>
          <Button
            btnClass="btnWhiteBackground"
            label={t.button.text_cancel}
            type="button"
            onClick={() => {
              dispatch(modalActions.closeModal());
            }}
            disabled={disabled}
          />
          <Button
            btnClass="btnNormal"
            label={t.button.text_save}
            type="submit"
            disabled={disabled || !newChanges}
          />
        </div>
      </form>
    </div>
  );
};
export default OperatorEmails;
