import QRCode from "qrcode";
import { qrRedirectUrl } from "../../../environment";

export const qrFunction = async (
  QRdimension = 500,
  id,
  uniqueId = "uniqueId",
  batchId,
  zip
) => {
  let response = "";
  try {
    response = await QRCode.toDataURL(
      `${qrRedirectUrl}?container=${id + ""}&batch=${batchId}`,
      { width: QRdimension, height: QRdimension, margin: 0 }
    );
  } catch (error) {
    console.log(error);
  }

  let fileName = uniqueId.split("/").join("-") + ".png";
  function resizedataURL(datas, QRdimension) {
    return new Promise(async function (resolve, reject) {
      let img = document.createElement("img");
      img.onload = function () {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        ctx.font = `${QRdimension / 6}px Helvetica`;
        const textWidth = ctx.measureText(uniqueId).width;
        const canvasWidth = textWidth > QRdimension ? textWidth : QRdimension;
        canvas.width = canvasWidth;
        canvas.height = QRdimension * 1.5;
        ctx.font = `${QRdimension / 6}px Helvetica`;

        ctx.fillText(
          "Scan to return",
          (canvas.width - QRdimension) / 2,
          QRdimension / 4 - (6 * QRdimension) / 100,
          QRdimension
        );

        ctx.drawImage(
          this,
          (canvas.width - QRdimension) / 2,
          QRdimension / 4,
          QRdimension,
          QRdimension
        );
        ctx.textAlign = "center";
        ctx.font = `${QRdimension / 6}px Helvetica`;
        ctx.fillText(
          uniqueId,
          canvasWidth / 2,
          QRdimension * 1.5 - (6 * QRdimension) / 100,
          canvas.width
        );
        let dataURI = canvas.toDataURL();
        resolve(dataURI);
      };
      img.src = datas;
    });
  }
  let newDataURI = await resizedataURL(response, QRdimension);
  let imgBase64 = newDataURI.split("base64,");
  return zip.file(fileName, imgBase64[1], { base64: true });
};
