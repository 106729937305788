import React, { useEffect } from "react";
import styles from "./restaurantsTable.module.scss";
import statusImage from "../../../_assets/icons/exclamation-mark.svg";
import Loading from "../../loading";
import Button from "../../button";
import arrowUp from "../../../_assets/icons/arrowUp.svg";
import arrowDown from "../../../_assets/icons/arrowDown.svg";
import upDownArrow from "../../../_assets/icons/upDownArrow.svg";
import useSortableData from "../../../_utils/useSortableData";
import t from "../../../_config/constants";
import { FcCheckmark } from "react-icons/fc";
const RestaurantsTable = ({
  restaurants = [],
  onSelectRestaurant,
  selectedRestaurantId,
  loadingData,
  changeSortConfig,
}) => {
  const { items, handleSort, sortConfig } = useSortableData(restaurants);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return upDownArrow;
    }
    return sortConfig.key === name
      ? sortConfig.direction === "ascending"
        ? arrowUp
        : arrowDown
      : upDownArrow;
  };
  useEffect(() => {
    changeSortConfig(sortConfig);
  }, [sortConfig]);

  const noRestaurantsMsg = (
    <p className={styles.noRestaurantsMsg}>
      {t.restaurant.text_no_restaurants}
    </p>
  );
  return (
    <div className={styles.tableWrapper}>
      <table>
        <thead>
          <tr>
            <th onClick={() => (loadingData ? null : handleSort("name"))}>
              {t.restaurant.text_th_name}
              <Button btnClass="btnBack" iconLeft={getClassNamesFor("name")} />
            </th>
            <th
              onClick={() =>
                loadingData ? null : handleSort("totalContainers")
              }
            >
              {t.restaurant.text_th_amount}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("totalContainers")}
              />
            </th>
            <th onClick={() => (loadingData ? null : handleSort("status"))}>
              {t.restaurant.text_th_status}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("status")}
              />
            </th>
            <th onClick={() => (loadingData ? null : handleSort("visible"))}>
              Visible
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("visible")}
              />
            </th>
            <th onClick={() => (loadingData ? null : handleSort("hasCard"))}>
              Card
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("hasCard")}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {!loadingData &&
            restaurants.length > 0 &&
            items.map((item) => {
              return (
                <tr
                  key={item._id}
                  className={
                    selectedRestaurantId === item._id ? styles.selected : ""
                  }
                  onClick={() => onSelectRestaurant(item._id)}
                >
                  <td>
                    <span className={styles.draft}>
                      {item.isDraft ? "[Draft] " : ""}
                    </span>
                    {item.name}
                  </td>
                  <td>{item.totalContainers > 0 ? item.totalContainers : 0}</td>
                  <td>
                    {item.status != undefined && item.status === false ? (
                      <img src={statusImage} />
                    ) : null}
                  </td>
                  <td>
                    {item.visible != undefined && item.visible === true ? (
                      <div className={styles.visible}>
                        <FcCheckmark />
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {item.hasCard != undefined && item.hasCard === true ? (
                      <div className={styles.visible}>
                        <FcCheckmark />
                      </div>
                    ) : null}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {!loadingData && !restaurants.length && noRestaurantsMsg}
      {loadingData && (
        <div className={styles.loading}>
          <Loading width={50} height={50} />
        </div>
      )}
    </div>
  );
};

export default RestaurantsTable;
