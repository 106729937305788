import React, { useState, useEffect } from "react";
import styles from "./notifications.module.scss";
import Searchbar from "../../_components/searchBar/index";
import Selector from "../../_components/selector/index";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../_store/actions/main.actions";
import useDebounce from "../../_utils/debounce";
import { termRegex } from "../../_utils/utils";
import moment from "moment";
import WithImages from "../../_components/withImages";
import Loading from "../../_components/loading";
import t from "../../_config/constants";
import Pagination from "../../_components/pagination";
import { BiEnvelopeOpen } from "react-icons/bi";
const Notifications = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({
    searchTerm: "",
    selectedValue: "",
  });
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [numberOfNotifications, setNumberOfNotifications] = useState(0);
  const debouncedSearchTerm = useDebounce(filterData.searchTerm, 500);
  const onChangeTerm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSelectFilter = (e) => {
    setFilterData((filter) => ({
      ...filter,
      selectedValue: e.value,
    }));
  };

  const getNotificationsUpdate = async () => {
    let filter = [];
    if (store?.auth?.user?.regionId && store?.auth?.user?.regionId !== "*") {
      filter.push({
        regionId: store?.auth?.user?.regionId,
        seen: false,
      });
      if (store?.auth?.user?.subregionId !== "*")
        filter.push({ subregionId: store?.auth?.user?.subregionId });
    } else {
      filter.push({
        seen: false,
      });
    }
    await dispatch(
      mainActions.run("notifications", "notification", "all", { filter })
    );
  };

  const getNotifications = async (
    term,
    selectValue,
    id,
    subregionId,
    pageNumber
  ) => {
    let filter = [];
    if (id && id !== "*") {
      filter.push({
        regionId: id,
      });
    }
    if (subregionId && subregionId !== "*") {
      filter.push({
        subregionId,
      });
    }
    if (term) {
      filter.push({
        $or: [
          { restaurantName: { $regex: `${termRegex(term)}`, $options: "i" } },
          { text: { $regex: `${termRegex(term)}`, $options: "i" } },
        ],
      });
    }
    if (selectValue) {
      filter.push({
        $or: [
          { restaurantName: { $regex: `${termRegex(term)}`, $options: "i" } },
          { text: { $regex: `${termRegex(term)}`, $options: "i" } },
        ],
        notificationType: { $eq: selectValue },
      });
    }
    let data = await dispatch(
      mainActions.run("notifications", "notification", "page", {
        filter,
        pageSize,
        pageNumber,
        sort: { createdAt: -1 },
      })
    );
    if (!data) data = { rows: [], total: 0 };

    setNumberOfNotifications(data.total);
    setData(data.rows);
    setLoading(false);
  };
  useEffect(() => {
    setPageNumber(0);
  }, [
    debouncedSearchTerm,
    filterData.selectedValue,
    store.auth && store.auth.user,
  ]);

  useEffect(() => {
    setLoading(true);
    setData([]);
    getNotifications(
      debouncedSearchTerm,
      filterData.selectedValue,
      store.auth.user.regionId,
      store.auth.user.subregionId,

      0
    );
  }, [
    debouncedSearchTerm,
    filterData.selectedValue,
    store.auth && store.auth.user,
  ]);
  useEffect(() => {
    setLoading(true);
    setData([]);
    getNotifications(
      debouncedSearchTerm,
      filterData.selectedValue,
      store.auth.user.regionId,
      store.auth.user.subregionId,
      pageNumber
    );
  }, [pageNumber]);
  const handleAllRead = async (data) => {
    let query = {};
    if (store?.auth?.user?.regionId && store?.auth?.user?.regionId !== "*") {
      query["regionId"] = store?.auth?.user?.regionId;
    }

    await dispatch(
      mainActions.run("notifications", "notification", "updateMulti", {
        query,
        update: { seen: true },
      })
    );
    getNotificationsUpdate();
    const readArr = data.map((item) => ({ ...item, seen: true }));
    setData(readArr);
  };

  const selectOptions = [
    { value: "", label: "All" },
    {
      value: "inventory_inaccuracy",
      label: t.notifications.selectOptions.text_inventory_inaccuracy,
    },
    {
      value: "request_more",
      label: t.notifications.selectOptions.text_request_more,
    },
    {
      value: "containers_missing",
      label: t.notifications.selectOptions.text_containers_missing,
    },
    {
      value: "transaction_inaccuracy",
      label: t.notifications.selectOptions.text_transaction_inaccuracy,
    },
    { value: "other", label: t.notifications.selectOptions.text_other },
  ];

  const statusGenerator = (status) => {
    let newStatus;
    switch (status) {
      case "inventory_inaccuracy":
        newStatus = "Inventory Inaccuracy";
        break;
      case "request_more":
        newStatus = "Need More Containers";
        break;
      case "containers_missing":
        newStatus = "Containers Missing";
        break;
      case "transaction_inaccuracy":
        newStatus = "Transaction Inaccuracy";
        break;
      default:
        newStatus = status;
        break;
    }
    return newStatus;
  };
  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1>{t.notifications.text_notifications}</h1>
        <div className={styles.selectAndSearch}>
          <div className={styles.searchBar}>
            <Searchbar
              customClass="transactionSearch"
              placeholder={t.button.text_search}
              name="searchTerm"
              value={filterData.searchTerm}
              onChange={onChangeTerm}
              disabled={loading}
            />
          </div>
          <div className={styles.selector}>
            <Selector
              placeholder={t.button.text_filter}
              multiselect={false}
              selectClassname="selectSecondary"
              options={selectOptions}
              handleChangeSelect={(e) => handleSelectFilter(e)}
              selectedOption={selectOptions.value}
              disabled={loading}
            />
          </div>
        </div>
      </div>
      <div className={styles.notifications}>
        {
          <div className={styles.icon} onClick={() => handleAllRead(data)}>
            Mark all as read <BiEnvelopeOpen />
          </div>
        }
        <div>
          {loading && <Loading width={50} height={50} />}
          {!loading &&
            data &&
            data.length > 0 &&
            data.map((item) => {
              return (
                <div
                  className={`${styles[item.seen ? "row" : "unread"]} `}
                  key={item._id}
                >
                  <span>
                    {t.common.text_th_created}{" "}
                    {moment(item.createdAt).format("MM/DD/YYYY")}
                  </span>
                  <div className={styles.item}>
                    <WithImages id={item.restaurantId} />
                    <p>
                      {item.restaurantName} -{" "}
                      {item.notificationType !== "other"
                        ? statusGenerator(item.notificationType)
                        : item.text}
                    </p>
                  </div>
                </div>
              );
            })}
          {!loading && !data.length && (
            <div>
              <p>{t.notifications.text_no_notifications}</p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.pagination}>
        <Pagination
          handlePageClick={onPageChange}
          pageCount={Math.ceil(numberOfNotifications / pageSize)}
          forcePage={pageNumber}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Notifications;
