import React, { useEffect, Suspense, useState } from "react";
import { Router } from "react-router-dom";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "./App.scss";
import MainView from "./_routes/mainView";
import { history } from "./_utils";

const App = () => {
  const [path, setPath] = useState(history.location.pathname);

  useEffect(() => {
    history.listen((location) => {
      setPath(location.pathname);
    });
  }, [history.location.pathname]);

  return (
    <div className="App">
      <Suspense fallback={"add loader here"}>
        <main className="main-wrapper">
          <Router history={history}>
            <MainView path={path} />
          </Router>
        </main>
      </Suspense>
    </div>
  );
};

export default App;
