import React, { useEffect, useState } from "react";
import styles from "../settings.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";

import Selector from "../../selector";
import Loading from "../../loading";
import PrivacyContainer from "./privacyContainer";
import ConfirmModal from "../../modal/confirmModal";

import {
  isObjectEmpty,
  makeSelectMap,
  appOptions,
} from "../../../_utils/utils";
import t from "../../../_config/constants";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);
  const [privacyPolicy, setPrivacyPolicy] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [privacyObject, setPrivacyObject] = useState({});
  const [error, setError] = useState(false);
  const [regionId, setRegionId] = useState();
  const [regionOptions, setRegionOptions] = useState([]);
  const [appType, setAppType] = useState("user");
  const [refetch, setRefetch] = useState(true);
  const [editingSectionInd, setEditingSectionInd] = useState(null);
  const [loading, setLoading] = useState(false);
  const regionList = useSelector((state) => state?.main?.data?.region?.list);

  useEffect(() => {
    if (regionList && regionList.length) {
      const arr = makeSelectMap(regionList);
      setRegionOptions(arr);
    }
  }, [regionList]);
  useEffect(() => {
    setPrivacyPolicy([]);
    setPrivacyObject({});
    if (user?.regionId && user?.regionId !== "*") {
      setRegionId(user.regionId);
    } else {
      setRegionId("");
    }
  }, [user]);
  useEffect(() => {
    const fetchPrivacyPolicy = async (regionId, appType) => {
      const response = await dispatch(
        mainActions.run("constants", "privacyPolicy", "get", {
          filter: [{ regionId, app: appType }],
        })
      );
      setPrivacyObject(response || {});
      setPrivacyPolicy(response.data || []);
      setLoading(false);
    };
    if (regionId && appType) {
      setEditingSectionInd(null);
      setError(false);
      setLoading(true);
      fetchPrivacyPolicy(regionId, appType);
    }
  }, [regionId, appType, refetch]);

  const handleAddSection = () => {
    const ind = privacyPolicy.length;
    setPrivacyPolicy((prev) => [
      ...prev,
      [
        { type: "heading", text: "" },
        { type: "subheading", text: "" },
        { type: "subsubheading", text: "" },
        { type: "baseText", text: "" },
      ],
    ]);
    setEditingSectionInd(ind);
  };

  const handleDeleteSection = async (titleInd) => {
    const deleteSection = privacyPolicy[titleInd];
    if (deleteSection.find((item) => item.type !== "heading" && item.text)) {
      return;
    }
    privacyPolicy.splice(titleInd, 1);
    setEditingSectionInd(null);

    if (!isObjectEmpty(privacyObject)) {
      const response = await dispatch(
        mainActions.run("constants", "privacyPolicy", "update", {
          _id: privacyObject._id,
          data: [...privacyPolicy],
        })
      );
      setPrivacyObject(response || {});
      setPrivacyPolicy(response?.data || []);
    } else {
      setPrivacyPolicy([...privacyPolicy]);
    }
  };
  const handleDeleteField = async (answer, { titleInd, ind }) => {
    setShowModal(false);
    if (answer) {
      if (!privacyPolicy[titleInd]) return;
      privacyPolicy[titleInd].splice(ind, 1);
      setPrivacyPolicy([...privacyPolicy]);
    }
    setModalData();
  };

  const handleAddField = (titleInd, fieldType) => {
    privacyPolicy[titleInd].push({ type: fieldType, text: "" });
    setPrivacyPolicy([...privacyPolicy]);
  };
  const deleteField = (titleInd, ind) => {
    if (!privacyPolicy[titleInd]) return;
    const section = privacyPolicy[titleInd][ind];
    if (section?.text) {
      setShowModal(true);
      setModalData({ titleInd, ind });
      return;
    }
    privacyPolicy[titleInd].splice(ind, 1);
    setPrivacyPolicy([...privacyPolicy]);
  };
  const handleChangeHeading = (e, headingInd) => {
    const { value, name } = e.target;
    privacyPolicy[headingInd].find((item) => item.type === name).text = value;
    setPrivacyPolicy([...privacyPolicy]);
    setError(false);
  };
  const handleSelect = (e, type) => {
    if (type === "app") {
      setAppType(e.value);
    } else {
      setRegionId(e.value);
    }
  };
  const handleChange = (e, titleInd, fieldInd) => {
    setError(false);
    const { value } = e.target;
    privacyPolicy[titleInd][fieldInd].text = value;
    setPrivacyPolicy([...privacyPolicy]);
  };
  const handleSubmit = async (titleInd) => {
    const section = privacyPolicy[titleInd];
    if (
      regionId &&
      appType &&
      section.length &&
      !section?.find((item) => !item.type || !item.text)
    ) {
      setLoading(true);
      const objectForDb = {};
      objectForDb.data = [...privacyPolicy];
      setEditingSectionInd(null);
      if (isObjectEmpty(privacyObject)) {
        objectForDb.regionId = regionId;
        objectForDb.app = appType;
      } else {
        objectForDb._id = privacyObject._id;
      }
      const response = await dispatch(
        mainActions.run(
          "constants",
          "privacyPolicy",
          objectForDb?._id ? "update" : "new",
          objectForDb
        )
      );
      setPrivacyObject(response || {});
      setPrivacyPolicy(response?.data || []);
      setLoading(false);
    } else {
      setError(true);
    }
  };
  const handleCancel = () => {
    setEditingSectionInd(null);
    setRefetch((prev) => !prev);
    setError(false);
  };

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };

  return (
    <div className={styles.wrapper}>
      <h3>{t.app_settings.privacy_policy}</h3>
      {showModal && (
        <ConfirmModal
          show={showModal}
          handleYesNoAnswer={handleDeleteField}
          modalData={modalData}
          question="Are you sure you want to delete this field?"
        />
      )}
      {!user.regionId ||
        (user.regionId === "*" && (
          <Selector
            options={regionOptions}
            placeholder={"Select Region"}
            handleChangeSelect={(e) => handleSelect(e)}
            selectClassname="selectSecondaryWhiteBg"
            disabled={loading}
          />
        ))}
      <Selector
        options={appOptions}
        handleChangeSelect={(e) => handleSelect(e, "app")}
        selectedOption={getSelectedValue(appOptions, appType)}
        selectClassname="selectSecondaryWhiteBg"
        disabled={loading}
      />
      {!loading && (
        <div className={styles.content}>
          {privacyPolicy.map((item, ind) => (
            <PrivacyContainer
              key={ind}
              titleInd={ind}
              data={item}
              handleAddField={handleAddField}
              deleteSection={handleDeleteSection}
              deleteField={deleteField}
              handleChangeHeading={handleChangeHeading}
              handleChange={handleChange}
              editingSectionInd={editingSectionInd}
              setEditingSectionInd={setEditingSectionInd}
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
              errorMsg={error}
            />
          ))}
          {editingSectionInd === null && regionId && (
            <div className={styles.addSection} onClick={handleAddSection}>
              <span>+</span>
              <p>Add Section</p>
            </div>
          )}
        </div>
      )}
      {loading && <Loading height={60} width={60} />}
    </div>
  );
};

export default PrivacyPolicy;
