import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import userDefault from "../../_assets/icons/user.svg";
import { mainActions } from "../../_store/actions/main.actions";
import Avatar from "../avatar";
import styles from "./withImages.module.scss";

const avatarMap = {};

const WithImages = ({ id }) => {
  const dispatch = useDispatch();
  const [loadingImg, setLoadingImg] = useState(true);
  const [avatarImg, setAvatarImg] = useState(
    avatarMap[id] ? avatarMap[id] : userDefault
  );

  const getAvatar = async (id) => {
    const res = await dispatch(
      mainActions.run("profiles", "restaurant", "id", id)
    );
    if (res && res.avatarURL) {
      setAvatarImg(res.avatarURL);
      avatarMap[id] = res.avatarURL;
    }
  };
  useEffect(() => {
    if (!avatarMap[id] && id) {
      setLoadingImg(true);
      getAvatar(id);
    }
  }, []);
  return (
    <div className={styles.wrapper}>
      <Avatar
        avatarClass={"medium"}
        imgUrl={avatarImg}
        loadingImg={loadingImg}
        setLoadingImg={setLoadingImg}
      />
    </div>
  );
};

export default WithImages;
