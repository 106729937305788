import { authConstants } from "../constants";
import UserService from "../../_services/user.service";
import { history } from "../../_utils";
import { localStorageService } from "../../_services/localStorage.service";
import toastService from "../../../src/_services/toast.service";

const login = (data) => {
  const request = () => {
    return { type: authConstants.LOGIN_REQUEST };
  };
  const success = (user) => {
    return { type: authConstants.LOGIN_SUCCESS, user };
  };
  const failure = (error) => {
    return { type: authConstants.LOGIN_FAILURE, error };
  };

  return (dispatch) => {
    dispatch(request());
    return UserService.login(data).then(
      (user) => {
        if (user) {
          localStorageService.set("user", user);
          dispatch(success(user));
          return user;
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const registerAdmin = (data) => {
  const request = () => {
    return { type: authConstants.ADMIN_REGISTER_REQUEST };
  };
  const success = (user) => {
    return { type: authConstants.ADMIN_REGISTER_SUCCESS, user };
  };
  const failure = (error) => {
    return { type: authConstants.ADMIN_REGISTER_FAILURE, error };
  };

  return (dispatch) => {
    dispatch(request());
    return UserService.registerAdmin(data).then(
      (user) => {
        if (user) {
          dispatch(success(user));
          return user;
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const resetLink = (data) => {
  const request = () => {
    return { type: authConstants.RESET_LINK_REQUEST };
  };
  const success = () => {
    return { type: authConstants.RESET_LINK_SUCCESS };
  };
  const failure = (error) => {
    return { type: authConstants.RESET_LINK_FAILURE, error };
  };
  return (dispatch) => {
    dispatch(request());
    UserService.resetLink(data).then(
      () => {
        dispatch(success());
        toastService.show("success", "Email sent successfully");
        history.push("/login");
        return true;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const forgotPassword = (data) => {
  const request = () => {
    return { type: authConstants.FORGOT_PASSWORD_REQUEST };
  };
  const success = () => {
    return { type: authConstants.FORGOT_PASSWORD_SUCCESS };
  };
  const failure = (error) => {
    return { type: authConstants.FORGOT_PASSWORD_FAILURE, error };
  };

  return (dispatch) => {
    dispatch(request());

    UserService.forgotPassword(data).then(
      () => {
        dispatch(success());
        toastService.show("success", "Password is successfully changed");
        history.push("/");
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const changePassword = (data) => {
  const request = () => {
    return { type: authConstants.CHANGE_PASSWORD_REQUEST };
  };
  const success = (user) => {
    return { type: authConstants.CHANGE_PASSWORD_SUCCESS, user };
  };
  const failure = (error) => {
    return { type: authConstants.CHANGE_PASSWORD_FAILURE, error };
  };
  return (dispatch) => {
    dispatch(request());
    return UserService.changePassword(data).then(
      (user) => {
        if (user) {
          localStorageService.set("user", user);
          dispatch(success(user));
        }
        return user;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const confirmEmail = (data) => {
  const request = () => {
    return { type: authConstants.CHANGE_EMAIL_REQUEST };
  };
  const success = (user) => {
    return { type: authConstants.CHANGE_EMAIL_SUCCESS, user };
  };
  const failure = (error) => {
    return { type: authConstants.CHANGE_EMAIL_FAILURE, error };
  };
  return (dispatch) => {
    dispatch(request());

    UserService.changeEmail(data).then(
      () => {
        dispatch(success());
        toastService.show("success", "Email is successfully confirmed.");
        history.push("/");
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};
const logout = () => {
  localStorageService.destroy("user");
  sessionStorage.clear();
  history.push("/");
  return { type: authConstants.LOGOUT };
};

const updateUser = (data) => {
  const request = () => {
    return { type: authConstants.UPDATE_USER_REQUEST };
  };
  const success = (user) => {
    return { type: authConstants.UPDATE_USER_SUCCESS, user };
  };
  const failure = (error) => {
    return { type: authConstants.UPDATE_USER_FAILURE, error };
  };

  return (dispatch) => {
    dispatch(request());
    return UserService.updateUser(data).then(
      (user) => {
        if (user) {
          localStorageService.set("user", user);
          dispatch(success(user));
        }
        return user;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const registerRestaurant = (data) => {
  const request = () => {
    return { type: authConstants.RESTAURANT_REGISTER_REQUEST };
  };
  const success = (restaurant) => {
    return { type: authConstants.RESTAURANT_REGISTER_SUCCESS, restaurant };
  };
  const failure = (error) => {
    return { type: authConstants.RESTAURANT_REGISTER_FAILURE, error };
  };

  return (dispatch) => {
    dispatch(request());
    return UserService.registerRestaurant(data).then(
      (restaurant) => {
        if (restaurant) {
          dispatch(success(restaurant));
          return restaurant;
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};
const saveRestaurantDraft = (data) => {
  const request = () => {
    return { type: authConstants.RESTAURANT_REGISTER_REQUEST };
  };
  const success = (restaurant) => {
    return { type: authConstants.RESTAURANT_REGISTER_SUCCESS, restaurant };
  };
  const failure = (error) => {
    return { type: authConstants.RESTAURANT_REGISTER_FAILURE, error };
  };

  return (dispatch) => {
    dispatch(request());
    return UserService.saveRestaurantDraft(data).then(
      (restaurant) => {
        if (restaurant) {
          dispatch(success(restaurant));
          return restaurant;
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const registerCleaner = (data) => {
  const request = () => {
    return { type: authConstants.CLEANER_REGISTER_REQUEST };
  };
  const success = (cleaner) => {
    return { type: authConstants.CLEANER_REGISTER_SUCCESS, cleaner };
  };
  const failure = (error) => {
    return { type: authConstants.CLEANER_REGISTER_FAILURE, error };
  };

  return (dispatch) => {
    dispatch(request());
    return UserService.registerCleaner(data).then(
      (cleaner) => {
        if (cleaner) {
          dispatch(success(cleaner));
          return cleaner;
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};
export const userActions = {
  login,
  logout,
  changePassword,
  resetLink,
  forgotPassword,
  confirmEmail,
  registerAdmin,
  updateUser,
  registerRestaurant,
  saveRestaurantDraft,
  registerCleaner,
};
