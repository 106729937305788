import React, { useState, useEffect } from "react";
import styles from "./regionManagement.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import toastService from "../../../_services/toast.service";

import Button from "../../button";
import Input from "../../input";
import Textarea from "../../textarea";
import Loading from "../../loading";
import ConfirmModal from "../../modal/confirmModal";
import Selector from "./../../selector";

import t from "./../../../_config/constants";
import trimObjectProps from "../../../_utils/trimObjectProps";
import {
  chargingOptions,
  countryOptions,
  dummyCurrencies,
} from "../../../_utils/utils";

const RegionManagement = ({
  onRegionClick,
  onSubRegionClick,
  onAdminClick,
  onNotificationsClick,
  onPostalCodesClick,
  activeTab,
}) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [loading, setLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [error, setError] = useState({
    name: false,
    shortName: false,
    description: false,
    currency: false,
    days_to_return_premium: false,
    days_to_return: false,
    tax_rate: false,
    chargingOption: false,
    country: false,
  });
  const [errorMsg, setErrorMsg] = useState(false);
  const [editing, setEditing] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalData, setModalData] = useState({});
  const [disabled, setDisabled] = useState(false);

  const getAllRegions = async () => {
    let response = await dispatch(mainActions.run("data", "region", "all", {}));
    setRegions(response);
    setLoading(false);
  };

  useEffect(() => {
    if (
      store.main &&
      store.main.data &&
      store.main.data.region &&
      store.main.data.region.list
    ) {
      setRegions(store.main.data.region.list);
    }
    setLoading(false);
  }, [store.main.data && store.main.data.region]);

  const [selected, setSelected] = useState({
    name: "",
    shortName: "",
    description: "",
    currency: "",
    days_to_return_premium: "",
    days_to_return: "",
    tax_rate: "",
    chargingOption: "",
    notifications: {},
    country: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    setError((error) => ({ ...error, [name]: !value }));
    setErrorMsg(false);
    let { name, value } = e.target;
    if (
      (name === "days_to_return_premium" ||
        name === "days_to_return" ||
        name === "tax_rate") &&
      value.slice(0, 1) === "0"
    ) {
      value = value.slice(1);
    }
    setSelected((selected) => ({ ...selected, [name]: value }));
  };

  const handleSelectEdit = (item) => {
    setEditing(true);
    setSelected(item);
  };

  const handleCancel = () => {
    setEditing(false);
    setError({
      name: false,
      shortName: false,
      description: false,
      currency: false,
      days_to_return_premium: false,
      days_to_return: false,
      tax_rate: false,
      chargingOption: false,
      country: false,
    });
    setSelected({
      name: "",
      shortName: "",
      description: "",
      currency: "",
      days_to_return_premium: "",
      days_to_return: "",
      tax_rate: "",
      chargingOption: "",
      notifications: {},
      country: "",
    });
    setErrorMsg(false);
  };
  const updateRestaurants = async (restaurant) => {
    await dispatch(
      mainActions.run("profiles", "restaurant", "update", {
        ...restaurant,
        currency: selected.currency,
      })
    );
  };
  const handleSubmit = async () => {
    const validForm = await validateForm();
    if (validForm) {
      setDisabled(true);
      let response = await dispatch(
        mainActions.run("data", "region", editing ? "update" : "new", selected)
      );
      if (response) {
        toastService.show("success", t.toast.text_successfully_submited);
        getAllRegions();
        handleCancel();
        if (editing) {
          let restaurants = await dispatch(
            mainActions.run("profiles", "restaurant", "all", {
              filter: [{ regionId: selected._id }],
            })
          );
          restaurants.forEach((restaurant) => updateRestaurants(restaurant));
        }
      }
    } else {
      toastService.show("error", `${t.common.text_toast_all_fields_required}`);
      setErrorMsg(true);
    }
    setDisabled(false);
  };

  const validateForm = async () => {
    trimObjectProps(selected);
    const err = {
      name: !selected.name,
      shortName: !selected.shortName,
      description: !selected.description,
      currency: !selected.currency,
      days_to_return_premium: !selected.days_to_return_premium,
      days_to_return: !selected.days_to_return,
      tax_rate: !selected.tax_rate,
      chargingOption: !selected.chargingOption,
      country: !selected.country,
    };
    const nameExists = await dispatch(
      mainActions.run("data", "region", "get", {
        filter: [{ _id: { $ne: selected._id } }, { name: selected.name }],
      })
    );
    const shortNameExists = await dispatch(
      mainActions.run("data", "region", "get", {
        filter: [
          { _id: { $ne: selected._id } },
          { shortName: selected.shortName },
        ],
      })
    );
    if (nameExists._id || shortNameExists._id) {
      toastService.show(
        "error",
        `Region name and/or short name already in use. Please choose a different name.`
      );
      setError({
        ...err,
        shortName: !!shortNameExists._id,
        name: !!nameExists._id,
      });
      return false;
    }
    setError(err);
    return (
      selected.name &&
      selected.shortName &&
      selected.description &&
      selected.currency &&
      selected.days_to_return &&
      selected.days_to_return_premium &&
      selected.tax_rate &&
      selected.country &&
      (selected.chargingOption === "penalties" ||
        (selected.chargingOption === "full" && selected.refund_period > 0))
    );
  };

  const openDeleteModal = async (e) => {
    e.preventDefault();
    setShowModalDelete(true);
    setModalData(selected);
  };

  const handleDelete = async (answer, data) => {
    if (answer) {
      await dispatch(
        mainActions.run(
          "data",
          "region",
          "delete",
          { _id: data._id, type: "region" },
          { message: "Successfully deleted region" }
        )
      );
      getAllRegions();
      handleCancel();
      setShowModalDelete(false);
      setModalData({});
    } else {
      setShowModalDelete(false);
    }
  };
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const handleSelect = (e, name) => {
    setError((error) => ({ ...error, [name]: false }));
    setErrorMsg(false);
    setSelected((data) => ({
      ...data,
      [name]: e.value,
    }));
  };
  const handleInputNumber = (e) => {
    const numbers = "0123456789";
    if (!numbers.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className={styles.wrapper}>
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={handleDelete}
          modalData={modalData}
          question={t.admin.regions.text_modal_delete}
        />
      )}
      <div className={styles.left}>
        <div className={styles.header}>
          <p
            className={
              activeTab === "region" ? styles.tabActive : styles.tabInactive
            }
            onClick={onRegionClick}
          >
            {t.admin.regions.text_regions}
          </p>
          <p
            className={
              activeTab === "subregion" ? styles.tabActive : styles.tabInactive
            }
            onClick={onSubRegionClick}
          >
            {t.admin.regions.text_subregions}
          </p>
          <p
            className={
              activeTab === "codes" ? styles.tabActive : styles.tabInactive
            }
            onClick={onPostalCodesClick}
          >
            {t.admin.regions.text_region_postal_codes}
          </p>
          <p
            className={
              activeTab === "notifications"
                ? styles.tabActive
                : styles.tabInactive
            }
            onClick={onNotificationsClick}
          >
            {t.admin.regions.text_region_notifications}
          </p>
          <p
            className={
              activeTab === "admin" ? styles.tabActive : styles.tabInactive
            }
            onClick={onAdminClick}
          >
            {t.admin.admins.text_admins}
          </p>
        </div>
        <h2>{t.admin.regions.text_list_of_regions}</h2>
        {loading && <Loading width={50} height={50} />}
        {regions && regions.length > 0 && (
          <div className="custom-table">
            <table>
              <thead>
                <tr className="tb-head-th">
                  <th>{t.admin.regions.text_th_name}</th>
                  <th>{t.admin.regions.text_input_short_name}</th>
                  <th>{t.admin.regions.text_th_description}</th>
                  <th>{t.admin.regions.text_th_currency}</th>
                  <th>{t.admin.regions.text_th_return_days_premium}</th>
                  <th>{t.admin.regions.text_th_return_days_free}</th>
                  <th>PST</th>
                </tr>
              </thead>
              <tbody>
                {regions?.map((item) => (
                  <React.Fragment key={item._id}>
                    <tr
                      onClick={() => handleSelectEdit(item)}
                      className={
                        selected._id === item._id ? styles.selected : ""
                      }
                    >
                      <td>{item.name}</td>
                      <td>{item.shortName}</td>
                      <td>{item.description}</td>
                      <td>
                        {item.currency === "usd"
                          ? t.currency.text_usd
                          : t.currency.text_can}
                      </td>
                      <td>{item.days_to_return_premium}</td>
                      <td>{item.days_to_return}</td>
                      <td>{item.tax_rate}%</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {!loading && regions.length < 1 && (
          <p>{t.admin.regions.text_no_regions}</p>
        )}
      </div>
      <div className={styles.right}>
        <h2>
          {editing
            ? t.admin.regions.text_edit
            : t.admin.regions.text_create_new}
        </h2>
        <div className={styles.content}>
          <Input
            label={t.admin.regions.text_input_name}
            error={error.name}
            name="name"
            inputClass="inputLogin"
            type="text"
            errorMsg={"Must be unique."}
            placeholder={t.admin.regions.text_input_name}
            onChange={handleChange}
            value={selected.name}
          />
          <Input
            label={t.admin.regions.text_input_short_name}
            error={error.shortName}
            name="shortName"
            errorMsg={"Must be unique."}
            inputClass="inputLogin"
            type="text"
            placeholder={t.admin.regions.text_input_short_name}
            onChange={handleChange}
            value={selected.shortName}
          />
          <div className={styles.selector}>
            <Selector
              options={countryOptions}
              selectedOption={getSelectedValue(
                countryOptions,
                selected.country
              )}
              error={error.country}
              handleChangeSelect={(e) => handleSelect(e, "country")}
              selectClassname="selectPrimary"
              label={t.admin.regions.text_country}
              placeholder={"Select..."}
            />
          </div>
          <Textarea
            label={t.admin.regions.text_input_description}
            error={error.description}
            name="description"
            textareaClass="textareaDefault"
            type="text"
            placeholder={t.admin.regions.text_input_description}
            onChange={handleChange}
            value={selected.description}
          />
          <Input
            label={t.admin.regions.text_input_days_to_return_premium}
            error={error.days_to_return_premium}
            name="days_to_return_premium"
            inputClass="inputLogin"
            type="number"
            value={selected.days_to_return_premium}
            onChange={handleChange}
            onKeyPress={handleInputNumber}
          />
          <Input
            label={t.admin.regions.text_input_days_to_return}
            error={error.days_to_return}
            name="days_to_return"
            inputClass="inputLogin"
            type="number"
            value={selected.days_to_return}
            onChange={handleChange}
            onKeyPress={handleInputNumber}
          />
          <Input
            label={t.admin.regions.text_input_tax_rate}
            error={error.tax_rate}
            name="tax_rate"
            inputClass="inputLoginDesc"
            type="number"
            value={selected.tax_rate}
            onChange={handleChange}
            onKeyPress={handleInputNumber}
            desc="%"
          />
          <div className={styles.selector}>
            <Selector
              options={dummyCurrencies}
              error={error.currency}
              selectedOption={getSelectedValue(
                dummyCurrencies,
                selected.currency
              )}
              handleChangeSelect={(e) => handleSelect(e, "currency")}
              selectClassname="selectPrimary"
              label={t.admin.regions.text_th_currency}
              placeholder={"Select..."}
            />
          </div>
          <div className={styles.selector}>
            <Selector
              options={chargingOptions}
              selectedOption={getSelectedValue(
                chargingOptions,
                selected.chargingOption
              )}
              error={error.chargingOption}
              handleChangeSelect={(e) => handleSelect(e, "chargingOption")}
              selectClassname="selectPrimary"
              label={t.admin.regions.text_charging}
              placeholder={"Select..."}
            />
          </div>
          {selected.chargingOption === "full" && (
            <Input
              label={"Refund Period"}
              error={!selected.refund_period || selected.refund_period <= 0}
              name="refund_period"
              inputClass="inputLoginDesc"
              type="number"
              value={selected.refund_period}
              onChange={handleChange}
              onKeyPress={handleInputNumber}
              desc="days"
            />
          )}
        </div>
        {errorMsg ? (
          <div className={styles.error}>
            <p>{t.admin.regions.text_all_fields_required}</p>
          </div>
        ) : null}
        <div className={styles.options}>
          <div className={styles.submit}>
            <Button
              btnClass="btnWhiteBackground"
              label={t.button.text_cancel}
              onClick={handleCancel}
            />
            <Button
              btnClass="btnNormalCasal"
              label={editing ? t.button.text_save : t.button.text_create}
              onClick={handleSubmit}
              disabled={disabled}
            />
          </div>
          {editing && (
            <div>
              <Button
                btnClass="btnNormal"
                label={t.button.text_delete}
                onClick={(e) => openDeleteModal(e, selected)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegionManagement;
