import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../_store/actions/main.actions";
import styles from "./tags.module.scss";
import Input from "../input";
import Selector from "../selector";
import Button from "../button";
import Loading from "./../loading";
import ConfirmModal from "../modal/confirmModal";
import t from "./../../_config/constants";
import { isObjectEmpty } from "./../../_utils/utils";
const StoreTagsModal = () => {
  const [tags, setTags] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState({});
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedTag, setSelectedTag] = useState({});
  const [error, setError] = useState(false);
  const [tagData, setTagData] = useState({
    tagType: "customTag",
    value: "",
  });
  const [disableSubmit, setDisableSubmit] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector((state) => state);

  useEffect(() => {
    if (store.auth.user.regionId != "*") {
      setTagData((data) => ({
        ...data,
        regionId: store.auth.user.regionId,
      }));
    }
  }, [store.auth && store.auth.user]);

  useEffect(() => {
    const getTags = async () => {
      const response = await dispatch(
        mainActions.run("data", "storeTag", "all", { sort: { createdAt: 1 } })
      );
      setTags(response);
      setLoading(false);
    };
    getTags();
  }, [refresh]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTagData((data) => ({ ...data, [name]: value }));
    setError(false);
  };
  const validateData = (data) => {
    let index = tags.findIndex(
      (tag) =>
        tag.value.toLowerCase() === data.value.toLowerCase() &&
        tag.tagType === data.tagType
    );
    if (index !== -1) {
      setError(true);
      return false;
    } else {
      return true;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (tagData.value && tagData.tagType && validateData(tagData)) {
      setDisableSubmit(true);
      let response;
      response = await dispatch(
        mainActions.run("data", "storeTag", "new", tagData)
      );
      setTagData({ value: "", tagType: "customTag" });
      setRefresh((prev) => !prev);
      setDisableSubmit(false);
    }
  };

  const handleDelete = async (answer, data) => {
    if (answer) {
      await dispatch(
        mainActions.run("data", "storeTag", "delete", { _id: data._id })
      );
      setShowModalDelete(false);
      clearData();
      setModalData({});
      setRefresh((prev) => !prev);
    } else {
      setShowModalDelete(false);
    }
  };
  const openDeleteModal = async (e, selected) => {
    e.preventDefault();
    setShowModalDelete(true);
    setModalData(selected);
  };
  const clearData = () => {
    setTagData({ value: "", tagType: "customTag" });
    setSelectedTag({});
    setError(false);
  };

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const handleSelect = (e) => {
    setError(false);
    setTagData((data) => ({
      ...data,
      tagType: e.value,
    }));
  };
  const tagTypeOptions = [
    { label: "Store type", value: "storeTypeTag" },
    { label: "Custom Tag", value: "customTag" },
  ];
  return (
    <div className={styles.wrapper}>
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={handleDelete}
          modalData={selectedTag}
          question={t.restaurant.text_modal_delete_tag}
        />
      )}
      <h2>Type of Store Tags</h2>
      {loading && <Loading width={70} />}
      <div className={styles.tags}>
        {tags?.length > 0 &&
          tags.filter((tag) => tag.tagType === "storeTypeTag").length > 0 &&
          !loading &&
          tags
            .filter((tag) => tag.tagType === "storeTypeTag")
            ?.map((tag) => {
              return (
                <div
                  key={tag._id}
                  className={`${styles.tag} ${
                    styles[selectedTag === tag ? "selected" : ""]
                  }`}
                  onClick={() => {
                    setSelectedTag(tag);
                    setTagData(tag);
                  }}
                >
                  {tag.value}
                </div>
              );
            })}
      </div>
      <h2>Custom Tags</h2>
      {loading && <Loading width={70} />}
      <div className={styles.tags}>
        {tags?.length > 0 &&
          tags.filter((tag) => tag.tagType === "customTag").length > 0 &&
          !loading &&
          tags
            .filter((tag) => tag.tagType === "customTag")
            ?.map((tag) => {
              return (
                <div
                  key={tag._id}
                  className={`${styles.tagCustom} ${
                    styles[selectedTag === tag ? "selectedCustom" : ""]
                  }`}
                  onClick={() => {
                    setSelectedTag(tag);
                    setTagData(tag);
                  }}
                >
                  {tag.value}
                </div>
              );
            })}
      </div>
      <hr />
      <div className={styles.addNew}>
        <h3>Add Tags</h3>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.content}>
            <div className={styles.inputs}>
              <Selector
                options={tagTypeOptions}
                multiselect={false}
                selectedOption={getSelectedValue(
                  tagTypeOptions,
                  tagData.tagType
                )}
                handleChangeSelect={(e) => handleSelect(e)}
                selectClassname="selectSecondary"
                label={"Tag Type"}
                disabled={!isObjectEmpty(selectedTag)}
              />
              <Input
                label="Tag"
                name="value"
                inputClass="inputForm"
                type="text"
                error={!tagData.value || error}
                value={tagData.value}
                placeholder={
                  tagData.tagType === "customTag"
                    ? "Vegan, Gluten Free..."
                    : "Cafe, Restaurant..."
                }
                errorMsg={error ? "Tag already exists" : ""}
                onChange={handleChange}
                disabled={!isObjectEmpty(selectedTag)}
              />
            </div>
          </div>

          <div className={styles.buttons}>
            {(tagData.value || !isObjectEmpty(selectedTag)) && (
              <Button
                btnClass="btnWhiteBackground"
                label="Cancel"
                onClick={clearData}
                disabled={disableSubmit}
              />
            )}
            {isObjectEmpty(selectedTag) && (
              <Button
                btnClass="btnNormalCasal"
                label={"Save"}
                type="submit"
                disabled={!tagData.value || disableSubmit}
              />
            )}
            {!isObjectEmpty(selectedTag) && (
              <Button
                btnClass="btnNormal"
                label="Delete"
                onClick={(e) => {
                  openDeleteModal(e, selectedTag);
                }}
                disabled={disableSubmit}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
export default StoreTagsModal;
