import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./reusersContainers.module.scss";
import Loading from "../../../loading";
import { FcCheckmark } from "react-icons/fc";

import { getStatistics } from "../../../../_services/statistics.service";
import Button from "../../../button";
import moment from "moment";
import FormModal from "../../../modal/formModal";
import RestaurantSelect from "../../../userManagement/single/containerDetails/borrowed/restaurantSelect";
import { mainActions } from "../../../../_store/actions/main.actions";
const ReusersContainers = ({ userId, hanleRefresh }) => {
  const dispatch = useDispatch();
  const [selectedContainer, setSelectedContainer] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [containersAtReuser, setContainersAtReuser] = useState([]);
  const [returnedContainers, setReturnedContainers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(true);
  useEffect(() => {
    const getData = async (userId) => {
      const response = await getStatistics({
        mode: "get-subregion-reuser-containers",
        filter: { userId },
      });
      const { containersAtReuser = [], returnedContainers = [] } = response;
      setContainersAtReuser(containersAtReuser);
      setReturnedContainers(returnedContainers);
      setLoadingData(false);
    };
    if (userId) {
      setLoadingData(true);
      getData(userId);
    }
  }, [userId, refresh]);
  const handleReturn = async (container) => {
    setSelectedContainer(container);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    setSelectedContainer();
    setRefresh((prev) => !prev);
    hanleRefresh();
  };
  return (
    <div className={styles.wrapper}>
      {showModal && (
        <FormModal
          show={showModal}
          handleClose={() => {
            setShowModal(false);
          }}
          content={
            <RestaurantSelect
              containers={[selectedContainer]}
              handleClose={closeModal}
            />
          }
          size="md"
        />
      )}
      {!userId && (
        <div className={styles.selectUser}>
          <span>Select Reuser</span>
        </div>
      )}
      {loadingData && (
        <div className={styles.loadingData}>
          <Loading height={50} width={50} />
        </div>
      )}
      {!loadingData &&
        userId &&
        !containersAtReuser.length &&
        !returnedContainers.length && (
          <div className={styles.selectUser}>
            <span>Reuser has no container history</span>
          </div>
        )}
      <div className={styles.containers}>
        {!loadingData &&
          containersAtReuser.map((item, index) => (
            <div key={index} className={styles.container}>
              <div className={styles.content}>
                <span className={styles.title}>{item.name}</span>
                <span>
                  Assigned from{" "}
                  <span className={styles.bold}>
                    {item.restaurantName || ""}
                  </span>
                </span>
                <span className={styles.italic}>
                  {item.orderedAt
                    ? moment(item.orderedAt).format("MMM Do [at] h:mm a")
                    : ""}
                </span>
              </div>
              <div className={styles.button}>
                <Button
                  btnClass="btnBlackBackground"
                  label="Return"
                  onClick={() => handleReturn(item)}
                  type="btn"
                />
              </div>
            </div>
          ))}

        {!loadingData &&
          returnedContainers.map((item, index) => (
            <div key={index} className={styles.container}>
              <div className={styles.content}>
                <span className={styles.title}>{item?.oldData?.name}</span>
                {item?.oldData?.restaurantName && (
                  <span>
                    Assigned from{" "}
                    <span className={styles.bold}>
                      {item.oldData.restaurantName}
                    </span>
                  </span>
                )}
                {item?.oldData?.orderedAt && (
                  <span className={styles.italic}>
                    {moment(item.oldData.orderedAt).format(
                      "MMM Do [at] h:mm a"
                    )}
                  </span>
                )}
                <span>
                  Returned to{" "}
                  <span className={styles.bold}>{item?.assignedTo?.name}</span>
                </span>
                <span className={styles.italic}>
                  {moment(item.createdAt).format("MMM Do [at] h:mm a")}
                </span>
              </div>
              <div className={styles.button}>
                <FcCheckmark />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default ReusersContainers;
