import React from "react";
import styles from "./transferForm.module.scss";
import moment from "moment";
import Button from "../../../button";
import logo from "./../../../../_assets/images/logo.svg";
import close from "./../../../../_assets/icons/close.svg";
import t from "../../../../_config/constants";
const TransferForm = ({ data, onSubmit, handleClose, cancelDelivery }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.close} onClick={handleClose}>
        <img src={close} alt="x" />
      </div>
      <div className={styles.header}>
        <img src={logo} alt="logo" />
        <div>{t.transfer.text_transfer}</div>
      </div>
      <div className={styles.content}>
        <div className={styles.restaurantInfo}>
          <div>
            <span> From:</span>
            <p className={styles.name}>{data.fromStoreName}</p>
          </div>
          <div>
            <span> To:</span>
            <p className={styles.name}>{data.toStoreName}</p>
          </div>
        </div>
        <div className={styles.containers}>
          {data.containers &&
            data.containers.length > 0 &&
            data.containers.map((item) => (
              <div className={styles.info} key={item.containerTypeId}>
                <p>{item.name}</p>
                <p>{item.amount}</p>
              </div>
            ))}
        </div>
      </div>
      <div className={styles.buttons}>
        {data.createdAt ? (
          <p>
            {t.common.text_th_created}
            {moment(data.createdAt).format("MM/DD/YYYY")}{" "}
          </p>
        ) : (
          ""
        )}

        {!data._id && (
          <Button
            btnClass="btnNormalCasal"
            label={"Transfer"}
            onClick={onSubmit}
          />
        )}
        {data._id && (
          <Button
            btnClass="btnNormal"
            label={t.button.text_delete}
            onClick={cancelDelivery}
          />
        )}
      </div>
    </div>
  );
};
export default TransferForm;
