import React from "react";
import styles from "./container.module.scss";
import Loading from "../../../../loading";
const ContainerBox = ({ name, iconURL, amount, type = "large" }) => {
  return (
    <div className={`${styles[`wrapper_${type}`]}`}>
      <div className={styles.header}>
        <p>{name}</p>
        {iconURL && (
          <div className={styles.imgContainer}>
            <img src={iconURL} />
          </div>
        )}
      </div>
      {!amount && <Loading height={50} width={50} />}
      <div className={styles.content}>{amount}</div>
    </div>
  );
};

export default ContainerBox;
