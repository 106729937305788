import httpService from "./http.service";

export const getLogs = async (data) => {
  return httpService.apiRequest("post", "/api/restaurant", {
    mode: "get-logs",
    data: data,
  });
};

export const deleteStore = async (data) => {
  return httpService.apiRequest("post", "/api/restaurant", {
    mode: "delete-store",
    data: data,
  });
};
