import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";

import Button from "../../../button/index";
import SMSModal from "../../smsModal";
import FormModal from "../../../modal/formModal";
import NotificationsModal from "../../notificationsModal";
import Avatar from "../../../avatar";
import EditForm from "../editUser";
import Form from "../form";
import ConfirmModal from "../../../modal/confirmModal";
import PaymentHistory from "../paymentHistory";
import ContainerDetails from "../containerDetails";

import toastService from "../../../../_services/toast.service";
import userService from "../../../../_services/user.service";
import { mainActions } from "../../../../_store/actions/main.actions";
import { modalActions } from "../../../../_store/actions/modal.actions";
import { isObjectEmpty } from "../../../../_utils/utils";
import t from "../../../../_config/constants";

import styles from "./singleUser.module.scss";

const SingleUser = ({ selectedUser, onUpdate, hideSingleOptions }) => {
  const [user, setUser] = useState({});
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [step, setStep] = useState("profile");
  const [showModal, setShowModal] = useState(false);
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [disabledCloseModal, setDisabledCloseModal] = useState(false);
  const [loadingImg, setLoadingImg] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedUser) {
      getUser(selectedUser);
      setLoadingImg(true);
    } else {
      setUser({});
    }
    dispatch(modalActions.closeModal());
  }, [selectedUser]);

  const getUser = async (id) => {
    let data = await dispatch(mainActions.run("profiles", "user", "id", id));
    if (!data) {
      data = await dispatch(
        mainActions.run("profiles", "unregistered", "id", id)
      );
    }
    setUser(data);
    if (!data.avatarURL) setLoadingImg(false);
  };

  const openDeleteModal = () => {
    setShowModalDelete(true);
  };

  const handleDelete = async (answer) => {
    if (answer) {
      try {
        let response = await userService.deleteUser({
          _id: user._id,
          email: user._email,
          customer: user.customer,
        });
        if (response) {
          toastService.show("success", t.user.text_successfully_deleted);
          setUser({});
          onUpdate();
          setShowModalDelete(false);
        }
      } catch (err) {
        setShowModalDelete(false);
      }
    } else {
      setShowModalDelete(false);
    }
  };

  const handleStep = (term) => {
    setStep(term);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowSMSModal(false);
  };

  const handleCloseDisabled = (val) => {
    setDisabledCloseModal(val);
  };

  const updateUser = () => {
    getUser(selectedUser);
  };

  return (
    <div className={styles.wrapper}>
      {showModal && (
        <FormModal
          show={showModal}
          handleClose={closeModal}
          closeOnOverlayClick={!disabledCloseModal}
          closeDisabled={disabledCloseModal}
          content={
            <NotificationsModal
              handleClose={closeModal}
              handleCancel={closeModal}
              users={[user]}
              handleDisabled={handleCloseDisabled}
            />
          }
          size="md"
        />
      )}
      {showSMSModal && (
        <FormModal
          show={showSMSModal}
          handleClose={closeModal}
          closeOnOverlayClick={!disabledCloseModal}
          closeDisabled={disabledCloseModal}
          content={
            <SMSModal
              handleClose={closeModal}
              handleCancel={closeModal}
              users={[user]}
              handleDisabled={handleCloseDisabled}
            />
          }
          size="md"
        />
      )}
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={handleDelete}
          modalData={{}}
          question={t.user.text_modal_delete}
        />
      )}
      {!isObjectEmpty(user) && step === "profile" && (
        <>
          {!hideSingleOptions && (
            <div className={styles.buttonsWrapper}>
              {user && user.type === "user" && (
                <Button
                  btnClass={"btnWhiteBackground"}
                  label={t.button.text_edit}
                  onClick={() => {
                    dispatch(
                      modalActions.openModal(
                        <EditForm user={user} update={updateUser} />
                      )
                    );
                  }}
                />
              )}

              <Button
                label={t.button.text_delete}
                btnClass="btnWhiteBackground"
                onClick={openDeleteModal}
              />
              {user.type === "user" && (
                <Button
                  label="Send Notification"
                  btnClass="btnNormalGlacier"
                  onClick={() => {
                    setShowModal(true);
                  }}
                />
              )}
              <Button
                label="Send SMS"
                btnClass="btnNormalGlacier"
                onClick={() => {
                  setShowSMSModal(true);
                }}
              />
            </div>
          )}
          <div className={styles.avatarSection}>
            <Avatar
              imgUrl={user.avatarURL}
              loadingImg={loadingImg}
              setLoadingImg={setLoadingImg}
            />
            <span className={styles.avatarName}>{user.fullName}</span>
            <Button
              label={
                user.membership ? user.membership : t.common.text_not_available
              }
              btnClass="btnPremium"
            />
          </div>
          <h3 className={styles.profileTitle}>{t.common.text_profile_info}</h3>
          <Form user={user} />
          {!hideSingleOptions && (
            <div className={styles.options}>
              <Button
                label={t.user.text_payment_history}
                btnClass="btnNormalGlacier"
                onClick={() => handleStep("payment")}
              />
              <Button
                label={t.user.text_container_details}
                btnClass="btnNormalCasal"
                onClick={() => handleStep("container")}
              />
            </div>
          )}
        </>
      )}
      {!isObjectEmpty(user) && step === "payment" && (
        <>
          <PaymentHistory
            id={selectedUser}
            goBack={() => handleStep("profile")}
          />
        </>
      )}
      {!isObjectEmpty(user) && step === "container" && (
        <>
          <ContainerDetails
            handleUpdate={onUpdate}
            id={selectedUser}
            goBack={() => handleStep("profile")}
          />
        </>
      )}
      {isObjectEmpty(user) && (
        <div className={styles.noData}>
          <span>{t.user.text_select_guideline}</span>
        </div>
      )}
    </div>
  );
};
export default SingleUser;
