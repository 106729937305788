import React, { useEffect, useState } from "react";
import styles from "./replenishmentHistory.module.scss";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FcCheckmark } from "react-icons/fc";

import SearchBar from "../../../searchBar";
import Checkbox from "../../../checkbox";
import Button from "../../../button";
import Loading from "./../../../loading";
import FormModal from "../../../modal/formModal";
import RecipientInput from "../recipientInput";
import Pagination from "../../../pagination";

import useDebounce from "../../../../_utils/debounce";
import { mainActions } from "../../../../_store/actions/main.actions";
import { termRegex } from "../../../../_utils/utils";
import useSortableData from "../../../../_utils/useSortableData";
import t from "../../../../_config/constants";

import statusImage from "../../../../_assets/icons/exclamation-mark.svg";
import arrowDown from "../../../../_assets/icons/blackArrowDown.svg";
import arrowUp from "../../../../_assets/icons/blackArrowUp.svg";
import upDownArrow from "../../../../_assets/icons/blackUpDown.svg";

const ReplenishmentHistory = ({ update, handleView, ids, handleSubmit }) => {
  const user = useSelector((state) => state?.auth?.user);
  const [replenishmentList, setReplenishmentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState({
    searchTerm: "",
    selectedValue: "",
  });
  const [selectReplenishments, setSelectReplenishments] = useState(false);
  const debouncedSearchTerm = useDebounce(filterData.searchTerm, 500);
  const { handleSort, sortConfig } = useSortableData(replenishmentList);
  const [selectedReplenishments, setSelectedReplenishments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [numberOfReplenishments, setNumberOfReplenishments] = useState(0);
  const [sortDataConfig, setSortDataConfig] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    handleCancel();
    setPageNumber(0);
  }, [ids]);

  useEffect(() => {
    setPageNumber(0);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setLoading(true);
    if (ids) {
      getReplenishmentList(
        debouncedSearchTerm,
        filterData.selectedValue,
        pageNumber,
        sortDataConfig
      );
    }
  }, [
    debouncedSearchTerm,
    filterData.selectedValue,
    update,
    ids,
    pageNumber,
    sortDataConfig,
  ]);

  useEffect(() => {
    if (!sortConfig) return;
    const { key, direction } = sortConfig;
    setSortDataConfig({ [key]: direction === "ascending" ? 1 : -1 });
  }, [sortConfig]);

  const getReplenishmentList = async (
    term,
    selectValue,
    pageNumber,
    sortConfig = { createdAt: -1 }
  ) => {
    const filter = [{ restaurantId: { $in: ids } }];
    if (term) {
      filter.push({
        name: { $regex: `${termRegex(term)}`, $options: "i" },
      });
    }

    let data = await dispatch(
      mainActions.run("data", "replenishmentForm", "page", {
        filter,
        pageSize,
        pageNumber,
        sort: sortConfig,
      })
    );
    if (!data) data = { rows: [], total: 0 };

    setReplenishmentList(data.rows);
    setNumberOfReplenishments(data.total);
    setLoading(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const closeModalOnSend = () => {
    setShowModal(false);
    handleCancel();
  };

  const onChangeTerm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterData((prevState) => ({ ...prevState, [name]: value }));
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return upDownArrow;
    }
    return sortConfig.key === name
      ? sortConfig.direction === "ascending"
        ? arrowUp
        : arrowDown
      : upDownArrow;
  };

  const handleCheckedReplenishments = (replenishment) => {
    const index = selectedReplenishments.findIndex(
      (item) => replenishment._id === item._id
    );
    if (index !== -1) {
      const filtered = selectedReplenishments.filter(
        (item) => item._id !== replenishment._id
      );
      setSelectedReplenishments(filtered);
    } else {
      setSelectedReplenishments((prevState) => [...prevState, replenishment]);
    }
  };

  const handleCancel = () => {
    setSelectReplenishments(false);
    setSelectedReplenishments([]);
  };

  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };

  const noReplenishmentList = (
    <p className={styles.noReplenishmentList}>
      {t.distribution.text_no_replenishment}
    </p>
  );

  return (
    <div className={styles.wrapper}>
      {showModal && (
        <FormModal
          show={showModal}
          handleClose={closeModal}
          content={
            <RecipientInput
              handleClose={closeModalOnSend}
              replenishmentData={selectedReplenishments}
            />
          }
          size="md"
        />
      )}
      <h3>{t.distribution.text_history}</h3>
      <div className={styles.searchAndSelect}>
        <div className={styles.select}>
          <SearchBar
            customClass="transactionSearch"
            placeholder={t.distribution.text_search_restaurants}
            name="searchTerm"
            value={filterData.searchTerm}
            onChange={onChangeTerm}
            disabled={loading}
          />
        </div>
        <div className={styles.buttons}>
          {!selectReplenishments ? (
            <Button
              btnClass="btnWhiteBackground"
              label="Select Replenishments"
              type="btn"
              onClick={() => setSelectReplenishments(true)}
              disabled={loading || !replenishmentList.length}
            />
          ) : (
            <>
              <Button
                btnClass="btnNormalCasal"
                label="Send via Email"
                type="btn"
                onClick={() => setShowModal(true)}
                disabled={!selectedReplenishments.length}
              />
              <Button
                btnClass="btnWhiteBackground"
                label="Cancel"
                type="btn"
                onClick={handleCancel}
              />
            </>
          )}
        </div>
      </div>
      <div className={styles.list}>
        <div className={styles.header}>
          <p className={styles.blockRestaurant}>
            {t.restaurant.text_input_name}
          </p>
          <div
            className={styles.blockCreated}
            onClick={() => handleSort("createdAt")}
          >
            {t.common.text_th_created}
            <Button
              btnClass="btnBack"
              iconLeft={getClassNamesFor("createdAt")}
            />
          </div>
          <div
            className={styles.blockStatus}
            onClick={() => handleSort("isDelivered")}
          >
            {t.common.text_th_status}
            <Button
              btnClass="btnBack"
              iconLeft={getClassNamesFor("isDelivered")}
            />
          </div>
          <div className={styles.blockButton}></div>
        </div>
        {loading && <Loading width={50} height={50} />}
        {!loading && !replenishmentList?.length && noReplenishmentList}
        {!loading &&
          replenishmentList?.length > 0 &&
          replenishmentList.map((item, index) => {
            return (
              <div className={styles.block} key={index}>
                <p className={styles.blockRestaurant}>{item.name}</p>
                <p className={styles.blockCreated}>
                  {moment(item.createdAt).format("MM/DD/YYYY")}
                </p>
                <div className={styles.blockStatus}>
                  {!item.isDelivered ? (
                    !user.secureCodeRequired && !selectReplenishments ? (
                      <div className={styles.blockButtonSave}>
                        <Button
                          label={t.button.text_save}
                          btnClass="btnNormalCasal"
                          onClick={() => handleSubmit(item)}
                        />
                      </div>
                    ) : (
                      <img src={statusImage} />
                    )
                  ) : (
                    <FcCheckmark />
                  )}
                </div>
                {!selectReplenishments && (
                  <div className={styles.blockButton}>
                    <Button
                      label={t.button.text_view}
                      btnClass="btnNormalGlacier"
                      onClick={() => handleView(item)}
                    />
                  </div>
                )}
                {selectReplenishments && (
                  <div className={styles.blockButton}>
                    <Checkbox
                      customClass="checkbox"
                      checked={
                        selectedReplenishments.findIndex(
                          (replenishment) => replenishment._id === item._id
                        ) === -1
                          ? false
                          : true
                      }
                      onChange={() => handleCheckedReplenishments(item)}
                      label=""
                    />
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <div className={styles.pagination}>
        <Pagination
          handlePageClick={onPageChange}
          pageCount={Math.ceil(numberOfReplenishments / pageSize)}
          forcePage={pageNumber}
          loading={loading}
        />
      </div>
    </div>
  );
};
export default ReplenishmentHistory;
