import React, { useEffect, useRef } from 'react';
import styles from './search.module.scss';
import search from '../../_assets/icons/search.svg';

const SearchBar = ({ customClass, onChange, value, placeholder, name, disabled = false }) => {
    const textInput = useRef(null);
    useEffect(() => {
        textInput.current.focus();
    }, [disabled]);
    return (
        <div className={styles[customClass]}>
            <div className={styles.icon}>
                <img src={search} alt="Search" />
            </div>
            <div className={styles.inputBox}>
                <input
                    ref={textInput}
                    type="text"
                    disabled={disabled}
                    className={styles.input}
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
}

export default SearchBar;