export const authConstants = {
  OTHER_REQUEST: "USERS_OTHER_REQUEST",
  OTHER_SUCCESS: "USERS_OTHER_SUCCESS",
  OTHER_FAILURE: "USERS_OTHER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  ADMIN_REGISTER_REQUEST: "ADMIN_REGISTER_REQUEST",
  ADMIN_REGISTER_SUCCESS: "ADMIN_REGISTER_SUCCESS",
  ADMIN_REGISTER_FAILURE: "ADMIN_REGISTER_FAILURE",

  RESET_LINK_REQUEST: "RESET_LINK_REQUEST",
  RESET_LINK_SUCCESS: "RESET_LINK_SUCCESS",
  RESET_LINK_FAILURE: "RESET_LINK_FAILURE",

  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  FORGOT_PASSWORD_REQUEST: "FORGOT_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  RESET_FORGOT_PASSWORD: "RESET_FORGOT_PASSWORD",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  CHANGE_EMAIL_REQUEST: "CHANGE_EMAIL_REQUEST",
  CHANGE_EMAIL_SUCCESS: "CHANGE_EMAIL_SUCCESS",
  CHANGE_EMAIL_FAILURE: "CHANGE_EMAIL_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",

  RESTAURANT_REGISTER_REQUEST: "RESTAURANT_REGISTER_REQUEST",
  RESTAURANT_REGISTER_SUCCESS: "RESTAURANT_REGISTER_SUCCESS",
  RESTAURANT_REGISTER_FAILURE: "RESTAURANT_REGISTER_FAILURE",

  CLEANER_REGISTER_REQUEST: "CLEANER_REGISTER_REQUEST",
  CLEANER_REGISTER_SUCCESS: "CLEANER_REGISTER_SUCCESS",
  CLEANER_REGISTER_FAILURE: "CLEANER_REGISTER_FAILURE",
};
