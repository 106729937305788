import React, { useState, useEffect } from "react";
import Button from "../../../button";
import styles from "./downloadData.module.scss";
import t from "../../../../_config/constants";
import { exportToCSV } from "../../../../_utils/exportToCSV";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../../_store/actions/main.actions";
import moment from "moment";
const DownloadData = ({ regionId, disabled, archived = false }) => {
  const fileName = (archived ? "Deactivated " : "") + "Restaurants";
  const [data, setData] = useState([]);
  const [loadingCSV, setLoadingCSV] = useState(false);

  const dispatch = useDispatch();

  const getRestaurants = async (regionId) => {
    setLoadingCSV(true);
    const filter = [{ archived: archived || { $ne: true } }];
    if (regionId && regionId !== "*") {
      filter.push({ regionId });
    }
    let restaurants = await dispatch(
      mainActions.run("profiles", "restaurant", "all", { filter })
    );

    let data = [];
    restaurants.map((item) => {
      data.push({
        Name: item.name,
        Owner: item.fullName,
        Email: item.email,
        Address: item.location,
        Phone_Number: item.phone,
        Registered: moment(item.createdAt).format("YYYYMMDD"),
      });
    });
    setData(data);
  };
  useEffect(() => {
    if (data.length > 0) {
      exportToCSV(data, fileName);
      setLoadingCSV(false);
    }
  }, [data]);
  const handleDownload = () => {
    if (regionId) {
      getRestaurants(regionId);
    }
  };
  return (
    <div className={styles.wrapper}>
      <Button
        disabled={disabled || loadingCSV}
        label={t.restaurant.text_download_data}
        btnClass="btnWhiteBackground"
        onClick={handleDownload}
      />
    </div>
  );
};
export default DownloadData;
