import React, { useEffect, useState } from "react";
import styles from "./containerDetails.module.scss";
import t from "../../../../../_config/constants";
import Loading from "../../../../loading";
import QrGenerator from "../../../qrCode";
import { getStatistics } from "../../../../../_services/statistics.service";
import moment from "moment";
const ContainerDetails = ({ containerId }) => {
  const [container, setContainer] = useState({});
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getContainer = async (id) => {
      const { container, history } = await getStatistics({
        mode: "get-container-history",
        filter: { containerId: id },
      });
      setHistory(history);
      setContainer(container);
      setLoading(false);
    };
    if (containerId) {
      setLoading(true);
      getContainer(containerId);
    }
  }, [containerId]);
  const maskState = (state) => {
    let val = "";
    switch (state) {
      case "user":
        val = t.user.text_member;
        break;
      case "unregistered":
        val = t.user.text_unregistered;
        break;
      case "restaurant":
        val = t.restaurant.text_restaurat;
        break;
      case "cleaner":
        val = t.cleaner.text_cleaner;
        break;
      case "location":
        val = t.dropoff.text_dropoff_location;
        break;
      case "master":
      case "admin":
        val = t.sidebar.text_admin;
        break;
      case "purchased":
        val = t.common.text_purchased;
        break;
      default:
        val = t.common.text_not_available;
    }
    return val;
  };
  return (
    <div className={styles.wrapper}>
      <h3>{t.common.text_container_details}</h3>
      <div className={styles.qrDiv}>
        <QrGenerator
          id={container._id}
          uniqueId={container.uniqueContainerId}
          name={container.name}
          material={container.material}
          batchId={container.uniqueBatchId}
          size={container.size}
          volume={container.volume}
        />
      </div>

      {!loading && (
        <div className="custom-table">
          <table>
            <tbody>
              <tr className="tb-head-th">
                <th>{t.common.text_th_name}</th>
                <td>{container.name}</td>
              </tr>
              <tr className="tb-head-th">
                <th>{t.containerManagement.add.text_th_uniqueID}</th>
                <td>{container.uniqueContainerId}</td>
              </tr>
              {container.uhf_id && (
                <tr className="tb-head-th">
                  <th>{t.containerManagement.add.text_th_uniqueuhf_ID}</th>
                  <td>{container.uhf_id}</td>
                </tr>
              )}
              <tr className="tb-head-th">
                <th>Container's Usage Count</th>
                <td>{container.usageCount || 0}</td>
              </tr>
              <tr className="tb-head-th">
                <th>{t.common.text_located_at}</th>
                <td>
                  {maskState(container.state)}
                  {" - "}
                  {["user", "cleaner", "purchased"].includes(container.state)
                    ? container.user_fullName || t.common.text_not_available
                    : container.state === "restaurant"
                    ? container.restaurantName
                    : container.state === "location"
                    ? container.locationName
                    : ""}
                </td>
              </tr>

              {(container.state === "location" ||
                container.state === "restaurant") &&
                container.user_fullName && (
                  <tr className="tb-head-th">
                    <th>Last Member</th>
                    <td>
                      {container.user_fullName ?? t.common.text_not_available}
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      )}

      <div>
        <h3>Container History</h3>
        <div className={styles.history}>
          {!loading && history && history.length > 0 && (
            <div className="custom-table">
              <table>
                <thead>
                  <tr className="tb-head-th">
                    <th>Scanned By</th>
                    <th>Scanned To</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {history.map((historyItem, ind) => (
                    <tr key={historyItem._id} className="tb-head-th">
                      <td>
                        <span>{ind + 1}.</span>{" "}
                        {maskState(historyItem?.scannedBy?.type)}-
                        {historyItem?.scannedBy?.name ||
                          historyItem?.scannedBy?.fullName ||
                          historyItem?.scannedBy?.email}
                      </td>
                      <td>
                        {maskState(
                          historyItem?.assignedTo?.type || historyItem?.newState
                        )}
                        -{" "}
                        {historyItem?.assignedTo?.name ||
                          historyItem?.assignedTo?.fullName ||
                          historyItem?.assignedTo?.email ||
                          historyItem?.assignedTo?.phone ||
                          historyItem?.assignedTo?.customer ||
                          ""}
                      </td>
                      <td>
                        {moment(
                          historyItem.timestamp || historyItem.createdAt
                        ).format("MMM DD, YYYY - HH:mm")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {!loading && (!history || history.length === 0) && (
            <p>No history found</p>
          )}
        </div>
      </div>

      {loading && <Loading width={50} height={50} />}
    </div>
  );
};
export default ContainerDetails;
