import React, { useEffect, useState } from "react";
import QRCode from "qrcode";
import Axios from "axios";
import Loading from "../../loading";
import { firebaseApiKey } from "../../../environment";

const QrGenerator = ({ id }) => {
  const [qr, setQr] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) generateQr(`https://reusables.com/?location=${id}`);
  }, [id]);

  const generateQr = async (redirectUrl) => {
    const FIREBASE_DYNAMIC_LINK_ENDPOINT = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseApiKey}`;

    try {
      const requestConfig = {
        method: "POST",
        url: FIREBASE_DYNAMIC_LINK_ENDPOINT,
        headers: {
          "content-type": "application/json",
        },
        data: {
          dynamicLinkInfo: {
            domainUriPrefix: "https://reusables.page.link",
            link: redirectUrl,
            androidInfo: {
              androidPackageName: "com.circular.reusables",
              androidFallbackLink:
                "https://play.google.com/store/apps/details?id=com.circular.reusables",
            },
            iosInfo: {
              iosBundleId: "com.circular.reusables",
              iosFallbackLink:
                "https://apps.apple.com/app/reusables/id1570782074",
            },
            navigationInfo: {
              enableForcedRedirect: true,
            },
            socialMetaTagInfo: {
              socialTitle: "Reusables.com | Zero Waste Packaging Platform",
              socialDescription:
                "Get your favourite food and drinks in Reusables and help reduce single-use packaging from our environment. We help food businesses reduce packaging waste by providing tech enabled reusables that makes it easy for customers to reuse!",
              socialImageLink:
                "https://cdn.shopify.com/s/files/1/0503/5963/2036/files/Reusables-All-Logo-vector_reusables-logo-tall_1200x1200.png",
            },
          },
          suffix: {
            option: "UNGUESSABLE",
          },
        },
      };

      const { shortLink } = await Axios(requestConfig);
      const response = await QRCode.toDataURL(`${shortLink}?location=${id}`);
      setQr(response);
      setLoading(false);
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      {!loading && qr !== "" && (
        <img src={qr} height="300px" width="300px" alt="qr_code" />
      )}
      {loading && !qr && <Loading width={25} height={25} />}
    </div>
  );
};

export default QrGenerator;
