import React, { useEffect, useState } from "react";
import styles from "./chargeAndRetire.module.scss";
import Selector from "../../../../../selector";
import Button from "../../../../../button";
import Input from "../../../../../input";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../../../../_store/actions/main.actions";
import toastService from "../../../../../../_services/toast.service";
import t from "../../../../../../_config/constants";
import { chargeUser } from "../../../../../../_services/payment.service";
import getDaysLeft from "../../../../../../_utils/getDaysLeft";
import Loading from "../../../../../loading";
import moment from "moment";
const ChargeAndRetire = ({ container, user, handleClose }) => {
  const dispatch = useDispatch();
  const [btnDisable, setBtnDisable] = useState(false);
  const overdue = getDaysLeft(container.days_to_return);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    amount: 25,
    currency: "cad",
    user: {
      userId: user?._id,
      type: "user",
      email: user?.email,
      customer: user?.customer,
      regionId: user?.regionId,
    },
  });
  const getContainerType = async (id) => {
    setLoading(true);
    let data = await dispatch(
      mainActions.run("data", "containerType", "id", id)
    );
    if (data && data.price) {
      setData((prevState) => ({ ...prevState, amount: data.price }));
    }
    setLoading(false);
  };
  useEffect(() => {
    if (container) getContainerType(container.containerTypeId);
  }, [container]);
  const calculateDaysToReturn = (container) => {
    const today = moment(new Date().getTime()).startOf("day");
    const duedate = moment(container.days_to_return).startOf("day");
    return today.diff(duedate, "days");
  };
  const handleCharge = async () => {
    if (validateData()) {
      setBtnDisable(true);
      let response = await chargeUser(data);
      if (response) {
        let isDuedate = calculateDaysToReturn(container);
        user.inPossession -= 1;
        if (isDuedate == 0) user.dueDate -= 1;
        if (isDuedate > 0) user.overdue -= 1;
        toastService.show("success", `Successfully charged penalties!`);
        let response2 = await dispatch(
          mainActions.run("data", "container", "delete", { _id: container._id })
        );
        if (response2) {
          if (user)
            await dispatch(
              mainActions.run("profiles", "user", "update", user, {
                _id: user._id,
              })
            );

          toastService.show(
            "success",
            t.containerManagement.add.text_successfully_deleted_container
          );
        }
        handleClose();
      }
      setBtnDisable(false);
    }
  };
  const validateData = () => {
    return (
      data.amount >= 1 && data.currency && data.user.email && data.user.customer
    );
  };
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const handleSelect = (e) => {
    setData((data) => ({
      ...data,
      currency: e.value,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  };
  const dummyCurrencies = [
    {
      label: "USD$",
      value: "usd",
    },
    {
      label: "CAN$",
      value: "cad",
    },
  ];
  return (
    <div className={styles.wrapper}>
      <h3>{t.report.text_charge_and_retire}</h3>
      <div className={styles.tableWrapper}>
        <table>
          <thead>
            <tr>
              <th>{t.report.text_user}</th>
              <th>{t.report.text_container}</th>
              <th>{t.report.text_overdue}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{user ? user.fullName : t.common.text_not_available}</td>
              <td>{container.name}</td>
              <td>
                {overdue && overdue < 0
                  ? Math.abs(overdue)
                  : t.report.text_on_time}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {loading && (
        <div className={styles.loading}>
          <Loading width={50} height={50} />
        </div>
      )}
      {!loading && (
        <div className={styles.input}>
          <div>
            <Input
              label={t.report.text_charge_container}
              inputClass="inputForm"
              type="number"
              name="amount"
              value={data.amount}
              onChange={handleChange}
              placeholder={0}
            />
          </div>
          <div className={styles.selector}>
            <Selector
              multiselect={false}
              selectClassname="selectGray"
              options={dummyCurrencies}
              selectedOption={getSelectedValue(dummyCurrencies, data.currency)}
              handleChangeSelect={(e) => handleSelect(e)}
            />
          </div>
        </div>
      )}
      <Button
        btnClass="btnNormal"
        label={t.button.text_charge}
        disabled={data.amount < 1 || user.type !== "user" || btnDisable}
        onClick={handleCharge}
      />
    </div>
  );
};

export default ChargeAndRetire;
