import React, { useEffect, useState } from "react";
import ListReturned from "./../list/listReturned";
import { getStatistics } from "../../../../../_services/statistics.service";

const ReturnedList = ({ id, update }) => {
  const [loading, setLoading] = useState(true);
  const [returnedData, setReturnedData] = useState([]);

  const getCurrentData = async (id) => {
    const response = await getStatistics({
      mode: "get-returned-containers-history",
      filter: { user_id: id },
    });
    setReturnedData(response);
    setLoading(false);
  };

  useEffect(() => {
    if (id) getCurrentData(id);
  }, [id, update]);

  return (
    <ListReturned
      data={returnedData}
      activeTab={"returned"}
      loadingData={loading}
    />
  );
};
export default ReturnedList;
