import React, { useEffect, useState } from "react";
import styles from "../settings.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";

import Selector from "../../selector";
import FAQContainer from "./faqContainer";
import Loading from "../../loading";
import ConfirmModal from "../../modal/confirmModal";

import { isObjectEmpty, makeSelectMap } from "../../../_utils/utils";
import t from "../../../_config/constants";

const FAQ = () => {
  const dispatch = useDispatch();
  const [faq, setFaq] = useState([]);
  const [faqObject, setFaqObject] = useState({});
  const [error, setError] = useState(false);
  const [regionId, setRegionId] = useState();
  const [regionOptions, setRegionOptions] = useState([]);
  const [refetch, setRefetch] = useState(true);
  const [editingTitle, setEditingTitle] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();
  const user = useSelector((state) => state?.auth?.user);
  const regionList = useSelector((state) => state?.main?.data?.region?.list);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (regionList && regionList.length) {
      const arr = makeSelectMap(regionList);
      setRegionOptions(arr);
    }
  }, [regionList]);
  useEffect(() => {
    setFaq([]);
    if (user?.regionId && user?.regionId !== "*") {
      setRegionId(user.regionId);
    } else {
      setRegionId("");
    }
  }, [user]);
  useEffect(() => {
    const fetchFAQ = async (regionId) => {
      const response = await dispatch(
        mainActions.run("constants", "faq", "get", { filter: [{ regionId }] })
      );
      setFaqObject(response || {});
      setFaq(response.data || []);
      setLoading(false);
    };
    if (regionId) {
      setEditingTitle(null);
      setError(false);
      setLoading(true);
      fetchFAQ(regionId);
    }
  }, [regionId, refetch]);

  const handleAddSection = () => {
    const ind = faq.length;
    setFaq((prev) => [
      ...prev,
      { title: "", questions: [{ question: "", answer: "" }] },
    ]);
    setEditingTitle(ind);
  };
  const handleDeleteSection = async (titleInd) => {
    const deleteSection = faq[titleInd];
    if (deleteSection.questions.find((item) => item.question || item.answer)) {
      return;
    }
    faq.splice(titleInd, 1);
    setEditingTitle(null);

    if (!isObjectEmpty(faqObject)) {
      const response = await dispatch(
        mainActions.run("constants", "faq", "update", {
          _id: faqObject._id,
          data: [...faq],
        })
      );
      setFaqObject(response || {});
      setFaq(response?.data || []);
    } else {
      setFaq([...faq]);
    }
  };
  const handleAddQA = (titleInd) => {
    faq[titleInd].questions = [
      ...faq[titleInd].questions,
      { question: "", answer: "" },
    ];
    setFaq([...faq]);
  };
  const handleDeleteQA = (answer, { titleInd, ind }) => {
    setShowModal(false);
    if (answer) {
      if (!faq[titleInd]) return;
      faq[titleInd].questions.splice(ind, 1);
      setFaq([...faq]);
    }
    setModalData();
  };
  const deleteQA = (titleInd, ind) => {
    if (!faq[titleInd]) return;
    const qa = faq[titleInd]?.questions[ind];

    if (qa?.question || qa?.answer) {
      setShowModal(true);
      setModalData({ titleInd, ind });
      return;
    }
    faq[titleInd].questions.splice(ind, 1);
    setFaq([...faq]);
  };
  const handleSelect = (e) => {
    setRegionId(e.value);
  };
  const handleChangeTitle = (e, titleInd) => {
    const { value } = e.target;
    faq[titleInd].title = value;
    setFaq([...faq]);
    setError(false);
  };

  const handleChange = (e, titleInd, questionInd) => {
    setError(false);
    const { name, value } = e.target;
    faq[titleInd].questions[questionInd][name] = value;
    setFaq([...faq]);
  };

  const handleSubmit = async (titleInd) => {
    const section = faq[titleInd];
    if (
      regionId &&
      section.title &&
      section?.questions?.length &&
      !section?.questions.find((item) => !item.answer || !item.question)
    ) {
      setLoading(true);
      const objectForDb = {};
      objectForDb.data = [...faq];
      setEditingTitle(null);
      if (isObjectEmpty(faqObject)) {
        objectForDb.regionId = regionId;
      } else {
        objectForDb._id = faqObject._id;
      }
      const response = await dispatch(
        mainActions.run(
          "constants",
          "faq",
          objectForDb?._id ? "update" : "new",
          objectForDb
        )
      );
      setFaqObject(response || {});
      setFaq(response?.data || []);
      setLoading(false);
    } else {
      setError(true);
    }
  };
  const handleCancel = () => {
    setEditingTitle(null);
    setRefetch((prev) => !prev);
    setError(false);
  };
  return (
    <div className={styles.wrapper}>
      <h3>{t.app_settings.faq}</h3>
      {showModal && (
        <ConfirmModal
          show={showModal}
          handleYesNoAnswer={handleDeleteQA}
          modalData={modalData}
          question="Are you sure you want to delete this QA?"
        />
      )}
      {!user.regionId ||
        (user.regionId === "*" && (
          <Selector
            options={regionOptions}
            placeholder={"Select Region"}
            handleChangeSelect={(e) => handleSelect(e)}
            selectClassname="selectSecondaryWhiteBg"
            disabled={loading}
          />
        ))}
      {!loading && (
        <div className={styles.content}>
          {faq.map((item, ind) => (
            <FAQContainer
              key={ind}
              titleInd={ind}
              data={item}
              handleAddQA={handleAddQA}
              deleteSection={handleDeleteSection}
              deleteQA={deleteQA}
              handleChangeTitle={handleChangeTitle}
              handleChange={handleChange}
              editingTitle={editingTitle}
              setEditingTitle={setEditingTitle}
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
              errorMsg={error}
            />
          ))}
          {editingTitle === null && regionId && (
            <div className={styles.addSection} onClick={handleAddSection}>
              <span>+</span>
              <p>Add Section</p>
            </div>
          )}
        </div>
      )}
      {loading && <Loading height={60} width={60} />}
    </div>
  );
};

export default FAQ;
