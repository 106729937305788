import React, { useEffect, useState } from "react";
import Selector from "../selector";
import styles from "./report.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../_store/actions/main.actions";

import {
  getDropdownListMonths,
  getDropdownListYears,
} from "../../_utils/getReportsDateOptions";
import t from "../../_config/constants";
import toastService from "../../_services/toast.service";
import { getStatistics } from "../../_services/statistics.service";
import { makeSelectMap } from "../../_utils/utils";
import Button from "../button";
import { exportToCSV } from "../../_utils/exportToCSV";
import moment from "moment";
import Input from "../input";
const SubregionStatisticsReport = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  const [loadingCSV, setLoadingCSV] = useState(false);
  const [data, setData] = useState({ wasteCoef: 0, waterCoef: 0, ghgCoef: 0 });
  const [selectedYear, setSelectedYear] = useState();
  const [yearsOptions, setYearsOptions] = useState([]);
  const [monthsOptions, setMonthsOptions] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState();
  const [subregions, setSubregions] = useState([]);
  const [subregionOptions, setSubregionOptions] = useState([]);
  const [subregion, setSubregion] = useState({});
  const [subregionId, setSubregionId] = useState("");
  const [csvObject, setCsvObject] = useState([]);
  useEffect(() => {
    const arr = getDropdownListMonths(selectedYear);
    setSelectedMonth(arr[0].value);
    setMonthsOptions(arr);
  }, [selectedYear]);
  useEffect(() => {
    const arr = getDropdownListYears(2023);
    setSelectedYear(arr[0].value);
    setYearsOptions(arr);
  }, []);
  useEffect(() => {
    const fetchSubregions = async () => {
      const response = await dispatch(
        mainActions.run("data", "subregion", "all", {})
      );
      setSubregions(response);
    };

    fetchSubregions();
  }, []);

  useEffect(() => {
    if (store.auth.user.regionId && subregions.length) {
      const regionId = store.auth.user.regionId;

      const filteredSubregions =
        regionId !== "*"
          ? subregions.filter(
              (item) => item.regionId === store.auth.user.regionId
            )
          : subregions;
      const arr = makeSelectMap(filteredSubregions);
      setSubregionOptions(arr);
    }
  }, [store.auth && store.auth.user.regionId, subregions]);
  useEffect(() => {
    const fetchReports = async (subregionId, date, data) => {
      const filter = {
        createdAt: {
          $gte: date.getTime(),
          $lte: new Date(date.getFullYear(), date.getMonth() + 1).getTime(),
        },
      };

      try {
        const response = await getStatistics({
          mode: "get-subregion-statistics",
          filter: { ...data, subregionId, filter },
        });
        setCsvObject(response);
      } catch (err) {
        toastService.show("warning", "Something went wrong, please try again.");
      }
    };
    if (
      selectedMonth &&
      subregionId &&
      data.wasteCoef &&
      data.waterCoef &&
      data.ghgCoef
    ) {
      fetchReports(subregionId, selectedMonth, data);
    }
  }, [selectedMonth, subregionId, data]);

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const saveCoefData = async (data) => {
    if (
      subregion.wasteCoef !== data.wasteCoef ||
      subregion.waterCoef !== data.waterCoef ||
      subregion.ghgCoef !== data.ghgCoef
    ) {
      await dispatch(
        mainActions.run("data", "subregion", "update", {
          _id: subregion._id,
          wasteCoef: data.wasteCoef,
          waterCoef: data.waterCoef,
          ghgCoef: data.ghgCoef,
        })
      );
    }
  };
  const downloadData = async () => {
    setLoadingCSV(true);
    await saveCoefData(data);
    if (csvObject && csvObject.length) {
      exportToCSV(
        csvObject,
        `Subregion Statistics - ${moment(selectedMonth).format("MMM, YYYY")}`
      );
    }
    setLoadingCSV(false);
  };

  const handleInputNumber = (e) => {
    const numbers = "0123456789.";
    if (!numbers.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  const handleSelectSubregion = (e) => {
    setSubregionId(e.value);
    const subregion = subregions.find((item) => item._id + "" == e.value);
    if (subregion) {
      setSubregion(subregion);
      setData({
        wasteCoef: subregion.wasteCoef || 0,
        waterCoef: subregion.waterCoef || 0,
        ghgCoef: subregion.ghgCoef || 0,
      });
    }
  };
  return (
    <div className={styles.wrapper}>
      <h3>{t.reports.subregion_stats}</h3>
      <div className={styles.filterOptions}>
        <div className={styles.selector}>
          <Selector
            label="Select Subregion"
            multiselect={false}
            selectClassname="selectSecondaryWhiteBg"
            selectedOption={getSelectedValue(subregionOptions, subregionId)}
            handleChangeSelect={(e) => handleSelectSubregion(e)}
            options={subregionOptions}
          />
        </div>
        <div className={styles.selectDate}>
          <div className={styles.selectMonth}>
            <Selector
              label=""
              multiselect={false}
              selectClassname="selectSecondaryWhiteBg"
              selectedOption={getSelectedValue(monthsOptions, selectedMonth)}
              handleChangeSelect={(e) => setSelectedMonth(e.value)}
              options={monthsOptions}
            />
          </div>
          <div className={styles.selectYear}>
            <Selector
              label=""
              multiselect={false}
              selectClassname="selectSecondaryWhiteBg"
              selectedOption={getSelectedValue(yearsOptions, selectedYear)}
              handleChangeSelect={(e) => setSelectedYear(e.value)}
              options={yearsOptions}
            />
          </div>
        </div>
      </div>
      <div className={styles.inputs}>
        <Input
          label={"Waste diverted (Kg's per container)"}
          error={!data.wasteCoef}
          name="wasteCoef"
          inputClass="inputForm"
          type="number"
          placeholder="0"
          value={data.wasteCoef}
          onChange={handleChange}
          onKeyPress={handleInputNumber}
        />
        <Input
          label={"Water saved (L per container)"}
          error={!data.waterCoef}
          name="waterCoef"
          inputClass="inputForm"
          type="number"
          placeholder="0"
          value={data.waterCoef}
          onChange={handleChange}
          onKeyPress={handleInputNumber}
        />
        <Input
          label={"GHG avoided (g per container)"}
          error={!data.ghgCoef}
          name="ghgCoef"
          inputClass="inputForm"
          type="number"
          placeholder="0"
          value={data.ghgCoef}
          onChange={handleChange}
          onKeyPress={handleInputNumber}
        />
      </div>
      <div
        className={
          loadingCSV || csvObject?.length === 0 ? styles.btnDisabled : ""
        }
      >
        <Button
          label={"Download"}
          onClick={downloadData}
          disabled={loadingCSV || csvObject?.length === 0}
        />
      </div>
      <div className={styles.report}></div>
    </div>
  );
};
export default SubregionStatisticsReport;
