import { mainConstants } from '../constants';
import toastService from '../../_services/toast.service';
import mainService from '../../_services/main.service';

const agg = (dataType, name, type, mode, pipeline, id, data = {}, dontStore = false) => {
    data = { ...data, mode, pipeline, id }
    const request = () => ({ actionType: mainConstants.MAIN_AGG_REQUEST, mode, name, type: dataType, sent: data, id })
    const success = (result) => ({ actionType: mainConstants.MAIN_AGG_SUCCESS, mode, name, type: dataType, sent: data, result, id })
    const failure = (error) => ({ actionType: mainConstants.MAIN_AGG_FAILURE, mode, name, type: dataType, sent: data, id, error })

    return dispatch => {
        dispatch(request());
        return mainService.run(name, type, 'agg', data)
            .then(
                result => {
                    dispatch(success(result));
                    return result;
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
}

const run = (name, type, mode, data = {}, { message, id } = {}) => {
    const request = () => ({ actionType: mainConstants.MAIN_REQUEST, mode, name, type, sent: data })
    const success = (result) => ({ actionType: mainConstants.MAIN_SUCCESS, mode, name, type, sent: data, result, id })
    const failure = (error) => ({ actionType: mainConstants.MAIN_FAILURE, mode, name, type, sent: data, error })

    return dispatch => {
        dispatch(request());
        return mainService.run(name, type, mode, data)
            .then(
                result => {
                    dispatch(success(result));
                    if (message) toastService.show('success', message);
                    return result
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
};

export const mainActions = {
    run,
    agg
}