import React, { useEffect, useState } from "react";
import styles from "./addRestaurant.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { GrDeliver } from "react-icons/gr";

import Input from "../../input";
import Button from "../../button";
import Selector from "../../selector";
import UploadAvatar from "../../uploadAvatar";
import Checkbox from "../../checkbox";
import GeoInput from "../../googleMaps/geoInput";
import PhoneInput from "./../../input/phoneInput";

import { userActions } from "../../../_store/actions/user.actions";
import { mainActions } from "../../../_store/actions/main.actions";
import { days, isObjectEmpty, makeSelectMap } from "../../../_utils/utils";
import { validatePhoneNumber } from "../../../_utils/formatPhone";

import { uploadToS3 } from "../../../_utils/auth/uploadPhoto";
import { amazonUrl, amazonUrlResized } from "../../../environment";
import toastService from "../../../_services/toast.service";
import trimObjectProps from "../../../_utils/trimObjectProps";
import validateEmail from "../../../_utils/validateEmail";
import {
  addRestaurantInitalErrors,
  addRestaurantInitialState,
  workingTime,
  chargingOptions,
  containerAssignmentOptions,
  userAssignmentOptions,
} from "./utils";
import t from "../../../_config/constants";
import WorkingDay from "../../workingDay";

const AddRestaurant = ({ editing, editRestaurant, handleClose }) => {
  const store = useSelector((state) => state);
  const [user, setUser] = useState({});
  const [errorMsg, setErrorMsg] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [regionOptions, setRegionOptions] = useState([]);
  const [subregionOptions, setSubregionOptions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [subregions, setSubregions] = useState([]);
  const [isDropoff, setIsDropoff] = useState(false);
  const [dropoffExists, setDropoffExists] = useState(false);
  const [existingDropoff, setExistingDropoff] = useState({});
  const [dropoffLoader, setDropoffLoader] = useState(true);
  const [orderingChannels, setOrderingChannels] = useState([]);
  const [tags, setTags] = useState([]);
  const [allWorkingHours, setAllWorkingHours] = useState(false);

  const dispatch = useDispatch();

  const getExistingDropoff = async (restaurant) => {
    const res = await dispatch(
      mainActions.run("data", "location", "get", {
        filter: { restaurant_id: restaurant._id },
      })
    );
    if (!isObjectEmpty(res)) {
      setDropoffExists(true);
      setExistingDropoff(res);
    }
    setDropoffLoader(false);
  };

  useEffect(() => {
    const getOrderingChannels = async () => {
      const response = await dispatch(
        mainActions.run("data", "orderingChannel", "all", {})
      );
      setOrderingChannels(response);
    };
    const getTags = async () => {
      const response = await dispatch(
        mainActions.run("data", "storeTag", "all", {})
      );
      if (response) {
        const options = response.map((tag) => ({
          value: tag.value,
          label: tag.value,
        }));
        setTags(options);
      }
    };
    const fetchSubregions = async () => {
      const response = await dispatch(
        mainActions.run("data", "subregion", "all", {})
      );
      setSubregions(response);
    };
    getTags();
    getOrderingChannels();
    fetchSubregions();
  }, []);

  useEffect(() => {
    if (editing) {
      getExistingDropoff(editRestaurant);
      return;
    }
    setDropoffLoader(false);
  }, [editing]);
  const [data, setData] = useState(
    editing
      ? {
          ...editRestaurant,
          tags: editRestaurant.tags || [],
          workingTime: editRestaurant.workingTime || workingTime,
          chargingOption: editRestaurant.chargingOption || "perUse",
        }
      : {
          ...addRestaurantInitialState,
          workingTime,
        }
  );
  useEffect(() => {
    if (data.subregionId && subregions?.length) {
      const subregion = subregions.find(
        (subregion) => subregion._id + "" == data.subregionId
      );
      setData((prev) => ({
        ...prev,
        user_assignment: subregion.checkoutType || [],
      }));
    }
  }, [data?.subregionId]);

  const [error, setError] = useState(addRestaurantInitalErrors);
  const [image, setImage] = useState({
    uri: data.avatarURL || "",
  });
  const [backgroundImage, setBackgroundImage] = useState({
    uri: data.backgroundURL || "",
  });

  useEffect(() => {
    setUser(store.auth.user);
    fetchRegions();
    if (store.auth.user.regionId != "*") {
      setData((prev) => ({ ...prev, regionId: store.auth.user.regionId }));
      setError((error) => ({ ...error, regionId: false }));
    }
  }, [store.auth && store.auth.user]);
  useEffect(() => {
    if (data.regionId && subregions.length) {
      const filteredSubregions = subregions.filter(
        (item) => item.regionId === data.regionId
      );
      const arr = makeSelectMap(filteredSubregions);
      setSubregionOptions(arr);
      const foundSubregion = filteredSubregions.find(
        (item) => item._id == data.subregionId
      );
      const openNetworkSubregion = subregions.find(
        (subregion) =>
          subregion.openNetwork && subregion.regionId == data.regionId
      );
      setData((prev) => ({
        ...prev,
        subregionId: foundSubregion
          ? foundSubregion._id
          : openNetworkSubregion?._id || "",
      }));
      setError((prev) => ({ ...prev, subregionId: false }));
    }
  }, [subregions]);

  useEffect(() => {
    if (data.regionId && subregions.length) {
      const filteredSubregions = subregions.filter(
        (item) => item.regionId === data.regionId
      );
      const arr = makeSelectMap(filteredSubregions);
      setSubregionOptions(arr);
      const foundSubregion = filteredSubregions.find(
        (item) => item._id == data.subregionId
      );
      const openNetworkSubregion = subregions.find(
        (subregion) =>
          subregion.openNetwork && subregion.regionId == data.regionId
      );
      setData((prev) => ({
        ...prev,
        subregionId: foundSubregion
          ? foundSubregion._id
          : openNetworkSubregion?._id || "",
      }));
    }
  }, [data.regionId]);
  useEffect(() => {
    if (
      regions.length &&
      store.auth?.user?.regionId &&
      store.auth.user.regionId != "*"
    ) {
      data.currency = regions.find(
        (region) => region._id === store.auth.user.regionId
      ).currency;
    }
  }, [regions, store.auth]);

  useEffect(() => {
    if (allWorkingHours) {
      const workingTime = { ...data?.workingTime };
      days.forEach((day) => {
        workingTime[day] = data?.workingTime?.monday
          ? data.workingTime.monday
          : {
              from: "",
              to: "",
            };
      });
      setData((data) => ({
        ...data,
        workingTime: workingTime,
      }));
    }
  }, [allWorkingHours]);

  useEffect(() => {
    if (editing && data.isDraft) {
      setData((prevState) => ({ ...prevState }));
    }
  }, [editing, data.isDraft]);

  const fetchRegions = async () => {
    const response = await dispatch(
      mainActions.run("data", "region", "all", {})
    );
    if (!response) return;
    setRegions(response);
    const arr = makeSelectMap(response);
    setRegionOptions(arr);
  };

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };

  const handleChange = (e) => {
    setErrorMsg(false);
    const { name, value } = e.target;
    setData((userData) => ({ ...userData, [name]: value }));
    if (name === "name" && value.includes(".")) {
      setError((error) => ({ ...error, [name]: true }));
    } else {
      setError((error) => ({
        ...error,
        [name]: name === "billingEmail" ? false : !value,
      }));
    }
  };

  const handleChangeChannel = (e) => {
    const { name, value } = e.target;
    if (value) {
      setData((data) => ({
        ...data,
        orderingChannels: { ...data.orderingChannels, [name]: value },
      }));
    } else {
      const channels = data.orderingChannels;
      delete channels[name];
      setData((data) => ({ ...data, orderingChannels: channels }));
    }
  };

  const handleSelect = (e, type) => {
    setErrorMsg(false);
    if (type === "tags" && e.length === 0) {
      setData((data) => ({
        ...data,
        tags: e,
      }));
    } else if (type === "tags") {
      const val = e.map((obj) => obj.value);
      setData((data) => ({
        ...data,
        tags: val,
      }));
    } else if (type === "user_assignment" && e.length === 0) {
      setData((data) => ({
        ...data,
        user_assignment: e,
        tenderNumber: "",
        institutionId: "",
      }));
    } else if (type === "user_assignment") {
      const val = e.map((obj) => obj.value);
      const transactIncluded = !!val.includes("transact");
      setData((data) => ({
        ...data,
        user_assignment: val,
        tenderNumber: transactIncluded
          ? data.tenderNumber || "TenderDefault"
          : "",
        institutionId: transactIncluded ? data.institutionId : "",
      }));
    } else if (type === "container_assignment") {
      setData((data) => ({
        ...data,
        container_assignment: e.value,
      }));
    } else if (type === "chargingOption") {
      setData((data) => ({ ...data, chargingOption: e.value }));
    } else if (type === "subregionId") {
      setData((data) => ({ ...data, subregionId: e.value }));
      setError((error) => ({ ...error, subregionId: false }));
    } else {
      setData((data) => ({
        ...data,
        regionId: e.value,
        currency: regions.find((region) => region._id === e.value).currency,
      }));
      setError((error) => ({ ...error, regionId: false, subregionId: false }));
    }
  };

  const getMultiSelectedValues = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (value?.findIndex((item) => item === obj.value) !== -1) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };

  const handleChooseImg = (e, type) => {
    e.preventDefault();
    const set = {
      uri: URL.createObjectURL(e.target.files[0]),
      type: e.target.files[0].type,
      file: e.target.files[0],
    };
    type === "avatar" ? setImage(set) : setBackgroundImage(set);
  };

  const generateData = (e) => {
    e.preventDefault();
    const randomSecureCode = Math.random().toString().substr(2, 5);
    setData((userData) => ({ ...userData, secureCode: randomSecureCode }));
    setError((error) => ({ ...error, secureCode: false }));
    setErrorMsg(false);
  };

  const clearData = () => {
    setData(addRestaurantInitialState);
    setError(addRestaurantInitalErrors);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (validateForm()) {
        setSubmitDisabled(true);
        const imgUrl = await uploadToS3(image);
        const backgroundImageUrl = await uploadToS3(backgroundImage);
        if (editing && !data.isDraft) {
          const response = await dispatch(
            mainActions.run("profiles", "restaurant", "update", {
              ...data,
              avatarURL: imgUrl ? amazonUrl + imgUrl : data.avatarURL,
              backgroundURL: backgroundImageUrl
                ? amazonUrl + backgroundImageUrl
                : data.backgroundURL,
              avatarURLResized: imgUrl
                ? amazonUrlResized + imgUrl
                : data.avatarURLResized,
              backgroundURLResized: backgroundImageUrl
                ? amazonUrlResized + backgroundImageUrl
                : data.backgroundURLResized,
            })
          );
          if (response) {
            toastService.show("success", t.restaurant.text_successfully_edited);
            if (isDropoff && !dropoffExists) await submitLocation(response);
            if (dropoffExists) {
              await editLocation(response);
            }
            clearData();
            handleClose();
          }
        } else {
          const newRestaurant = { ...data };

          imgUrl
            ? (newRestaurant.avatarURL = amazonUrl + imgUrl) &&
              (newRestaurant.avatarURLResized = amazonUrlResized + imgUrl)
            : (newRestaurant.avatarURL = data.avatarURL) &&
              (newRestaurant.avatarURLResized = data.avatarURLResized);
          backgroundImageUrl
            ? (newRestaurant.backgroundURL = amazonUrl + backgroundImageUrl) &&
              (newRestaurant.backgroundURLResized =
                amazonUrlResized + backgroundImageUrl)
            : (newRestaurant.backgroundURL = data.backgroundURL) &&
              (newRestaurant.backgroundURLResized = data.backgroundURLResized);
          const response = await dispatch(
            userActions.registerRestaurant(newRestaurant)
          );
          if (response) {
            if (response.emailVerified) {
              toastService.show(
                "success",
                `${t.restaurant.text_successfully_sent} ${data.email}`
              );
            } else {
              toastService.show("success", `Successfully saved store`);
              toastService.show(
                "error",
                `We are unable to send invitation to ${data.email}. Please change the email address.`
              );
            }
            if (isDropoff && !dropoffExists) await submitLocation(response);
            if (dropoffExists) {
              await editLocation(response);
            }
            clearData();
            handleClose();
          }
        }
      } else {
        toastService.show(
          "error",
          `${t.common.text_toast_all_fields_required}`
        );
        setErrorMsg(true);
      }
      setSubmitDisabled(false);
    } catch (e) {
      setSubmitDisabled(false);
    }
  };

  const validateSaveDraftForm = () => {
    trimObjectProps(data);
    setError({
      ...addRestaurantInitalErrors,
      email: !data.email,
      name: data.name.includes("."),
      phone: data.phone && !validatePhoneNumber(data.phone),
    });
    return (
      data.email &&
      !data.name.includes(".") &&
      validateEmail(data.email) &&
      (!data.phone || validatePhoneNumber(data.phone))
    );
  };

  const handleSaveDraft = async () => {
    if (!validateSaveDraftForm()) return;
    let imgUrl, backgroundImageUrl;
    if (image) {
      imgUrl = await uploadToS3(image);
    }
    if (backgroundImage) {
      backgroundImageUrl = await uploadToS3(backgroundImage);
    }

    if (editing) {
      const response = await dispatch(
        mainActions.run("profiles", "restaurant", "update", {
          ...data,
          avatarURL: imgUrl ? amazonUrl + imgUrl : data.avatarURL,
          backgroundURL: backgroundImageUrl
            ? amazonUrl + backgroundImageUrl
            : data.backgroundURL,
          avatarURLResized: imgUrl
            ? amazonUrlResized + imgUrl
            : data.avatarURLResized,
          backgroundURLResized: backgroundImageUrl
            ? amazonUrlResized + backgroundImageUrl
            : data.backgroundURLResized,
          visible: false,
        })
      );
      if (response) {
        toastService.show("success", "Successfully edited draft");
        if (isDropoff && !dropoffExists) await submitLocation(response);
        if (dropoffExists && existingDropoff?.isDraft) {
          await editLocation(response);
        }
        clearData();
        handleClose();
      }
    } else {
      const newRestaurant = data;
      imgUrl
        ? (newRestaurant.avatarURL = amazonUrl + imgUrl) &&
          (newRestaurant.avatarURLResized = amazonUrlResized + imgUrl)
        : (newRestaurant.avatarURL = data.avatarURL) &&
          (newRestaurant.avatarURLResized = data.avatarURLResized);
      backgroundImageUrl
        ? (newRestaurant.backgroundURL = amazonUrl + backgroundImageUrl) &&
          (newRestaurant.backgroundURLResized =
            amazonUrlResized + backgroundImageUrl)
        : (newRestaurant.backgroundURL = data.backgroundURL) &&
          (newRestaurant.backgroundURLResized = data.backgroundURLResized);
      delete newRestaurant._id;
      delete newRestaurant.password;

      newRestaurant.isDraft = true;
      newRestaurant.visible = false;
      clearData();
      handleClose();
      const response = await dispatch(
        userActions.saveRestaurantDraft(newRestaurant)
      );
      if (response) {
        toastService.show("success", `Successfully created Draft`);
        if (isDropoff) await submitLocation(response);
        clearData();
        handleClose();
      }
    }
  };

  const validateForm = () => {
    trimObjectProps(data);
    setError({
      name: !data.name || data.name.includes("."),
      fullName: !data.fullName,
      location: !data.location,
      email: !data.email,
      phone: !data.phone || !validatePhoneNumber(data.phone),
      password: !data.password,
      secureCode: !data.secureCode,
      regionId: !data.regionId,
      subregionId: !data.subregionId,
      billingEmail: !(
        (data.billingEmail && validateEmail(data.billingEmail)) ||
        !data.billingEmail
      ),
      user_assignment:
        !data.user_assignment || data.user_assignment.length == 0,
      container_assignment: !data.container_assignment,
    });
    return (
      data.name &&
      !data.name.includes(".") &&
      data.fullName &&
      data.location &&
      data.email &&
      data.phone &&
      validatePhoneNumber(data.phone) &&
      data.password &&
      data.password.length >= 6 &&
      data.secureCode &&
      data.regionId &&
      data.regionId != "*" &&
      data.subregionId &&
      validateEmail(data.email) &&
      ((data.billingEmail && validateEmail(data.billingEmail)) ||
        !data.billingEmail) &&
      data.container_assignment &&
      data.user_assignment &&
      data.user_assignment.length
    );
  };

  const handleChangeSelect = () => {
    setIsDropoff(!isDropoff);
  };

  const handleCheckVisible = () => {
    setData((prevState) => ({ ...prevState, visible: !prevState.visible }));
  };

  const handleCheckTagLinking = () => {
    setData((prevState) => ({
      ...prevState,
      showTagLinking: !prevState.showTagLinking,
    }));
  };

  const onChangeLocation = (value, coordinates) => {
    setErrorMsg(false);
    const obj = { ...data };
    obj["location"] = value;
    obj["coordinates"] = coordinates;
    setData(obj);
    setError((error) => ({ ...error, location: !value }));
  };

  const handleChangeWorktime = (e) => {
    setErrorMsg(false);
    const { name, value } = e.target;
    const [nameVal, fromTo] = name.split(".");
    if (allWorkingHours && nameVal == "monday") {
      const workingTime = { ...data?.workingTime };
      days.forEach((day) => {
        workingTime[day] = { ...workingTime[day], [fromTo]: value };
      });
      setData((data) => ({
        ...data,
        workingTime: workingTime,
      }));
    } else {
      setAllWorkingHours(false);
      setData((data) => ({
        ...data,
        workingTime: {
          ...data?.workingTime,
          [nameVal]: {
            ...data?.workingTime[nameVal],
            [fromTo]: value,
          },
        },
      }));
    }
  };

  const submitLocation = async (obj) => {
    const locationObj = {
      restaurant_id: obj._id,
      name: obj.name,
      description: "",
      location: obj.location,
      isRestaurant: true,
      isCleaning: true,
      coordinates: obj.coordinates,
      regionId: data.regionId,
      subregionId: obj.subregionId,
      visible: data.visible,
      totalContainers: 0,
      workingTime: data.workingTime,
    };
    if (obj.isDraft) {
      locationObj.isDraft = true;
      locationObj.visible = false;
    }
    await dispatch(mainActions.run("data", "location", "new", locationObj));
  };

  const editLocation = async (obj) => {
    const location = {
      _id: existingDropoff._id,
      name: obj.name,
      location: obj.location,
      coordinates: obj.coordinates,
      regionId: data.regionId,
      workingTime: data.workingTime,
    };
    if (!obj.isDraft) {
      location.isDraft = false;
    }
    await dispatch(mainActions.run("data", "location", "update", location));
  };

  const handleClosedDay = (day) => {
    setAllWorkingHours(false);
    setData((data) => ({
      ...data,
      workingTime: {
        ...data?.workingTime,
        [day]: {
          from: "",
          to: "",
        },
      },
    }));
  };

  return (
    <div className={styles.wrapper}>
      <h2>
        {editing ? t.button.text_edit_restaurant : t.button.text_add_restaurant}
      </h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.center}>
          <UploadAvatar
            uri={image.uri}
            setImg={(e) => handleChooseImg(e, "avatar")}
          />
          <UploadAvatar
            uri={backgroundImage.uri}
            setImg={(e) => handleChooseImg(e, "background")}
            type="background"
          />

          <div className={styles.inputName}>
            <Input
              label={t.restaurant.text_input_name}
              error={error.name}
              errorMsg={"Character . is not allowed"}
              name="name"
              inputClass="inputForm"
              type="text"
              value={data.name}
              placeholder={t.restaurant.text_input_name}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.left}>
            <Input
              label={t.restaurant.text_input_owner}
              error={error.fullName}
              name="fullName"
              inputClass="inputForm"
              type="text"
              value={data.fullName}
              placeholder={t.restaurant.text_input_owner_placeholder}
              onChange={handleChange}
            />
            <Input
              label={t.common.input.text_input_email_address}
              error={error.email}
              name="email"
              inputClass="inputForm"
              type="email"
              value={data.email.trim()}
              placeholder={t.auth.text_markup_mail}
              onChange={handleChange}
              disabled={editing}
            />
            <Input
              label={"Billing Email Address"}
              name="billingEmail"
              inputClass="inputForm"
              type="email"
              value={data.billingEmail?.trim()}
              placeholder={t.auth.text_markup_mail}
              onChange={handleChange}
              error={error.billingEmail}
            />
            <PhoneInput
              handleChange={handleChange}
              value={data.phone}
              error={error.phone}
              errorMsg={"Invalid phone number"}
            />
            <Input
              label={t.restaurant.text_input_website}
              name="website"
              inputClass="inputForm"
              type="text"
              value={data.website}
              placeholder={t.restaurant.text_url_example}
              onChange={handleChange}
            />
            <GeoInput
              error={error.location}
              onChangeLocation={onChangeLocation}
              editLocation={editRestaurant}
              inputVal={data.location}
              name={t.common.input.text_input_address}
            />
            <Input
              label="Default Zoom Level"
              name="zoomLevel"
              inputClass="inputForm"
              type="number"
              value={data.zoomLevel}
              onChange={handleChange}
              desc={"%"}
            />
          </div>
          <div className={styles.middle}>
            <div className={styles.generate}>
              <Button
                btnClass="btnNormalCasal"
                label={t.button.text_generate_data}
                type="btn"
                onClick={generateData}
              />
              <Input
                label="Secure Code"
                name="secureCode"
                error={error.secureCode}
                inputClass="inputForm"
                type="number"
                value={data.secureCode}
                placeholder={t.restaurant.text_input_secureCode}
                onChange={handleChange}
                showCopyToClipboard={editing}
              />
            </div>
            <div>
              <Input
                label="Password"
                name="password"
                error={
                  error.password || (data.password && data.password.length < 6)
                }
                inputClass="inputForm"
                type="text"
                errorMsg={"Must be at least 6 characters"}
                value={editing ? "********" : data.password}
                placeholder={t.restaurant.text_input_password}
                onChange={handleChange}
                disabled={editing}
              />
            </div>
            {(user.regionId === "*" || editing) && (
              <div className={styles.selectRegion}>
                <Selector
                  options={regionOptions}
                  error={error.regionId}
                  multiselect={false}
                  selectedOption={getSelectedValue(
                    regionOptions,
                    data.regionId
                  )}
                  handleChangeSelect={(e) => handleSelect(e)}
                  selectClassname="selectPrimary"
                  placeholder={t.common.input.text_select_region}
                />
              </div>
            )}
            <div className={styles.selectRegion}>
              <Selector
                options={subregionOptions}
                multiselect={false}
                selectedOption={getSelectedValue(
                  subregionOptions,
                  data.subregionId
                )}
                handleChangeSelect={(e) => handleSelect(e, "subregionId")}
                selectClassname="selectPrimary"
                placeholder={"Select Subregion"}
                error={error.subregionId}
              />
            </div>
            {orderingChannels?.length > 0 &&
              orderingChannels?.map((channel) => {
                return (
                  <div key={channel._id} className={styles.channel}>
                    {channel.avatarURL ? (
                      <img src={channel.avatarURL} alt={channel.name} />
                    ) : (
                      <div className={styles.icon}>
                        <GrDeliver />
                      </div>
                    )}
                    <Input
                      label={channel.name}
                      name={channel._id + ""}
                      inputClass="inputForm"
                      value={
                        data.orderingChannels
                          ? data.orderingChannels[channel._id + ""]
                          : ""
                      }
                      type="text"
                      placeholder={t.restaurant.text_url_example}
                      onChange={handleChangeChannel}
                    />
                  </div>
                );
              })}
            <div className={styles.checkboxDiv}>
              {!dropoffLoader && (
                <div className={dropoffExists ? styles.checkboxDisabled : null}>
                  <Checkbox
                    customClass="checkbox"
                    label={t.restaurant.text_is_dropoff}
                    checked={dropoffExists ? true : isDropoff ? true : false}
                    onChange={handleChangeSelect}
                    disabled={dropoffExists}
                  />
                </div>
              )}
              <Checkbox
                customClass="checkbox"
                label={t.restaurant.text_is_visible}
                checked={data.visible}
                onChange={handleCheckVisible}
              />
              <Checkbox
                customClass="checkbox"
                label={"Show Tag Linking?"}
                checked={data.showTagLinking}
                onChange={handleCheckTagLinking}
              />
              <Checkbox
                customClass="checkbox"
                label={"Assign multiple containers at once?"}
                checked={data.multiContainerAssignment}
                onChange={() => {
                  setData((prevState) => ({
                    ...prevState,
                    multiContainerAssignment:
                      !prevState.multiContainerAssignment,
                  }));
                }}
              />
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.workingTime}>
              <p>{t.dropoff.text_working_hours}</p>
              <hr />
              <Checkbox
                label={"Set the same working hours Mon-Sun"}
                customClass="checkbox"
                onChange={() => {
                  setAllWorkingHours((state) => !state);
                }}
                checked={allWorkingHours}
              />
              {days.map((day) => (
                <WorkingDay
                  key={day}
                  day={day}
                  handleChangeWorktime={handleChangeWorktime}
                  workingTime={data?.workingTime}
                  handleClosedDay={handleClosedDay}
                />
              ))}
            </div>
            <div className={styles.select}>
              <Selector
                label="Store Tags"
                multiselect={true}
                options={tags}
                handleChangeSelect={(e) => {
                  handleSelect(e, "tags");
                }}
                selectedOption={getMultiSelectedValues(tags, data.tags)}
                selectClassname="selectSecondaryWhiteBg"
              />
            </div>
            <div className={styles.select}>
              <Selector
                label="Charging Options"
                options={chargingOptions}
                handleChangeSelect={(e) => {
                  handleSelect(e, "chargingOption");
                }}
                selectedOption={getSelectedValue(
                  chargingOptions,
                  data.chargingOption
                )}
                selectClassname="selectSecondaryWhiteBg"
              />
            </div>
            <div className={styles.select}>
              <Selector
                label="User Assignment"
                multiselect={true}
                options={userAssignmentOptions}
                handleChangeSelect={(e) => {
                  handleSelect(e, "user_assignment");
                }}
                selectedOption={getMultiSelectedValues(
                  userAssignmentOptions,
                  data.user_assignment
                )}
                selectClassname="selectSecondaryWhiteBg"
                error={!data.user_assignment || !data.user_assignment.length}
              />
            </div>
            {data?.user_assignment?.includes("transact") && (
              <div className={styles.input}>
                <Input
                  label={t.restaurant.text_tender_number}
                  name="tenderNumber"
                  inputClass="inputForm"
                  type="text"
                  value={data.tenderNumber}
                  placeholder={t.restaurant.text_tender_number}
                  onChange={handleChange}
                />
                <Input
                  label={t.restaurant.text_institution_route_ID}
                  name="institutionId"
                  inputClass="inputForm"
                  type="text"
                  value={data.institutionId}
                  placeholder={t.restaurant.text_institution_route_ID}
                  onChange={handleChange}
                />
              </div>
            )}
            <div className={styles.select}>
              <Selector
                label="Container Assignment"
                multiselect={false}
                options={containerAssignmentOptions}
                handleChangeSelect={(e) => {
                  handleSelect(e, "container_assignment");
                }}
                selectedOption={getSelectedValue(
                  containerAssignmentOptions,
                  data.container_assignment
                )}
                selectClassname="selectSecondaryWhiteBg"
                error={!data.container_assignment}
              />
            </div>
          </div>
        </div>
        <div className={styles.submit}>
          {(!editing || (editing && data.isDraft)) && (
            <Button
              btnClass="btnNormalCasalLight"
              label="Save Draft"
              type="button"
              onClick={handleSaveDraft}
            />
          )}
          <Button
            btnClass="btnNormal"
            label={
              !editing || (editing && data.isDraft)
                ? "Save and Publish"
                : t.button.text_save
            }
            type="submit"
            disabled={submitDisabled}
          />
        </div>
      </form>
      {errorMsg ? (
        <div className={styles.error}>
          <p>{t.common.text_all_fields_required}</p>
        </div>
      ) : null}
    </div>
  );
};

export default AddRestaurant;
