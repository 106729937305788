import React, { useEffect } from "react";
import styles from "./table.module.scss";
import Loading from "../../../loading";
import Button from "../../../button";
import Checkbox from "../../../checkbox";
import arrowUp from "../../../../_assets/icons/arrowUp.svg";
import arrowDown from "../../../../_assets/icons/arrowDown.svg";
import upDownArrow from "../../../../_assets/icons/upDownArrow.svg";
import useSortableData from "../../../../_utils/useSortableData";
import uber from "../../../../_assets/images/uber-eats.png";
import appSignup from "../../../../_assets/images/reusablesAppLogo.jpg";
import nubpn from "../../../../_assets/images/phone.jpg";
import t from "../../../../_config/constants";
import moment from "moment";
import { AiOutlineDollarCircle } from "react-icons/ai";
const UsersTable = ({
  data,
  handleSelectUser,
  selectedUser,
  loadingData,
  showMembers,
  changeSortConfig,
  handleSelectedUser,
  selectedUsers,
  selectUsers,
  selectAll,
  handleCheckboxChange,
}) => {
  const { handleSort, sortConfig } = useSortableData(data);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return upDownArrow;
    }
    return sortConfig.key === name
      ? sortConfig.direction === "ascending"
        ? arrowUp
        : arrowDown
      : upDownArrow;
  };
  useEffect(() => {
    changeSortConfig(sortConfig);
  }, [sortConfig]);
  const getTagValue = (tag) => {
    let retVal = "";
    switch (tag) {
      case "uber":
        retVal = "Uber";
        break;
      case "nubpn":
        retVal = "NUbPN";
        break;
      case "appSignup":
        retVal = "Mobile App";
        break;
      default:
        retVal = "";
    }
    return retVal;
  };
  const getTagIcon = (tag) => {
    let retVal = "";
    switch (tag) {
      case "uber":
        retVal = uber;
        break;
      case "appSignup":
        retVal = appSignup;
        break;
      case "nubpn":
        retVal = nubpn;
        break;
      default:
        retVal = "";
    }
    return retVal;
  };
  return (
    <div className={styles.wrapper}>
      <table>
        <thead>
          <tr>
            {selectUsers && (
              <th>
                <p> Select</p>
                <div className={styles.checkbox}>
                  <Checkbox
                    customClass="largeCheckbox"
                    name="all"
                    checked={selectAll}
                    onChange={handleCheckboxChange}
                    disabled={loadingData}
                  />
                </div>
              </th>
            )}
            <th onClick={() => (loadingData ? null : handleSort("tag"))}>
              Tag
              <Button btnClass="btnBack" iconLeft={getClassNamesFor("tag")} />
            </th>
            <th onClick={() => (loadingData ? null : handleSort("hasCard"))}>
              Card
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("hasCard")}
              />
            </th>
            {showMembers ? (
              <th
                className={styles.userData}
                onClick={() => (loadingData ? null : handleSort("fullName"))}
              >
                {t.user.text_th_data}
                <Button
                  btnClass="btnBack"
                  iconLeft={getClassNamesFor("fullName")}
                />
              </th>
            ) : (
              <th onClick={() => (loadingData ? null : handleSort("phone"))}>
                {t.user.text_th_data}
                <Button
                  btnClass="btnBack"
                  iconLeft={getClassNamesFor("phone")}
                />
              </th>
            )}
            {showMembers && (
              <th
                onClick={() => (loadingData ? null : handleSort("membership"))}
              >
                {t.user.text_th_membership}
                <Button
                  btnClass="btnBack"
                  iconLeft={getClassNamesFor("membership")}
                />
              </th>
            )}
            <th onClick={() => (loadingData ? null : handleSort("createdAt"))}>
              {showMembers ? "Registered" : "Created"}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("createdAt")}
              />
            </th>
            <th
              onClick={() => (loadingData ? null : handleSort("inPossession"))}
            >
              {t.user.text_th_in_possession}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("inPossession")}
              />
            </th>
            <th onClick={() => (loadingData ? null : handleSort("overdue"))}>
              {t.user.text_overdue}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("overdue")}
              />
            </th>
            <th onClick={() => (loadingData ? null : handleSort("dueDate"))}>
              {t.user.text_th_dueDate}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("dueDate")}
              />
            </th>
            <th
              onClick={() =>
                loadingData ? null : handleSort("totalContainersUsed")
              }
            >
              {t.user.text_containers_used}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("totalContainersUsed")}
              />
            </th>
            <th
              onClick={() =>
                loadingData ? null : handleSort("averageHoldingTime")
              }
            >
              {t.user.text_average_holding_time}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("averageHoldingTime")}
              />
            </th>
            <th
              onClick={() => (loadingData ? null : handleSort("mostUsedStore"))}
            >
              {t.user.text_most_used_store}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("mostUsedStore")}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {!loadingData &&
            data &&
            data.length > 0 &&
            data.map((item) => (
              <tr
                className={selectedUser === item._id ? styles.active : ""}
                key={item._id}
                onClick={() => handleSelectUser(item._id)}
              >
                {selectUsers && (
                  <td>
                    <Checkbox
                      customClass="largeCheckbox"
                      checked={
                        selectedUsers.findIndex(
                          (user) => user._id === item._id
                        ) === -1
                          ? false
                          : true
                      }
                      onChange={() => handleSelectedUser(item)}
                      label=""
                    />
                  </td>
                )}
                <td>
                  <div className={styles.tag}>
                    {" "}
                    {getTagValue(item.tag)}
                    {item.tag && <img src={getTagIcon(item.tag)} />}
                  </div>
                </td>
                <td>
                  <div className={styles.card}>
                    {item.hasCard && <AiOutlineDollarCircle />}
                  </div>
                </td>
                <td>
                  <p>
                    {!item.phone && !item.email && item.customer ? (
                      <span className={styles.noPhone}>[No Phone]</span>
                    ) : (
                      item.fullName || item.phone || ""
                    )}
                  </p>
                  <span>{item.email || item.customer}</span>
                </td>
                {showMembers && (
                  <td>
                    {item.membership
                      ? item.membership
                      : t.common.text_not_available}{" "}
                  </td>
                )}
                <td>{moment(item.createdAt).format("MM/DD/YYYY")}</td>
                <td>{item.inPossession}</td>
                <td>{item.overdue}</td>
                <td>{item.dueDate}</td>
                <td>{item.totalContainersUsed}</td>
                <td>
                  {item.averageHoldingTime || item.averageHoldingTime == 0
                    ? Number(item.averageHoldingTime).toFixed(1)
                    : "N/A"}
                </td>

                <td>{item.mostUsedStore ? item.mostUsedStore : "N/A"}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {!loadingData && !data.length && (
        <p className={styles.noData}>{t.user.text_no_users}</p>
      )}
      {loadingData && (
        <div className={styles.loading}>
          <Loading width={50} height={50} />
        </div>
      )}
    </div>
  );
};

export default UsersTable;
