import React, { useState } from "react";
import styles from "./form.module.scss";

import { createContainerBatch } from "../../../../_services/container.service";
import toastService from "../../../../_services/toast.service";
import t from "../../../../_config/constants";

import Input from "../../../input";
import Selector from "../../../selector";
import Button from "../../../button";

const CreateBatchForm = ({ options, refetch, setLoading, loading }) => {
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState({
    containerTypeId: "",
    amount: "",
  });

  const validateForm = () => {
    return (
      data.containerTypeId &&
      data.amount &&
      data.amount > 0 &&
      Number(data.amount) === Math.round(data.amount)
    );
  };

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };

  const handleChange = (e) => {
    e.preventDefault();
    setError(false);
    let { name, value } = e.target;
    if (name === "amount" && value.slice(0, 1) === "0") {
      value = value.slice(1);
    }
    setData((data) => ({ ...data, [name]: value }));
  };

  const handleSelectContainer = (e) => {
    setError(false);
    setData((data) => ({
      ...data,
      containerTypeId: e.value,
    }));
  };

  const submitBatch = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setDisabled(true);
      setLoading(true);
      await createContainerBatch(data);
      setData({
        containerTypeId: "",
        amount: "",
      });
      refetch();
      toastService.show(
        "success",
        t.containerManagement.add.text_successfully_created_batch
      );
    } else {
      toastService.show("error", `${t.common.text_toast_all_fields_required}`);
      setError(true);
    }
    setDisabled(false);
  };
  const handleInputNumber = (e) => {
    const numbers = "0123456789";
    if (!numbers.includes(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <div className={styles.form}>
      <form>
        <div className={styles.select}>
          <Selector
            label=""
            placeholder={t.containerManagement.add.text_select_placeholder}
            options={options}
            multiselect={false}
            selectedOption={getSelectedValue(options, data.containerTypeId)}
            handleChangeSelect={(e) => handleSelectContainer(e)}
            selectClassname="selectSecondary"
          />
        </div>
        <div className={styles.container}>
          <Input
            label=""
            name="amount"
            inputClass="inputBorder"
            type="number"
            onChange={handleChange}
            value={data.amount}
            placeholder={t.containerManagement.add.text_th_amount}
            onKeyPress={handleInputNumber}
          />
          <Button
            btnClass="btnNormalCasal"
            label={t.containerManagement.add.text_add_containers}
            onClick={submitBatch}
            disabled={
              !data.amount || !data.containerTypeId || disabled || loading
            }
          />
        </div>
      </form>{" "}
      <div className={styles.error}>
        {<p>{error ? t.common.text_all_fields_required : " "}</p>}
      </div>
    </div>
  );
};

export default CreateBatchForm;
