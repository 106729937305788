import React, { useEffect } from "react";
import styles from "./dropoffTable.module.scss";
import Loading from "../../../loading";
import restaurantImg from "./../../../../_assets/icons/restaurantManagementImg.svg";
import Button from "../../../button";
import arrowUp from "../../../../_assets/icons/arrowUp.svg";
import arrowDown from "../../../../_assets/icons/arrowDown.svg";
import upDownArrow from "../../../../_assets/icons/upDownArrow.svg";
import useSortableData from "../../../../_utils/useSortableData";
import t from "../../../../_config/constants";
import { FcCheckmark } from "react-icons/fc";
const DropoffTable = ({
  locations,
  selectedLocationId,
  onSelectLocation,
  loadingData,
  changeSortConfig,
}) => {
  const { handleSort, sortConfig } = useSortableData(locations);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return upDownArrow;
    }
    return sortConfig.key === name
      ? sortConfig.direction === "ascending"
        ? arrowUp
        : arrowDown
      : upDownArrow;
  };
  useEffect(() => {
    changeSortConfig(sortConfig);
  }, [sortConfig]);
  const showLocations = locations.map((location) => {
    return (
      <tr
        key={location._id}
        className={location._id === selectedLocationId ? styles.selected : ""}
        onClick={() => {
          onSelectLocation(location._id);
        }}
      >
        <td>
          {location.isDraft ? (
            <span className={styles.isDraft}> [Draft] </span>
          ) : (
            ""
          )}
          {location.name}
        </td>
        <td>{location.totalContainers}</td>
        <td>{location.description}</td>
        <td>{location.isRestaurant ? <img src={restaurantImg} /> : ""}</td>
        <td>
          {location.visible != undefined && location.visible === true ? (
            <div className={styles.visible}>
              <FcCheckmark />
            </div>
          ) : null}
        </td>
      </tr>
    );
  });

  const noLocationMsg = (
    <p className={styles.noLocationMsg}>{t.dropoff.text_no_locations}</p>
  );

  return (
    <div className={styles.tableWrapper}>
      <table>
        <thead>
          <tr>
            <th onClick={() => (loadingData ? null : handleSort("name"))}>
              {t.dropoff.text_th_name}
              <Button btnClass="btnBack" iconLeft={getClassNamesFor("name")} />
            </th>
            <th
              onClick={() =>
                loadingData ? null : handleSort("totalContainers")
              }
            >
              {t.dropoff.text_number_of_containers}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("totalContainers")}
              />
            </th>
            <th
              onClick={() => (loadingData ? null : handleSort("description"))}
            >
              {t.dropoff.text_th_description}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("description")}
              />
            </th>
            <th
              onClick={() => (loadingData ? null : handleSort("isRestaurant"))}
            >
              {t.dropoff.text_th_restaurant}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("isRestaurant")}
              />
            </th>

            <th onClick={() => (loadingData ? null : handleSort("visible"))}>
              {t.common.text_visible}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("visible")}
              />
            </th>
          </tr>
        </thead>
        {!loadingData && locations.length > 0 && (
          <tbody>{showLocations.length > 0 && showLocations}</tbody>
        )}
      </table>
      {!loadingData && !locations.length && noLocationMsg}
      {loadingData && (
        <div className={styles.loading}>
          <Loading width={50} height={50} />
        </div>
      )}
    </div>
  );
};
export default DropoffTable;
