import React, { useState, useEffect } from "react";
import styles from "./containerManagement.module.scss";
import AddContainer from "../../_components/containers/add";
import CreateContainer from "../../_components/containers/create";
import useIsCleaner from "../../_utils/isCleaner";

const ContainerManagement = () => {
  const [activeTab, setActiveTab] = useState("add");
  const isCleaner = useIsCleaner();
  useEffect(() => {
    isCleaner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {activeTab === "add" && (
        <AddContainer
          onAddClick={() => setActiveTab("add")}
          onCreateClick={() => setActiveTab("new")}
          onEditClick={() => setActiveTab("edit")}
          activeTab={activeTab}
        />
      )}
      {activeTab === "new" && (
        <CreateContainer
          onAddClick={() => setActiveTab("add")}
          onCreateClick={() => setActiveTab("new")}
          onEditClick={() => setActiveTab("edit")}
          activeTab={activeTab}
        />
      )}
      {activeTab === "edit" && (
        <CreateContainer
          onAddClick={() => setActiveTab("add")}
          onCreateClick={() => setActiveTab("new")}
          onEditClick={() => setActiveTab("edit")}
          activeTab={activeTab}
        />
      )}
    </>
  );
};

export default ContainerManagement;
