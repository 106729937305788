import React, { useState, useEffect, useRef } from "react";

import { FaRegSmile } from "react-icons/fa";
import Picker from "emoji-picker-react";

import Button from "../../button";
import Input from "../../input";

import userService from "../../../_services/user.service";
import toastService from "../../../_services/toast.service";
import t from "../../../_config/constants";

import styles from "./../smsModal/smsModal.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { makeSelectMap } from "../../../_utils/utils";
import { mainActions } from "../../../_store/actions/main.actions";
import Selector from "../../selector";

const ReuserCardModal = ({
  handleClose,
  handleDisabled,
  handleCancel,
  setRefresh,
}) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  const [user, setUser] = useState({});
  const [errorMsg, setErrorMsg] = useState(false);
  const [saving, setSaving] = useState(false);
  const [regionOptions, setRegionOptions] = useState([]);
  const [subregionOptions, setSubregionOptions] = useState([]);
  const [subregions, setSubregions] = useState([]);

  const [data, setData] = useState({
    regionId: "",
    subregionId: "",
    amount: 0,
  });
  const [error, setError] = useState({
    regionId: false,
    amount: false,
  });
  useEffect(() => {
    const fetchSubregions = async () => {
      const response = await dispatch(
        mainActions.run("data", "subregion", "all", {})
      );
      setSubregions(response);
    };
    fetchSubregions();
  }, []);
  useEffect(() => {
    const fetchRegions = async () => {
      const response = await dispatch(
        mainActions.run("data", "region", "all", {})
      );
      if (!response) return;
      const arr = makeSelectMap(response);
      setRegionOptions(arr);
    };

    setUser(store.auth.user);
    fetchRegions();
    if (store.auth.user.regionId != "*") {
      setData((prev) => ({ ...prev, regionId: store.auth.user.regionId }));
      setError((error) => ({ ...error, regionId: false }));
    }
  }, [store.auth && store.auth.user]);

  useEffect(() => {
    if (data.regionId) {
      const filteredSubregions = subregions.filter(
        (item) => item.regionId === data.regionId
      );
      const arr = makeSelectMap(filteredSubregions);
      arr.length && arr.push({ label: "-", value: "" });
      setSubregionOptions(arr);
      setData((prev) => ({ ...prev, subregionId: "" }));
    }
  }, [data.regionId, subregions]);
  const handleChange = (e) => {
    setErrorMsg(false);
    let { name, value } = e.target;

    if (name === "amount" && value.slice(0, 1) === "0") {
      value = value.slice(1);
    }

    setData((prev) => ({ ...prev, [name]: value }));
    setError((error) => ({ ...error, [name]: !value }));
  };
  const handleInputNumber = (e) => {
    const numbers = "0123456789";
    if (!numbers.includes(e.key)) {
      e.preventDefault();
    }
  };
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };

  const handleSelect = (e, type) => {
    setErrorMsg(false);
    setData((data) => ({ ...data, [type]: e.value }));
    setError((error) => ({ ...error, [type]: false }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data.regionId && data.amount) {
      setSaving(true);
      handleDisabled(true);
      document.body.style.cursor = "wait";
      try {
        const res = await userService.createReuserCards(data);
        if (res) {
          toastService.show("success", `Successfully created Reuser cards`);
        }
      } catch (err) {
        toastService.show("error", "Something went wrong. Try again.");
      }
      setRefresh((prev) => !prev);
      handleClose();
      handleCancel();

      handleDisabled(false);
      setSaving(false);
    } else {
      setErrorMsg(true);
      setError({
        regionId: !data.regionId,
        amount: !data.amount,
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <h3>Add Reuser Cards</h3>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.reuserCardBody}>
          {user.regionId === "*" && (
            <div className={styles.selectRegion}>
              <Selector
                options={regionOptions}
                error={error.regionId}
                multiselect={false}
                selectedOption={getSelectedValue(regionOptions, data.regionId)}
                handleChangeSelect={(e) => handleSelect(e, "regionId")}
                selectClassname="selectPrimary"
                placeholder={t.common.input.text_select_region}
                label={t.common.input.text_select_region}
              />
            </div>
          )}
          <div className={styles.selectRegion}>
            <Selector
              options={subregionOptions}
              multiselect={false}
              selectedOption={getSelectedValue(
                subregionOptions,
                data.subregionId
              )}
              handleChangeSelect={(e) => handleSelect(e, "subregionId")}
              selectClassname="selectPrimary"
              placeholder={"Select Subregion"}
              label={"Select Subregion"}
            />
          </div>
          <Input
            label={"Amount"}
            error={error.amount}
            name="amount"
            inputClass="inputLogin"
            type="number"
            value={data.amount}
            onChange={handleChange}
            onKeyPress={handleInputNumber}
          />
          <Button
            label={t.button.text_add}
            disabled={saving}
            btnClass="btnNormalGlacier"
            onClick={handleSubmit}
          />
        </div>{" "}
      </form>
      {errorMsg ? (
        <div className={styles.error}>
          <p>{t.common.text_all_fields_required}</p>
        </div>
      ) : null}
    </div>
  );
};
export default ReuserCardModal;
