import React from "react";
import styles from "./lineInput.module.scss";
const LineInput = ({
  inputClass = "input-wrapper",
  type,
  name,
  value,
  placeholder,
  onChange,
  autofocus = false,
  disabled = false,
  maxLength,
  error = false,
  title,
  pattern,
  errorMsg = "",
}) => {
  return (
    <div className={`${styles[inputClass]} ${error ? styles.borderRed : ""}`}>
      <input
        className={styles["input-field"]}
        id={name}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        autoFocus={autofocus}
        disabled={disabled}
        autoComplete={"off"}
        maxLength={maxLength}
        pattern={pattern}
        title={title}
      />
      {error && errorMsg && <div className={styles.errorMsg}>{errorMsg}</div>}{" "}
    </div>
  );
};

export default LineInput;
