import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "./index.scss";
import App from "./App";
import { store } from "./_store/store";
import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer
        className="toast"
        toastClassName="toast-wrapper"
        bodyClassName="toast-body"
      />
      <Suspense fallback={"add loader here"}>
        <App />
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
