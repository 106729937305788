import React, { useEffect, useState } from "react";
import styles from "./transferHistory.module.scss";
import useDebounce from "../../../../_utils/debounce";
import SearchBar from "../../../searchBar";
import Button from "../../../button";
import Loading from "./../../../loading";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../../_store/actions/main.actions";
import { termRegex } from "../../../../_utils/utils";
import arrowDown from "../../../../_assets/icons/blackArrowDown.svg";
import arrowUp from "../../../../_assets/icons/blackArrowUp.svg";
import upDownArrow from "../../../../_assets/icons/blackUpDown.svg";
import useSortableData from "../../../../_utils/useSortableData";
import t from "../../../../_config/constants";
import Pagination from "../../../pagination";
import moment from "moment";
const TransferHistory = ({ update, handleView }) => {
  const [transferList, setTransferList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState({
    searchTerm: "",
    selectedValue: "",
  });
  const debouncedSearchTerm = useDebounce(filterData.searchTerm, 500);
  const dispatch = useDispatch();
  const { handleSort, sortConfig } = useSortableData(transferList);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [numberOfTransfers, setNumberOfTransfers] = useState(0);
  const [sortDataConfig, setSortDataConfig] = useState({});

  const getTransferList = async (
    term,
    selectValue,
    pageNumber,
    sortConfig = { createdAt: -1 }
  ) => {
    let filter = [];
    if (term) {
      filter.push({
        $or: [
          { fromStoreName: { $regex: `${termRegex(term)}`, $options: "i" } },
          { toStoreName: { $regex: `${termRegex(term)}`, $options: "i" } },
        ],
      });
    }
    let data = await dispatch(
      mainActions.run("data", "transferForm", "page", {
        filter,
        pageSize,
        pageNumber,
        sort: sortConfig,
      })
    );
    if (!data) data = { rows: [], total: 0 };

    setTransferList(data.rows);
    setNumberOfTransfers(data.total);
    setLoading(false);
  };
  useEffect(() => {
    setPageNumber(0);
  }, [debouncedSearchTerm]);
  useEffect(() => {
    setLoading(true);
    getTransferList(
      debouncedSearchTerm,
      filterData.selectedValue,
      pageNumber,
      sortDataConfig
    );
  }, [
    debouncedSearchTerm,
    filterData.selectedValue,
    update,
    pageNumber,
    sortDataConfig,
  ]);

  const onChangeTerm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterData((prevState) => ({ ...prevState, [name]: value }));
  };
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return upDownArrow;
    }
    return sortConfig.key === name
      ? sortConfig.direction === "ascending"
        ? arrowUp
        : arrowDown
      : upDownArrow;
  };
  useEffect(() => {
    if (sortConfig) {
      const { key, direction } = sortConfig;
      setSortDataConfig({ [key]: direction === "ascending" ? 1 : -1 });
    }
  }, [sortConfig]);

  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };
  const noTransferList = (
    <p className={styles.noTransferList}>{t.transfer.text_no_transfers}</p>
  );
  return (
    <div className={styles.wrapper}>
      <h3>{t.transfer.text_history}</h3>
      <div className={styles.searchAndSelect}>
        <div className={styles.select}>
          <SearchBar
            customClass="transactionSearch"
            placeholder={t.distribution.text_search_restaurants}
            name="searchTerm"
            value={filterData.searchTerm}
            onChange={onChangeTerm}
            disabled={loading}
          />
        </div>
      </div>
      <div className={styles.list}>
        <div className={styles.header}>
          <p className={styles.blockRestaurant}>{t.transfer.text_from_store}</p>
          <p className={styles.blockRestaurant}>{t.transfer.text_to_store}</p>
          <div
            className={styles.blockCreated}
            onClick={() => handleSort("createdAt")}
          >
            {t.common.text_th_created}
            <Button
              btnClass="btnBack"
              iconLeft={getClassNamesFor("createdAt")}
            />
          </div>
          <div className={styles.blockButton}></div>
        </div>
        {loading && <Loading width={50} height={50} />}
        {!loading && !transferList.length && noTransferList}
        {!loading &&
          transferList.length > 0 &&
          transferList.map((item, index) => {
            return (
              <div className={styles.block} key={index}>
                <p className={styles.blockRestaurant}>{item.fromStoreName}</p>
                <p className={styles.blockRestaurant}>{item.toStoreName}</p>
                <p className={styles.blockCreated}>
                  {moment(item.createdAt).format("MM/DD/YYYY")}
                </p>{" "}
                <div className={styles.blockButton}>
                  <Button
                    label={t.button.text_view}
                    btnClass="btnNormalGlacier"
                    onClick={() => handleView(item)}
                  />
                </div>
              </div>
            );
          })}
      </div>
      <div className={styles.pagination}>
        <Pagination
          handlePageClick={onPageChange}
          pageCount={Math.ceil(numberOfTransfers / pageSize)}
          forcePage={pageNumber}
          loading={loading}
        />
      </div>
    </div>
  );
};
export default TransferHistory;
