export const monthsOptions = [
  { label: "January", value: 0 },
  { label: "February", value: 1 },
  { label: "March", value: 2 },
  { label: "April", value: 3 },
  { label: "May", value: 4 },
  { label: "June", value: 5 },
  { label: "July", value: 6 },
  { label: "August", value: 7 },
  { label: "September", value: 8 },
  { label: "October", value: 9 },
  { label: "November", value: 10 },
  { label: "December", value: 11 },
];

export const yearsOptions = (() => {
  const year = new Date().getFullYear();
  const array = [];
  for (let i = 2022; i <= year; i++) {
    array.push({ label: i, value: i });
  }
  return array;
})();

export const dateOptions = Array.from({ length: 31 }, (_, index) => ({
  value: index + 1,
  label: index + 1,
}));

export const numberOptions = Array.from({ length: 99 }, (_, index) => ({
  value: index + 1,
  label: index + 1,
}));

export const periodOptions = [
  { label: "Hours", value: 60 * 60 * 1000 },
  { label: "Days", value: 24 * 60 * 60 * 1000 },
  { label: "Weeks", value: 7 * 24 * 60 * 60 * 1000 },
  { label: "Months", value: 30 * 24 * 60 * 60 * 1000 },
];
