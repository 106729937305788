import React from "react";
import styles from "./input.module.scss";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { IoMdCopy } from "react-icons/io";
const Input = ({
  inputClass,
  type,
  name,
  value,
  placeholder,
  onChange,
  autofocus = false,
  disabled = false,
  label,
  desc,
  preDesc,
  onKeyPress,
  autoComplete,
  maxLength,
  error = false,
  title,
  pattern,
  errorMsg,
  showEyeIcon = false,
  onClickEyeIcon,
  showCopyToClipboard = false,
}) => {
  return (
    <div
      className={`${styles[inputClass]} ${preDesc ? styles.preDescInput : ""} 
      ${
        error &&
        [
          "inputForm",
          "inputTable",
          "inputFormWhite",
          "inputLoginBorder",
        ].includes(inputClass)
          ? styles.borderRed
          : ""
      }`}
    >
      {label && (
        <label htmlFor={name}>
          {label} {error && <span> *{errorMsg}</span>}{" "}
        </label>
      )}{" "}
      {!label &&
        error &&
        !["inputFormWhite", "inputTable", "inputLoginBorder"].includes(
          inputClass
        ) && <span> *</span>}
      {desc && <small className={styles.desc}>{desc}</small>}
      {preDesc && <small className={styles.preDesc}>{preDesc}</small>}
      <input
        className={styles.input}
        id={name}
        type={type}
        name={name}
        onKeyPress={onKeyPress}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        autoFocus={autofocus}
        disabled={disabled}
        autoComplete={"off"}
        maxLength={maxLength}
        pattern={pattern}
        title={title}
      />
      <div className={styles.eyeIcon}>
        {showEyeIcon && type === "text" && (
          <AiFillEye onClick={onClickEyeIcon} />
        )}
        {showEyeIcon && type === "password" && (
          <AiFillEyeInvisible onClick={onClickEyeIcon} />
        )}
      </div>
      <div className={styles.copyToClipboard}>
        {showCopyToClipboard && (
          <IoMdCopy
            onClick={() => {
              navigator.clipboard.writeText(value);
            }}
            color={false ? "#929292" : "#000000"}
            title="Copy to clipboard"
          />
        )}
      </div>
    </div>
  );
};

export default Input;
