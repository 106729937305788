import React from "react";
import styles from "../faqContainer.module.scss";

import Input from "../../../../input";
import Textarea from "../../../../textarea";

const FAQQAContainer = ({ data, editing, handleChange }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.question}>
        {editing ? (
          <Input
            value={data.question}
            inputClass="inputLoginBorder"
            name="question"
            onChange={handleChange}
            error={!data.question}
          />
        ) : (
          <p>Q: {data.question}</p>
        )}
      </div>
      {editing ? (
        <div className={styles.answer}>
          <Textarea
            textareaClass="textareaWhite"
            value={data.answer}
            name="answer"
            onChange={handleChange}
            error={!data.answer}
          />
        </div>
      ) : (
        <p className={styles.textAnswer}>A: {data.answer}</p>
      )}
    </div>
  );
};

export default FAQQAContainer;
