import React, { useContext, useState } from "react";
import styles from "./singleCoupon.module.scss";
import moment from "moment";
import toastService from "../../../_services/toast.service";
import { deletePromoCode } from "../../../_services/payment.service";
import Button from "../../button";
import Input from "../../input";
import ConfirmModal from "../../modal/confirmModal";
import t from "../../../_config/constants";
import { CouponContext } from "../../../_pages/couponManagement";
import { isObjectEmpty } from "../../../_utils/utils";
const SingleCoupon = () => {
  const [
    ,
    selectedCoupon,
    setSelectedCoupon,
    ,
    ,
    closeModalAndRefresh,
    ,
    ,
    ,
    ,
    setStep,
  ] = useContext(CouponContext);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const handleDelete = async (answer) => {
    if (answer) {
      let response = await deletePromoCode(selectedCoupon);
      if (response) {
        toastService.show("success", t.coupon.text_successfully_deleted);
        setShowModalDelete(false);
        setSelectedCoupon({});
        closeModalAndRefresh();
      }
    } else {
      setShowModalDelete(false);
    }
  };
  return (
    <>
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={handleDelete}
          modalData={{}}
          question={t.coupon.text_modal_delete}
        />
      )}
      {isObjectEmpty(selectedCoupon) && (
        <div className={styles.noData}>
          <span>{t.coupon.text_select_guideline}</span>
        </div>
      )}
      {!isObjectEmpty(selectedCoupon) && (
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Button
              label={t.button.text_delete}
              btnClass="btnWhiteBackground"
              disabled={false}
              onClick={() => setShowModalDelete(true)}
            />
          </div>
          <div className={styles.couponInfos}>
            <h2>{selectedCoupon.name || ""}</h2>
            <p>Coupon Information</p>
            <Input
              inputClass="inputForm"
              value={selectedCoupon.code}
              disabled={true}
              label={"Promo Code"}
            />
            <Input
              inputClass="inputForm"
              value={
                selectedCoupon.promoCodeData.coupon.amount_off
                  ? Number(
                      selectedCoupon.promoCodeData.coupon.amount_off / 100
                    ).toFixed(2)
                  : selectedCoupon.promoCodeData.coupon.percent_off
              }
              disabled={true}
              label={
                selectedCoupon.promoCodeData.coupon.amount_off
                  ? "Fixed Amount Discount"
                  : "Percentage Discount"
              }
              desc={
                selectedCoupon.promoCodeData.coupon.amount_off ? "CA$" : "%"
              }
            />
            <Input
              inputClass="inputForm"
              value={
                selectedCoupon.promoCodeData.coupon.duration === "repeating"
                  ? selectedCoupon.promoCodeData.coupon.duration_in_months === 1
                    ? `1 month`
                    : ` ${selectedCoupon.promoCodeData.coupon.duration_in_months} months`
                  : selectedCoupon.promoCodeData.coupon.duration
              }
              disabled={true}
              label={"Duration"}
            />
            {selectedCoupon.promoCodeData.coupon.max_redemptions && (
              <Input
                inputClass="inputForm"
                value={selectedCoupon.promoCodeData.coupon.max_redemptions}
                disabled={true}
                label={"Maximum redemptions"}
              />
            )}
            {selectedCoupon.promoCodeData.coupon.redeem_by && (
              <Input
                inputClass="inputForm"
                value={moment(
                  selectedCoupon.promoCodeData.coupon.redeem_by * 1000
                ).format("MMM DD, YYYY")}
                disabled={true}
                label={"Expires on"}
              />
            )}
          </div>
          <div className={styles.showDetails}>
            <Button
              label={t.button.text_show_details}
              btnClass={"btnNormalCasal"}
              onClick={() => setStep("details")}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SingleCoupon;
