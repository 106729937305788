import styles from "./downloadPNGs.module.scss";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import Button from "../../../button";
import Input from "../../../input";

import { isObjectEmpty } from "../../../../_utils/utils";
import { mainActions } from "../../../../_store/actions/main.actions";
import { qrFunction } from "../../qrCode/qrPNGfunction";

const DownloadPNGs = ({ selectedBatch }) => {
  const dispatch = useDispatch();
  const [dimension, setDimension] = useState(500);
  const [containers, setContainers] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const fetchContainers = async (id) => {
    let response = await dispatch(
      mainActions.run("data", "container", "all", { filter: { batchId: id } })
    );
    if (response) {
      const data = [];
      const containers = response.sort(
        (a, b) =>
          Number(a.uniqueContainerId.split("/")[2]) >
          Number(b.uniqueContainerId.split("/")[2])
      );
      for (let i = 0; i < containers.length; i += 100) {
        const slicedArr = containers.slice(i, i + 100);
        data.push(slicedArr);
      }
      setContainers(data || []);
    }
  };

  useEffect(() => {
    setContainers([]);
    if (!isObjectEmpty(selectedBatch)) {
      fetchContainers(selectedBatch._id);
    } else {
      setContainers([]);
    }
  }, [selectedBatch]);

  const handleDownload = async (containers, range) => {
    setDownloading(true);
    let zip = new JSZip();
    await Promise.all(
      containers.map((item) => {
        return qrFunction(
          dimension,
          item._id,
          item.uniqueContainerId,
          selectedBatch.uniqueBatchId,
          zip
        );
      })
    )
      .then(() => {
        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, selectedBatch.uniqueBatchId + `(${range})` + ".zip");
        });
        setDownloading(false);
      })
      .catch((err) => {
        setDownloading(false);
      });
  };

  const handleInputNumber = (e) => {
    const numbers = "0123456789";
    if (!numbers.includes(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <div className={styles.wrapper}>
      <h3>Download QR codes</h3>
      <h4>
        {selectedBatch.name}-{selectedBatch.uniqueBatchId}
      </h4>
      <Input
        label={"QR code Height/Width (px)"}
        inputClass="inputForm"
        value={dimension}
        type="number"
        errorMsg={"LTE 2048px"}
        error={!dimension || dimension > 2048}
        onKeyPress={handleInputNumber}
        onChange={(e) => {
          setDimension(e.target.value ? Number(e.target.value) : "");
        }}
      />
      {containers.length &&
        containers.map((containersSlice, ind) => {
          const start = ind * 100 + 1;
          const end =
            containersSlice.length === 100
              ? (ind + 1) * 100
              : ind * 100 + containersSlice.length;
          const range = start + "-" + end;
          return (
            <div key={ind} className={styles.row}>
              <p>{range}</p>
              <Button
                disabled={
                  containersSlice.length === 0 ||
                  Number(dimension) !== dimension ||
                  !dimension ||
                  dimension > 2048 ||
                  downloading
                }
                label={"Download"}
                btnClass="btnWhiteBackground"
                onClick={() => handleDownload(containersSlice, range)}
              />
            </div>
          );
        })}
    </div>
  );
};

export default DownloadPNGs;
