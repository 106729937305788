import React from "react";
import styles from "./monthlyPayment.module.scss";
import Button from "../../../button/index";
import close from "../../../../_assets/icons/close.svg";
import moment from "moment";
import t from "../../../../_config/constants";
import { capitalizeWord } from "../../../../_utils/utils";
import { sortUserTransactions } from "../../../../_utils/mapTransactions";
import { modalActions } from "../../../../_store/actions/modal.actions";
import ContainerDetails from "../../../containers/add/list/details";
import { useDispatch } from "react-redux";
const MonthlyPayment = ({ transaction, param, handleExit }) => {
  const dispatch = useDispatch();
  const subtotal = (
    transaction.amount /
    (1.05 + (transaction.pst ? transaction.pst / 100 : 0))
  ).toFixed(2);
  const details = transaction?.transactionList
    ? sortUserTransactions(transaction.transactionList).map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.name}</td>
            <td>{item.quantity} pcs</td>
            <td>$ {Number(item.price).toFixed(2)}</td>
            <td>$ {Number(item.quantity * item.price).toFixed(2)}</td>
          </tr>
        );
      })
    : "";
  return (
    <div className={styles.wrapper}>
      <div className={styles.monthlyTransactions}>
        <div className={styles.transactions}>
          <div className={styles.closeButton}>
            <Button btnClass="btnClose" iconLeft={close} onClick={handleExit} />
          </div>
          <div className={styles.table}>
            <h1>
              {param}{" "}
              {param == "Charged Container" && (
                <span
                  className={styles.name}
                  title={"Show history"}
                  onClick={() =>
                    dispatch(
                      modalActions.openModal(
                        <ContainerDetails
                          containerId={transaction?.container?._id}
                        />,
                        "",
                        "xlg"
                      )
                    )
                  }
                >
                  : {transaction?.container?.uniqueContainerId}
                </span>
              )}
            </h1>
            {param != "Per Use Report" && param != "Per Use Charge" ? (
              <table>
                <caption>{t.common.text_details}</caption>
                <thead>
                  <tr>
                    <th>{t.common.text_th_service}</th>
                    <th>{t.common.text_th_status}</th>
                    {param != "Refund" ? (
                      <th>{t.common.text_th_charged}</th>
                    ) : (
                      <th>{t.common.text_th_amount}</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>{transaction.info}</td>
                    <td>{capitalizeWord(transaction.status)}</td>
                    <td>
                      {transaction.currency?.slice(0, 2).toUpperCase()}${" "}
                      {transaction.amount}
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <table>
                <caption>{t.restaurant.text_details}</caption>
                <thead>
                  <tr>
                    <th>{t.restaurant.text_th_containers}</th>
                    <th>{t.restaurant.text_th_replenishment}</th>
                    <th>{t.restaurant.text_th_price}</th>
                    <th>{t.restaurant.text_th_charged}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {details}
                </tbody>
              </table>
            )}
            {param != "Refund" &&
              param != "Penalty Charge" &&
              param != "Charged Container" &&
              param != "Per Use Report" &&
              param != "Per Use Charge" && (
                <div className={styles.priceRow}>
                  <div>
                    <span className={styles.totalPrice}>
                      <span> Subtotal:</span> ${" "}
                      {(transaction.amount / 1.05).toFixed(2)}
                    </span>
                    <span className={styles.totalPrice}>
                      <span> Tax (GST):</span>${" "}
                      {((transaction.amount * 0.05) / 1.05).toFixed(2)}
                    </span>
                    <span className={styles.totalPrice}>
                      <span> Total:</span>
                      {transaction.currency?.slice(0, 2).toUpperCase()}${" "}
                      {Number(transaction.amount).toFixed(2)}
                    </span>
                  </div>
                </div>
              )}
            {(param == "Charged Container" ||
              param == "Per Use Charge" ||
              param == "Per Use Report") && (
              <div className={styles.priceRow}>
                <div>
                  <span className={styles.totalPrice}>
                    <span> Subtotal:</span> $ {subtotal}
                  </span>
                  <span className={styles.totalPrice}>
                    <span> Tax (GST):</span>$ {(subtotal * 0.05)?.toFixed(2)}
                  </span>
                  <span className={styles.totalPrice}>
                    <span> Tax (PST):</span>${" "}
                    {(
                      (subtotal * (transaction.pst ? transaction.pst : 0)) /
                      100
                    )?.toFixed(2)}
                  </span>
                  <span className={styles.totalPrice}>
                    <span> Total:</span>
                    {transaction.currency?.slice(0, 2).toUpperCase()}${" "}
                    {Number(transaction.amount).toFixed(2)}
                  </span>
                </div>
              </div>
            )}
            <p className={styles.totalPrice}></p>
            <div className={styles.date}>
              <p className={styles.createdDate}>
                {t.common.text_th_created}:{" "}
                {moment(transaction.createdAt).format("MM/DD/YYYY")}
              </p>
              <div>
                <p className={styles.signatureLine}></p>
                <p className={styles.signature}>{t.common.text_signature}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MonthlyPayment;
