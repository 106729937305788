import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Modal from "react-modal";
import styles from "./confirmModal.module.scss";
import Button from "../button";

const ConfirmModal = ({
  show,
  handleYesNoAnswer,
  modalData,
  question,
  confirmLabel = "Delete",
}) => {
  const [modalIsOpen] = useState(show);
  const [t] = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const handleSubmit = (answer) => {
    handleYesNoAnswer(answer, modalData);
    if (answer) setDisabled(true);
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      contentLabel="Custom modal"
      className={`${styles.customModal} ${styles.md} ${styles.modalYesNo}`}
      overlayClassName={styles.customOverlay}
      ariaHideApp={false}
    >
      <div className={styles.modalBody}>
        <span
          className={styles.close}
          onClick={() => {
            handleSubmit(false);
          }}
        >
          <i className="fa fa-times" aria-hidden="true"></i>
        </span>
        <h4 className={styles.title}> {t(question)}</h4>
        <div className={styles.modalFooter}>
          <Button
            btnClass="btnWhiteBackground"
            label="Cancel"
            onClick={() => {
              handleSubmit(false);
            }}
            disabled={disabled}
          />
          <Button
            btnClass="btnNormal"
            label={confirmLabel}
            onClick={() => {
              handleSubmit(true);
            }}
            disabled={disabled}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
