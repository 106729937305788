import React, { useState } from "react";
import styles from "./faqContainer.module.scss";

import { RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlineEdit } from "react-icons/ai";

import Input from "../../../input";
import Button from "../../../button";
import FAQQAContainer from "./faqQAContainer";

import t from "../../../../_config/constants";

const FAQContainer = ({
  titleInd,
  data,
  deleteSection,
  handleAddQA,
  deleteQA,
  handleChangeTitle,
  handleChange,
  editingTitle,
  setEditingTitle,
  handleSubmit,
  handleCancel,
  errorMsg,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <p
          onClick={() => {
            setShowDetails((prev) => !prev);
          }}
          className={styles.showDetails}
        >
          {showDetails || editingTitle === titleInd ? "-" : "+"}
        </p>
        {editingTitle === titleInd ? (
          <Input
            value={data.title}
            inputClass="inputFormWhite"
            name="title"
            onChange={(e) => {
              handleChangeTitle(e, titleInd);
            }}
            error={!data.title}
          />
        ) : (
          <p className={styles.titleText}>{data.title}</p>
        )}

        {editingTitle === titleInd ? (
          !data.questions.length ? (
            <RiDeleteBin5Line
              onClick={() => {
                deleteSection(titleInd);
              }}
            />
          ) : null
        ) : editingTitle === null ? (
          <AiOutlineEdit
            onClick={() => {
              setEditingTitle(titleInd);
              setShowDetails(true);
            }}
          />
        ) : null}
      </div>
      {(showDetails || editingTitle === titleInd) && (
        <>
          <div className={styles.questions}>
            {data.questions.map((item, ind) => (
              <div key={ind} className={styles.qa}>
                <FAQQAContainer
                  data={item}
                  handleChange={(e) => handleChange(e, titleInd, ind)}
                  editing={editingTitle === titleInd}
                />
                {editingTitle === titleInd && (
                  <RiDeleteBin5Line
                    onClick={() => {
                      deleteQA(titleInd, ind);
                    }}
                  />
                )}
              </div>
            ))}
          </div>
          {editingTitle === titleInd && (
            <div className={styles.addQA} onClick={() => handleAddQA(titleInd)}>
              <span>+</span>
              <p>Add QA</p>
            </div>
          )}
        </>
      )}
      {errorMsg && editingTitle === titleInd && (
        <p className={styles.error}>{t.common.text_all_fields_are_required}</p>
      )}
      {editingTitle === titleInd && (
        <div className={styles.buttons}>
          <Button
            btnClass="btnWhiteBackground"
            label={"Cancel"}
            onClick={handleCancel}
          />
          <Button
            btnClass="btnNormalCasal"
            label={"Save"}
            onClick={() => {
              handleSubmit(titleInd);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FAQContainer;
