import React, { useEffect } from "react";
import Header from "../../_components/dashboard/header";
import styles from "./dashboard.module.scss";
import DashboardRestaurant from "../../_components/dashboard/restaurant";
import DashboardUsers from "../../_components/dashboard/users";
import useIsNotAdmin from "../../_utils/isNotAdmin";

const Dashboard = () => {
  const isNotAdmin = useIsNotAdmin();
  useEffect(() => {
    isNotAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.wrapper}>
      <Header />
      <div className={styles.content}>
        <div className={styles.right}>
          <DashboardRestaurant />
        </div>
        <div className={styles.left}>
          <DashboardUsers />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
