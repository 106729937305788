import React from "react";
import moment from "moment";
import styles from "./returnedTable.module.scss";
import Loading from "../../loading";
import statusImage from "../../../_assets/icons/exclamation-mark.svg";
import t from "../../../_config/constants";

const ReturnedTable = ({ containers, loadingData, onUpdate }) => {
  const getOverdue = (item) => {
    let duedate = moment(item.days_to_return).startOf("day");
    let returned = moment(item.return_date).startOf("day");
    let overdue = returned.diff(duedate, "days");
    return overdue < 1 ? false : overdue;
  };

  return (
    <>
      <div className={styles.list}>
        <div className={styles.header}>
          <p className={styles.blockUser}>{t.report.text_user}</p>
          <p className={styles.blockContainer}>{t.report.text_container}</p>
          <p className={styles.blockContainer}>{t.report.text_uniqueId}</p>
          <p className={styles.blockLocation}>{t.report.text_location}</p>
          <p className={styles.blockReturned}>{t.report.text_returned}</p>
          <p className={styles.blockDuedate}>{t.report.text_dueDate}</p>
          <p className={styles.blockOverdue}>{t.report.text_overdue}</p>
        </div>
        {loadingData && (
          <div className={styles.loader}>
            <Loading width={75} height={75} />
          </div>
        )}
        {!loadingData && !containers.length && (
          <p className={styles.noContainersMsg}>{t.report.text_no_reports}</p>
        )}
        {!loadingData &&
          containers.length > 0 &&
          containers.map((item) => {
            return (
              <div className={styles.block} key={item._id}>
                <p className={styles.blockUser}>
                  {item?.user
                    ? item?.user?.fullName || t.common.text_not_available
                    : t.report.text_deleted}
                </p>
                <p className={styles.blockContainer}>{item.name}</p>
                <p className={styles.blockContainer}>
                  {item.uniqueContainerId}
                </p>
                <p className={styles.blockLocation}>{item.locationName}</p>
                <p className={styles.blockReturned}>
                  {moment(item.return_date).format("MM/DD/YYYY")}
                </p>
                <p className={styles.blockDuedate}>
                  {moment(item.days_to_return).format("MM/DD/YYYY")}
                </p>
                <p className={styles.blockOverdue}>
                  {getOverdue(item) ? <img src={statusImage} /> : ""}
                </p>
              </div>
            );
          })}
      </div>
    </>
  );
};
export default ReturnedTable;
