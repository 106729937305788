import React, { useState } from "react";
import styles from "./addCoupon.module.scss";

import { createPromoCode } from "../../../_services/payment.service";
import toastService from "../../../_services/toast.service";
import trimObjectProps from "../../../_utils/trimObjectProps";
import t from "../../../_config/constants";

import Input from "../../input";
import Button from "../../button";
import Selector from "../../selector";
import Checkbox from "./../../checkbox";
import Datepicker from "../../newDatepicker";

const AddCoupon = ({ handleClose }) => {
  const [errorMsg, setErrorMsg] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [options, setOptions] = useState({
    discountType: "amount",
    redeemBy: false,
    maxRedemptions: false,
  });
  const [data, setData] = useState({
    coupon: {
      amount_off: "",
      currency: "cad",
      duration: "forever",
      duration_in_months: "",
      max_redemptions: "",
      name: "",
      percent_off: "",
      redeem_by: "",
    },
    code: "",
  });

  const [error, setError] = useState({
    code: false,
    coupon: {
      amount_off: false,
      duration_in_months: false,
      max_redemptions: false,
      name: false,
      percent_off: false,
      redeem_by: false,
    },
  });

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };

  const handleChange = (e) => {
    setErrorMsg(false);

    const { name, value } = e.target;
    if (name === "code") {
      setData((data) => ({
        ...data,
        code: value,
      }));
      setError((error) => ({ ...error, code: !value }));
    } else {
      setData((data) => ({
        ...data,
        coupon: { ...data.coupon, [name]: value },
      }));
      setError((error) => ({
        ...error,
        coupon: { ...error.coupon, [name]: !value },
      }));
    }
  };

  const handleSelect = (e) => {
    setErrorMsg(false);
    setData((data) => ({
      ...data,
      coupon: { ...data.coupon, duration: e.value, duration_in_months: "" },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const dataForSubmit = {};
      dataForSubmit.code = data.code;
      dataForSubmit.coupon = { ...data.coupon };
      for (const [key, value] of Object.entries(dataForSubmit.coupon)) {
        if (key === "amount_off" && value)
          dataForSubmit.coupon[key] = 100 * Number(value).toFixed(2);
        if (key === "percent_off" && value) {
          dataForSubmit.coupon[key] = Number(value).toFixed(2);
        }
        if (key === "redeem_by" && value) {
          dataForSubmit.coupon[key] = (
            Number(value) / 1000 +
            60 * 60 * 24
          ).toFixed();
        }
        if (!value) delete dataForSubmit.coupon[key];
      }

      setSubmitDisabled(true);
      let response = await createPromoCode(dataForSubmit);
      if (response) {
        toastService.show("success", `Successfully created cupoun.`);
        handleClose();
      }
    } else {
      toastService.show("error", `${t.common.text_toast_all_fields_required}`);
      setErrorMsg(true);
    }
    setSubmitDisabled(false);
  };

  const validateForm = () => {
    trimObjectProps(data);
    setError({
      code: !data.code,
      coupon: {
        name: !data.coupon.name,
      },
    });
    let isValid = true;
    if (options.discountType === "amount") {
      if (!data.coupon.amount_off || data.coupon.amount_off < 0) {
        setError((error) => ({
          ...error,
          coupon: { ...error.coupon, amount_off: true },
        }));
        isValid = false;
      }
    } else {
      if (
        !data.coupon.percent_off ||
        data.coupon.percent_off < 0 ||
        data.coupon.percent_off > 100
      ) {
        setError((error) => ({
          ...error,
          coupon: { ...error.coupon, percent_off: true },
        }));
        isValid = false;
      }
    }
    if (options.maxRedemptions) {
      if (
        !data.coupon.max_redemptions ||
        data.coupon.max_redemptions < 0 ||
        data.coupon.max_redemptions !==
          Number(data.coupon.max_redemptions).toFixed()
      ) {
        setError((error) => ({
          ...error,
          coupon: {
            ...error.coupon,
            max_redemptions: true,
          },
        }));
        isValid = false;
      }
    } else {
      setData((data) => ({
        ...data,
        coupon: { ...data.coupon, max_redemptions: "" },
      }));
    }
    if (options.redeemBy) {
      setError((error) => ({
        ...error,
        coupon: {
          ...error.coupon,
          redeem_by: !data.coupon.redeem_by,
        },
      }));
      if (!data.coupon.redeem_by) isValid = false;
    } else {
      setData((data) => ({
        ...data,
        coupon: { ...data.coupon, redeem_by: "" },
      }));
    }
    if (data.coupon.duration === "repeating") {
      if (
        !data.coupon.duration_in_months ||
        data.coupon.duration_in_months < 0 ||
        data.coupon.duration_in_months !==
          Number(data.coupon.duration_in_months).toFixed()
      ) {
        setError((error) => ({
          ...error,
          coupon: {
            ...error.coupon,
            duration_in_months: true,
          },
        }));
        isValid = false;
      }
    }
    return isValid && data.code && data.coupon.name;
  };

  const durationOptions = [
    { label: "Forever", value: "forever" },
    { label: "Once", value: "once" },
    { label: "Multiple months", value: "repeating" },
  ];

  return (
    <div className={styles.wrapper}>
      <h2>Add Coupon</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.center}>
          <div className={styles.row}>
            {" "}
            <Input
              label="Name"
              error={error.coupon.name}
              name="name"
              inputClass="inputForm"
              type="text"
              value={data.name}
              placeholder="3 months 50% off"
              onChange={handleChange}
            />{" "}
            <Input
              label={"Code"}
              error={error.code}
              name="code"
              inputClass="inputForm"
              type="text"
              value={data.code}
              placeholder={"50OFF"}
              onChange={handleChange}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.checkbox}>
              <p>Type</p>
              <Checkbox
                label={"Percentage discount"}
                customClass="checkbox"
                checked={options.discountType === "percentage"}
                onChange={() => {
                  setOptions((data) => ({
                    ...data,
                    discountType: "percentage",
                  }));
                  setData((data) => ({
                    ...data,
                    coupon: { ...data.coupon, amount_off: "" },
                  }));
                }}
              />
              <Checkbox
                label={"Fixed amount discount"}
                customClass="checkbox"
                checked={options.discountType === "amount"}
                onChange={() => {
                  setOptions((data) => ({ ...data, discountType: "amount" }));
                  setData((data) => ({
                    ...data,
                    coupon: { ...data.coupon, percent_off: "" },
                  }));
                }}
              />
            </div>

            {options.discountType === "percentage" ? (
              <Input
                label={"Percentage off"}
                error={error.coupon.percent_off}
                name={"percent_off"}
                inputClass="inputForm"
                type="number"
                value={data.coupon.percent_off}
                onChange={handleChange}
                desc={"%"}
              />
            ) : (
              <Input
                label={"Discount amount"}
                error={error.coupon.amount_off}
                name={"amount_off"}
                inputClass="inputForm"
                type="number"
                value={data.coupon.amount_off}
                onChange={handleChange}
                desc={"CA$"}
              />
            )}
          </div>
          <div className={styles.rowSelector}>
            <div className={styles.selector}>
              <Selector
                label={"Duration"}
                options={durationOptions}
                selectedOption={getSelectedValue(
                  durationOptions,
                  data.coupon.duration
                )}
                handleChangeSelect={(e) => handleSelect(e)}
                selectClassname="selectGray"
              />
            </div>
            {data.coupon.duration === "repeating" && (
              <Input
                label={"Number of months"}
                error={error.coupon.duration_in_months}
                name={"duration_in_months"}
                inputClass="inputForm"
                type="number"
                value={data.coupon.duration_in_months}
                onChange={handleChange}
              />
            )}
          </div>
          <div className={styles.checkbox}>
            <p>Redemption limits</p>
            <Checkbox
              customClass="checkbox"
              label={
                "Limit the date range when customers can redeem this coupon"
              }
              checked={options.redeemBy}
              onChange={() => {
                setOptions((data) => ({ ...data, redeemBy: !data.redeemBy }));
              }}
            />
            {options.redeemBy && (
              <div className={styles.datepicker}>
                <Datepicker
                  selected={data.coupon.redeem_by}
                  onDatePickerChange={(val) =>
                    setData((data) => ({
                      ...data,
                      coupon: { ...data.coupon, redeem_by: val?.getTime() },
                    }))
                  }
                  label={"Redeem by"}
                  name="date"
                  placeholder="mm/dd/yyyy"
                  error={error.coupon.redeem_by}
                />
              </div>
            )}

            <Checkbox
              customClass="checkbox"
              label={
                "Limit the total number of times this coupon can be redeemed"
              }
              checked={options.maxRedemptions}
              onChange={() => {
                setOptions((data) => ({
                  ...data,
                  maxRedemptions: !data.maxRedemptions,
                }));
              }}
            />
            {options.maxRedemptions && (
              <div className={styles.inputSpan}>
                <Input
                  name={"max_redemptions"}
                  inputClass="inputForm"
                  type="number"
                  value={data.coupon.max_redemptions}
                  onChange={handleChange}
                  desc={data.coupon.max_redemptions === 1 ? "time" : "times"}
                  label={"Total number of times"}
                  error={error.coupon.max_redemptions}
                />
                <span>
                  *This limit applies across customers so it won't prevent a
                  single customer from redeeming multiple times.
                </span>
              </div>
            )}
          </div>
        </div>

        <div className={styles.submit}>
          <Button
            btnClass="btnNormal"
            label={t.button.text_save}
            type="submit"
            disabled={submitDisabled}
          />
        </div>
      </form>
      {errorMsg ? (
        <div className={styles.error}>
          <p>{t.common.text_all_fields_required}</p>
        </div>
      ) : null}
    </div>
  );
};

export default AddCoupon;
