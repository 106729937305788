import React, { useState } from 'react';
import Modal from 'react-modal';
import styles from './formModal.module.scss';

const FormModal = ({ show, handleClose, content, size = '', closeOnOverlayClick = true, closeDisabled = false }) => {
  const [modalIsOpen] = useState(show);
  const closeModal = () => {
    handleClose();

  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Form modal"
      className={`${styles.customForm} ${styles[size]}`}
      overlayClassName={styles.customOverlay}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={closeOnOverlayClick}
    >
      <div className={styles.modalFormBody}>
        <div className={styles.scrollWrapper}>
          <span className={styles.closeForm} onClick={closeDisabled ? null : closeModal}><i className="fa fa-times" aria-hidden="true"></i></span>
          {content}
        </div>
      </div>
    </Modal>

  );
};


export default FormModal;