import { useState, useMemo } from "react";
export default function useSortableData(items, config = null) {
  const [sortConfig, setSortConfig] = useState(config);
  const sortedItems = useMemo(() => {
    if (!items) items = [];
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        if (a[sortConfig.key] === null && b[sortConfig.key] === null) {
          return 0;
        }
        if (a[sortConfig.key] === null) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (b[sortConfig.key] === null) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const handleSort = (key, initialDirection = "ascending") => {
    let direction = initialDirection;
    let newDir = initialDirection === "ascending" ? "descending" : "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === initialDirection
    ) {
      direction = newDir;
    }
    setSortConfig({ key, direction });
  };
  return { items: sortedItems, handleSort, sortConfig };
}
