import React, { useEffect, useState } from "react";
import styles from "./notifications.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../_components/button";
import { mainActions } from "../../../_store/actions/main.actions";
import LineInput from "../../../_components/input/lineInput";
import validateEmail from "../../../_utils/validateEmail";
import toastService from "../../../_services/toast.service";
const OperatorPortalNotifications = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [locations, setLocations] = useState([]);
  const [activeLocation, setActiveLocation] = useState("");
  const [activeEmail, setActiveEmail] = useState("");
  const [activeLimit, setActiveLimit] = useState("");

  const [showLimitEditor, setShowLimitEditor] = useState(false);
  const [error, setError] = useState(false);
  const [errorLimit, setErrorLimit] = useState(false);

  useEffect(() => {
    const fetchLocations = async (subregionId) => {
      const locations = await dispatch(
        mainActions.run("data", "location", "all", {
          filter: [{ isSmartBin: true, subregionId }],
        })
      );
      setLocations(locations);
    };
    if (store.auth.user.subregionId) {
      fetchLocations(store.auth.user.subregionId);
    }
  }, [store.auth.user]);
  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name == "amount") {
      setActiveLimit(value);
    } else {
      setActiveEmail(value);
    }
    setError(false);
    setErrorLimit(false);
  };
  const handleOnClick = (id) => {
    setShowLimitEditor(false);
    if (activeLocation == id) return;
    setActiveLimit("");
    setActiveEmail("");
    setActiveLocation(id);
    setError(false);
    setErrorLimit(false);
  };
  const handleShowLimitEditor = (location) => {
    setActiveEmail("");
    setActiveLimit(location.maxContainersAmount || 50);
    setActiveLocation(location._id);
    setShowLimitEditor(true);
    setError(false);
    setErrorLimit(false);
  };
  const updateLocations = (location, action = "add", email, amount) => {
    const locationsCopy = [...locations];
    const foundLocation = locationsCopy.find((l) => l._id == location._id);
    if (action == "add") {
      foundLocation.operatorEmails = [
        ...(foundLocation.operatorEmails || []),
        activeEmail,
      ];
    } else if (action == "remove") {
      foundLocation.operatorEmails = foundLocation.operatorEmails.filter(
        (item) => item !== email
      );
    } else {
      foundLocation.maxContainersAmount = amount;
    }
    setLocations(locationsCopy);
    setActiveEmail("");
    setActiveLimit("");
  };

  const handleSubmit = async (location, activeEmail) => {
    if (!validateEmail(activeEmail)) {
      setError(true);
      return;
    }
    const exists = location?.operatorEmails?.find(
      (email) => email == activeEmail
    );
    if (exists) {
      toastService.show("warning", `Email already added.`);
      return;
    }
    let emails = [...(location.operatorEmails || []), activeEmail];
    const response = await dispatch(
      mainActions.run("data", "location", "update", {
        _id: location._id,
        operatorEmails: emails,
      })
    );

    if (response) {
      toastService.show(
        "success",
        `Successfully updated location operator email list.`
      );
      updateLocations(location, "add");
    } else {
      toastService.show("error", `Something went wrong. Please try again.`);
    }
  };
  const handleUpdateLimit = async (location, amount) => {
    if (isNaN(amount) || amount <= 0) {
      setErrorLimit(true);
      return;
    }
    const response = await dispatch(
      mainActions.run("data", "location", "update", {
        _id: location._id,
        maxContainersAmount: amount,
      })
    );

    if (response) {
      toastService.show(
        "success",
        `Successfully updated location container limit.`
      );
      setShowLimitEditor(false);
      updateLocations(location, "limit", "", amount);
    } else {
      toastService.show("error", `Something went wrong. Please try again.`);
    }
  };

  const handleRemove = async (location, email) => {
    const emails = location?.operatorEmails?.filter((e) => e !== email);
    const response = await dispatch(
      mainActions.run("data", "location", "update", {
        _id: location._id,
        operatorEmails: emails || [],
      })
    );

    if (response) {
      toastService.show(
        "success",
        `Successfully updated location operator email list.`
      );
      updateLocations(location, "remove", email);
    } else {
      toastService.show("error", `Something went wrong. Please try again.`);
    }
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p>Notify a staff member by email when a Smart Return Bin is full.</p>
        <p>
          Configure the number of containers required to trigger the
          notification via “Container Limit”
        </p>
      </div>
      <div className={styles.contentContainer}>
        {locations?.map((location) => (
          <div className={styles.content} key={location._id}>
            <div className={styles.row}>
              <div className={styles.name}>
                <p>{location.name}</p>
              </div>
              <div className={styles.emails}>
                {location?.operatorEmails?.map((email, ind) => (
                  <div className={styles.email} key={ind}>
                    {email}
                    <span
                      title="remove"
                      onClick={() => {
                        handleRemove(location, email);
                      }}
                    >
                      x
                    </span>
                  </div>
                ))}
              </div>
              <div
                className={styles.addEmail}
                onClick={() => {
                  handleOnClick(location._id);
                }}
              >
                <LineInput
                  placeholder={"add new operator e-mail"}
                  value={
                    activeLocation == location._id ? activeEmail.trim() : ""
                  }
                  name={"email"}
                  type={"email"}
                  onChange={(e) => {
                    handleChange(e, location._id);
                  }}
                  error={error && activeLocation == location._id}
                  errorMsg={"Invalid email address"}
                />
                <Button
                  btnClass={"btnNormalCasal"}
                  label={"Add"}
                  disabled={activeLocation != location._id}
                  onClick={() => {
                    handleSubmit(location, activeEmail);
                  }}
                />
              </div>
            </div>{" "}
            <div className={styles.limit}>
              <div className={styles.limitDesc}>
                <p>Container Limit: </p>
                <span
                  title="edit"
                  onClick={() => {
                    handleShowLimitEditor(location);
                  }}
                >
                  {location.maxContainersAmount}
                </span>
              </div>
              {showLimitEditor && activeLocation == location._id && (
                <div className={styles.editor}>
                  <LineInput
                    value={activeLocation == location._id ? activeLimit : ""}
                    name={"amount"}
                    type={"number"}
                    onChange={(e) => {
                      handleChange(e, location._id);
                    }}
                    error={errorLimit}
                    errorMsg={"Invalid amount"}
                  />
                  <Button
                    btnClass={"btnNormalCasal"}
                    label={"Save"}
                    disabled={activeLocation != location._id}
                    onClick={() => {
                      handleUpdateLimit(location, activeLimit);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OperatorPortalNotifications;
