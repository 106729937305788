import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import styles from "../singleCoupon.module.scss";

import Button from "../../../button";
import Loader from "../../../loading";
import leftArrow from "../../../../_assets/icons/leftArrow.svg";
import t from "../../../../_config/constants";

import { CouponContext } from "../../../../_pages/couponManagement";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../../_store/actions/main.actions";

const CouponDetails = () => {
  const [, selectedCoupon, , , , , , , , , setStep] = useContext(CouponContext);
  const [redemptionList, setRedemptionList] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchDetails = async (id) => {
      const response = await dispatch(
        mainActions.run("data", "promoCode", "get-details", { _id: id })
      );
      if (response && response.length) {
        setRedemptionList(response);
      } else setRedemptionList([]);

      setLoadingData(false);
    };
    if (selectedCoupon._id) {
      if (
        selectedCoupon?.usedByMembers &&
        selectedCoupon?.usedByMembers.length
      ) {
        setLoadingData(true);
        fetchDetails(selectedCoupon._id);
      } else {
        setRedemptionList([]);
        setLoadingData(false);
      }
    }
  }, [selectedCoupon]);
  return (
    <div className={styles.detailsWrapper}>
      <Button
        label={t.button.text_back}
        btnClass="btnBack"
        iconLeft={leftArrow}
        onClick={() => setStep("single")}
      />
      <h2>Coupon Redemptions</h2>
      {!loadingData && (
        <div className={styles.table}>
          {redemptionList && redemptionList.length > 0 ? (
            redemptionList.map((redemption, ind) => {
              return (
                <div className={styles.row} key={redemption.user_id}>
                  <span className={styles.ind}>{ind + 1}.</span>
                  <p>
                    {redemption.user_id && redemption.user_name ? (
                      <Link to={`/user-management?id=${redemption.user_id}`}>
                        <span title="Show Member">{redemption.user_name}</span>
                      </Link>
                    ) : (
                      <span>Deleted Member</span>
                    )}
                    <span>{moment(redemption.date).format("MMM D, YYYY")}</span>
                  </p>
                </div>
              );
            })
          ) : (
            <p>No member has redeemed this coupon</p>
          )}
        </div>
      )}
      {loadingData && <Loader height={50} />}
    </div>
  );
};

export default CouponDetails;
