import React from 'react';
import Loader from 'react-loader-spinner';
import './style.scss';

const Loading = ({ className, custom, height, width }) => {
    return (
        <Loader
            className={className + (!custom ? " app-loader" : "")}
            type="Oval"
            color="#73aabd"
            height={height || 100}
            width={width || 100}
        />
    )
}

export default Loading;