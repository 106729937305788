import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import Button from "../../button";
import Loading from "../../loading";
import CreateRefund from "../createRefund";
import leftArrow from "./../../../_assets/icons/leftArrow.svg";
import styles from "./transactions.module.scss";
import FormModal from "../../modal/formModal";
import moment from "moment";
import { getTransactionsType } from "../../../_utils/utils";
import t from "../../../_config/constants";
import { capitalizeWord } from "../../../_utils/utils";
const TransactionsHistory = ({ handleBack, user }) => {
  const [transactions, setTransactions] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRefunds, setSelectedRefunds] = useState({});
  const [filteredTransactions, setFilteredTransactions] = useState({});
  const [update, setUpdate] = useState(1);
  const getTransactions = async () => {
    let filter = [];
    filter.push({
      user_id: user._id,
      transactionType: { $nin: ["SUBSCRIPTION"] },
      amount: { $ne: 0 },
    });
    let response = await dispatch(
      mainActions.run("transactions", "transaction", "all", { filter })
    );
    setTransactions(response);
    setFilteredTransactions(
      response.filter((item) => item.transactionType !== "REFUND")
    );
    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      setLoading(true);
      getTransactions();
    }
  }, [user, update]);

  const handleClose = () => {
    setSelectedTransaction({});
    setShowModal(false);
  };

  const handleView = (transaction) => {
    setSelectedTransaction(transaction);
    let refunds = transactions.filter(
      (refund) => refund.transactionId === transaction.id
    );
    setSelectedRefunds(refunds);
    setShowModal(true);
  };
  const onUpdate = () => {
    setUpdate((prevState) => (prevState === 1 ? 2 : 1));
  };
  return (
    <div className={styles.wrapper}>
      {showModal && (
        <FormModal
          handleClose={handleClose}
          show={showModal}
          size="md"
          content={
            <CreateRefund
              user={user}
              transaction={selectedTransaction}
              handleClose={handleClose}
              refunds={selectedRefunds}
              onUpdate={onUpdate}
            />
          }
        />
      )}
      <div className={styles.backButton}>
        <Button
          label="Back"
          btnClass="btnBack"
          iconLeft={leftArrow}
          onClick={handleBack}
        />
      </div>
      <div className={styles.content}>
        <h2>{user.type === "user" ? user.fullName : user.name}</h2>
        <h3>{t.common.text_transaction_history}</h3>
        <div className={styles.list}>
          <div className={styles.header}>
            <div className={styles.transactionTypeBlock}>
              {t.common.text_transaction}
            </div>
            <div className={styles.transactionStatus}>
              {t.common.text_th_status}
            </div>
            <div className={styles.transactionDateBlock}>
              {t.common.text_date}
            </div>
            <div className={styles.amountBlock}>{t.common.text_th_amount}</div>
            <div className={styles.blockButton}></div>
          </div>
          {loading && (
            <div className={styles.loading}>
              <Loading width={50} height={50} />
            </div>
          )}
          {!loading && !filteredTransactions.length && (
            <div className={styles.noTransactionsMsg}>
              {t.common.text_no_transactions}
            </div>
          )}
          {!loading &&
            filteredTransactions.length > 0 &&
            filteredTransactions.map((item) => (
              <div key={item._id} className={styles.block}>
                <div className={styles.transactionTypeBlock}>
                  {getTransactionsType(item.transactionType)}
                </div>
                <div className={styles.transactionStatus}>
                  {capitalizeWord(item.status)}
                </div>
                <div className={styles.transactionDateBlock}>
                  {moment(item.createdAt).format("MM/DD/YYYY")}
                </div>
                <div className={styles.amountBlock}>
                  {item.currency?.slice(0, 2).toUpperCase()}${" "}
                  {Number(item.amount).toFixed(2)}
                </div>
                {item.transactionType !== "REFUND" && (
                  <div className={styles.blockButton}>
                    <Button
                      label={
                        Number(item.refunded) === Number(item.amount)
                          ? "Refunded"
                          : item.refunded
                          ? "Partially Refunded"
                          : "Refund"
                      }
                      btnClass="btnNormalGlacier"
                      onClick={() => handleView(item)}
                      disabled={Number(item.refunded) === Number(item.amount)}
                    />
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default TransactionsHistory;
