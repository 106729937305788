import React from "react";
import Select from "react-select";
import "./selector.scss";

const Selector = ({
  selectClassname = "",
  options,
  label,
  selectedOption,
  handleChangeSelect,
  placeholder,
  multiselect = false,
  disabled = false,
  error = false,
  customLabel,
}) => {
  const selectorClass = selectClassname.split("'").join("");

  return (
    <>
      <div className={selectClassname}>
        {label && (
          <p className={selectClassname}>
            {label} {error && <span className="selectErr"> *</span>}
          </p>
        )}{" "}
        {!label && error && <span className="selectErr"> *</span>}
        <Select
          isMulti={multiselect}
          className={selectorClass}
          classNamePrefix={selectorClass}
          value={selectedOption}
          onChange={handleChangeSelect}
          options={options}
          getOptionLabel={(option) => option[customLabel || "label"]}
          placeholder={placeholder}
          isDisabled={disabled}
        />
      </div>
    </>
  );
};

export default Selector;
