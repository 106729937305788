import React, { useEffect } from "react";
import styles from "./table.module.scss";
import Loading from "../../loading";
import Button from "../../button";
import arrowUp from "../../../_assets/icons/arrowUp.svg";
import arrowDown from "../../../_assets/icons/arrowDown.svg";
import upDownArrow from "../../../_assets/icons/upDownArrow.svg";
import useSortableData from "../../../_utils/useSortableData";
import t from "../../../_config/constants";
const ArchivedStoresTable = ({
  restaurants = [],
  onSelectRestaurant,
  selectedRestaurantId,
  loadingData,
  changeSortConfig,
}) => {
  const { items, handleSort, sortConfig } = useSortableData(restaurants);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return upDownArrow;
    }
    return sortConfig.key === name
      ? sortConfig.direction === "ascending"
        ? arrowUp
        : arrowDown
      : upDownArrow;
  };
  useEffect(() => {
    changeSortConfig(sortConfig);
  }, [sortConfig]);

  const noStoresMsg = (
    <p className={styles.noRestaurantsMsg}>
      {t.restaurant.text_no_archived_stores}
    </p>
  );
  return (
    <div className={styles.tableWrapper}>
      <table>
        <thead>
          <tr>
            <th onClick={() => (loadingData ? null : handleSort("name"))}>
              {t.common.text_th_name}
              <Button btnClass="btnBack" iconLeft={getClassNamesFor("name")} />
            </th>
          </tr>
        </thead>
        <tbody>
          {!loadingData &&
            restaurants.length > 0 &&
            items.map((item) => {
              return (
                <tr
                  key={item._id}
                  className={
                    selectedRestaurantId === item._id ? styles.selected : ""
                  }
                  onClick={() => onSelectRestaurant(item._id)}
                >
                  <td>{item.name}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {!loadingData && !restaurants.length && noStoresMsg}
      {loadingData && (
        <div className={styles.loading}>
          <Loading width={50} height={50} />
        </div>
      )}
    </div>
  );
};

export default ArchivedStoresTable;
