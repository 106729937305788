import React from "react";
import styles from "./textarea.module.scss";

const Textarea = ({
  textareaClass,
  type,
  name,
  value,
  placeholder,
  onChange,
  autofocus = false,
  disabled = false,
  label,
  desc,
  onKeyPress,
  autoComplete,
  error = false,
}) => {
  return (
    <div
      className={`${styles[textareaClass]} ${error ? styles.borderRed : ""}`}
    >
      {label && (
        <label htmlFor={name}>
          {label} {error && <span> *</span>}
        </label>
      )}
      {desc && <small className={styles.desc}>{desc}</small>}
      <textarea
        className={styles.input}
        id={name}
        type={type}
        name={name}
        onKeyPress={onKeyPress}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        autoFocus={autofocus}
        disabled={disabled}
        autoComplete={"off"}
      />
    </div>
  );
};

export default Textarea;
