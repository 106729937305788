import React, { useEffect } from "react";
import styles from "./table.module.scss";
import Loading from "../../../loading";
import Button from "../../../button";
import arrowUp from "../../../../_assets/icons/arrowUp.svg";
import arrowDown from "../../../../_assets/icons/arrowDown.svg";
import upDownArrow from "../../../../_assets/icons/upDownArrow.svg";
import useSortableData from "../../../../_utils/useSortableData";
import uber from "../../../../_assets/images/uber-eats.png";
import appSignup from "../../../../_assets/images/reusablesAppLogo.jpg";
import nubpn from "../../../../_assets/images/phone.jpg";
import t from "../../../../_config/constants";
import { AiOutlineDollarCircle } from "react-icons/ai";
import transactIcon from "../../../../_assets/icons/student_cap.png";
const ReusersTable = ({
  data,
  setSelectedUser,
  selectedUser,
  loadingData,
  changeSortConfig,
}) => {
  const { handleSort, sortConfig } = useSortableData(data);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return upDownArrow;
    }
    return sortConfig.key === name
      ? sortConfig.direction === "ascending"
        ? arrowUp
        : arrowDown
      : upDownArrow;
  };
  useEffect(() => {
    changeSortConfig(sortConfig);
  }, [sortConfig]);
  const getTagValue = (tag, hasCard) => {
    let retVal = "";
    switch (tag) {
      case "uber":
        retVal = "Uber";
        break;
      case "nubpn":
        retVal = "NUbPN";
        break;
      case "appSignup":
        retVal = "Mobile App";
        break;
      case "webapp":
        retVal = "Web App";
        break;
      default:
        retVal = "";
    }
    if (!retVal && hasCard) retVal = "Card";
    return retVal;
  };

  return (
    <div className={styles.wrapper}>
      <table>
        <thead>
          <tr>
            <th
              className={styles.userData}
              onClick={() => (loadingData ? null : handleSort("fullName"))}
            >
              {t.user.text_th_name}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("fullName")}
              />
            </th>
            <th>{t.user.text_th_contact}</th>
            <th onClick={() => (loadingData ? null : handleSort("tag"))}>
              {t.user.text_th_type}
              <Button btnClass="btnBack" iconLeft={getClassNamesFor("tag")} />
            </th>
            <th onClick={() => (loadingData ? null : handleSort("hasCard"))}>
              Card
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("hasCard")}
              />
            </th>
            <th
              onClick={() => (loadingData ? null : handleSort("inPossession"))}
            >
              {t.user.text_th_in_possession}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("inPossession")}
              />
            </th>
            <th onClick={() => (loadingData ? null : handleSort("overdue"))}>
              {t.user.text_overdue}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("overdue")}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {!loadingData &&
            data &&
            data.length > 0 &&
            data.map((item) => (
              <tr
                className={selectedUser === item._id ? styles.active : ""}
                key={item._id}
                onClick={() => setSelectedUser(item._id)}
              >
                <td>
                  <p>{item.fullName}</p>
                </td>
                <td>
                  <div className={styles.contact}>
                    <span>{item.phone || ""}</span>
                    <span>{item.email || ""}</span>
                  </div>
                </td>
                <td>
                  <div className={styles.tag}>
                    {getTagValue(item.tag, item.hasCard)}
                  </div>
                </td>
                <td>
                  <div className={styles.card}>
                    {item.hasCard ? (
                      item.accountability == "TRANSACT" ? (
                        <img src={transactIcon} />
                      ) : (
                        <AiOutlineDollarCircle />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </td>
                <td>{item.inPossession}</td>
                <td>{item.overdue}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {!loadingData && !data.length && (
        <p className={styles.noData}>There are no reusers</p>
      )}
      {loadingData && (
        <div className={styles.loading}>
          <Loading width={50} height={50} />
        </div>
      )}
    </div>
  );
};

export default ReusersTable;
