import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";

export default function useIsNotAdmin() {
  const history = useHistory();
  const store = useSelector((state) => state);
  const isAdmin = () => {
    if (
      store.auth &&
      store.auth.user &&
      store.auth.user.subType === "subregionAdmin"
    ) {
      history.push("/containers-stats");
    } else if (
      store.auth &&
      store.auth.user &&
      store.auth.user.type === "restaurant"
    ) {
      history.push("/store-inventory");
    } else if (
      store.auth &&
      store.auth.user &&
      store.auth.user.type === "cleaner"
    ) {
      history.push("/cleaning");
    }
  };
  return isAdmin;
}
