export const groupOptions = [
  { label: "Total number of users", value: "number" },
  { label: "Percentage %", value: "percentage" },
];
export const userActionOptions = [
  {
    label: "Check list of participating store",
    value: "interactionBeforeRenting",
  },
  { label: "Open the app", value: "interactionBeforeReturning" },
];
export const eventOptionsBeforeReturning = [
  {
    label: " On the same day that user returns container",
    value: "interactionBeforeReturning",
  },
];

export const eventOptionsBeforeRenting = [
  {
    label: "On the same day that containers are assigned to user",
    value: "interactionBeforeRenting",
  },
];
export const mapReportsForChart = (
  reports = [],
  actionType = "",
  display = "number",
  selectedMonth
) => {
  let data = [];
  let today;
  let month;
  let year;
  let date = new Date();
  if (
    selectedMonth.getFullYear() === date.getFullYear() &&
    selectedMonth.getMonth() === date.getMonth()
  ) {
    today = new Date().getDate();
    month = new Date().getMonth();
    year = new Date().getFullYear();
  } else {
    today = new Date(
      selectedMonth.getFullYear(),
      selectedMonth.getMonth() + 1,
      0
    ).getDate();
    month = selectedMonth.getMonth();
    year = selectedMonth.getFullYear();
  }

  for (let i = 0; i < today; i++) {
    data.push({
      day: i + 1,
      quantity: 0,
      totalUsers: 0,
    });
  }
  if (reports && reports.length > 0) {
    reports.map((report) => {
      let day = new Date(report.createdAt).getDate();
      let index = data.findIndex((item) => item.day === day);

      if (index !== -1) {
        data[index].quantity += report[actionType]?.length || 0;
        data[index].totalUsers +=
          (report[actionType]?.length || 0) +
          (report[
            "no" + actionType.charAt(0).toUpperCase() + actionType.slice(1)
          ]?.length || 0);
      } else {
        data.push({
          day: new Date(report.createdAt).getDate(),
          quantity: report[actionType]?.length || 0,
          totalUsers:
            (report[actionType]?.length || 0) +
            (report[
              "no" + actionType.charAt(0).toUpperCase() + actionType.slice(1)
            ]?.length || 0),
        });
      }
    });

    data.sort(function (a, b) {
      return a.day - b.day;
    });
  }

  let chartData = data.map((item) => ({
    labels: item.day,
    value:
      display === "number"
        ? Number(item.quantity)
        : item.totalUsers !== 0
        ? Number(((item.quantity / item.totalUsers) * 100).toFixed())
        : 0,
  }));
  return chartData;
};
