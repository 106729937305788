import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toastService from "../../../_services/toast.service";
import { mainActions } from "../../../_store/actions/main.actions";
import { modalActions } from "../../../_store/actions/modal.actions";
import { makeSelectMap } from "../../../_utils/utils";
import Button from "../../button";
import Input from "../../input";
import Selector from "../../selector";
import styles from "./addGroup.module.scss";
import t from "../../../_config/constants";
import UploadAvatar from "../../uploadAvatar";
import { amazonUrl, amazonUrlResized } from "../../../environment";
import { uploadToS3 } from "../../../_utils/auth/uploadPhoto";
import Checkbox from "../../checkbox";
const AddGroup = ({ editing, group, refreshTableData }) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [regionOptions, setRegionOptions] = useState([]);
  const [data, setData] = useState(
    editing
      ? group
      : {
          name: "",
          contactName: "",
          email: "",
          restaurants: [],
          parentRestaurant: {},
          regionId: "",
          chargingOption: "separately",
          totalStores: 0,
          avatarURL: "",
        }
  );
  const [hasParentStore, setHasParentStore] = useState(
    editing
      ? group.parentRestaurant && group.parentRestaurant._id
        ? true
        : false
      : false
  );

  const [image, setImage] = useState({
    uri: data.avatarURL ? data.avatarURL : "",
  });
  const chargingOptions = [
    {
      label: "Charge parent store",
      value: "chargeParent",
    },
    {
      label: "Charge stores separately",
      value: "separately",
    },
  ];
  const [error, setError] = useState({
    name: false,
    restaurants: false,
    regionId: false,
    contactName: false,
    email: false,
    parentRestaurant: false,
  });
  const [restaurantsOptions, setRestaurantsOptions] = useState([]);
  const [parentRestaurantOptions, setParentRestaurantOptions] = useState([]);
  useEffect(() => {
    if (
      store.auth.user &&
      store.auth.user.regionId &&
      store.auth.user.regionId !== "*"
    ) {
      setData((data) => ({ ...data, regionId: store.auth.user.regionId }));
    }
  }, [store.auth.user]);
  useEffect(() => {
    if (
      store.main &&
      store.main.data &&
      store.main.data.region &&
      store.main.data.region.list
    ) {
      let arr = makeSelectMap(store.main.data.region.list);
      setRegionOptions(arr);
    }
  }, [store.main.data && store.main.data.region]);

  useEffect(() => {
    const getRestaurants = async (regionId) => {
      let filter = [];
      if (regionId && regionId !== "*") {
        filter.push({
          regionId,
        });
      }
      let data = await dispatch(
        mainActions.run("profiles", "restaurant", "all", {
          filter,
        })
      );
      let arr = makeSelectMap(data);
      setRestaurantsOptions(arr);
    };
    if (data.regionId) {
      getRestaurants(data.regionId);
      if (!editing) {
        setData((data) => ({
          ...data,
          restaurants: [],
          parentRestaurant: {},
        }));
      }
    }
  }, [data.regionId]);

  useEffect(() => {
    if (restaurantsOptions.length > 0) {
      let opt = restaurantsOptions.filter((opt) =>
        data.restaurants.find((restaurant) => restaurant._id == opt.value)
      );
      setParentRestaurantOptions(opt);
      if (
        opt.findIndex((obj) => obj.value === data.parentRestaurant._id) == -1
      ) {
        setData((data) => ({ ...data, parentRestaurant: {} }));
      }
    }
  }, [data.restaurants, restaurantsOptions]);

  const handleChooseImg = (e) => {
    e.preventDefault();
    let set = {
      uri: URL.createObjectURL(e.target.files[0]),
      type: e.target.files[0].type,
      file: e.target.files[0],
    };
    setImage(set);
  };
  const handleSelect = (e, name) => {
    if (name === "restaurants" && e.length === 0) {
      setData((data) => ({
        ...data,
        restaurants: e,
        parentRestaurant: {},
      }));
    } else if (name === "restaurants") {
      let val = e.map((obj) => ({ _id: obj.value, name: obj.label }));
      setData((data) => ({
        ...data,
        restaurants: val,
      }));
    } else if (name === "parentRestaurant") {
      setData((data) => ({
        ...data,
        parentRestaurant: { _id: e.value, name: e.label },
      }));
    } else {
      setData((data) => ({
        ...data,
        [name]: e.value,
      }));
    }
    setError((data) => ({ ...data, [name]: false }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setError((data) => ({ ...data, [name]: false }));
    setData((data) => ({ ...data, [name]: value }));
  };
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const getSelectedValueStores = (options, value, parent) => {
    const selectedData = [];
    if (parent) {
      options &&
        options.forEach((obj) => {
          if (value === obj.value) {
            selectedData.push(obj);
          }
        });
    } else {
      options &&
        options.forEach((obj) => {
          if (value.findIndex((item) => item._id === obj.value) !== -1) {
            selectedData.push(obj);
          }
        });
    }

    return selectedData;
  };
  const validateData = () => {
    setError({
      name: !data.name,
      restaurants: data.restaurants.length === 0,
      regionId: !data.regionId,
      contactName: !data.contactName,
      email: !data.email,
      parentRestaurant: !data.parentRestaurant._id && hasParentStore,
    });
    return (
      data.name &&
      data.restaurants.length > 0 &&
      data.regionId &&
      data.contactName &&
      data.email &&
      ((hasParentStore && data.parentRestaurant._id) || !hasParentStore)
    );
  };
  const handleSave = async (e) => {
    e.preventDefault();

    if (validateData()) {
      const imgUrl = await uploadToS3(image);
      let res = await dispatch(
        mainActions.run("data", "restaurantGroup", editing ? "update" : "new", {
          ...data,
          totalStores: data.restaurants.length,
          avatarURL: imgUrl ? amazonUrl + imgUrl : data.avatarURL,
          avatarURLResized: imgUrl
            ? amazonUrlResized + imgUrl
            : data.avatarURLResized,
          chargingOption:
            data.parentRestaurant && data.parentRestaurant._id
              ? data.chargingOption
              : "separately",
        })
      );
      dispatch(modalActions.closeModal());
      refreshTableData();
      if (res)
        toastService.show(
          "success",
          `Successfully ${editing ? "Updated Group." : "Created Group."}`
        );
    }
  };
  const handleCheckboxParentStore = () => {
    if (hasParentStore) setData((data) => ({ ...data, parentRestaurant: {} }));
    setHasParentStore((prev) => !prev);
  };
  return (
    <div className={styles.wrapper}>
      <h2>{editing ? t.button.text_edit_group : t.button.text_add_group}</h2>
      <form onSubmit={handleSave} className={styles.form}>
        <UploadAvatar uri={image.uri} setImg={handleChooseImg} />
        <Input
          inputClass="inputForm"
          label={t.restaurant.text_th_group_name}
          placeholder={"Name"}
          value={data.name}
          onChange={handleChange}
          name={"name"}
          error={error.name}
        />
        <Input
          label={"Contact Name"}
          error={error.contactName}
          name="contactName"
          inputClass="inputForm"
          type="text"
          value={data.contactName}
          placeholder={t.restaurant.text_input_owner_placeholder}
          onChange={handleChange}
        />
        <Input
          label={t.common.input.text_input_email_address}
          error={error.email}
          name="email"
          inputClass="inputForm"
          type="email"
          value={data?.email?.trim()}
          placeholder={t.auth.text_markup_mail}
          onChange={handleChange}
        />
        {store?.auth?.user?.regionId === "*" && (
          <div className={styles.selectorRegion}>
            <Selector
              error={error.regionId}
              placeholder={"Select Region"}
              multiselect={false}
              options={regionOptions}
              selectedOption={getSelectedValue(regionOptions, data.regionId)}
              handleChangeSelect={(e) => handleSelect(e, "regionId")}
              selectClassname="selectPrimary"
              disabled={editing}
            />
          </div>
        )}
        <div className={styles.selector}>
          <Selector
            label={t.restaurant.text_restaurats}
            selectClassname="selectGray"
            options={restaurantsOptions}
            multiselect={true}
            handleChangeSelect={(e) => handleSelect(e, "restaurants")}
            error={error.restaurants}
            selectedOption={getSelectedValueStores(
              restaurantsOptions,
              data.restaurants
            )}
          />{" "}
        </div>{" "}
        <div className={styles.checkbox}>
          <Checkbox
            label={"Parent Store"}
            checked={hasParentStore}
            customClass="checkbox"
            onChange={handleCheckboxParentStore}
          />
        </div>
        {hasParentStore && (
          <div className={styles.selector}>
            <Selector
              label={"Parent Store (Optional)"}
              selectClassname="selectGray"
              options={parentRestaurantOptions}
              multiselect={false}
              selectedOption={getSelectedValueStores(
                parentRestaurantOptions,
                data.parentRestaurant._id,
                true
              )}
              handleChangeSelect={(e) => handleSelect(e, "parentRestaurant")}
              error={error.parentRestaurant}
            />
          </div>
        )}
        {hasParentStore && (
          <div className={styles.selector}>
            <Selector
              options={chargingOptions}
              selectedOption={getSelectedValue(
                chargingOptions,
                data.chargingOption
              )}
              error={error.chargingOption}
              handleChangeSelect={(e) => handleSelect(e, "chargingOption")}
              selectClassname="selectGray"
              label={t.admin.regions.text_charging}
            />
          </div>
        )}
        <Button
          btnClass={"btnNormalCasal"}
          label={t.button.text_save}
          type={"submit"}
        />
      </form>
    </div>
  );
};
export default AddGroup;
