import React, { useState } from "react";
import styles from "./editURL.module.scss";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../../_store/actions/main.actions";
import { validateURL } from "../../../../_utils/utils";
import Button from "../../../button";
import Input from "../../../input";
import t from "../../../../_config/constants";
import { modalActions } from "../../../../_store/actions/modal.actions";
import toastService from "../../../../_services/toast.service";
const EditRedirectURL = ({ batch }) => {
  const dispatch = useDispatch();
  const [newURL, setNewURL] = useState(batch.redirectURL);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const handleChange = (e) => {
    const { value } = e.target;
    setNewURL(value);
    setError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    setError(!newURL || !validateURL(newURL));
    if (newURL && validateURL(newURL)) {
      if (newURL !== batch.redirectURL) {
        const res = await dispatch(
          mainActions.run("data", "containerBatch", "update", {
            _id: batch._id,
            uniqueBatchId: batch.uniqueBatchId,
            redirectURL: newURL,
          })
        );
        if (res) {
          toastService.show("success", `Successfully submitted`);
          batch.redirectURL = newURL;
          dispatch(modalActions.closeModal());
        }
      } else {
        toastService.show("success", `Successfully submitted`);
        dispatch(modalActions.closeModal());
      }
    }
    setDisabled(false);
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setNewURL(batch.redirectURL);
    setError(false);
  };
  return (
    <div className={styles.wrapper}>
      <h2>Change Redirect URL</h2>
      <form onSubmit={handleSubmit}>
        <Input
          label={"Unique Batch Id"}
          name="batchId"
          inputClass="inputForm"
          type="text"
          value={batch.uniqueBatchId}
          disabled={true}
        />
        <Input
          label={"Redirect URL"}
          name="redirectURL"
          inputClass="inputForm"
          type="text"
          value={newURL}
          onChange={handleChange}
          disabled={disabled}
          error={error}
          errorMsg={"Please enter valid URL"}
        />
        <div className={styles.buttons}>
          <Button
            btnClass="btnNormal"
            label={t.button.text_save}
            type="submit"
            disabled={!newURL || disabled}
          />
          <Button
            btnClass="btnWhiteBackground"
            label={"Revert to previous"}
            type="btn"
            onClick={handleCancel}
            disabled={disabled}
          />
        </div>
      </form>
    </div>
  );
};

export default EditRedirectURL;
