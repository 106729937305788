import React, { useEffect, useState } from "react";
import Button from "../../../button";
import styles from "../containersAtStores/containersAtStores.module.scss";
import { exportToCSV } from "../../../../_utils/exportToCSV";
import moment from "moment";
import { useSelector } from "react-redux";
import { getStatistics } from "../../../../_services/statistics.service";
import Datepicker from "../../../newDatepicker";
import LineChart from "../../../charts/line";
import { Link } from "react-router-dom";
import {
  getDropdownListMonths,
  getDropdownListYears,
} from "../../../../_utils/getReportsDateOptions";
import Selector from "../../../selector";

const ContainersAtMembers = ({ containerTypes = [] }) => {
  const store = useSelector((state) => state);
  const [date, setDate] = useState(new Date());
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [membersInventory, setMembersInventory] = useState([]);
  const [totalContainers, setTotalContainers] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [yearsOptions, setYearsOptions] = useState([]);
  const [monthsOptions, setMonthsOptions] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState();

  const [distributionData, setDistributionData] = useState({
    graphData: [],
    overduePercentage: 0,
  });
  const [distributionDataOnTime, setDistributionDataOnTime] = useState([]);
  const [activeTab, setActiveTab] = useState("table");
  useEffect(() => {
    const arr = getDropdownListYears();
    setSelectedYear(arr[0].value);
    setYearsOptions(arr);
  }, []);
  useEffect(() => {
    const arr = getDropdownListMonths(selectedYear);
    setSelectedMonth(arr[0].value);
    setMonthsOptions(arr);
  }, [selectedYear]);
  useEffect(() => {
    const getContainerAtMembers = async (date, regionId) => {
      try {
        const filter = {};
        regionId !== "*" && (filter.regionId = regionId);
        if (
          date.getFullYear() === new Date().getFullYear() &&
          date.getMonth() === new Date().getMonth() &&
          date.getDate() === new Date().getDate()
        ) {
          filter.date = new Date().getTime();
        } else {
          filter.date = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
          ).getTime();
        }
        const response = await getStatistics({
          mode: "get-containers-in-use-statistics",
          filter: filter,
        });

        if (response) {
          const { csvInventory, membersInventory, totalContainers } = response;
          setMembersInventory(membersInventory || []);
          setTotalContainers(totalContainers || {});
          setCsvData(csvInventory || []);
        }
      } catch (err) {}
    };

    if (date && store?.auth?.user?.regionId) {
      getContainerAtMembers(date, store.auth.user.regionId);
    }
  }, [date, store.auth && store.auth.user]);
  useEffect(() => {
    const getHoldingTimeDistribution = async (date, regionId) => {
      try {
        const filter = {};
        regionId !== "*" && (filter.regionId = regionId);
        if (
          date.getFullYear() === new Date().getFullYear() &&
          date.getMonth() === new Date().getMonth()
        ) {
          filter.date = new Date(
            Date.UTC(date.getFullYear(), date.getMonth(), new Date().getDate())
          ).getTime();
        } else {
          filter.date = new Date(
            Date.UTC(date.getFullYear(), date.getMonth() + 1, date.getDate())
          ).getTime();
        }

        const { distributionData, distributionDataOnTime, overduePercentage } =
          await getStatistics({
            mode: "get-average-holding-time",
            filter: filter,
          });
        setDistributionData({
          graphData: distributionData,
          overduePercentage: overduePercentage,
        });
        setDistributionDataOnTime(distributionDataOnTime);
      } catch (err) {}
    };

    if (selectedMonth && store?.auth?.user?.regionId) {
      getHoldingTimeDistribution(selectedMonth, store.auth.user.regionId);
    }
  }, [selectedMonth, store.auth && store.auth.user]);
  const downloadData = () => {
    setLoadingCSV(true);
    if (csvData && csvData.length) {
      exportToCSV(
        csvData,
        `Containers In Use - ${moment(date).format("MMM DD, YYYY")}`
      );
    }
    setLoadingCSV(false);
  };

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.topBar}>
        <h3
          className={
            activeTab === "table" ? styles.tabActive : styles.tabInactive
          }
          onClick={() => {
            setActiveTab("table");
          }}
        >
          Containers In Use
        </h3>
        <h3
          className={
            activeTab === "graph" ? styles.tabActive : styles.tabInactive
          }
          onClick={() => {
            setActiveTab("graph");
          }}
        >
          Distribution
        </h3>
        {activeTab === "table" ? (
          <div className={styles.datepicker}>
            <Datepicker
              minDate={new Date(2021, 5, 1).getTime()}
              maxDate={new Date().getTime()}
              selected={date}
              onDatePickerChange={(val) => setDate(val)}
              name="date"
              placeholder="mm/dd/yyyy"
            />
          </div>
        ) : (
          <div className={styles.selectedDate}>
            <div className={styles.selectMonth}>
              <Selector
                label=""
                multiselect={false}
                selectClassname="selectGray"
                selectedOption={getSelectedValue(monthsOptions, selectedMonth)}
                handleChangeSelect={(e) => setSelectedMonth(e.value)}
                options={monthsOptions}
                disabled={loadingData}
              />
            </div>
            <div className={styles.selectYear}>
              <Selector
                label=""
                multiselect={false}
                selectClassname="selectGray"
                selectedOption={getSelectedValue(yearsOptions, selectedYear)}
                handleChangeSelect={(e) => setSelectedYear(e.value)}
                disabled={loadingData}
                options={yearsOptions}
              />
            </div>
          </div>
        )}
        {activeTab === "table" && (
          <div
            className={
              loadingCSV || csvData.length === 0 ? styles.btnDisabled : ""
            }
          >
            <Button
              label={"Download"}
              onClick={downloadData}
              disabled={loadingCSV || csvData.length === 0}
            />
          </div>
        )}
      </div>
      {activeTab === "table" ? (
        <div className={styles.inventory}>
          <table>
            <thead>
              <tr>
                <th>Member</th>
                <th>Total</th>
                {containerTypes?.map((type) => (
                  <th key={type?._id}>{type?.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={styles.firstTd}>All Members</td>
                <td className={styles.firstTd}>{totalContainers?.total}</td>
                {containerTypes?.map((type, ind) => (
                  <td key={ind} className={styles.firstTd}>
                    {totalContainers[type.name]}
                  </td>
                ))}
              </tr>
              {membersInventory?.map((member) => (
                <tr key={member?.user_id}>
                  <td>
                    {member?.user_id && member?.user_name ? (
                      <Link to={`/user-management?id=${member.user_id}`}>
                        <span title="Show Member">{member.user_name}</span>
                      </Link>
                    ) : (
                      member?.user_name
                    )}
                  </td>
                  <td>{member?.inventory.total}</td>

                  {containerTypes?.map((type, ind) => (
                    <td key={ind}>{member?.inventory[type.name]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className={styles.graphWrapper}>
          <div className={styles.graphs}>
            <div className={styles.graph}>
              <LineChart arrayForLineChart={distributionDataOnTime} />
              <p className={styles.xLabel}>Average Holding Time [days]</p>
              <p className={styles.yLabel}>Number of Reusers</p>
            </div>
            <div className={styles.graph}>
              <LineChart arrayForLineChart={distributionData.graphData} />
              <p className={styles.xLabel}>Average Holding Time [days]</p>
              <p className={styles.yLabel}>% of Reusers</p>{" "}
              <p className={styles.xUnderLabel}>
                {distributionData.overduePercentage}% of members{" "}
                {date.getFullYear() === new Date().getFullYear() &&
                date.getMonth() === new Date().getMonth() &&
                date.getDate() === new Date().getDate()
                  ? "are "
                  : "were "}
                late
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ContainersAtMembers;
