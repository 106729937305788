
import httpService from './http.service';

class MainService {
    async run(name, type, mode, data = {}) {
        return httpService.apiRequest('post', `/api/main/${name}/${type}`, { mode, data });
    }
}

export default new MainService();
