import React from "react";
import styles from "./toggle.module.scss";

const Toggle = ({
  checked,
  onChange,
  disabled = false,
  toggleClass = "toggleMD",
  name,
}) => {
  return (
    <>
      <label className={`${styles[toggleClass]}`}>
        <input
          className={styles["switch-checkbox"]}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          name={name}
        />
        <div className={styles["switch-label"]}>
          <span className={styles[`switch-button`]} />
        </div>
      </label>
    </>
  );
};

export default Toggle;
