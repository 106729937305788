import React, { useEffect, useState } from "react";
import styles from "./restaurantTransactions.module.scss";
import moment from "moment";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../_store/actions/modal.actions";
import { mainActions } from "../../../_store/actions/main.actions";

import Loading from "./../../loading";
import Button from "./../../button";
import Selector from "../../selector";
import ChargeInvoiceModal from "../chargeInvoiceModal";

import leftArrow from "../../../_assets/icons/leftArrow.svg";

import { getTransactionsType } from "../../../_utils/utils";
import { getDropdownListYears } from "../../../_utils/mapTransactions";

import t from "../../../_config/constants";

const RestaurantTransactions = ({ restaurant, handleBack }) => {
  const dispatch = useDispatch();
  const [loadingData, setLoadingData] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [update, setUpdate] = useState(1);
  const [yearsOptions, setYearsOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  useEffect(() => {
    const arr = getDropdownListYears();
    setSelectedYear(arr[0].value);
    setYearsOptions(arr);
  }, []);

  useEffect(() => {
    const fetchTransactions = async (restaurant, year) => {
      const filter = [];
      filter.push({
        user_id: restaurant._id,
        transactionType: "RESTAURANT_INVOICE",
        createdAt: {
          $gte: new Date(year, 0, 15).getTime(),
          $lte: new Date(year + 1, 0, 15).getTime(),
        },
      });

      const response = await dispatch(
        mainActions.run("transactions", "transaction", "all", { filter })
      );
      setTransactions(response);
      setLoadingData(false);
    };
    if (restaurant) {
      setLoadingData(true);
      fetchTransactions(restaurant, selectedYear);
    }
  }, [restaurant, update, selectedYear]);

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.options}>
        <div className={styles.back}>
          <Button
            label={"Back to stores"}
            btnClass="btnBack"
            iconLeft={leftArrow}
            onClick={handleBack}
          />
        </div>
        <div className={styles.selectDate}>
          <Selector
            label=""
            multiselect={false}
            selectClassname="selectGray"
            selectedOption={getSelectedValue(yearsOptions, selectedYear)}
            handleChangeSelect={(e) => setSelectedYear(e.value)}
            disabled={loadingData}
            options={yearsOptions}
          />
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.header}>
          <p className={styles.blockUser}>{restaurant.name}</p>
          <p className={styles.blockDate}>{t.common.text_date_created}</p>
          <p className={styles.blockStatus}>{t.common.text_th_status}</p>
          <p className={styles.blockAmount}>{t.common.text_th_amount}</p>
        </div>
        {loadingData && (
          <div className={styles.loader}>
            <Loading width={75} height={75} />
          </div>
        )}
        {!loadingData && !transactions.length && (
          <div className={styles.noTransactionsMsg}>
            <span>{`${t.common.text_no_transactions}. Are you looking at the right year? ^`}</span>
          </div>
        )}
        {!loadingData &&
          transactions.length > 0 &&
          transactions.map((item) => {
            return (
              <div className={styles.block} key={item._id}>
                <p className={styles.blockUser}>
                  {getTransactionsType(item.transactionType)} -{" "}
                  {moment
                    .utc(item.createdAt)
                    .subtract(1, "months")
                    .format("MMMM")}
                </p>

                <p className={styles.blockDate}>
                  {moment(item.createdAt).format("YYYY-MM-DD")}
                </p>
                <p className={styles.blockStatus}>
                  {!item.paymentIntentId && !item.chargedManually ? (
                    <Button
                      label={"Charge"}
                      btnClass="btnNormalCasal"
                      onClick={() =>
                        dispatch(
                          modalActions.openModal(
                            <ChargeInvoiceModal
                              invoice={item}
                              updateList={() => {
                                setUpdate((prev) => !prev);
                              }}
                              user={restaurant}
                            />,
                            "",
                            "lg"
                          )
                        )
                      }
                    />
                  ) : (
                    <span
                      style={{ color: "#929292" }}
                      className={styles.blockMsg}
                    >
                      Payment Received{" "}
                      {moment(item.chargedAt || item.updatedAt).format(
                        "YYYY-MM-DD"
                      )}
                    </span>
                  )}
                </p>
                <p className={styles.blockAmount}>
                  $
                  {item.amount && item.amount != "NaN"
                    ? Number(item.amount).toFixed(2)
                    : "0.00"}{" "}
                  {item.currency?.toUpperCase()}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default RestaurantTransactions;
