import React from "react";
import styles from "./usage.module.scss";
import { useDispatch, useSelector } from "react-redux";
import UsageRentedChart from "../../../_components/subregionPanel/usage/rentedChart";
import UsageReturnedChart from "../../../_components/subregionPanel/usage/returnedChart";
import StoresCheckoutLog from "../../../_components/dashboard/header/storeCheckoutLog";
import { modalActions } from "../../../_store/actions/modal.actions";
import Button from "../../../_components/button";
import t from "../../../_config/constants";
import ReturnedBarChart from "../../../_components/subregionPanel/usage/returnedBarChart";
const Usage = () => {
  const dispatch = useDispatch();

  return (
    <div className={styles.wrapper}>
      <Button
        label={t.restaurant.text_stores_checkout_feed}
        btnClass="btnNormalCasal"
        onClick={() =>
          dispatch(modalActions.openModal(<StoresCheckoutLog />, "", ""))
        }
      />
      <div className={styles.contentContainer}>
        <div className={styles.graphWrapper}>
          <div className={styles.graphContainer}>
            <UsageRentedChart />
          </div>
          <div className={styles.graphContainer}>
            <UsageReturnedChart />
          </div>
        </div>
        <hr />

        <div className={styles.graphContainer}>
          <ReturnedBarChart />
        </div>
      </div>
    </div>
  );
};

export default Usage;
