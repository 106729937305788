import React, { useState } from "react";
import moment from "moment";
import styles from "./../overdueTable/overdueTable.module.scss";
import { mainActions } from "../../../_store/actions/main.actions";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../_store/actions/modal.actions";
import Loading from "../../loading";
import Button from "./../../button";
import statusImage from "../../../_assets/icons/exclamation-mark.svg";
import ConfirmModal from "../../modal/confirmModal";
import toastService from "../../../_services/toast.service";
import RefundReport from "../refundReport";
import t from "../../../_config/constants";

const PurchasedTable = ({ containerReports, loadingData, onUpdate }) => {
  const dispatch = useDispatch();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});

  const handleResolve = async (item) => {
    setSelectedReport(item);
    dispatch(
      modalActions.openModal(
        <RefundReport
          report={item}
          user={item.user}
          overdue={getOverdue(item)}
          handleClose={closeModal}
        />,
        "Report",
        "lg"
      )
    );
  };
  const handleShowModal = (item) => {
    setSelectedReport(item);
    setShowModalDelete(true);
  };
  const handleDelete = async (answer) => {
    if (answer) {
      let response = await dispatch(
        mainActions.run("overdueContainers", "overdueContainer", "delete", {
          _id: selectedReport._id,
        })
      );
      if (response) {
        toastService.show("success", t.report.text_successfully_deleted);
        setSelectedReport({});
        setShowModalDelete(false);
        onUpdate();
      }
    } else {
      setShowModalDelete(false);
    }
  };
  const getOverdue = (item) => {
    let duedate = moment(item.days_to_return).startOf("day");
    let returned = moment(item.return_date).startOf("day");
    return returned.diff(duedate, "days");
  };
  const closeModal = async () => {
    await dispatch(modalActions.closeModal());
    onUpdate();
  };
  return (
    <>
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={handleDelete}
          modalData={{}}
          question={
            selectedReport.isResolved
              ? t.report.text_modal_delete
              : t.report.text_modal_delete_no_refund
          }
        />
      )}

      <div className={styles.list}>
        <div className={styles.headerCharged}>
          <p className={styles.blockUser}>{t.report.text_user}</p>
          <p className={styles.blockContainer}>{t.report.text_container}</p>
          <p className={styles.blockContainer}>{t.report.text_uniqueId}</p>
          <p className={styles.blockOverdue}>{t.report.text_overdue}</p>
          <p className={styles.blockOverdue}>{t.report.text_refund_period}</p>

          <div className={styles.blockButtons}></div>
          <div className={styles.blockStatus}></div>
        </div>
        {loadingData && (
          <div className={styles.loader}>
            <Loading width={75} height={75} />
          </div>
        )}
        {!loadingData && !containerReports.length && (
          <p className={styles.noContainersMsg}>
            {t.report.text_no_overdue_reports}
          </p>
        )}
        {!loadingData &&
          containerReports.length > 0 &&
          containerReports.map((item) => {
            return (
              <div className={styles.blockCharged} key={item._id}>
                <p className={styles.blockUser}>
                  {item?.user
                    ? item?.user?.fullName || t.common.text_not_available
                    : t.report.text_deleted}
                </p>
                <p className={styles.blockContainer}>{item.name}</p>
                <p className={styles.blockContainer}>
                  {item.uniqueContainerId}
                </p>
                <p className={styles.blockOverdue}>{getOverdue(item)} days</p>
                <p className={styles.blockOverdue}>
                  {getOverdue(item) > 30 && <img src={statusImage} />}
                </p>

                <div className={styles.blockButtons}>
                  <div className={item.isResolved ? styles.resolveHidden : ""}>
                    <Button
                      label={t.button.text_resolve}
                      btnClass="btnNormalCasal"
                      onClick={() => handleResolve(item)}
                    />
                  </div>
                  <Button
                    label={t.button.text_delete}
                    btnClass="btnNormal"
                    onClick={() => handleShowModal(item)}
                  />
                </div>
                <div className={styles.blockStatus}>
                  {!item.isResolved && <img src={statusImage} />}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
export default PurchasedTable;
