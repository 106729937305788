import React, { useState } from "react";
import styles from "./customSelect.module.scss";
const CustomSelect = ({
  imgLeft,
  label,
  options,
  selectContainer,
  disabled,
  error,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const handleSelectContainer = (value) => {
    setDropdown(false);
    selectContainer(value);
  };
  return (
    <div
      className={dropdown ? styles.showDropdown : styles.hideDropdown}
      onMouseLeave={() => setDropdown(false)}
    >
      <div
        className={disabled ? styles.selectDisabled : styles.select}
        onClick={!disabled ? () => setDropdown(true) : null}
      >
        <img src={imgLeft} alt={""}></img>
        <p>{label}</p>
        {error && <span className={styles.error}> *</span>}
      </div>
      <div className={styles.dropdown}>
        {options &&
          options.map((item) => (
            <p
              key={item.value}
              className={styles.dropdownItem}
              onClick={() => handleSelectContainer(item)}
            >
              {item.label}
            </p>
          ))}
      </div>
    </div>
  );
};
export default CustomSelect;
