import React, { useState, useEffect } from "react";
import styles from "./cleanersManagement.module.scss";
import CleanersList from "../../_components/cleaners/cleanersList/index";
import SingleCleaner from "../../_components/cleaners/singleCleaner/index";
import useIsCleaner from "../../_utils/isCleaner";

const CleanersManagement = () => {
  const isCleaner = useIsCleaner();
  useEffect(() => {
    isCleaner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [selectedCleanerId, setSelectedCleanerId] = useState("");
  const [cleanerIsDeleted, setCleanerIsDeleted] = useState(1);
  const [refreshList, setRefreshList] = useState(1);

  const selectCleanerHandler = (id) => {
    setSelectedCleanerId(id);
  };

  const onCleanerDelete = () => {
    setCleanerIsDeleted((prev) => (prev === 1 ? 2 : 1));
  };
  const refreshCleanersList = () => {
    setRefreshList((prev) => (prev === 1 ? 2 : 1));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <CleanersList
          selectedCleanerId={selectedCleanerId}
          onSelectCleaner={selectCleanerHandler}
          cleanerIsDeleted={cleanerIsDeleted}
          refreshList={refreshList}
        />
      </div>
      <div className={styles.right}>
        <SingleCleaner
          cleanerId={selectedCleanerId}
          onDelete={onCleanerDelete}
          getAllCleaners={refreshCleanersList}
        />
      </div>
    </div>
  );
};

export default CleanersManagement;
