import React, { useEffect, useState } from "react";
import styles from "./containersGraph.module.scss";
import LineChart from "../../../charts/line";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { mainActions } from "../../../../_store/actions/main.actions";
import Loading from "../../../loading";
import t from "../../../../_config/constants";
const ContainersGraph = ({ update }) => {
  const [arrayForLineChart, setarrayForLineChart] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const store = useSelector((state) => state);

  const getArrayForLineChart = (list) => {
    let arr = [];
    list.forEach((batch) => {
      let index = arr.findIndex(
        (item) => item.labels === moment(batch.createdAt).format("MMM")
      );
      if (index === -1) {
        arr.push({
          labels: moment(batch.createdAt).format("MMM"),
          value: Number(batch.containerIds.length),
        });
      } else {
        let val = arr[index].value + Number(batch.containerIds.length);
        arr[index].value = val;
      }
    });
    setLoading(false);
    setarrayForLineChart(arr.reverse());
  };
  const getCleanerBatches = async (id, regionId) => {
    let filter = [{ userId: id }];
    if (store.auth && store.auth.user && store.auth.user.type === "cleaner") {
      let response = await dispatch(
        mainActions.run("data", "cleanerBatch", "all", { filter })
      );
      if (response) {
        getArrayForLineChart(response);
      } else {
        setLoading(false);
      }
    } else if (
      store.auth &&
      store.auth.user &&
      (store.auth.user.type === "master" || store.auth.user.type === "admin")
    ) {
      let filter = [];
      if (regionId && regionId !== "*") {
        filter.push({ regionId });
      }
      let response = await dispatch(
        mainActions.run("data", "cleanerBatch", "all", { filter })
      );
      if (response) {
        getArrayForLineChart(response);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    getCleanerBatches(store.auth.user._id, store.auth.user.regionId);
  }, [update, store.auth && store.auth.user]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleAndYear}>
        <h3>{t.cleaning.text_cleaned_containers}</h3>
      </div>
      {loading && <Loading width={50} height={50} />}
      {!loading && arrayForLineChart.length > 0 && (
        <div className={styles.graph}>
          <LineChart arrayForLineChart={arrayForLineChart} />
        </div>
      )}
      {!loading && !arrayForLineChart.length && (
        <div className={styles.noDataMsg}>{t.cleaning.text_no_chart_data}</div>
      )}
    </div>
  );
};

export default ContainersGraph;
