import React, { useState, useEffect } from "react";
import Pricing from "./pricing";
import Containers from "./containers";
import styles from "./settings.module.scss";
import leftArrow from "./../../../_assets/icons/leftArrow.svg";
import Button from "../../button";
import queryString from "query-string";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import Loading from "../../loading";
import LogsOfActivity from "./logs";
const Settings = () => {
  const [activeTab, setActiveTab] = useState("containers");
  const history = useHistory();
  const { id } = queryString.parse(history.location.search);
  const [loading, setLoading] = useState(true);
  const [restaurant, setRestaurant] = useState({});
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [regionId, setRegionId] = useState("");
  const getRestaurant = async (id) => {
    let data = await dispatch(
      mainActions.run("profiles", "restaurant", "id", id)
    );
    setRestaurant(data);
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    getRestaurant(id);
  }, []);
  useEffect(() => {
    if (store.auth && store.auth.user) {
      setRegionId(store.auth.user.regionId);
    }
  }, [store.auth && store.auth.user]);
  const handleBack = () => {
    history.push(`/store-management?id=${id}`);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {loading && (
          <Loading className={styles.loader} width={70} height={70} />
        )}
        {!loading && restaurant && (
          <>
            <div className={styles.back}>
              <Button
                label={"Back to " + restaurant.name}
                btnClass="btnBack"
                iconLeft={leftArrow}
                onClick={handleBack}
              />
            </div>
            {activeTab === "containers" && (
              <Containers
                onContainersClick={() => setActiveTab("containers")}
                onPricingClick={() => setActiveTab("pricing")}
                onLogsClick={() => setActiveTab("logs")}
                activeTab={activeTab}
                regionId={regionId}
              />
            )}
            {activeTab === "pricing" && (
              <Pricing
                onContainersClick={() => setActiveTab("containers")}
                onPricingClick={() => setActiveTab("pricing")}
                onLogsClick={() => setActiveTab("logs")}
                activeTab={activeTab}
                regionId={regionId}
              />
            )}
            {activeTab === "logs" && (
              <LogsOfActivity
                onContainersClick={() => setActiveTab("containers")}
                onPricingClick={() => setActiveTab("pricing")}
                onLogsClick={() => setActiveTab("logs")}
                activeTab={activeTab}
                restaurant={restaurant}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default Settings;
