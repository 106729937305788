import React from "react";
import styles from "./uploadAvatar.module.scss";
import noAvatar from "../../_assets/images/noAvatar.png";
import uploadImg from "../../_assets/images/uploadImg.png";
import uploadIcon from "../../_assets/icons/uploadIcon.png";

const UploadAvatar = ({ uri, setImg, type = "avatar" }) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={
          styles[
            type === "avatar"
              ? "imgContainer"
              : type === "icon" || type === "logo"
              ? "imgContainerSM"
              : "imgContainerSq"
          ]
        }
      >
        <img src={uri ? uri : type === "avatar" ? noAvatar : uploadIcon} />
      </div>
      <div className={styles.inputContainer}>
        <label htmlFor={type === "avatar" ? "imgInput" : "bkgInput"}>
          Upload{" "}
          {type === "avatar"
            ? "Logo"
            : type === "background"
            ? "Background"
            : type === "logo"
            ? "Logo"
            : "Icon"}{" "}
        </label>
        <input
          type="file"
          id={type === "avatar" ? "imgInput" : "bkgInput"}
          accept="image/jpeg, image/jpg, image/png"
          style={{ display: "none" }}
          onChange={(e) => {
            setImg(e);
          }}
        />
      </div>
    </div>
  );
};
export default UploadAvatar;
