import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import SearchBar from "../../../searchBar";
import Button from "../../../button";
import Selector from "../../../selector";
import FormModal from "../../../modal/formModal";
import NotificationsModal from "../../notificationsModal";
import SMSModal from "../../smsModal";
import Loading from "../../../loading";
import Input from "../../../input";
import DownloadData from "./downloadData";
import Pagination from "../../../pagination";
import Checkbox from "../../../checkbox";
import UsersTable from "../usersTable";

import { mainActions } from "../../../../_store/actions/main.actions";
import { termRegex } from "../../../../_utils/utils";
import useDebounce from "../../../../_utils/debounce";
import t from "../../../../_config/constants";

import UsersImg from "../../../../_assets/icons/user.svg";

import styles from "./list.module.scss";
import ReusersCardsTable from "../reuserCardsTable";
import ReuserCardModal from "../../reuserCardModal";

const membershipOptions = [
  { value: "", label: "Free & Premium" },
  { value: "Free", label: "Free" },
  { value: "Premium", label: "Premium" },
];
const operators = [
  { label: "-", value: "" },
  { label: "1 or more", value: "$gt" },
  { label: "None", value: "$eq" },
];

const topBottom = [
  { label: "Top", value: "top" },
  { label: "Bottom", value: "bottom" },
];

const UsersList = ({
  handleSelectUser,
  selectedUser,
  update,
  setHideSingle,
  setIsUser,
  setSelectedUser,
}) => {
  const store = useSelector((state) => state);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);
  const [showMembers, setShowMembers] = useState(true);
  const [showReuserCards, setShowReuserCards] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showUnregistered, setShowUnregistered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [showReuserCardsModal, setShowReuserCardsModal] = useState(false);
  const [disabledCloseModal, setDisabledCloseModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const pageSize = 50;
  const [numberOfMembers, setNumberOfMembers] = useState(0);
  const [percentageOfSubscribed, setPercentageOfSubscribed] = useState(0);
  const [sortConfig, setSortConfig] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectUsers, setSelectUsers] = useState(false);
  const [filterData, setFilterData] = useState({
    searchTerm: "",
    selectedValue: "",
  });
  const [postalCodes, setPostalCodes] = useState([]);
  const [filterBy, setFilterBy] = useState({
    membership: "",
    postalCodes: [],
    totalContainersUsed: { section: "top", value: "" },
    inPossession: "",
  });

  const debouncedSearchTerm = useDebounce(filterData.searchTerm, 500);

  const dispatch = useDispatch();

  useEffect(() => {
    setPageNumber(0);
    setSelectedUsers([]);
    setSelectAll(false);
    setFilterBy({
      membership: "",
      postalCodes: [],
      totalContainersUsed: { section: "top", value: "" },
      inPossession: "",
    });
  }, [store.auth && store.auth.user]);

  useEffect(() => {
    setPageNumber(0);
  }, [showMembers, debouncedSearchTerm, filterBy]);

  useEffect(() => {
    if (selectAll) {
      getAllUsers(store.auth.user.regionId);
    }
  }, [filterBy]);

  useEffect(() => {
    if (store?.main?.data?.region?.list) {
      let regions = store.main.data.region.list;
      if (store.auth.user.regionId !== "*")
        regions = regions.filter(
          (region) => region._id + "" === store.auth.user.regionId
        );
      const postalCodes = [];
      regions.forEach((region) => {
        if (region.postal_codes && region.country === "Canada") {
          postalCodes.push(...region.postal_codes);
        } else if (region.used_postal_codes) {
          postalCodes.push(...region.used_postal_codes);
        }
      });
      const options = postalCodes.map((postalCode) => ({
        value: postalCode,
        label: postalCode,
      }));
      setPostalCodes(options);
    }
  }, [store?.main?.data?.region?.list]);

  useEffect(() => {
    setHideSingle(selectUsers);
  }, [selectUsers]);

  const getNumericTerm = (term) => {
    const num = term.replace(/[- )(/|Rr]/g, "");
    if (!num) return " ";
    return num;
  };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const name = e.target.name;
    if (name === "members") {
      setIsUser(true);
      setSelectedUser("");
      setShowMembers(true);
      setShowUnregistered(false);
      setShowReuserCards(false);
      setFilterBy({
        membership: "",
        postalCodes: [],
        totalContainersUsed: { section: "top", value: "" },
        inPossession: "",
      });
    } else if (name === "unregistered") {
      setIsUser(true);
      setSelectedUser("");
      setShowMembers(false);
      setShowUnregistered(true);
      setShowReuserCards(false);
      setFilterBy({
        membership: "",
        postalCodes: [],
        totalContainersUsed: { section: "top", value: "" },
        inPossession: "",
      });
    } else if (name === "reuserCards") {
      setIsUser(false);
      setSelectedUser("");
      setShowMembers(false);
      setShowUnregistered(false);
      setShowReuserCards(true);
      setFilterBy({
        membership: "",
        postalCodes: [],
        totalContainersUsed: { section: "top", value: "" },
        inPossession: "",
      });
    } else if (name === "all") {
      setSelectAll(e.target.checked);
      if (e.target.checked) {
        getAllUsers(store.auth.user.regionId);
      } else {
        setSelectedUsers([]);
      }
    }
  };

  const getAllUsers = async (regionId) => {
    let filter = [];

    if (filterBy.membership === "N/A") {
      filter.push({
        membership: {
          $nin: ["Basic", "Basic Yearly", "Per Use"], //izmena ovde
        },
      });
    } else if (filterBy.membership === "*") {
      filter.push({
        membership: {
          $in: ["Basic", "Basic Yearly", "Per Use"],
        },
      });
    } else if (filterBy.membership) {
      filter.push({ membership: filterBy.membership });
    }

    if (filterBy.postalCodes.length > 0) {
      filter.push({
        postalCode: {
          $in: filterBy.postalCodes,
        },
      });
    }
    if (filterBy.inPossession) {
      filter.push({
        inPossession: {
          [filterBy.inPossession]: 0,
        },
      });
    }
    if (
      filterBy.totalContainersUsed.section &&
      filterBy.totalContainersUsed.value &&
      Number(filterBy.totalContainersUsed.value) >= 0 &&
      Number(filterBy.totalContainersUsed.value) <= 100
    ) {
      filter.push({
        totalContainersUsed: filterBy.totalContainersUsed,
      });
    }
    if (regionId && regionId !== "*") {
      filter.push({
        regionId: regionId,
      });
    }
    let users = await dispatch(
      mainActions.run("profiles", "user", "all", { filter })
    );
    setSelectedUsers(users);
  };

  const getData = async (
    term,
    filterBy,
    regionId,
    subregionId,
    pageNumber,
    sortConfig = { createdAt: -1 }
  ) => {
    setLoadingData(true);
    let filter = [];
    const numericTerm = getNumericTerm(term);
    if (regionId && regionId !== "*" && (showMembers || showReuserCards)) {
      filter.push({
        regionId: regionId,
      });
      if (subregionId && subregionId !== "*")
        filter.push({
          subregionId,
        });
    }
    if (filterBy.membership === "N/A") {
      filter.push({
        membership: {
          $nin: ["Basic", "Basic Yearly", "Per Use"],
        },
      });
    } else if (filterBy.membership === "*") {
      filter.push({
        membership: {
          $in: ["Basic", "Basic Yearly", "Per Use"],
        },
      });
    } else if (filterBy.membership) {
      filter.push({ membership: filterBy.membership });
    }

    if (filterBy.postalCodes.length > 0) {
      filter.push({
        postalCode: {
          $in: filterBy.postalCodes,
        },
      });
    }
    if (filterBy.inPossession) {
      filter.push({
        inPossession: {
          [filterBy.inPossession]: 0,
        },
      });
    }
    if (
      filterBy.totalContainersUsed.section &&
      filterBy.totalContainersUsed.value &&
      Number(filterBy.totalContainersUsed.value) > 0 &&
      Number(filterBy.totalContainersUsed.value) <= 100
    ) {
      filter.push({
        totalContainersUsed: filterBy.totalContainersUsed,
      });
    }
    if (term) {
      filter.push({
        $or: [
          { fullName: { $regex: `${termRegex(term)}`, $options: "i" } },
          { phone: { $regex: `${termRegex(numericTerm)}`, $options: "i" } },
          { email: { $regex: `${termRegex(term)}`, $options: "i" } },
          { customer: { $regex: `${termRegex(term)}`, $options: "i" } },
          {
            orderingCode: {
              $regex: `${termRegex(numericTerm)}`,
              $options: "i",
            },
          },
        ],
      });
    }
    if (showMembers) {
      let response = await dispatch(
        mainActions.run("profiles", "user", "page", {
          filter,
          pageSize,
          pageNumber,
          sort: sortConfig,
        })
      );
      if (!response)
        response = { rows: [], total: 0, percentageOfSubscribed: 0 };

      setNumberOfMembers(response.total);
      setData(response.rows);
      setPercentageOfSubscribed(
        Number(response.percentageOfSubscribed).toFixed()
      );
    } else if (showUnregistered) {
      let response = await dispatch(
        mainActions.run("profiles", "unregistered", "page", {
          filter,
          pageSize,
          pageNumber,
          sort: sortConfig,
        })
      );
      if (!response) response = { rows: [], total: 0 };

      setNumberOfMembers(response.total);
      setData(response.rows);
    } else {
      let response = await dispatch(
        mainActions.run("profiles", "reuserCard", "page", {
          filter,
          pageSize,
          pageNumber,
          sort: sortConfig,
        })
      );
      if (!response) response = { rows: [], total: 0 };

      setNumberOfMembers(response.total);
      setData(response.rows);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    if (store.auth?.user?.regionId)
      getData(
        debouncedSearchTerm,
        filterBy,
        store.auth.user.regionId,
        store.auth.user.subregionId,
        pageNumber,
        sortConfig
      );
  }, [
    debouncedSearchTerm,
    store.auth?.user,
    filterBy,
    update,
    showMembers,
    pageNumber,
    sortConfig,
    refresh,
  ]);

  const handleCloseDisabled = (val) => {
    setDisabledCloseModal(val);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const closeReuserModal = () => {
    setShowReuserCardsModal(false);
  };
  const closeSMSModal = () => {
    setShowSMSModal(false);
  };

  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };

  const changeSortConfig = (data) => {
    if (data) {
      const { key, direction } = data;
      setSortConfig({ [key]: direction === "ascending" ? 1 : -1 });
    }
  };

  const handleSelectedUser = (user) => {
    let index = selectedUsers.findIndex((item) => user._id === item._id);
    if (index !== -1) {
      let filtered = selectedUsers.filter((item) => item._id !== user._id);
      setSelectedUsers(filtered);
      setSelectAll(false);
    } else {
      setSelectedUsers((prevState) => [...prevState, user]);
    }
  };

  const handleCancel = () => {
    setSelectUsers(false);
    setSelectedUsers([]);
    setSelectAll(false);
  };

  const handleSelect = (e, name) => {
    if (name === "postalCodes" && e.length === 0) {
      setFilterBy((data) => ({
        ...data,
        postalCodes: e,
      }));
    } else if (name === "postalCodes") {
      let val = e.map((obj) => obj.value);
      setFilterBy((data) => ({
        ...data,
        postalCodes: val,
      }));
    } else if (name === "operator") {
      setFilterBy((data) => ({
        ...data,
        inPossession: e.value,
      }));
    } else if (name === "section") {
      setFilterBy((data) => ({
        ...data,
        totalContainersUsed: { ...data.totalContainersUsed, section: e.value },
      }));
    }
  };

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    if (name === "totalContainersUsed") {
      setFilterBy((data) => ({
        ...data,
        totalContainersUsed: { ...data.totalContainersUsed, value: value },
      }));
    } else setFilterBy((data) => ({ ...data, [name]: value }));
  };

  const getMultiSelectedValues = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (value.findIndex((item) => item === obj.value) !== -1) {
          selectedData.push(obj);
        }
      });

    return selectedData;
  };

  return (
    <div className={styles.wrapper}>
      {showModal && (
        <FormModal
          show={showModal}
          handleClose={closeModal}
          closeOnOverlayClick={!disabledCloseModal}
          closeDisabled={disabledCloseModal}
          content={
            <NotificationsModal
              handleClose={closeModal}
              users={selectedUsers}
              handleDisabled={handleCloseDisabled}
              handleCancel={handleCancel}
            />
          }
          size="md"
        />
      )}
      {showSMSModal && (
        <FormModal
          show={showSMSModal}
          handleClose={closeSMSModal}
          closeOnOverlayClick={!disabledCloseModal}
          closeDisabled={disabledCloseModal}
          content={
            <SMSModal
              handleClose={closeSMSModal}
              users={selectedUsers}
              handleDisabled={handleCloseDisabled}
              handleCancel={handleCancel}
            />
          }
          size="md"
        />
      )}
      {showReuserCardsModal && (
        <FormModal
          show={showReuserCardsModal}
          handleClose={closeReuserModal}
          closeOnOverlayClick={!disabledCloseModal}
          closeDisabled={disabledCloseModal}
          content={
            <ReuserCardModal
              handleClose={closeReuserModal}
              handleDisabled={handleCloseDisabled}
              handleCancel={handleCancel}
              setRefresh={setRefresh}
            />
          }
          size="md"
        />
      )}
      <div className={styles.title}>
        <h2>
          {showMembers
            ? t.user.text_list_of_members
            : showUnregistered
            ? t.user.text_list_of_unregistered
            : "Reuser Cards"}
        </h2>
        <div className={styles.buttons}>
          {showMembers && (
            <Button
              label="Send Notifications"
              btnClass="btnNormalGlacier"
              disabled={
                loadingData ||
                data.length === 0 ||
                (selectUsers && selectedUsers.length === 0)
              }
              onClick={() => {
                selectUsers ? setShowModal(true) : setSelectUsers(true);
              }}
            />
          )}
          {!showReuserCards && (
            <Button
              label="Send SMS"
              btnClass="btnNormalGlacier"
              disabled={
                loadingData ||
                data.length === 0 ||
                (selectUsers && selectedUsers.length === 0)
              }
              onClick={() => {
                selectUsers ? setShowSMSModal(true) : setSelectUsers(true);
              }}
            />
          )}
          {showReuserCards && (
            <Button
              label="Add Reuser Cards "
              btnClass="btnNormalGlacier"
              disabled={loadingData}
              onClick={() => {
                setShowReuserCardsModal(true);
              }}
            />
          )}
          {selectUsers ? (
            <Button
              label="Cancel"
              btnClass="btnWhiteBackground"
              disabled={loadingData}
              onClick={handleCancel}
            />
          ) : (
            <DownloadData
              disabled={!numberOfMembers || loadingData}
              type={
                showMembers
                  ? "user"
                  : showUnregistered
                  ? "unregistered"
                  : "reuserCard"
              }
              regionId={store.auth.user.regionId}
            />
          )}
        </div>
      </div>

      <div className={styles.header}>
        {isNaN(percentageOfSubscribed) ||
        isNaN(numberOfMembers) ||
        loadingData ? (
          <div className={styles.loadingData}>
            <Loading width={50} height={50} />
            <img src={UsersImg} />
          </div>
        ) : (
          <div>
            <div className={styles.info}>
              <div>
                <span>{numberOfMembers}</span>
                <img src={UsersImg} />
              </div>
              <span>
                {showMembers
                  ? t.user.text_reusable_members
                  : showUnregistered
                  ? t.user.text_unregistered_users
                  : ""}{" "}
                {showMembers && numberOfMembers !== 0 && (
                  <div className={styles.subs}>
                    <p>
                      ({" "}
                      {isNaN(percentageOfSubscribed) || isNaN(numberOfMembers)
                        ? ""
                        : percentageOfSubscribed}
                      % Subscribed )
                    </p>
                  </div>
                )}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className={styles.searchAndFilter}>
        {!showReuserCards && (
          <SearchBar
            customClass="transactionSearch"
            placeholder={
              showMembers
                ? t.user.text_search_member_placeholder
                : t.user.text_search_user_placeholder
            }
            name="searchTerm"
            value={filterData.searchTerm}
            onChange={handleChange}
            disabled={loadingData}
          />
        )}
        <div
          className={
            loadingData ? styles.disabledCheckbox : styles.checkboxWrapper
          }
        >
          {!selectUsers ? (
            <>
              <Checkbox
                customClass="secondaryCheckbox"
                name="members"
                label={t.user.text_members}
                checked={showMembers}
                onChange={handleCheckboxChange}
                disabled={loadingData}
              />
              <Checkbox
                customClass="secondaryCheckbox"
                name="unregistered"
                label={t.user.text_unregistered}
                checked={showUnregistered}
                onChange={handleCheckboxChange}
                disabled={loadingData}
              />
              <Checkbox
                customClass="secondaryCheckbox"
                name="reuserCards"
                label={"Reuser Cards"}
                checked={showReuserCards}
                onChange={handleCheckboxChange}
                disabled={loadingData}
              />
            </>
          ) : (
            <>
              <Checkbox
                customClass="secondaryCheckbox"
                name="all"
                label="Select All"
                checked={selectAll}
                onChange={handleCheckboxChange}
                disabled={loadingData}
              />
            </>
          )}
        </div>
      </div>
      {showMembers && (
        <div className={styles.filter}>
          <div className={styles.selector}>
            <Selector
              label={"Membership"}
              multiselect={false}
              options={membershipOptions}
              selectedOption={getSelectedValue(
                membershipOptions,
                filterBy.membership
              )}
              handleChangeSelect={(e) =>
                setFilterBy((prevState) => ({
                  ...prevState,
                  membership: e.value,
                }))
              }
              selectClassname="selectSecondaryWhiteBg"
            />
          </div>
          <div className={styles.selector}>
            <Selector
              label={"Postal Code (FSA)"}
              multiselect={true}
              options={postalCodes}
              handleChangeSelect={(e) => {
                handleSelect(e, "postalCodes");
              }}
              selectedOption={getMultiSelectedValues(
                postalCodes,
                filterBy.postalCodes
              )}
              selectClassname="selectSecondaryWhiteBg"
            />
          </div>

          <div className={styles.selector}>
            <Selector
              label={"Containers In Possession "}
              placeholder="Select"
              selectClassname="selectSecondaryWhiteBg"
              options={operators}
              handleChangeSelect={(e) => handleSelect(e, "operator")}
              value={getSelectedValue(operators, filterBy.inPossession)}
              selectedOption={getSelectedValue(
                operators,
                filterBy.inPossession
              )}
            />
          </div>

          <div className={styles.selectorAndInput}>
            <p>Total Containers Used</p>
            <div className={styles.container}>
              <div className={styles.selectorSm}>
                <Selector
                  placeholder="Select"
                  selectClassname="selectSecondaryWhiteBg"
                  options={topBottom}
                  handleChangeSelect={(e) => handleSelect(e, "section")}
                  selectedOption={getSelectedValue(
                    topBottom,
                    filterBy.totalContainersUsed.section
                  )}
                />
              </div>
              <div className={styles.input}>
                <Input
                  inputClass="inputFormWhiteBorderGray"
                  value={filterBy.totalContainersUsed.value}
                  onChange={handleChangeFilter}
                  desc={"%"}
                  type="number"
                  name="totalContainersUsed"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.tableWrapper}>
        {showReuserCards ? (
          <ReusersCardsTable
            changeSortConfig={changeSortConfig}
            data={data}
            handleSelectUser={handleSelectUser}
            loadingData={loadingData}
            selectedUser={selectedUser}
          />
        ) : (
          <UsersTable
            data={data}
            showMembers={showMembers}
            handleSelectUser={handleSelectUser}
            selectedUser={selectedUser}
            loadingData={loadingData}
            changeSortConfig={changeSortConfig}
            handleSelectedUser={handleSelectedUser}
            selectedUsers={selectedUsers}
            selectUsers={selectUsers}
            handleCheckboxChange={handleCheckboxChange}
            selectAll={selectAll}
          />
        )}
      </div>
      <div className={styles.pagination}>
        <Pagination
          handlePageClick={onPageChange}
          pageCount={Math.ceil(numberOfMembers / pageSize)}
          forcePage={pageNumber}
          loading={loadingData}
        />
      </div>
    </div>
  );
};
export default UsersList;
