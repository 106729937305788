import React, { useEffect, useState } from "react";
import styles from "./groupList.module.scss";
import Button from "../../button/index";
import SearchBar from "../../searchBar/index";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import { modalActions } from "../../../_store/actions/modal.actions";
import useDebounce from "../../../_utils/debounce";
import { termRegex } from "../../../_utils/utils";
import t from "../../../_config/constants";
import Pagination from "../../pagination";
import GroupTable from "../groupTable";
import AddGroup from "../addGroup";
const GroupList = ({
  refreshList,
  onSelectGroup,
  selectedGroupId,
  setActiveTab,
  activeTab,
  handleRefreshList,
}) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [numberOfGroups, setNumberOfGroups] = useState(0);
  const [sortConfig, setSortConfig] = useState({});
  const [filterData, setFilterData] = useState({
    searchTerm: "",
    selectedValue: "",
  });

  const debouncedSearchTerm = useDebounce(filterData.searchTerm, 500);

  const onChangeTerm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterData((prevState) => ({ ...prevState, [name]: value }));
  };

  const getFilteredGroups = async (
    term,
    id,
    pageNumber,
    sortConfig = { createdAt: -1 }
  ) => {
    setLoadingData(true);
    let filter = [];

    if (id && id !== "*") {
      filter.push({
        regionId: id,
      });
    }

    if (term) {
      filter.push({
        $or: [{ name: { $regex: `${termRegex(term)}`, $options: "i" } }],
      });
    }
    let data = await dispatch(
      mainActions.run("data", "restaurantGroup", "page", {
        filter,
        pageSize,
        pageNumber,
        sort: sortConfig,
      })
    );

    setFilteredGroups(data.rows);
    setNumberOfGroups(data.total);
    setLoadingData(false);
  };
  useEffect(() => {
    setPageNumber(0);
  }, [debouncedSearchTerm, store.auth && store.auth.user]);
  useEffect(() => {
    getFilteredGroups(
      debouncedSearchTerm,
      store.auth.user.regionId,
      pageNumber,
      sortConfig
    );
  }, [
    debouncedSearchTerm,
    store.auth && store.auth.user,
    refreshList,
    pageNumber,
    sortConfig,
  ]);

  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };
  const changeSortConfig = (data) => {
    if (data) {
      const { key, direction } = data;
      setSortConfig({ [key]: direction === "ascending" ? 1 : -1 });
    }
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.tabs}>
        <p
          className={
            activeTab === "single" ? styles.tabActive : styles.tabInactive
          }
          onClick={() => setActiveTab("single")}
        >
          {t.restaurant.text_restaurats}
        </p>
        <p
          className={
            activeTab === "groups" ? styles.tabActive : styles.tabInactive
          }
          onClick={() => setActiveTab("groups")}
        >
          {t.restaurant.text_groups}
        </p>
        <p
          className={
            activeTab === "archived" ? styles.tabActive : styles.tabInactive
          }
          onClick={() => setActiveTab("archived")}
        >
          {t.restaurant.text_archived_stores}
        </p>
      </div>
      <div className={styles.header}>
        <div className={styles.buttons}>
          <Button
            label={t.button.text_add_group}
            btnClass="btnNormalCasal"
            disabled={false}
            onClick={() => {
              dispatch(
                modalActions.openModal(
                  <AddGroup refreshTableData={handleRefreshList} />
                )
              );
            }}
          />
        </div>
      </div>
      <h2>{t.restaurant.text_list_groups}</h2>
      <div className={styles.searchAndFilter}>
        <SearchBar
          customClass="restaurantSearch"
          placeholder={t.restaurant.text_search_groups}
          name="searchTerm"
          onChange={onChangeTerm}
          disabled={loadingData}
        />
      </div>
      <div className={styles.tableWrapper}>
        <GroupTable
          groups={filteredGroups}
          onSelectGroup={onSelectGroup}
          selectedGroupId={selectedGroupId}
          loadingData={loadingData}
          changeSortConfig={changeSortConfig}
        />
      </div>
      <div className={styles.pagination}>
        <Pagination
          handlePageClick={onPageChange}
          pageCount={Math.ceil(numberOfGroups / pageSize)}
          forcePage={pageNumber}
          loading={loadingData}
        />
      </div>
    </div>
  );
};

export default GroupList;
