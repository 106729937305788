import React, { useState, useEffect } from "react";
import styles from "./statistics.module.scss";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../_utils/debounce";
import Searchbar from "../../searchBar/index";
import CleanedContainers from "./cleanedContainers";
import ContainersGraph from "./containersGraph";
import Button from "../../button/index";
import Loading from "../../loading";
import scan from "../../../_assets/icons/scan.svg";
import { mainActions } from "../../../../src/_store/actions/main.actions";
import Checkbox from "../../checkbox/index";
import leftArrow from "../../../_assets/icons/leftArrow.svg";
import QrGenerator from "../../containers/qrCode/index";
import toastService from "../../../_services/toast.service";
import ConfirmModal from "../../../_components/modal/confirmModal";
import trash from "../../../_assets/icons/trash.svg";
import edit from "../../../_assets/icons/edit.svg";
import moment from "moment";
import { makeSelectMap, termRegex } from "../../../_utils/utils";
import t from "../../../_config/constants";
import Pagination from "../../pagination";
import Selector from "../../selector";
const Statistics = ({ handleNext, handleEdit }) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [batch, setBatch] = useState([]);
  const [viewBatch, setViewBatch] = useState(false);
  const [selectedContainers, setSelectedContainers] = useState([]);
  const [selectedSingleContainer, setSelectedSingleContainer] = useState([]);
  const [containers, setContainers] = useState([]);
  const [showDeleteContainers, setShowDeleteContainers] = useState(false);
  const [showDeleteSingleContainer, setShowDeleteSingleContainer] =
    useState(false);
  const [update, setUpdate] = useState(1);
  const [selectedBatch, setSelectedBatch] = useState({});
  const openDeleteContainer = () => {
    setShowDeleteContainers(true);
  };
  const [selectedCleaner, setSelectedCleaner] = useState("");
  const [cleanerOptions, setCleanerOptions] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [numberOfData, setNumberOfData] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [filterData, setFilterData] = useState({
    searchTerm: "",
    selectedValue: "",
  });

  const debouncedSearchTerm = useDebounce(filterData.searchTerm, 500);

  const onChangeTerm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterData((prevState) => ({ ...prevState, [name]: value }));
  };

  const getCleanerBatches = async (
    id,
    term,
    pageNumber,
    cleanerId,
    sortConfig = { createdAt: -1 }
  ) => {
    setLoadingData(true);
    let filter = [{ userId: id }];
    if (term) {
      filter.push({
        $or: [
          {
            uniqueCleanerBatchId: {
              $regex: `${termRegex(term)}`,
              $options: "i",
            },
          },
        ],
      });
    }

    let filterAdmin = [];
    if (store.auth.user.regionId !== "*") {
      filterAdmin.push({ regionId: store.auth.user.regionId });
    }
    if (term) {
      filterAdmin.push({
        $or: [
          {
            uniqueCleanerBatchId: {
              $regex: `${termRegex(term)}`,
              $options: "i",
            },
          },
        ],
      });
    }
    if (cleanerId) filterAdmin.push({ userId: cleanerId });
    if (store.auth && store.auth.user && store.auth.user.type === "cleaner") {
      let response = await dispatch(
        mainActions.run("data", "cleanerBatch", "page", {
          filter,
          pageSize,
          pageNumber,
          sort: sortConfig,
        })
      );
      setBatch(response.rows);
      setNumberOfData(response.total);
    } else if (
      store.auth &&
      store.auth.user &&
      (store.auth.user.type === "master" || store.auth.user.type === "admin")
    ) {
      let response = await dispatch(
        mainActions.run("data", "cleanerBatch", "page", {
          filter: filterAdmin,
          pageSize,
          pageNumber,
          sort: sortConfig,
        })
      );
      if (!response) response = { rows: [], total: 0, cleaners: [] };
      const arr = makeSelectMap(response.cleaners || []);
      setCleanerOptions([{ label: "All Cleaners", value: "" }, ...arr]);
      setBatch(response.rows);
      setNumberOfData(response.total);
    }
    setLoadingData(false);
  };
  useEffect(() => {
    getCleanerBatches(
      store.auth.user._id,
      debouncedSearchTerm,
      pageNumber,
      selectedCleaner
    );
  }, [
    debouncedSearchTerm,
    update,
    store.auth && store.auth.user,
    selectedCleaner,
    pageNumber,
  ]);
  useEffect(() => {
    setPageNumber(0);
  }, [debouncedSearchTerm, store.auth && store.auth.user]);
  const handleView = async (item) => {
    setSelectedBatch(item);
    setViewBatch(true);
    await getContainersByIds(item.containerIds);
  };

  const getContainersByIds = async (ids) => {
    setLoadingData(true);
    let response = await dispatch(
      mainActions.run("data", "container", "all", {
        filter: { _id: { $in: ids } },
      })
    );
    if (response) setContainers(response);
    setLoadingData(false);
  };

  const handleCheckedContainers = (id) => {
    if (selectedContainers.includes(id)) {
      let filtered = selectedContainers.filter((item) => item !== id);
      setSelectedContainers(filtered);
    } else {
      setSelectedContainers((prevState) => [...prevState, id]);
    }
  };

  const handleSingleContainer = (id) => {
    setSelectedSingleContainer([id]);
    setShowDeleteSingleContainer(true);
  };

  const mapContainersBatch = (containers) => {
    let map = {};
    containers.map((container) => {
      if (!map[container.batchId]) {
        map[container.batchId] = [];
      }
      map[container.batchId] = [...map[container.batchId], container._id];
    });
    return map;
  };

  const deleteContainers = async (answer, data) => {
    if (answer) {
      let batchMap = mapContainersBatch(data);
      if (batchMap) {
        let showSuccessMsg = true;
        for (let batchId in batchMap) {
          let response = await dispatch(
            mainActions.run("data", "container", "deleteMulti", {
              ids: batchMap[batchId],
              type: "container",
              batchId: batchId,
            })
          );
          if (!response.success) {
            showSuccessMsg = false;
          }
        }
        if (showSuccessMsg) {
          toastService.show(
            "success",
            t.containerManagement.add.text_successfully_deleted_containers
          );
          setShowDeleteContainers(false);
          setSelectedContainers([]);
          setViewBatch(false);
        } else {
          toastService.show("warning", t.common.text_something_went_wrong);
        }
      }
    } else {
      setShowDeleteContainers(false);
    }
  };

  const deleteSingleContainer = async (answer, data) => {
    if (answer) {
      let response = await dispatch(
        mainActions.run(
          "data",
          "cleanerBatch",
          "deleteMulti",
          { ids: data },
          {
            message:
              t.containerManagement.add.text_successfully_deleted_container,
          }
        )
      );
      if (response.success) {
        setShowDeleteSingleContainer(false);
        setSelectedSingleContainer([]);
        setUpdate(update === 1 ? 2 : 1);
      } else {
        toastService.show("warning", t.common.text_something_went_wrong);
      }
    } else {
      setShowDeleteSingleContainer(false);
    }
  };
  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  return (
    <>
      {showDeleteContainers && (
        <ConfirmModal
          show={showDeleteContainers}
          handleYesNoAnswer={deleteContainers}
          modalData={selectedContainers}
          question={t.containerManagement.add.text_modal_delete_containers}
        />
      )}
      {showDeleteSingleContainer && (
        <ConfirmModal
          show={showDeleteSingleContainer}
          handleYesNoAnswer={deleteSingleContainer}
          modalData={selectedSingleContainer}
          question={t.containerManagement.add.text_modal_delete_container}
        />
      )}
      {!viewBatch ? (
        <div className={styles.left}>
          <h1>{t.cleaning.text_scanned_batch_list}</h1>
          <div className={styles.searchAndFilter}>
            <Searchbar
              customClass="batchSearch"
              placeholder={t.cleaning.text_search_batch}
              disabled={loadingData}
              name="searchTerm"
              value={filterData.searchTerm}
              onChange={onChangeTerm}
            />
            {["admin", "master"].includes(store?.auth?.user?.type) && (
              <Selector
                placeholder={"All Cleaners"}
                multiselect={false}
                options={cleanerOptions}
                selectedOption={getSelectedValue(
                  cleanerOptions,
                  selectedCleaner
                )}
                handleChangeSelect={(e) => setSelectedCleaner(e.value)}
                selectClassname="selectSecondaryWhiteBg"
              />
            )}
          </div>

          <div className={styles.batchList}>
            {loadingData && (
              <div style={{ marginTop: "20px" }}>
                <Loading width={50} height={50} />
              </div>
            )}
            {!loadingData && (
              <div>
                {batch.map((value, index) => {
                  return (
                    <div className={styles.block} key={index}>
                      <p className={styles.index}>
                        {pageNumber * pageSize + index + 1}.
                      </p>
                      <p className={styles.batchId}>
                        {value.uniqueCleanerBatchId}
                      </p>
                      <p className={styles.batchId}>{value.cleanerName}</p>
                      <p className={styles.batchId}>
                        {value.createdAt
                          ? moment(value.createdAt).format("MM/DD/YYYY")
                          : null}{" "}
                      </p>

                      <div className={styles.blockButton}>
                        <Button
                          label="View"
                          btnClass="btnNormalGlacier"
                          onClick={() => handleView(value)}
                        />
                      </div>
                      <img
                        src={trash}
                        alt="Delete container"
                        className={styles.deleteButton}
                        onClick={() => handleSingleContainer(value._id)}
                      />
                      {store.auth.user.type === "cleaner" ? (
                        <img
                          src={edit}
                          alt="Edit container"
                          className={styles.deleteButton}
                          onClick={() => handleEdit(value)}
                        />
                      ) : null}
                    </div>
                  );
                })}
                {!batch.length && (
                  <div>
                    <p className={styles.noResults}>
                      {t.containerManagement.add.text_no_batches}
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={styles.pagination}>
            <Pagination
              handlePageClick={onPageChange}
              pageCount={Math.ceil(numberOfData / pageSize)}
              forcePage={pageNumber}
              loading={loadingData}
            />
          </div>
        </div>
      ) : (
        <div className={styles.leftDetails}>
          <Button
            label="Back"
            btnClass="btnBack"
            iconLeft={leftArrow}
            onClick={() => setViewBatch(false)}
          />
          <div className={styles.tableHeader}>
            <span>Batch ID : {selectedBatch.uniqueCleanerBatchId}</span>
            <span
              style={
                selectedContainers.length > 0
                  ? { cursor: "pointer" }
                  : { cursor: "not-allowed" }
              }
              onClick={
                selectedContainers.length > 0 ? openDeleteContainer : null
              }
            >
              {t.button.text_retire}
            </span>
          </div>
          <div className={styles.list}>
            <div>
              {loadingData && (
                <div style={{ marginTop: "20px" }}>
                  <Loading width={50} height={50} />
                </div>
              )}
              {!loadingData &&
                containers.map((item, index) => (
                  <div className={styles.item} key={index}>
                    <p>
                      {index + 1}. {item.uniqueContainerId}
                    </p>
                    <div className={styles.qrDiv}>
                      <QrGenerator
                        id={item._id}
                        uniqueId={item.uniqueContainerId}
                        name={item.name}
                        material={item.material}
                        batchId={item.uniqueBatchId}
                        size={item.size}
                        volume={item.volume}
                      />
                      <span className={styles.itemCheckbox}>
                        <Checkbox
                          customClass="checkboxFix"
                          label=""
                          checked={selectedContainers.includes(item)}
                          onChange={() => handleCheckedContainers(item)}
                        />
                      </span>
                    </div>
                  </div>
                ))}
              {!loadingData && !containers.length && (
                <div className={styles.noResults}>
                  <p>{t.containerManagement.add.text_no_containers}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={styles.right}>
        <CleanedContainers />
        <div className={styles.subColumn}>
          <ContainersGraph update={update} />
          <div className={styles.button}>
            {store.auth &&
            store.auth.user &&
            store.auth.user.type === "cleaner" ? (
              <Button
                label={t.button.text_start_scanning}
                btnClass="btnStartScaning"
                disabled={false}
                iconLeft={scan}
                onClick={() => handleNext()}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Statistics;
