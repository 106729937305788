import React, { useState, useEffect } from "react";
import styles from "./refundHistory.module.scss";
import Loading from "../../loading";
import Button from "../../button";
import SearchBar from "../../searchBar";
import Checkbox from "../../checkbox";
import useDebounce from "../../../_utils/debounce";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import { termRegex } from "../../../_utils/utils";
import t from "../../../_config/constants";
import Pagination from "../../pagination";
const RefundHistory = ({
  handleView,
  showRestaurants,
  showUsers,
  onChange,
}) => {
  const [restaurants, setRestaurants] = useState([]);
  const [users, setUsers] = useState([]);
  const [filterData, setFilterData] = useState({
    searchTerm: "",
    selectedValue: "",
  });
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [numberOfMembers, setNumberOfMembers] = useState(0);
  const [sortConfig, setSortConfig] = useState({});
  const debouncedSearchTerm = useDebounce(filterData.searchTerm, 500);
  const [listLoading, setListLoading] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector((state) => state);


  const onChangeTerm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterData((prevState) => ({ ...prevState, [name]: value }));
  };

  const getData = async (
    term,
    selectValue,
    id,
    pageNumber,
    sortConfig = { createdAt: -1 }
  ) => {
    let filter = [{ isDraft: { $ne: true } }];

    if (id && id !== "*") {
      filter.push({
        regionId: id,
      });
    }

    if (term) {
      filter.push({
        $or: [
          { name: { $regex: `${termRegex(term)}`, $options: "i" } },
          { fullName: { $regex: `${termRegex(term)}`, $options: "i" } },
          { email: { $regex: `${termRegex(term)}`, $options: "i" } },
          { owner: { $regex: `${termRegex(term)}`, $options: "i" } },
        ],
      });
    }

    if (showRestaurants) {
      let response = await dispatch(
        mainActions.run("profiles", "restaurant", "page", {
          filter,
          pageSize,
          pageNumber,
          sort: sortConfig,
        })
      );
      if (!response) response = { rows: [], total: 0 };

      setRestaurants(response.rows);
      setNumberOfMembers(response.total);
      setListLoading(false);
    } else {
      let response = await dispatch(
        mainActions.run("profiles", "user", "page", {
          filter,
          pageSize,
          pageNumber,
          sort: sortConfig,
        })
      );
      if (!response) response = { rows: [], total: 0 };

      setUsers(response.rows);
      setNumberOfMembers(response.total);
      setListLoading(false);
    }
  };
  useEffect(() => {
    setPageNumber(0);
  }, [showRestaurants, debouncedSearchTerm, store.auth && store.auth.user]);
  useEffect(() => {
    setListLoading(true);
    if (
      (debouncedSearchTerm, filterData.selectedValue, store.auth.user.regionId)
    ) {
      getData(
        debouncedSearchTerm,
        filterData.selectedValue,
        store.auth.user.regionId,
        pageNumber
      );
    }
  }, [
    debouncedSearchTerm,
    filterData.selectedValue,
    store.auth && store.auth.user,
    showRestaurants,
    pageNumber,
    sortConfig,
  ]);
  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };
  return (
    <div className={styles.wrapper}>
      <header>
        <h2>{t.common.text_th_refund}</h2>
        <div className={styles.checkboxWrapper}>
          <Checkbox
            customClass="secondaryCheckbox"
            name="restaurants"
            label={t.restaurant.text_restaurats}
            checked={showRestaurants}
            onChange={onChange}
          />
          <Checkbox
            customClass="secondaryCheckbox"
            name="users"
            label={t.user.text_members}
            checked={showUsers}
            onChange={onChange}
          />
        </div>
      </header>
      <div className={styles.searchOptions}>
        <div className={styles.search}>
          <SearchBar
            customClass="restaurantSearch"
            placeholder={
              showRestaurants
                ? t.refund.text_search_restaurant
                : t.refund.text_search_user
            }
            name="searchTerm"
            value={filterData.searchTerm}
            onChange={onChangeTerm}
            disabled={listLoading}
          />
        </div>
      </div>
      <div className={styles.list}>
        {listLoading && (
          <div className={styles.loading}>
            <Loading width={50} height={50} />
          </div>
        )}
        {!listLoading && showRestaurants && restaurants.length > 0 && (
          <div>
            {restaurants.map((value, index) => {
              return (
                <div className={styles.block} key={index}>
                  <div className={styles.blockUser}>
                    <p className={styles.blockId}>
                      {pageNumber * pageSize + index + 1}
                    </p>
                    <p>{value.name}</p>
                  </div>

                  <div className={styles.blockButton}>
                    <Button
                      label={t.button.text_view}
                      btnClass="btnNormalGlacier"
                      onClick={() => handleView(value)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {!listLoading && showUsers && users.length > 0 && (
          <div>
            {users.map((value, index) => {
              return (
                <div className={styles.block} key={index}>
                  <div className={styles.blockUser}>
                    <p className={styles.blockId}>
                      {pageNumber * pageSize + index + 1}
                    </p>
                    <p>{value.fullName}</p>
                  </div>
                  <div className={styles.blockButton}>
                    <Button
                      label={t.button.text_view}
                      btnClass="btnNormalGlacier"
                      onClick={() => handleView(value)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {!listLoading && !restaurants.length && showRestaurants && (
          <div>
            <p>{t.restaurant.text_no_restaurants}</p>
          </div>
        )}
        {!listLoading && !users.length && showUsers && (
          <div>
            <p>{t.user.text_no_users}</p>
          </div>
        )}
      </div>
      <div className={styles.pagination}>
        <Pagination
          handlePageClick={onPageChange}
          pageCount={Math.ceil(numberOfMembers / pageSize)}
          forcePage={pageNumber}
          loading={listLoading}
        />
      </div>
    </div>
  );
};
export default RefundHistory;
