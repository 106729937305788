import React, { useState, useEffect } from "react";
import styles from "./editForm.module.scss";
import { mainActions } from "../../../../_store/actions/main.actions";
import toastService from "../../../../_services/toast.service";
import Input from "../../../input";
import PhoneInput from "../../../input/phoneInput";
import t from "../../../../_config/constants";
import Selector from "../../../selector";
import GeoInput from "../../../googleMaps/geoInput";
import { makeSelectMap } from "../../../../_utils/utils";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../button";
import { modalActions } from "../../../../_store/actions/modal.actions";
const EditForm = ({ user, update }) => {
  const [regionOptions, setRegionOptions] = useState();
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [data, setData] = useState(user);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (store?.main?.data?.region?.list) {
      let arr = makeSelectMap(store.main.data.region.list);
      setRegionOptions(arr);
    }
  }, []);
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const handleSelect = (e) => {
    setData((userData) => ({ ...userData, regionId: e.value }));
  };
  const onChangeAddress = (value) => {
    setData((userData) => ({ ...userData, address: value }));
  };
  const handleSave = async () => {
    if (data.regionId) {
      const result = await dispatch(
        mainActions.run("profiles", "user", "update", {
          _id: user._id,
          regionId: data.regionId,
          address: data.address,
        })
      );
      update();
      dispatch(modalActions.closeModal());
      if (result) toastService.show("success", t.user.text_successfully_edited);
    } else {
      setError(true);
    }
  };
  return (
    <div className={styles.wrapper}>
      <h3>{user.fullName}</h3>
      <Input
        inputClass="inputProfileForm"
        label={t.common.input.text_input_email_address}
        value={user.email ? user.email : ""}
        name="email"
        disabled={true}
      />
      <PhoneInput
        inputClass="phoneInputForm"
        value={user.phone}
        disabled={true}
      />
      {store?.auth?.user?.regionId === "*" && (
        <div style={{ marginBottom: "60px" }}>
          <Selector
            label={"Region"}
            placeholder={t.admin.admins.text_input_region_placeholder}
            multiselect={false}
            options={regionOptions}
            selectedOption={getSelectedValue(regionOptions, data.regionId)}
            handleChangeSelect={(e) => handleSelect(e)}
            selectClassname="selectPrimary"
          />
        </div>
      )}

      <GeoInput
        error={error}
        onChangeLocation={onChangeAddress}
        editLocation={user._id}
        inputVal={user.address ? user.address : ""}
        inputClass={"inputProfileForm"}
        name={t.common.input.text_input_address}
        onSave={1}
      />
      <Button
        label={t.button.text_save}
        btnClass="btnNormalCasal"
        onClick={handleSave}
      />
    </div>
  );
};
export default EditForm;
