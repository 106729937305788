export const addRestaurantInitalErrors = {
  name: false,
  fullName: false,
  email: false,
  billingEmail: false,
  phone: false,
  location: false,
  password: false,
  secureCode: false,
  regionId: false,
  subregionId: false,
};

export const addRestaurantInitialState = {
  name: "",
  fullName: "",
  email: "",
  billingEmail: "",
  phone: "",
  location: "",
  website: "",
  password: "",
  secureCode: "",
  regionId: "",
  subregionId: "",
  currency: "",
  chargingOption: "unlimitedMembership",
  avatarURL: "",
  backgroundURL: "",
  visible: false,
  isUsingStripe: false,
  orderingChannels: {},
  tags: [],
  showTagLinking: false,
  user_assignment: [],
  container_assignment: "qr",
  device_mounted: true,
  tenderNumber: "",
  institutionId: "",
  multiContainerAssignment: true,
  zoomLevel: 30,
};

export const workingTime = {
  monday: {
    from: "",
    to: "",
  },
  tuesday: {
    from: "",
    to: "",
  },
  wednesday: {
    from: "",
    to: "",
  },
  thursday: {
    from: "",
    to: "",
  },
  friday: {
    from: "",
    to: "",
  },
  saturday: {
    from: "",
    to: "",
  },
  sunday: {
    from: "",
    to: "",
  },
};

export const chargingOptions = [
  { label: "Paying per used container", value: "perUse" },
  { label: "Membership plan", value: "unlimitedMembership" },
  { label: "Membership plan + Pay-Per-Use", value: "limitedMembership" },
];

export const userAssignmentOptions = [
  { label: "Stripe", value: "stripe" },
  { label: "Tap to Reuse", value: "tapToReuse" },
  { label: "Transact", value: "transact" },
  { label: "Atrium", value: "atrium" },
  { label: "Phone", value: "phone" },
  { label: "Reuser ID", value: "reuserid" },
  { label: "CBORD", value: "cbord" },
];

export const containerAssignmentOptions = [
  { label: "UHF and QR", value: "uhf" },
  { label: "QR only", value: "qr" },
  { label: "USB UHF", value: "uhf_usb" },
];
