import httpService from './http.service';

export const createContainerBatch = async (data) => {
    return httpService.apiRequest('post', '/api/container', data)
}

export const createCleanerBatch = async (data) => {
    return httpService.apiRequest('post', '/api/container/cleanerBatch', data)
}

export const deleteContainerBatch = async (batchId) => {
    return httpService.apiRequest('delete', `/api/container/${batchId}`)
}
export const checkAvailable = async (data) => {
    return httpService.apiRequest('post', '/api/container/availableContainers', { data })
}
export const updateAvailable = async (data) => {
    return httpService.apiRequest('post', '/api/container/editAvailable', data)
}