import React, { useEffect, useState } from "react";
import Input from "../../input";
import Button from "../../button";
import Selector from "../../selector";
import UploadAvatar from "../../uploadAvatar";
import { useDispatch, useSelector } from "react-redux";
import styles from "./addCleaner.module.scss";
import { userActions } from "../../../_store/actions/user.actions";
import { mainActions } from "../../../_store/actions/main.actions";
import toastService from "../../../_services/toast.service";
import { makeSelectMap } from "../../../_utils/utils";
import { uploadToS3 } from "../../../_utils/auth/uploadPhoto";
import { amazonUrl, amazonUrlResized } from "../../../environment";
import GeoInput from "../../googleMaps/geoInput";
import PhoneInput from "../../input/phoneInput";
import t from "../../../_config/constants";
import trimObjectProps from "../../../_utils/trimObjectProps";
import { validatePhoneNumber } from "../../../_utils/formatPhone";
const AddCleaner = ({ editing, editCleaner, handleClose }) => {
  const store = useSelector((state) => state);
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [regionOptions, setRegionOptions] = useState([]);

  const [data, setData] = useState(
    editing
      ? editCleaner
      : {
          _id: "",
          fullName: "",
          email: "",
          phone: "",
          address: "",
          password: "password123",
          regionId: "",
          avatarURL: "",
          avatarURLResized: "",
        }
  );

  const [error, setError] = useState({
    fullName: false,
    email: false,
    phone: false,
    address: false,
    regionId: false,
  });
  const [image, setImage] = useState({
    uri: data.avatarURL ? data.avatarURL : "",
  });

  useEffect(() => {
    setUser(store.auth.user);
    fetchRegions();
    if (store.auth.user.regionId != "*") {
      data.regionId = store.auth.user.regionId;
      setError((error) => ({ ...error, regionId: false }));
    }
  }, [store.auth && store.auth.user]);

  const fetchRegions = async () => {
    let response = await dispatch(mainActions.run("data", "region", "all", {}));
    let arr = makeSelectMap(response);
    setRegionOptions(arr);
  };

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };

  const handleChange = (e) => {
    setErrorMsg(false);
    const { name, value } = e.target;
    setData((userData) => ({ ...userData, [name]: value }));
    setError((error) => ({ ...error, [name]: !value }));
  };

  const handleSelect = (e) => {
    setErrorMsg(false);
    setData((data) => ({
      ...data,
      regionId: e.value,
    }));
    setError((error) => ({ ...error, regionId: false }));
  };

  const handleChooseImg = (e) => {
    e.preventDefault();
    let set = {
      uri: URL.createObjectURL(e.target.files[0]),
      type: e.target.files[0].type,
      file: e.target.files[0],
    };
    setImage(set);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const imgUrl = await uploadToS3(image);
      setSubmitDisabled(true);
      if (editing) {
        let response = await dispatch(
          mainActions.run("profiles", "cleaner", "update", {
            ...data,
            avatarURL: imgUrl ? amazonUrl + imgUrl : data.avatarURL,
            avatarURLResized: imgUrl
              ? amazonUrlResized + imgUrl
              : data.avatarURLResized,
          })
        );
        if (response) {
          toastService.show("success", t.cleaner.text_successfully_edited);
          handleClose();
        }
      } else {
        let newCleaner = data;
        if (imgUrl) {
          newCleaner.avatarURL = amazonUrl + imgUrl;
          newCleaner.avatarURLResized = amazonUrlResized + imgUrl;
        } else {
          newCleaner.avatarURL = "";
          newCleaner.avatarURLResized = "";
        }

        delete newCleaner._id;
        let response = await dispatch(userActions.registerCleaner(newCleaner));
        if (response) {
          toastService.show(
            "success",
            `${t.admin.admins.text_successfully_sent} ${data.email}`
          );
          handleClose();
        }
      }
    } else {
      toastService.show("error", `${t.common.text_toast_all_fields_required}`);
      setErrorMsg(true);
    }
    setSubmitDisabled(false);
  };

  const validateForm = () => {
    trimObjectProps(data);
    setError({
      fullName: !data.fullName,
      address: !data.address,
      email: !data.email,
      phone: !data.phone || !validatePhoneNumber(data.phone),
      regionId: !data.regionId,
    });
    return (
      data.fullName &&
      data.address &&
      data.email &&
      data.phone &&
      validatePhoneNumber(data.phone) &&
      data.password &&
      data.regionId &&
      data.regionId != "*"
    );
  };

  const onChangeAddress = (value) => {
    setErrorMsg(false);
    let obj = { ...data };
    obj["address"] = value;
    setData(obj);
    setError((error) => ({ ...error, address: !value }));
  };

  return (
    <div className={styles.wrapper}>
      <h2>
        {editing ? "Edit" : "Add"} {t.cleaner.text_cleaner}
      </h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.center}>
          <UploadAvatar uri={image.uri} setImg={handleChooseImg} />
          <Input
            label={t.common.input.text_input_full_name}
            error={error.fullName}
            name="fullName"
            inputClass="inputForm"
            type="text"
            value={data.fullName}
            placeholder={t.common.input.text_fullName_placeholder}
            onChange={handleChange}
          />
          <Input
            label={t.common.input.text_input_email_address}
            error={error.email}
            name="email"
            inputClass="inputForm"
            type="email"
            value={data.email.trim()}
            placeholder={t.common.input.text_input_email_address}
            onChange={handleChange}
            disabled={editing}
          />
          <PhoneInput
            handleChange={handleChange}
            value={data.phone}
            error={error.phone}
            errorMsg={"Invalid phone number"}
          />
          <GeoInput
            error={error.address}
            onChangeLocation={onChangeAddress}
            editLocation={editCleaner}
            inputVal={data.address}
            name="address"
          />
          {(user.regionId === "*" || editing) && (
            <div className={styles.selectRegion}>
              <Selector
                options={regionOptions}
                error={error.regionId}
                multiselect={false}
                selectedOption={getSelectedValue(regionOptions, data.regionId)}
                handleChangeSelect={(e) => handleSelect(e)}
                selectClassname="selectPrimary"
                placeholder={t.common.input.text_select_region}
              />
            </div>
          )}
        </div>
        <div className={styles.submit}>
          <Button
            btnClass="btnNormal"
            label={t.button.text_save}
            type="submit"
            disabled={submitDisabled}
          />
        </div>
      </form>
      {errorMsg ? (
        <div className={styles.error}>
          <p>{t.common.text_all_fields_required}</p>
        </div>
      ) : null}
    </div>
  );
};

export default AddCleaner;
