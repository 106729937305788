import React, { useState, useEffect, useContext } from "react";
import styles from "./scanContainer.module.scss";
import Button from "../../../button";
import QrReader from "react-qr-reader";
import { useDispatch } from "react-redux";
import { isObjectEmpty } from "../../../../_utils/utils";
import toastService from "../../../../_services/toast.service";
import Loading from "../../../loading";
import { mainActions } from "../../../../_store/actions/main.actions";
import t from "../../../../_config/constants";
import { isMobile, isTablet } from "react-device-detect";
import { DistributionContext } from "../../../../_pages/distribution";
const ScanContainer = ({
  scannedContainers,
  setScannedContainers,
  enableScan,
  setErrorMsg,
}) => {
  const dispatch = useDispatch();
  const [disableScan, setDisableScan] = useState(true);
  const [scannedData, setScannedData] = useState({});
  const [updateScanning, setUpdateScanning] = useState(1);
  const [, , containerTypes] = useContext(DistributionContext);
  const [tab, setTab] = useState(isMobile || isTablet ? "camera" : "scanner");
  const validateQr = (obj) => {
    let lastChar = obj.substr(obj.length - 1);
    let firstChar = obj.charAt(0);
    if (lastChar === "}" && firstChar === "{") {
      let trueObj = JSON.parse(obj);
      let validateArr = [
        "_id",
        "uniqueContainerId",
        "name",
        "material",
        "uniqueBatchId",
        "size",
        "volume",
      ];
      let testArr = [];
      Object.keys(trueObj).forEach((key) => {
        testArr.push(key);
      });
      return JSON.stringify(validateArr) == JSON.stringify(testArr);
    } else {
      return false;
    }
  };
  const fetchContainer = async (id) => {
    let response = await dispatch(
      mainActions.run("data", "container", "get", { filter: { _id: id } })
    );
    if (!isObjectEmpty(response)) {
      if (!isDuplicate(response.name)) {
        let containerType = containerTypes.find(
          (type) => type.name === response.name
        );
        setScannedContainers((prev) => [
          ...prev,
          { containerTypeId: containerType._id, name: containerType.name },
        ]);
        toastService.show(
          "success",
          `Scanned container type ${response.name}!`
        );
      } else {
        toastService.show(
          "warning",
          `Already scanned container type ${response.name}!`
        );
      }
    } else {
      if (window.navigator.onLine) {
        toastService.show("warning", t.cleaning.text_invalid_code);
      } else {
        toastService.show(
          "warning",
          "Something went wrong. Please check your internet connection."
        );
      }
    }
  };
  const generateId = (str) => {
    let id = str.split("id@>@")[1].slice(0, 24);
    return id;
  };
  const isDuplicate = (name) => {
    const index = scannedContainers.findIndex((item) => item.name === name);
    if (index === -1) {
      return false;
    } else return true;
  };
  const handleDuplicates = (container) => {
    if (container.data) {
      let obj = container.data;
      let newObj = JSON.parse(JSON.stringify(obj.slice(0, -5)));
      if (validateQr(newObj)) {
        if (!isDuplicate(JSON.parse(newObj).name)) {
          let containerType = containerTypes.find(
            (type) => type.name === JSON.parse(newObj).name
          );
          if (containerType) {
            setScannedContainers((prev) => [
              ...prev,
              { containerTypeId: containerType._id, name: containerType.name },
            ]);
            toastService.show(
              "success",
              `Scanned container type ${JSON.parse(newObj).name}!`
            );
          } else {
            toastService.show("warning", `Please try again`);
          }
        } else {
          toastService.show(
            "warning",
            `Already scanned container type ${JSON.parse(newObj).name}!`
          );
        }
      } else if (newObj && container.data.length > 150) {
        let id = generateId(newObj);
        fetchContainer(id);
      } else {
        toastService.show("warning", `Please try again`);
      }
      setUpdateScanning((prev) => (prev === 1 ? 2 : 1));
      handleScan();
    } else {
      setUpdateScanning((prev) => (prev === 1 ? 2 : 1));
    }
  };

  let barcode = {
    timing: 500,
    data: "",
  };

  const barcodeReaded = () => {
    if (barcode.data.length > 1) {
      let obj = barcode;
      setScannedData(obj);
    }
  };

  let timeout = setTimeout(barcodeReaded, 500);

  useEffect(() => {
    if (!disableScan) {
      handleDuplicates(scannedData);
    }
  }, [scannedData]);

  let sBrowser,
    sUsrAg = navigator.userAgent;
  let showTabs = false;
  let browser = "";
  if (sUsrAg.indexOf("Firefox") > -1) {
    browser = "firefox";
  } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
    browser = "firefox";
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    browser = "opera";
    showTabs = true;
  } else if (sUsrAg.indexOf("Trident") > -1) {
    browser = "internetExplorer";
  } else if (sUsrAg.indexOf("Edge") > -1) {
    browser = "edge";
    showTabs = true;
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    browser = "chrome";
    showTabs = true;
  } else if (sUsrAg.indexOf("Safari") > -1) {
    browser = "safari";
  } else {
    sBrowser = "unknown";
  }

  useEffect(() => {
    if (browser === "chrome" || browser === "edge" || browser === "opera") {
      if (!disableScan && tab === "scanner") {
        const startScan = (e) => {
          if (barcode.data.length === 0 || e.timeStamp - barcode.timing < 200) {
            barcode.data += e.key;
            barcode.timing = e.timeStamp;
            clearTimeout(timeout);
            timeout = setTimeout(barcodeReaded, 100);
          }
        };
        document.onkeypress = startScan;
      }
    } else {
      if (isMobile || isTablet) return;
      setTab("camera");
    }
  }, [updateScanning, tab]);

  const handleScan = () => {
    if (disableScan) {
      setDisableScan(false);
      setErrorMsg(false);
      setUpdateScanning(updateScanning === 1 ? 2 : 1);
    }
  };

  const handleCameraScan = (code) => {
    if (code) {
      try {
        if (validateQr(code)) {
          if (!isDuplicate(JSON.parse(code).name)) {
            let containerType = containerTypes.find(
              (type) => type.name === JSON.parse(code).name
            );
            if (containerType) {
              setScannedContainers((prev) => [
                ...prev,
                {
                  containerTypeId: containerType._id,
                  name: containerType.name,
                },
              ]);
              toastService.show(
                "success",
                `Scanned container type ${JSON.parse(code).name}!`
              );
            } else {
              toastService.show("warning", `Please try again`);
            }
          } else {
            toastService.show(
              "warning",
              `Already scanned container type ${JSON.parse(code).name}!`
            );
          }
        } else {
          toastService.show("warning", `Please try again`);
        }
        handleScan();
      } catch (e) {}
    }
  };

  const handleCameraError = (error) => {
    toastService.show("error", error?.message);
  };

  return (
    <div className={styles.wrapper}>
      <h3>{t.transfer.text_scanning_containers}</h3>
      <div className={styles.buttonsWrapper}>
        {disableScan && (
          <Button
            label={t.button.text_scan}
            name="scan"
            btnClass="btnNormalCasal"
            onClick={handleScan}
            disabled={!enableScan}
          />
        )}
        {!disableScan && (
          <Button
            label={"Finish Scanning"}
            btnClass="btnWhiteBackground"
            onClick={() => setDisableScan(true)}
          />
        )}
        {showTabs && enableScan && !disableScan && !isMobile && !isTablet && (
          <div className={styles.scanOptions}>
            <Button
              label={"Scanner"}
              btnClass={
                tab === "scanner" ? "tabButtonActive" : "tabButtonInactive"
              }
              onClick={() => setTab("scanner")}
            />
            <Button
              label={"Camera"}
              btnClass={
                tab === "camera" ? "tabButtonActive" : "tabButtonInactive"
              }
              onClick={() => setTab("camera")}
            />
          </div>
        )}
      </div>
      {!disableScan && tab === "scanner" && (
        <div className={styles.scanningProgress}>
          <p className={styles.noScannedCodes}>{t.cleaning.text_waiting}</p>
          <Loading className={styles.loader} width={50} height={50} />
        </div>
      )}
      {enableScan && !disableScan && tab === "camera" && (
        <div className={styles.camera}>
          <QrReader
            delay={1000}
            facingMode="environment"
            onScan={handleCameraScan}
            onError={handleCameraError}
            className={styles.camera}
          />
        </div>
      )}
    </div>
  );
};

export default ScanContainer;
