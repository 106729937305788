import React, { useState, useEffect } from "react";
import styles from "./restaurantTable.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";

import Searchbar from "../../searchBar/index";
import Button from "../../button/index";
import Loading from "../../loading";
import Pagination from "../../pagination";

import statusImage from "../../../_assets/icons/exclamation-mark.svg";

import useDebounce from "../../../_utils/debounce";
import { termRegex } from "../../../_utils/utils";
import t from "../../../_config/constants";

const RestaurantTable = ({ handleNext }) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [restaurants, setRestaurants] = useState([]);
  const [filterData, setFilterData] = useState({
    searchTerm: "",
  });
  const pageSize = 50;
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfUsers, setNumberOfUsers] = useState(0);
  const [loading, setLoading] = useState(true);
  const debouncedSearchTerm = useDebounce(filterData.searchTerm, 500);
  const onChangeTerm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterData((prevState) => ({ ...prevState, [name]: value }));
  };

  const getData = async (
    term,
    id,
    pageNumber,
    sortConfig = { createdAt: -1 }
  ) => {
    setLoading(true);
    let filter = [{ isDraft: { $ne: true } }];

    if (id && id !== "*") {
      filter.push({
        regionId: id,
      });
    }

    if (term) {
      filter.push({
        $or: [
          { name: { $regex: `${termRegex(term)}`, $options: "i" } },
          { fullName: { $regex: `${termRegex(term)}`, $options: "i" } },
          { email: { $regex: `${termRegex(term)}`, $options: "i" } },
        ],
      });
    }

    let response = await dispatch(
      mainActions.run("profiles", "restaurant", "page", {
        filter,
        pageSize,
        pageNumber,
        sort: sortConfig,
        transactionsStatus: true,
      })
    );
    setRestaurants(response.rows);
    setNumberOfUsers(response.total);
    setLoading(false);
  };
  useEffect(() => {
    setPageNumber(0);
  }, [debouncedSearchTerm, store.auth && store.auth.user]);

  useEffect(() => {
    if ((debouncedSearchTerm, store.auth.user.regionId)) {
      getData(debouncedSearchTerm, store.auth.user.regionId, pageNumber);
    }
  }, [debouncedSearchTerm, store.auth && store.auth.user, pageNumber]);

  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.filter}>
        <div className={styles.searchBar}>
          <Searchbar
            customClass="transactionSearch"
            placeholder={t.restaurant.text_search_restaurants}
            name="searchTerm"
            value={filterData.searchTerm}
            onChange={onChangeTerm}
            disabled={loading}
          />
        </div>
      </div>

      <div className={styles.restaurants}>
        <div className={styles.header}>
          <p className={styles.blockUser}>Store</p>
          <p className={styles.blockButton}></p>
        </div>
        {loading && <Loading width={50} height={50} />}
        {!loading && restaurants.length > 0 && (
          <div>
            {restaurants.map((item, index) => {
              return (
                <div className={styles.block} key={index}>
                  <p className={styles.blockUser}>{item.name}</p>
                  <div className={styles.blockButton}>
                    {item.unpaidInvoices && <img src={statusImage} />}
                    <Button
                      label={t.button.text_view}
                      btnClass="btnNormalGlacier"
                      onClick={() => handleNext(item)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {!loading && !restaurants.length && (
          <div className={styles.noDataMsg}>
            <p>{t.restaurant.text_no_restaurants}</p>
          </div>
        )}
      </div>
      <div className={styles.pagination}>
        <Pagination
          handlePageClick={onPageChange}
          pageCount={Math.ceil(numberOfUsers / pageSize)}
          forcePage={pageNumber}
          loading={loading}
        />
      </div>
    </div>
  );
};
export default RestaurantTable;
