import React, { useEffect, useState } from "react";
import styles from "../../../dashboard/header/containersAtStores/containersAtStores.module.scss";
import { useSelector } from "react-redux";
import { getStatistics } from "../../../../_services/statistics.service";
import Datepicker from "../../../newDatepicker";
import useSortableData from "../../../../_utils/useSortableData";
import arrowUp from "../../../../_assets/icons/arrowUp.svg";
import arrowDown from "../../../../_assets/icons/arrowDown.svg";
import upDownArrow from "../../../../_assets/icons/upDownArrow.svg";
import Button from "../../../button";

const StatisticsModal = ({ mode = "rank", title = "" }) => {
  const store = useSelector((state) => state);
  const [dateFrom, setDateFrom] = useState(new Date(2021, 5, 1));
  const [dateTo, setDateTo] = useState(new Date());

  const [statisticsData, setStatisticsData] = useState([]);

  const { handleSort, sortConfig } = useSortableData(statisticsData, null);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return upDownArrow;
    }
    return sortConfig.key === name
      ? sortConfig.direction === "ascending"
        ? arrowUp
        : arrowDown
      : upDownArrow;
  };

  useEffect(() => {
    const getContainerAtLocations = async (
      mode,
      dateFrom,
      dateTo,
      regionId,
      sortConfig
    ) => {
      try {
        const filter = {};
        regionId !== "*" && (filter.regionId = regionId);
        filter.dateFrom = dateFrom.getTime();
        filter.sortConfig = sortConfig;
        if (
          dateTo.getFullYear() === new Date().getFullYear() &&
          dateTo.getMonth() === new Date().getMonth() &&
          dateTo.getDate() === new Date().getDate()
        ) {
          filter.dateTo = new Date().getTime();
        } else {
          filter.dateTo = new Date(
            dateTo.getFullYear(),
            dateTo.getMonth(),
            dateTo.getDate()
          ).getTime();
        }
        const response = await getStatistics({
          mode:
            mode === "rank"
              ? "get-ranked-locations"
              : "get-locations-ratio-statistics",
          filter: filter,
        });
        if (response) {
          setStatisticsData(response);
        }
      } catch (err) {}
    };

    if (mode && dateFrom && dateTo && store?.auth?.user?.regionId) {
      getContainerAtLocations(
        mode,
        dateFrom,
        dateTo,
        store.auth.user.regionId,
        sortConfig
      );
    }
  }, [dateFrom, dateTo, store.auth && store.auth.user, mode, sortConfig]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.topBar}>
        <h3>{title}</h3>
        <div className={styles.datepickers}>
          <div className={styles.datepicker}>
            <Datepicker
              label={"From"}
              minDate={new Date(2021, 5, 1).getTime()}
              maxDate={dateTo.getTime() || new Date().getTime()}
              selected={dateFrom}
              onDatePickerChange={(val) => setDateFrom(val)}
              name="dateFrom"
              placeholder="mm/dd/yyyy"
            />
          </div>{" "}
          <div className={styles.datepicker}>
            <Datepicker
              label={"To"}
              minDate={dateFrom.getTime() || new Date(2021, 5, 1).getTime()}
              maxDate={new Date().getTime()}
              selected={dateTo}
              onDatePickerChange={(val) => setDateTo(val)}
              name="dateTo"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
      </div>
      <div className={styles.inventory}>
        <table>
          <thead>
            <tr>
              <th
                onClick={() =>
                  statisticsData.length === 0 ? null : handleSort("name")
                }
              >
                Location
                <Button
                  btnClass="btnBack"
                  iconLeft={getClassNamesFor("name")}
                />
              </th>
              {mode === "rank" && (
                <th
                  onClick={() =>
                    statisticsData.length === 0
                      ? null
                      : handleSort("totalContainersReturned", "descending")
                  }
                >
                  Number of Containers Returned
                  <Button
                    btnClass="btnBack"
                    iconLeft={getClassNamesFor("totalContainersReturned")}
                  />
                </th>
              )}
              {mode === "ratio" && (
                <>
                  <th
                    onClick={() =>
                      statisticsData.length === 0
                        ? null
                        : handleSort("totalContainersCheckedout", "descending")
                    }
                  >
                    Number of Containers Assigned
                    <Button
                      btnClass="btnBack"
                      iconLeft={getClassNamesFor("totalContainersCheckedout")}
                    />
                  </th>
                  <th
                    onClick={() =>
                      statisticsData.length === 0
                        ? null
                        : handleSort("totalContainersReturned", "descending")
                    }
                  >
                    Number of Containers Returned
                    <Button
                      btnClass="btnBack"
                      iconLeft={getClassNamesFor("totalContainersReturned")}
                    />
                  </th>
                  <th
                    onClick={() =>
                      statisticsData.length === 0
                        ? null
                        : handleSort("ratio", "descending")
                    }
                  >
                    Ratio
                    <Button
                      btnClass="btnBack"
                      iconLeft={getClassNamesFor("ratio")}
                    />
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {statisticsData?.map((location) => (
              <tr key={location?._id}>
                <td>{location?.name}</td>
                {mode === "rank" && (
                  <td>{location?.totalContainersReturned}</td>
                )}
                {mode === "ratio" && (
                  <>
                    <td>{location?.totalContainersCheckedout}</td>
                    <td>{location?.totalContainersReturned}</td>
                    <td>{location?.ratio}</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default StatisticsModal;
