import React, { useState } from "react";
import styles from "./settings.module.scss";

import TermsOfService from "../../_components/appSettings/temrsOfService";
import PrivacyPolicy from "../../_components/appSettings/privacyPolicy";
import FAQ from "../../_components/appSettings/faq";

import t from "../../_config/constants";

const AppSettings = () => {
  const [activeTab, setActiveTab] = useState("terms");
  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.sideMenu}>
          <div className={styles.header}>Settings</div>
          <div
            className={
              activeTab === "terms" ? styles.activeTab : styles.listItem
            }
            onClick={() => {
              setActiveTab("terms");
            }}
          >
            {t.app_settings.terms_of_service}
          </div>
          <div
            className={
              activeTab === "privacy" ? styles.activeTab : styles.listItem
            }
            onClick={() => {
              setActiveTab("privacy");
            }}
          >
            {t.app_settings.privacy_policy}
          </div>
          <div
            className={activeTab === "faq" ? styles.activeTab : styles.listItem}
            onClick={() => {
              setActiveTab("faq");
            }}
          >
            {t.app_settings.faq}
          </div>
        </div>
        <div className={styles.content}>
          {activeTab === "terms" && <TermsOfService />}
          {activeTab === "privacy" && <PrivacyPolicy />}
          {activeTab === "faq" && <FAQ />}
        </div>
      </div>
    </div>
  );
};

export default AppSettings;
