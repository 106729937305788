import React, { useState, useEffect } from "react";
import styles from "../singleLocation.module.scss";
import Map from "../../../../googleMaps/markerMap";
import Selector from "../../../../selector";
import Button from "../../../../button";
import leftArrow from "../../../../../_assets/icons/leftArrow.svg";
import t from "../../../../../_config/constants";
import { mainActions } from "../../../../../_store/actions/main.actions";
import { useDispatch } from "react-redux";
import Loading from "../../../../loading";
const LocationDetails = ({ dropoffLocation, setStep }) => {
  const dispatch = useDispatch();
  const [containersAtLocation, setContainersAtLocation] = useState({});
  const [containerTypes, setContainerTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [period, setPeriod] = useState("");
  const [sortBy, setSortBy] = useState("");
  useEffect(() => {
    const getContainerTypes = async (regionId) => {
      let filter = [];
      if (regionId && regionId !== "*") {
        filter.push({ regionId: regionId });
      }
      let data = await dispatch(
        mainActions.run("data", "containerType", "all", { filter })
      );
      setContainerTypes(data);
      setLoading(false);
    };
    if (dropoffLocation && dropoffLocation.regionId) {
      setLoading(true);
      getContainerTypes(dropoffLocation.regionId);
    }
  }, [dropoffLocation && dropoffLocation.regionId]);
  useEffect(() => {
    setPeriod("");
    setSortBy("");
    if (dropoffLocation.isDraft) setStep("location");
    if (dropoffLocation && dropoffLocation.containers) {
      setContainersAtLocation(dropoffLocation.containers);
    } else {
      setContainersAtLocation({});
    }
  }, [dropoffLocation]);

  useEffect(() => {
    const getContainers = async (period) => {
      let filter = [
        { "change.location_id": dropoffLocation._id },
        { "change.state": { $in: ["location", "restaurant"] } },
      ];
      let fromDate;
      if (period === 7 || period === 14) {
        fromDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - period
        );
      } else {
        fromDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth() - period,
          new Date().getDate()
        );
      }
      filter.push({ createdAt: { $gte: fromDate.getTime() } });
      let response = await dispatch(
        mainActions.run("history", "container", "all", { filter })
      );
      let obj = mapContainers(response);
      setContainersAtLocation(obj);
    };
    if (period) {
      getContainers(period);
    } else {
      if (dropoffLocation && dropoffLocation.containers) {
        setContainersAtLocation(dropoffLocation.containers);
      } else {
        setContainersAtLocation({});
      }
    }
  }, [period]);
  useEffect(() => {
    if (sortBy === 1) {
      const types = [...containerTypes];
      types.sort((a, b) => {
        return (
          containersAtLocation[a._id + ""] - containersAtLocation[b._id + ""]
        );
      });
      setContainerTypes(types);
    } else if (sortBy === -1) {
      const types = [...containerTypes];

      types.sort((a, b) => {
        return (
          containersAtLocation[b._id + ""] - containersAtLocation[a._id + ""]
        );
      });
      setContainerTypes(types);
    }
  }, [sortBy, containersAtLocation]);

  const mapContainers = (data) => {
    const containersArr = data.map((item) => item.oldDiff);
    const obj = {};
    containersArr.forEach((item) => {
      if (!obj[item.containerTypeId]) obj[item.containerTypeId] = 0;
      obj[item.containerTypeId] += 1;
    });
    containerTypes.forEach((type) => {
      if (!obj[type._id + ""]) obj[type._id + ""] = 0;
    });
    return obj;
  };

  const periodOptions = [
    { label: "Currently at location", value: "" },
    { label: "Last 7 days", value: 7 },
    { label: "Last 14 days", value: 14 },
    { label: "Last month", value: 1 },
    { label: "Last 3 months", value: 3 },
    { label: "Last 6 months", value: 6 },
    { label: "Last year", value: 12 },
  ];
  const sortOptions = [
    { label: "Ascending", value: 1 },
    { label: "Descending", value: -1 },
  ];
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const handleSelect = (e, type) => {
    if (type === "period") {
      setPeriod(e.value);
    } else {
      setSortBy(e.value);
    }
  };
  return (
    <div className={styles.details}>
      <Button
        label={t.button.text_back}
        btnClass="btnBack"
        iconLeft={leftArrow}
        onClick={() => setStep("location")}
      />

      <h2>Containers At Location</h2>

      <div className={styles.containers}>
        <div className={styles.selectors}>
          <Selector
            selectClassname="selectSecondary"
            label={"Select Return Period"}
            options={periodOptions}
            multiselect={false}
            selectedOption={getSelectedValue(periodOptions, period)}
            handleChangeSelect={(e) => handleSelect(e, "period")}
          />
          <div className={styles.sort}>
            <Selector
              selectClassname="selectSecondary"
              label={"Sort by"}
              options={sortOptions}
              multiselect={false}
              selectedOption={getSelectedValue(sortOptions, sortBy)}
              handleChangeSelect={(e) => handleSelect(e)}
              placeholder={"Select..."}
            />
          </div>
        </div>
        {!loading && (
          <div className={styles.content}>
            {containerTypes?.map((containerType) => {
              if (containersAtLocation) {
                return (
                  <p key={containerType._id}>
                    {containerType.name}
                    <span>
                      {containersAtLocation[containerType._id + ""]
                        ? containersAtLocation[containerType._id + ""]
                        : 0}
                    </span>
                  </p>
                );
              }
            })}
          </div>
        )}
        {loading && <Loading width={50} height={50} />}
      </div>

      <div className={styles.map}>
        <Map location={dropoffLocation.coordinates} />
      </div>
    </div>
  );
};
export default LocationDetails;
