import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import { stripeKey } from "../../../environment";
import { ReactComponent as CreditCardLayout } from "../../../_assets/images/creditCard.svg";
import {
  addPaymentMethod,
  listRestaurantPaymentMethods,
  removePaymentMethod,
} from "../../../_services/payment.service";
import toastService from "../../../_services/toast.service";
import { modalActions } from "../../../_store/actions/modal.actions";
import { mainActions } from "../../../_store/actions/main.actions";

import amex from "../../../_assets/cardBrands/americanExpress.svg";
import diners from "../../../_assets/cardBrands/dinersClub.svg";
import discover from "../../../_assets/cardBrands/discover.svg";
import jcb from "../../../_assets/cardBrands/jcb.svg";
import mastercard from "../../../_assets/cardBrands/master.svg";
import visa from "../../../_assets/cardBrands/visa.svg";
import unionpay from "../../../_assets/cardBrands/unionPay.svg";

import styles from "./cardModal.module.scss";
import Loading from "../../loading";

import t from "../../../_config/constants";
const cardBrands = {
  visa: visa,
  jcb: jcb,
  mastercard: mastercard,
  unionpay: unionpay,
  amex: amex,
  discover: discover,
  diners: diners,
};
const AddCardModal = ({ user, refreshAllRestaurants }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [paymentMethodExists, setPaymentMethodExists] = useState();

  useEffect(() => {
    const fetchPaymentMethod = async () => {
      setLoading(true);
      const response = await listRestaurantPaymentMethods({
        customer: user.customer,
      });
      if (response?.data?.[0]) {
        setPaymentMethodExists(response.data[0]);
      }
      setLoading(false);
    };
    user && user.customer && fetchPaymentMethod();
  }, []);
  const handleRemoveCard = async () => {
    setSaving(true);
    try {
      const removedMethod = await removePaymentMethod({
        methodId: paymentMethodExists.id,
      });
      if (removedMethod) {
        dispatch(
          mainActions.run("profiles", "restaurant", "update", {
            _id: user._id,
            hasCard: false,
          })
        );
        setPaymentMethodExists();
        refreshAllRestaurants();

        toastService.show("success", "Removed payment method");
      }
    } catch (error) {
      console.log(error);
      toastService.show(
        "error",
        `${t.common.text_something_went_wrong}! Please try again.`
      );
    }
    setSaving(false);
  };
  const AddCardForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (elements === null) {
        return null;
      }
      setSaving(true);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (error && error.message) {
        toastService.show("error", error.message);
        dispatch(modalActions.closeModal());
      } else if (paymentMethod) {
        try {
          const newPaymentMethod = await addPaymentMethod({
            customer: user?.customer,
            paymentMethod: paymentMethod.id,
          });
          setPaymentMethodExists(newPaymentMethod);
          dispatch(
            mainActions.run("profiles", "restaurant", "update", {
              _id: user._id,
              hasCard: true,
            })
          );
          refreshAllRestaurants();
          toastService.show("success", "Added payment method");
        } catch (error) {
          console.log(error);
        }
      }
      setSaving(false);
    };
    return (
      <form onSubmit={handleSubmit} className={styles.form}>
        <h3>Add Credit Card</h3>
        <div className={styles.cardElement}>
          <CardElement />
        </div>

        <button
          disabled={!stripe || !elements || loading || saving}
          type="submit"
          className={`${styles.stripeButton} ${
            !stripe || !elements || saving ? styles.disabled : ""
          }`}
        >
          {saving && (
            <i
              className="fa fa-spinner fa-spin"
              aria-hidden="true"
              style={{ color: "white" }}
            ></i>
          )}{" "}
          {saving ? "Saving..." : "Add Card"}
        </button>
      </form>
    );
  };
  const stripePromise = loadStripe(stripeKey);

  return (
    <div className={styles.wrapper}>
      <h2>Credit Card Information</h2>
      {loading ? (
        <div className={styles.loading}>
          <Loading width={75} height={75} />
        </div>
      ) : !paymentMethodExists ? (
        <Elements stripe={stripePromise}>
          <AddCardForm />
        </Elements>
      ) : (
        <div className={styles.paymentWrapper}>
          <div className={styles.paymentMethod}>
            <CreditCardLayout className={styles.card} />
            {paymentMethodExists?.card?.brand && (
              <img src={cardBrands[paymentMethodExists?.card?.brand]} />
            )}
            <div className={styles.cardNumber}>
              <span className={styles.label}>Card Number</span>
              <p className={styles.dots}>
                <span>**** **** ****</span>
                <span>{paymentMethodExists?.card?.last4}</span>
              </p>
            </div>
            <div className={styles.expDate}>
              <span>
                {paymentMethodExists?.card?.exp_month}/
                {paymentMethodExists?.card?.exp_year}
              </span>
            </div>
          </div>
          <button
            className={`${styles.stripeButtonRed} ${
              saving || loading ? styles.disabledRed : ""
            }`}
            disabled={saving || loading}
            type="button"
            onClick={handleRemoveCard}
          >
            {saving && (
              <i
                className="fa fa-spinner fa-spin"
                aria-hidden="true"
                style={{ color: "white" }}
              ></i>
            )}
            {saving ? "Processing..." : "Remove Card"}
          </button>
        </div>
      )}
    </div>
  );
};

export default AddCardModal;
