import { toast } from 'react-toastify'

const defaultOptions = {
  autoClose: 5000,
  hideProgressBar: true
}

// types: success, info, warn, error
function show (type, message, options) {
  const toastOptions = (!options) ? defaultOptions : options
  toast[type](message, toastOptions)
}

export default {
  show
}