import React, { useEffect, useState } from "react";
import List from "./../list";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../../../_store/actions/main.actions";
import styles from "./../containerDetails.module.scss";
import FormModal from "../../../../modal/formModal";
import RestaurantSelect from "./restaurantSelect";
import t from "../../../../../_config/constants";
import ChargeAndRetire from "./chargeAndRetire";
const BorrowedList = ({
  id,
  update,
  handleUpdate,
  handleCheckedContainers,
  selectedContainers,
  openDeleteContainer,
  handleNoSelectedContainers,
}) => {
  const [currentData, setCurrentData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showChargeModal, setShowChargeModal] = useState(false);
  const [user, setUser] = useState({});
  const openModal = () => {
    setShowModal(true);
  };
  const openChargeModal = () => {
    setShowChargeModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    setShowChargeModal(false);
    getCurrentData(id);
    handleUpdate();
    handleNoSelectedContainers();
  };
  const closeModalNoAction = () => {
    setShowModal(false);
    setShowChargeModal(false);
  };
  const getUser = async (id) => {
    let data = await dispatch(mainActions.run("profiles", "user", "id", id));
    if (!data) {
      data = await dispatch(
        mainActions.run("profiles", "unregistered", "id", id)
      );
    }
    setUser(data);
  };
  const getCurrentData = async (id) => {
    let filter = [];
    if (id) {
      filter.push({
        state: { $eq: "user" },
        user_id: { $eq: id },
      });
    }
    let response = await dispatch(
      mainActions.run("data", "container", "all", { filter })
    );
    setCurrentData(response);
    setLoadingData(false);
  };
  useEffect(() => {
    if (id) {
      getUser(id);
      getCurrentData(id);
    }
  }, [id, update]);

  return (
    <>
      {showModal && (
        <FormModal
          show={showModal}
          handleClose={closeModalNoAction}
          content={
            <RestaurantSelect
              containers={selectedContainers}
              handleClose={closeModal}
            />
          }
          size="md"
        />
      )}
      {showChargeModal && (
        <FormModal
          show={showChargeModal}
          handleClose={closeModalNoAction}
          content={
            <ChargeAndRetire
              container={selectedContainers[0]}
              user={user}
              handleClose={closeModal}
            />
          }
          size="md"
        />
      )}
      <div className={styles.options}>
        <p
          className={styles.retire}
          style={
            selectedContainers.length > 0
              ? { cursor: "pointer" }
              : { cursor: "not-allowed" }
          }
          onClick={selectedContainers.length > 0 ? openDeleteContainer : null}
        >
          {t.button.text_retire}
        </p>
        <p
          className={styles.retire}
          style={
            selectedContainers.length > 0
              ? { cursor: "pointer" }
              : { cursor: "not-allowed" }
          }
          onClick={selectedContainers.length > 0 ? openModal : null}
        >
          {t.button.text_return}
        </p>
        {user.type === "user" && (
          <p
            className={styles.retire}
            style={
              selectedContainers.length === 1
                ? { cursor: "pointer" }
                : { cursor: "not-allowed" }
            }
            onClick={
              selectedContainers.length === 1 && user?._id
                ? openChargeModal
                : null
            }
          >
            {t.button.text_charge_and_retire}
          </p>
        )}
      </div>
      <List
        data={currentData}
        activeTab={"borrowed"}
        handleCheckedContainers={handleCheckedContainers}
        selectedContainers={selectedContainers}
        loadingData={loadingData}
      />
    </>
  );
};
export default BorrowedList;
