import React, { useState } from "react";
import styles from "./monthlySummary.module.scss";
import noAvatarImg from "../../../_assets/images/noAvatar.png";
import Button from "../../button";
import Statistic from "./statistic";
import AccountSummary from "./accountSummary";
import leftArrow from "../../../_assets/icons/leftArrow.svg";
import t from "../../../_config/constants";

const MonthlySummary = ({ handleClose, restaurant }) => {
  const [activeButton, setActiveButton] = useState("statistic");

  const statisticButtonHandler = () => {
    setActiveButton("statistic");
  };
  const accountSummaryButtonHndler = () => {
    setActiveButton("accountSummary");
  };
  return (
    <div className={styles.wrapper}>
      <Button
        label={t.button.text_back}
        btnClass="btnBack"
        iconLeft={leftArrow}
        onClick={handleClose}
      />
      <div className={styles.restaurantInfo}>
        <img src={restaurant.avatarURL ? restaurant.avatarURL : noAvatarImg} />
        <h2>{restaurant.name || ""}</h2>
      </div>
      <div className={styles.options}>
        <Button
          label={t.button.text_statistic}
          btnClass={
            activeButton === "statistic"
              ? "btnNormalCasal"
              : "btnWhiteBackground"
          }
          onClick={statisticButtonHandler}
        />
        <Button
          label={t.button.text_account_summary}
          btnClass={
            activeButton === "accountSummary"
              ? "btnNormalCasal"
              : "btnWhiteBackground"
          }
          onClick={accountSummaryButtonHndler}
        />
      </div>
      <div className={styles.content}>
        {activeButton === "statistic" ? (
          <Statistic restaurantId={restaurant._id} />
        ) : (
          <AccountSummary restaurant={restaurant} />
        )}
      </div>
    </div>
  );
};
export default MonthlySummary;
