import React from "react";
import styles from "./workingDay.module.scss";

import t from "../../_config/constants";

import Checkbox from "../checkbox";
import Input from "../input";

const WorkingDay = ({
  day,
  handleChangeWorktime,
  workingTime,
  handleClosedDay,
}) => {
  return (
    <>
      <div className={styles.label}>
        <Checkbox
          label={`${t.common.days[day]}`}
          customClass="checkbox"
          description={
            workingTime?.[day]?.from && workingTime?.[day]?.to
              ? "Open"
              : "Closed"
          }
          onChange={() => {
            handleClosedDay(day);
          }}
          checked={workingTime?.[day]?.from && workingTime?.[day]?.to}
        />
        <div className={styles.fromTo}>
          <div>
            <Input
              name={`${day}.from`}
              inputClass="inputForm"
              type="time"
              value={workingTime?.[day]?.from}
              onChange={handleChangeWorktime}
            />
          </div>

          <div>
            <Input
              name={`${day}.to`}
              inputClass="inputForm"
              type="time"
              value={workingTime?.[day]?.to}
              onChange={handleChangeWorktime}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkingDay;
