import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getHoursInADay } from "../../../_utils/utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ dataForLineChart, getTodaysStats = true }) => {
  const [lineLabels, setLineLabels] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [emptiedBinData, setEmptiedBinData] = useState([]);
  useEffect(() => {
    const dataMap = {};
    const emptiedFlag = {};
    const lastHour = getTodaysStats ? new Date().getHours() : 23;
    for (const [key, value] of Object.entries(dataForLineChart)) {
      if (key <= lastHour) {
        dataMap[key] = value.quantity;
        emptiedFlag[key] = value.emptied;
      } else {
        dataMap[key] = 0;
        emptiedFlag[key] = 0;
      }
    }
    const valueArr = Object.values(dataMap);
    const labelsArr = getHoursInADay();
    setLineData(valueArr);
    setLineLabels(labelsArr);
    setEmptiedBinData(Object.values(emptiedFlag));
  }, [dataForLineChart]);

  const data = {
    labels: lineLabels,
    datasets: [
      {
        label: "Number of containers",
        data: lineData,
        backgroundColor: "#8bb8c6",
        borderColor: "#8bb8c6",
        borderWidth: 1,
      },
      {
        label: "Bin Emptied",
        data: emptiedBinData,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Line data={data} options={options} />;
};
export default LineChart;
