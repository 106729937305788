import React, { useEffect, useContext } from "react";
import styles from "./couponTable.module.scss";
import { CouponContext } from "../../../_pages/couponManagement";

import Loading from "../../loading";
import Button from "../../button";
import arrowUp from "../../../_assets/icons/arrowUp.svg";
import arrowDown from "../../../_assets/icons/arrowDown.svg";
import upDownArrow from "../../../_assets/icons/upDownArrow.svg";
import useSortableData from "../../../_utils/useSortableData";
import t from "../../../_config/constants";
const CouponTable = () => {
  const [
    coupons,
    selectedCoupon,
    setSelectedCoupon,
    ,
    loadingData,
    ,
    changeSortConfig,
  ] = useContext(CouponContext);
  const { handleSort, sortConfig } = useSortableData([]);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return upDownArrow;
    }
    return sortConfig.key === name
      ? sortConfig.direction === "ascending"
        ? arrowUp
        : arrowDown
      : upDownArrow;
  };
  useEffect(() => {
    changeSortConfig(sortConfig);
  }, [sortConfig]);

  return (
    <div className={styles.tableWrapper}>
      <table>
        <thead>
          <tr>
            <th onClick={() => (loadingData ? null : handleSort("fullName"))}>
              Name
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("fullName")}
              />
            </th>
            <th onClick={() => (loadingData ? null : handleSort("code"))}>
              Promo Code
              <Button btnClass="btnBack" iconLeft={getClassNamesFor("code")} />
            </th>
            <th
              onClick={() => (loadingData ? null : handleSort("timesRedeemed"))}
            >
              Redemptions
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("timesRedeemed")}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {!loadingData &&
            coupons?.length > 0 &&
            coupons.map((item) => (
              <tr
                key={item._id}
                className={
                  selectedCoupon._id === item._id ? styles.selected : ""
                }
                onClick={() => setSelectedCoupon(item)}
              >
                <td>{item?.name}</td>
                <td>{item?.code}</td>
                <td>{item?.timesRedeemed || 0}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {!loadingData && !coupons.length && (
        <p className={styles.noCouponMsg}>{t.coupon.text_no_coupons}</p>
      )}
      {loadingData && (
        <div className={styles.loading}>
          <Loading width={50} height={50} />
        </div>
      )}
    </div>
  );
};

export default CouponTable;
