import React, { useEffect, useState } from "react";
import styles from "./barchart.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getStatistics } from "../../../../_services/statistics.service";
import Selector from "../../../selector";
import Loading from "../../../loading";
import BarChart from "../../../charts/containersBarChart";
import { mainActions } from "../../../../_store/actions/main.actions";
import { makeSelectMap } from "../../../../_utils/utils";
import Datepicker from "../../../newDatepicker";

const ReturnedBarChart = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  const [loadingData, setLoadingData] = useState(true);
  const [locationOptions, setLocationOptions] = useState([]);
  const [date, setDate] = useState(new Date());
  const [getTodaysStats, setGetTodaysStats] = useState(true);
  const [statisticsFilter, setStatisticsFilter] = useState({
    locationId: "",
    containerTypeId: "",
  });
  const [dataForBarChart, setDataForBarChart] = useState([]);
  const [locationsPopularity, setLocationsPopularity] = useState([]);
  const [dateFrom, setDateFrom] = useState(new Date(2024, 0, 1));
  const [dateTo, setDateTo] = useState(new Date());

  useEffect(() => {
    const getData = async (subregionId, filter, date) => {
      setLoadingData(true);
      filter.subregionId = subregionId;
      filter.period = "hourly";
      filter.year = date.getFullYear();
      filter.month = date.getMonth();
      filter.day = date.getDate();
      const isDateCurrentDay =
        date.getFullYear() === new Date().getFullYear() &&
        date.getMonth() === new Date().getMonth() &&
        date.getDate() === new Date().getDate();

      setGetTodaysStats(isDateCurrentDay);

      const response = await getStatistics({
        mode: "get-subregion-return-statistics",
        filter: filter,
      });
      if (response) {
        setDataForBarChart(response.array || {});
      }
      setLoadingData(false);
    };
    if (
      store.auth &&
      store.auth.user &&
      store.auth.user.subregionId &&
      statisticsFilter.locationId &&
      date
    ) {
      getData(store.auth.user.subregionId, statisticsFilter, date);
    } else {
      setLoadingData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statisticsFilter, date]);
  useEffect(() => {
    const getLocations = async (id) => {
      let filter = [];
      if (id) filter.push({ subregionId: id, isSmartBin: true });
      let data = await dispatch(
        mainActions.run("data", "location", "all", { filter })
      );
      if (data) {
        let arr = makeSelectMap(data);
        let filterOptions = [...arr];
        if (arr.length == 0) {
          filterOptions.unshift({ label: "All Bins", value: "" });
        } else {
          setStatisticsFilter((prev) => ({
            ...prev,
            locationId: arr[0].value,
          }));
        }
        setLocationOptions(filterOptions);
      }
    };

    if (store.auth && store.auth.user && store?.auth?.user?.subregionId) {
      getLocations(store?.auth?.user?.subregionId);
    }
  }, [store.auth && store.auth.user]);
  useEffect(() => {
    const getPopularityData = async (dateFrom, dateTo) => {
      const filter = { dateFrom: dateFrom.getTime() };
      if (
        dateTo.getFullYear() === new Date().getFullYear() &&
        dateTo.getMonth() === new Date().getMonth() &&
        dateTo.getDate() === new Date().getDate()
      ) {
        filter.dateTo = new Date().getTime();
      } else {
        filter.dateTo = new Date(
          dateTo.getFullYear(),
          dateTo.getMonth(),
          dateTo.getDate()
        ).getTime();
      }
      const response = await getStatistics({
        mode: "get-subregion-locations-popularity",
        filter,
      });
      if (Array.isArray(response)) {
        setLocationsPopularity(response);
      } else {
        setLocationsPopularity([]);
      }
    };
    if (store.auth.user.subregionId && dateFrom && dateTo) {
      getPopularityData(dateFrom, dateTo);
    }
  }, [store.auth.user.subregionId, dateFrom, dateTo]);
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <h3>Smart Return Bins Status</h3>
        <div className={styles.header}>
          <div className={styles.select}>
            <Selector
              label=""
              placeholder="Select Bin"
              multiselect={false}
              selectClassname="selectGray"
              options={locationOptions}
              disabled={loadingData}
              handleChangeSelect={(e) =>
                setStatisticsFilter((prev) => ({
                  ...prev,
                  locationId: e.value,
                }))
              }
              selectedOption={getSelectedValue(
                locationOptions,
                statisticsFilter.locationId
              )}
            />
          </div>

          <div className={styles.row}>
            <div className={styles.selectDate}>
              {" "}
              <div className={styles.datepicker}>
                <Datepicker
                  label={"Date"}
                  minDate={new Date(2023, 9, 1).getTime()}
                  maxDate={new Date().getTime()}
                  selected={date}
                  onDatePickerChange={(val) => setDate(val)}
                  name="date"
                  placeholder="mm/dd/yyyy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.chartWrapper}>
          {loadingData && (
            <div className={styles.loading}>
              <Loading width={50} height={50} />
            </div>
          )}

          {!loadingData && dataForBarChart && (
            <div className={styles.bigChartSection}>
              {!statisticsFilter.locationId && (
                <div className={styles.selectLocationMsg}>
                  Select Bin To See Stats
                </div>
              )}
              <BarChart
                dataForBarChart={dataForBarChart}
                getTodaysStats={getTodaysStats}
              />
            </div>
          )}
        </div>
      </div>

      <div className={styles.right}>
        <h3>Return Location Popularity</h3>
        <div className={styles.date}>
          <div className={styles.datepicker}>
            <Datepicker
              label={"From"}
              minDate={new Date(2024, 0, 1).getTime()}
              maxDate={dateTo.getTime() || new Date().getTime()}
              selected={dateFrom}
              onDatePickerChange={(val) => setDateFrom(val)}
              name="dateFrom"
              placeholder="mm/dd/yyyy"
            />
          </div>{" "}
          <div className={styles.datepicker}>
            <Datepicker
              label={"To"}
              minDate={dateFrom.getTime() || new Date(2024, 0, 1).getTime()}
              maxDate={new Date().getTime()}
              selected={dateTo}
              onDatePickerChange={(val) => setDateTo(val)}
              name="dateTo"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className={styles.popularity}>
          {locationsPopularity?.map((item) => (
            <div className={styles.popularityRow} key={item.name}>
              <p className={styles.name}>{item.name}</p>
              <p>{item.percentage}%</p>
            </div>
          ))}
          {locationsPopularity?.length == 0 && (
            <p className={styles.noData}>No returns recorded for this period</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReturnedBarChart;
