import React, { useEffect, useState } from "react";
import styles from "./details.module.scss";
import Button from "../../../../button";
import moment from "moment";
import t from "../../../../../_config/constants";
import FormModal from "../../../../modal/formModal";
import EmailRecipientInput from "../../../../emailRecipientInput";
import toastService from "../../../../../_services/toast.service";
import documentService from "../../../../../_services/document.service";
import { sortTransactions } from "../../../../../_utils/mapTransactions";
import { BlobProvider } from "@react-pdf/renderer";
import InvoiceDoc from "../../../pdfInvoice";
const SummaryDetails = ({ transaction, handleClose }) => {
  const [transactionList, setTransactionList] = useState([]);
  const [subtotal, setSubtotal] = useState();
  const [sending, setSending] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [recipient, setRecipient] = useState("");
  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (transaction.transactionList) {
      let { list, subtotal } = sortTransactions(transaction.transactionList);
      setTransactionList(list);
      setSubtotal(subtotal);
    }
  }, [transaction]);
  const handleSendEmail = async (e) => {
    e.preventDefault();
    if (recipient && transaction) {
      setSending(true);
      const response = await documentService.sendViaEmail("email-invoice", {
        invoiceId: transaction._id,
        recipient: recipient,
      });
      if (response) {
        toastService.show(
          "success",
          `${t.distribution.text_successfully_sent_email} ${recipient}`
        );
      }
      handleClose();
      setSending(false);
    }
  };

  const details = transactionList.map((item, index) => {
    return (
      <tr key={index}>
        <td>{item.name}</td>
        <td>{item.quantity}</td>
        <td>${Number(item.price).toFixed(2)}</td>
        <td>${Number(item.quantity * item.price).toFixed(2)}</td>
      </tr>
    );
  });
  return (
    <div className={styles.wrapper}>
      {showModal && (
        <FormModal
          show={showModal}
          handleClose={closeModal}
          content={
            <EmailRecipientInput
              handleClose={closeModal}
              recipient={recipient}
              setRecipient={setRecipient}
              title={"Send Invoice via Email"}
              handleSend={handleSendEmail}
              sending={sending}
            />
          }
          size="md"
        />
      )}
      <div className={styles.content}>
        <span className={styles.close} onClick={handleClose}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </span>
        <div className={styles.table}>
          <h1>
            {moment
              .utc(transaction.createdAt)
              .subtract(1, "months")
              .format("MMMM YYYY")}
          </h1>
          <table>
            <caption>{t.restaurant.text_details}</caption>
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {details}
            </tbody>
          </table>
          <div className={styles.priceRow}>
            <div>
              <span className={styles.totalPrice}>
                <span> Subtotal:</span> $ {subtotal?.toFixed(2)}
              </span>
              {Number(transaction.amount).toFixed(2) !==
                subtotal?.toFixed(2) && (
                <>
                  {transaction.pst ? (
                    <>
                      <span className={styles.totalPrice}>
                        <span> Tax (GST):</span>${(subtotal * 0.05)?.toFixed(2)}
                      </span>
                      <span className={styles.totalPrice}>
                        <span> Tax (PST):</span>$
                        {((subtotal * transaction.pst) / 100)?.toFixed(2)}
                      </span>
                    </>
                  ) : (
                    <span className={styles.totalPrice}>
                      <span> Tax (GST):</span>$ {(subtotal * 0.05)?.toFixed(2)}
                    </span>
                  )}
                </>
              )}
              <span className={styles.totalPrice}>
                <span> Total:</span>
                {transaction.currency?.slice(0, 2).toUpperCase()}${" "}
                {Number(transaction.amount).toFixed(2)}
              </span>
            </div>
          </div>
          <div className={styles.date}>
            <p className={styles.createdDate}>
              Created: {moment(transaction.createdAt).format("MM/DD/YYYY")}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <BlobProvider document={<InvoiceDoc invoice={transaction} />}>
          {({ url }) => (
            <a className={styles.downloadButton} href={url} target="_blank">
              Download Pdf
            </a>
          )}
        </BlobProvider>
        <Button
          label="Send via Email"
          btnClass="btnNormalCasal"
          onClick={() => setShowModal(true)}
        />
      </div>
    </div>
  );
};
export default SummaryDetails;
