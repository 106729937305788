import React from "react";
import styles from "./reusers.module.scss";
import { useDispatch, useSelector } from "react-redux";
import UsageRentedChart from "../../../_components/subregionPanel/usage/rentedChart";
import UsageReturnedChart from "../../../_components/subregionPanel/usage/returnedChart";
import StoresCheckoutLog from "../../../_components/dashboard/header/storeCheckoutLog";
import { modalActions } from "../../../_store/actions/modal.actions";
import Button from "../../../_components/button";
import t from "../../../_config/constants";
import RegistrationChart from "../../../_components/subregionPanel/reusers/registrationChart";
import ReusersList from "../../../_components/subregionPanel/reusers/reusersList";
const Reusers = () => {
  const dispatch = useDispatch();

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentContainer}>
        <RegistrationChart />
        <div className={styles.membersData}>
          <ReusersList />
        </div>
      </div>
    </div>
  );
};

export default Reusers;
