import React from "react";
import InputMask from "react-input-mask";
import styles from "../input.module.scss";
const PhoneInput = ({
  handleChange,
  value,
  error,
  inputClass = "phoneInput",
  disabled = false,
  errorMsg = "",
}) => {
  return (
    <div className={`${styles[inputClass]} ${error ? styles.borderRed : ""}`}>
      <label htmlFor={"phone"}>
        {"Phone Number"} {error && <span> *{errorMsg}</span>}{" "}
      </label>
      <InputMask
        key="uniqueKey"
        onChange={handleChange}
        value={value}
        name="phone"
        placeholder="Phone Number"
        type="text"
        disabled={disabled}
      />
    </div>
  );
};
export default PhoneInput;
