import React, { useEffect, useState } from "react";
import styles from "./dashboard.module.scss";
import ContainerStats from "../../../_components/subregionPanel/dashboard/containers";
import BinsStats from "../../../_components/subregionPanel/dashboard/bins";
import { useSelector } from "react-redux";
import { getStatistics } from "../../../_services/statistics.service";

const ContainerAndBinsStats = () => {
  const store = useSelector((state) => state);
  const [data, setData] = useState();
  useEffect(() => {
    const fetchStats = async (subregionId) => {
      try {
        const response = await getStatistics({
          mode: "get-subregion-containers-and-srbs",
          filter: { subregionId },
        });
        response && setData(response);
      } catch (err) {}
    };
    if (store?.auth?.user?.subregionId)
      fetchStats(store?.auth?.user?.subregionId);
  }, [store?.auth?.user]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <ContainerStats data={data?.containerStats} />
      </div>
      <div className={styles.container}>
        <BinsStats
          binsData={data?.binsStats}
          binsCapacity={data?.binsCapacity}
        />
      </div>
    </div>
  );
};

export default ContainerAndBinsStats;
