import React, { useState, useCallback } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { googleMapsApiKey } from "../../../environment";
import styles from "./mapStyles.module.scss";
import { SiGooglemaps } from "react-icons/si";
const SelectLocationMap = ({
  location = { lat: 0, lng: 0 },
  setLocation,
  label = "",
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsApiKey,
  });
  const [state, setState] = useState({
    mapInstance: null,
    zoom: 10,
  });

  const handleLocationChange = useCallback(() => {
    if (state.mapInstance) {
      const newCenter = state.mapInstance.getCenter();
      if (newCenter !== undefined) {
        setLocation({ lat: newCenter.lat(), lng: newCenter.lng() });
      }
    }
  }, [state.mapInstance]);

  const onLoad = React.useCallback(
    (mapInstance) =>
      setState((prevState) => ({
        ...prevState,
        mapInstance,
      })),
    []
  );

  const CustomMarker = ({}) => (
    <div onClick={handleLocationChange} className={styles.marker}>
      <SiGooglemaps />
    </div>
  );
  return isLoaded ? (
    <div className={styles.wrapper}>
      {label && <label>{label}</label>}
      <div className={styles.mapWrapper}>
        <CustomMarker />
        <GoogleMap
          id="example-map"
          mapContainerStyle={{
            width: "100%",
            height: "100%",
          }}
          center={location}
          zoom={state.zoom || 10}
          onLoad={onLoad}
        >
          <Marker position={location} />
        </GoogleMap>
      </div>
    </div>
  ) : (
    <></>
  );
};
export { SelectLocationMap };
