import React from "react";
import { useSelector } from "react-redux";
import Login from "../_components/auth/login";
import ModalWrapper from "../_components/modal/basic";
import LeftSidebar from "../_components/leftSidebar";
import { Routes } from "./routes";
import Navbar from "../_components/navbar";
import SubregionNavbar from "../_components/subregionNavbar";

const MainView = ({ path }) => {
  const store = useSelector((state) => state);
  return (
    <>
      {store.modal.show && (
        <ModalWrapper
          show={store.modal.show}
          content={store.modal.content}
          size={store.modal.size}
        />
      )}
      {store.auth.user ? (
        <>
          <div className="page-content-sidebar">
            <LeftSidebar />
            {store.auth.user.subType === "subregionAdmin" ? (
              <SubregionNavbar />
            ) : (
              <Navbar />
            )}
            <Routes />
          </div>
        </>
      ) : (
        <div className="page-content-public">
          {" "}
          {path === "/" ? <Login /> : <Routes />}
        </div>
      )}
    </>
  );
};
export default MainView;
