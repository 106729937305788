import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../../_store/actions/user.actions";
import styles from "../auth.module.scss";
import Input from "../../input";
import Button from "../../button";
import logo from "../../../_assets/images/logo.svg";
import logoAuth from "../../../_assets/images/logoAuth.svg";
import toastService from "../../../_services/toast.service";
import { isObjectEmpty } from "../../../_utils/utils";
import t from "../../../_config/constants";
import { Link } from "react-router-dom";

const ResetPassword = (props) => {
  const forgotPasswordData = props.match.params ? props.match.params : {};
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [password, setPassword] = useState({
    // for change password
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  // for forgot - change - password
  const [user, setUser] = useState({
    token: forgotPasswordData.token,
    email: forgotPasswordData.email,
    password: "",
  });

  const [inputType, setInputType] = useState("password");
  const handleChange = (e) => {
    setError(false);
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
    if (!isObjectEmpty(forgotPasswordData)) {
      setUser({ ...user, password: e.target.value });
    }
  };

  const validateForm = () => {
    if (
      password.password !== "" &&
      password.password.length >= 5 &&
      password.password === password.confirmPassword
    ) {
      return true;
    } else if (password.password.length < 5) {
      toastService.show("warning", t.auth.text_password_length_error);
    } else if (password.password !== password.confirmPassword) {
      toastService.show("warning", t.auth.text_password_match_error);
    } else {
      toastService.show("warning", t.auth.text_warning);
    }
  };

  const handleSubmitForgot = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let response = await dispatch(userActions.forgotPassword(user));
      if (response) {
        toastService.show("success", t.auth.text_password_changed);
      }
    } else if (password.password === "" || password.confirmPassword === "") {
      setError(true);
    }
  };
  const onClickEyeIcon = () => {
    setInputType((prevState) =>
      prevState === "password" ? "text" : "password"
    );
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img src={logoAuth} alt="Logo" />
      </div>
      <div className={styles.container}>
        <div>
          <div className={styles.logo}>
            <img src={logo} alt="Logo" />
          </div>
          <h2>{t.auth.text_reset_password_title}</h2>
          <div>
            <form className={styles.form} onSubmit={handleSubmitForgot}>
              <section>
                <div>
                  <Input
                    label={t.auth.text_new_password}
                    inputClass="inputLogin"
                    type={inputType}
                    name="password"
                    placeholder="•••••••••••"
                    onChange={handleChange}
                    showEyeIcon={true}
                    onClickEyeIcon={onClickEyeIcon}
                  />
                </div>
                <div>
                  <Input
                    label={t.auth.text_confirm_password}
                    inputClass="inputLogin"
                    type={inputType}
                    name="confirmPassword"
                    placeholder="•••••••••••"
                    onChange={handleChange}
                  />
                </div>

                {error ? (
                  <div className="message-alert">
                    <p>{t.auth.text_all_fields_required}</p>
                  </div>
                ) : null}
              </section>
              <Button
                type="submit"
                label={t.auth.text_reset_password}
                btnClass="btnLogin"
              />
            </form>
            <div className={styles.backToLoginDiv}>
              <div className={styles.backToLogin}>
                <Link to="/forgot-pass">{t.auth.text_send_new_link}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
