import React, { useEffect, useState } from "react";
import styles from "./form.module.scss";
import Input from "../../../input";
import { useSelector } from "react-redux";
import t from "../../../../_config/constants";
import PhoneInput from "../../../input/phoneInput";
import moment from "moment";
const Form = ({ user }) => {
  const [regions, setRegions] = useState([]);
  const store = useSelector((state) => state);

  useEffect(() => {
    if (store?.main?.data?.region?.list) {
      setRegions(store.main.data.region.list);
    }
  }, [
    store.main.data && store.main.data.region && store.main.data.region.list,
  ]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.formWrapper}>
          <div>
            <Input
              inputClass="inputProfileForm"
              label={t.common.input.text_input_email_address}
              placeholder={t.auth.text_markup_mail}
              value={user.email ? user.email : ""}
              name="email"
              disabled={true}
            />
          </div>
          <div>
            <PhoneInput
              inputClass="phoneInputForm"
              value={user.phone}
              disabled={true}
            />
          </div>
        </div>
        {user.orderingCode && (
          <div>
            <Input
              inputClass="inputProfileForm"
              label={"Reuser Id"}
              value={user.orderingCode}
              disabled={true}
            />
          </div>
        )}
        {user.promoCode && (
          <div>
            <Input
              inputClass="inputProfileForm"
              label={"Access/Promo code"}
              value={user.promoCode}
              disabled={true}
            />
          </div>
        )}
        {user.isPerUse && (
          <div>
            <Input
              inputClass="inputProfileForm"
              label={"Start Per Use Date"}
              value={moment(user.startPerUseAt).format("MM/DD/YYYY")}
              disabled={true}
            />
          </div>
        )}
        <div>
          <Input
            inputClass="inputProfileForm"
            label={"Region"}
            placeholder={
              regions.find((region) => region._id + "" === user.regionId)?.name
            }
            disabled={true}
          />
        </div>
        <div>
          <Input
            inputClass="inputProfileForm"
            label={"Address"}
            value={user.address ? user.address : ""}
            disabled={true}
          />
        </div>
      </div>

      <h1 className={styles.containerTitle}>{t.common.text_container_info}</h1>
      <div className={styles.containerForm}>
        <div>
          <Input
            inputClass="inputProfileForm"
            label={t.user.text_container_in_possession}
            placeholder={user.inPossession}
            disabled={true}
          />
        </div>
        <div>
          <Input
            inputClass="inputProfileForm"
            label={t.user.text_overdue}
            placeholder={user.overdue}
            disabled={true}
          />
        </div>
        <div>
          <Input
            inputClass="inputProfileForm"
            label={t.user.text_th_dueDate}
            placeholder={user.dueDate}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Form;
