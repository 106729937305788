import httpService from "./http.service";

class PublicService {
  async getQRRedirectUrl(data = {}) {
    return httpService.apiRequest("post", "/api/public/data", {
      mode: "get-qr-redirect-url",
      data,
    });
  }
}

export default new PublicService();
