import React, { useState, useEffect, createContext } from "react";
import styles from "./couponManagement.module.scss";

import { mainActions } from "../../_store/actions/main.actions";
import { modalActions } from "../../_store/actions/modal.actions";
import CouponList from "../../_components/couponManagement/couponList";
import SingleCoupon from "../../_components/couponManagement/singleCoupon";
import useDebounce from "../../_utils/debounce";
import { useDispatch } from "react-redux";
import { termRegex } from "../../_utils/utils";
import CouponDetails from "../../_components/couponManagement/singleCoupon/details";
export const CouponContext = createContext();

const CouponManagement = () => {
  const dispatch = useDispatch();
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState({});
  const [numberOfCoupons, setNumberOfCoupons] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [step, setStep] = useState("single");
  const [sortConfig, setSortConfig] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [refreshList, setRefreshList] = useState(1);
  const [loadingData, setLoadingData] = useState(true);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    getFilteredCoupons(debouncedSearchTerm, pageNumber, sortConfig);
  }, [debouncedSearchTerm, pageNumber, sortConfig, refreshList]);
  useEffect(() => {
    setPageNumber(0);
  }, [debouncedSearchTerm]);
  const getFilteredCoupons = async (
    term,
    pageNumber,
    sortConfig = { createdAt: -1 }
  ) => {
    setLoadingData(true);
    let filter = [];
    const pageSize = 20;
    if (term) {
      filter.push({
        $or: [
          { name: { $regex: `${termRegex(term)}`, $options: "i" } },
          { code: { $regex: `${termRegex(term)}`, $options: "i" } },
        ],
      });
    }

    let data = await dispatch(
      mainActions.run("data", "promoCode", "page", {
        filter,
        pageSize,
        pageNumber,
        sort: sortConfig,
      })
    );
    if (!data) data = { rows: [], total: 0 };
    setCoupons(data.rows);
    setNumberOfCoupons(data.total);
    setLoadingData(false);
  };
  const closeModalAndRefresh = async () => {
    await dispatch(modalActions.closeModal());
    setRefreshList((prev) => (prev === 1 ? 2 : 1));
  };
  const changeSortConfig = (data) => {
    if (data) {
      const { key, direction } = data;
      setSortConfig({ [key]: direction === "ascending" ? 1 : -1 });
    }
  };
  return (
    <div className={styles.wrapper}>
      <CouponContext.Provider
        value={[
          coupons,
          selectedCoupon,
          setSelectedCoupon,
          setSearchTerm,
          loadingData,
          closeModalAndRefresh,
          changeSortConfig,
          numberOfCoupons,
          pageNumber,
          setPageNumber,
          setStep,
        ]}
      >
        <div className={styles.left}>
          <CouponList />
        </div>
        <div className={styles.right}>
          {step === "single" ? <SingleCoupon /> : <CouponDetails />}
        </div>
      </CouponContext.Provider>
    </div>
  );
};

export default CouponManagement;
