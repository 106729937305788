import React, { useState, useEffect } from "react";
import styles from "./users.module.scss";
import Selector from "../../selector";
import { useSelector } from "react-redux";
import t from "../../../_config/constants";
import Loading from "../../loading";
import LineChart from "../../charts/line";
import {
  getDropdownListMonths,
  getDropdownListYears,
} from "../../../_utils/mapTransactions";
import { getStatistics } from "../../../_services/statistics.service";
const DashboardUsers = () => {
  const store = useSelector((state) => state);
  const [numberOfRegistered, setNumberOfRegistered] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [monthsOptions, setMonthsOptions] = useState([]);
  const [yearsOptions, setYearsOptions] = useState([]);
  const [statisticsFilter, setStatisticsFilter] = useState({
    period: "daily",
    month: "",
    year: "",
    membership: "",
    userType: "user",
  });
  const [arrayForLineChart, setarrayForLineChart] = useState([]);
  const selectTypeOptions = [
    { value: "user", label: "Members" },
    { value: "unregistered", label: "Unregistered" },
  ];

  useEffect(() => {
    const arrYears = getDropdownListYears();
    const year = arrYears[0].value;
    setYearsOptions(arrYears);
    setStatisticsFilter((prev) => ({
      ...prev,
      year: year,
    }));
  }, []);
  useEffect(() => {
    if (statisticsFilter.year) {
      const arr = getDropdownListMonths(statisticsFilter.year, true);
      setStatisticsFilter((prev) => ({
        ...prev,
        month: arr[0].value,
      }));
      setMonthsOptions(arr);
    }
  }, [statisticsFilter.year]);

  const membershipOptions = [
    { value: "", label: "Free & Premium" },
    { value: "Free", label: "Free" },
    { value: "Premium", label: "Premium" },
  ];
  const periodOptions = [
    { value: "daily", label: "Daily" },
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
    { value: "all", label: "All Time" },
  ];
  useEffect(() => {
    setLoadingData(true);
    const getData = async (regionId, data) => {
      data.regionId = regionId;

      const response = await getStatistics({
        mode: "get-registration-statistics",
        filter: data,
      });
      if (response) {
        setNumberOfRegistered(response.total);
        setarrayForLineChart(response.array);
      }
      setLoadingData(false);
    };
    if (
      store.auth &&
      store.auth.user &&
      store.auth.user.regionId &&
      statisticsFilter.year &&
      (statisticsFilter.month || statisticsFilter.month === 0) &&
      statisticsFilter.period &&
      statisticsFilter.userType
    ) {
      getData(store.auth.user.regionId, statisticsFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.auth && store.auth.user, statisticsFilter]);
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2>{t.user.text_registration_statistics}</h2>
        <div className={styles.select}>
          <Selector
            label=""
            placeholder="Members"
            multiselect={false}
            selectClassname="selectGray"
            options={selectTypeOptions}
            disabled={loadingData}
            handleChangeSelect={(e) =>
              setStatisticsFilter((prev) => ({
                ...prev,
                userType: e.value,
              }))
            }
          />
        </div>
        <div
          className={
            styles[
              statisticsFilter.userType === "unregistered"
                ? "selectDisabled"
                : "select"
            ]
          }
        >
          <Selector
            label=""
            placeholder="Membership"
            multiselect={false}
            selectClassname="selectGray"
            options={membershipOptions}
            disabled={
              loadingData || statisticsFilter.userType === "unregistered"
            }
            handleChangeSelect={(e) =>
              setStatisticsFilter((prev) => ({
                ...prev,
                membership: e.value,
              }))
            }
            selectedOption={getSelectedValue(
              membershipOptions,
              statisticsFilter.membership
            )}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.icon}>
            <div>
              <span>{numberOfRegistered || "0"}</span>
            </div>
            <span>
              {statisticsFilter.userType === "user"
                ? "Registered"
                : "Unregistered"}
            </span>
          </div>
          <div className={styles.selectDate}>
            <div className={styles.selectPeriod}>
              <Selector
                label=""
                multiselect={false}
                selectClassname="selectGray"
                selectedOption={getSelectedValue(
                  periodOptions,
                  statisticsFilter.period
                )}
                handleChangeSelect={(e) =>
                  setStatisticsFilter((prev) => ({
                    ...prev,
                    period: e.value,
                  }))
                }
                options={periodOptions}
                disabled={loadingData}
              />
            </div>
            <div
              className={`${styles.selectMonth} ${
                statisticsFilter.period !== "daily" ? styles.selectDisabled : ""
              }`}
            >
              <Selector
                label=""
                multiselect={false}
                selectClassname="selectGray"
                selectedOption={getSelectedValue(
                  monthsOptions,
                  statisticsFilter.month
                )}
                handleChangeSelect={(e) =>
                  setStatisticsFilter((prev) => ({
                    ...prev,
                    month: e.value,
                  }))
                }
                options={monthsOptions}
                disabled={loadingData || statisticsFilter.period !== "daily"}
              />
            </div>
            <div
              className={`${styles.selectYear} ${
                ["yearly", "all"].includes(statisticsFilter.period)
                  ? styles.selectDisabled
                  : ""
              }`}
            >
              <Selector
                label=""
                multiselect={false}
                selectClassname="selectGray"
                selectedOption={getSelectedValue(
                  yearsOptions,
                  statisticsFilter.year
                )}
                handleChangeSelect={(e) =>
                  setStatisticsFilter((prev) => ({
                    ...prev,
                    year: e.value,
                  }))
                }
                options={yearsOptions}
                disabled={
                  loadingData ||
                  ["yearly", "all"].includes(statisticsFilter.period)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.chartWrapper}>
        {loadingData && (
          <div className={styles.loading}>
            <Loading width={50} height={50} />
          </div>
        )}
        {!loadingData && !arrayForLineChart.length && (
          <div className={styles.noMembersMsg}>{t.user.text_no_registered}</div>
        )}
        {!loadingData && arrayForLineChart.length > 0 && (
          <div className={styles.bigChartSection}>
            <LineChart arrayForLineChart={arrayForLineChart} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardUsers;
