import React, { useState, useEffect } from "react";
import styles from "./report.module.scss";
import Button from "../../../button";
import {
  compareUniqueContainerIds,
  isObjectEmpty,
} from "../../../../_utils/utils";
import { mainActions } from "../../../../_store/actions/main.actions";
import { useDispatch } from "react-redux";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import t from "../../../../_config/constants";
import { qrRedirectUrl } from "../../../../environment";
const Report = ({ selectedBatch }) => {
  const dispatch = useDispatch();

  const [containers, setContainers] = useState([]);
  const [fileName, setFileName] = useState("");
  const fetchContainers = async (id) => {
    let response = await dispatch(
      mainActions.run("data", "container", "all", { filter: { batchId: id } })
    );
    let data = [];
    if (response) {
      const sortedData = response.sort(compareUniqueContainerIds);
      sortedData.forEach((item) => {
        data.push({
          URL: `${qrRedirectUrl}?container=${item._id + ""}&batch=${
            selectedBatch.uniqueBatchId
          }`,
          "Short URL": `rsbls.com/${item.uniqueContainerId}`,
          "Unique Container Id": item.uniqueContainerId,
        });
      });
      let name = selectedBatch.name + ".xls";
      setFileName(name);
    }
    setContainers(data);
  };

  useEffect(() => {
    setContainers([]);
    if (!isObjectEmpty(selectedBatch)) {
      fetchContainers(selectedBatch._id);
    } else {
      setContainers([]);
    }
  }, [selectedBatch]);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div className={styles.download}>
      <Button
        disabled={
          (!isObjectEmpty(selectedBatch) ? false : true) ||
          containers.length === 0
        }
        label={t.containerManagement.add.text_download}
        btnClass="btnWhiteBackground"
        onClick={(e) => exportToCSV(containers, fileName)}
      />
    </div>
  );
};

export default Report;
