import React from 'react';
import styles from './button.module.scss';

const Button = ({ label, btnClass, iconRight, iconLeft, disabled, onClick, loading, active, type }) => {
  return (
    <> { !loading ?
      <button className={active ? styles[`${btnClass}-active`] : styles[btnClass]} onClick={onClick} disabled={disabled} type={type}>
        {iconLeft && <div><img className={styles.iconLeft} src={iconLeft} /></div>} {label && label}  {iconRight && <div><img className={styles.iconRight} src={iconRight} /></div>}
      </button> :
      <button className={`${styles[btnClass]}`} disabled={true} type={type}>
        <i className="fa fa-spinner fa-spin" aria-hidden="true" ></i>
      </button>
    }
    </>
  );
};

export default Button;
