import React, { useState } from "react";
import styles from "./termsContainer.module.scss";

import { RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlineEdit } from "react-icons/ai";

import Input from "../../../input";
import Button from "../../../button";
import Textarea from "../../../textarea";
import Selector from "../../../selector";

import t from "../../../../_config/constants";

const TermsContainer = ({
  titleInd,
  data,
  deleteSection,
  handleAddField,
  deleteField,
  handleChangeHeading,
  handleChange,
  editingSectionInd,
  setEditingSectionInd,
  handleSubmit,
  handleCancel,
  errorMsg,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const fieldOptions = [
    { label: "Subheading", value: "subheading" },
    { label: "Sub-Subheading", value: "subsubheading" },
    { label: "Text", value: "baseText" },
  ];
  const [newField, setNewField] = useState("subheading");
  const getLabel = (type) => {
    let val = "";
    switch (type) {
      case "heading":
        val = "Heading";
        break;
      case "subheading":
        val = "Subheading";
        break;
      case "subsubheading":
        val = "Sub-Subheading";
        break;
      case "baseText":
        val = "Text";
        break;
      default:
        val = "";
    }
    return val;
  };
  const handleSelect = (e) => {
    setNewField(e.value);
  };
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <p
          onClick={() => {
            setShowDetails((prev) => !prev);
          }}
          className={styles.showDetails}
        >
          {showDetails || editingSectionInd === titleInd ? "-" : "+"}
        </p>
        {editingSectionInd === titleInd ? (
          <Input
            value={data[0].text}
            inputClass="inputFormWhite"
            name={data[0].type}
            onChange={(e) => {
              handleChangeHeading(e, titleInd);
            }}
            error={!data[0].text}
          />
        ) : (
          <p className={styles.titleText}>{data[0].text}</p>
        )}

        {editingSectionInd === titleInd ? (
          data.length === 1 ? (
            <RiDeleteBin5Line
              onClick={() => {
                deleteSection(titleInd);
              }}
            />
          ) : null
        ) : editingSectionInd === null ? (
          <AiOutlineEdit
            onClick={() => {
              setEditingSectionInd(titleInd);
              setShowDetails(true);
            }}
          />
        ) : null}
      </div>
      {(showDetails || editingSectionInd === titleInd) && (
        <>
          <div className={styles.sections}>
            {editingSectionInd === titleInd
              ? data.map((item, ind) => (
                  <div key={ind} className={styles[item.type]}>
                    {item.type === "baseText" ? (
                      <Textarea
                        textareaClass="textareaWhite"
                        label={"Text"}
                        value={item.text}
                        name="baseText"
                        onChange={(e) => {
                          handleChange(e, titleInd, ind);
                        }}
                        error={!item.text}
                      />
                    ) : !["heading", "text"].includes(item.type) ? (
                      <Input
                        label={getLabel(item.type)}
                        value={item.text}
                        name={item.type}
                        inputClass={"inputForm"}
                        error={!item.text}
                        onChange={(e) => {
                          handleChange(e, titleInd, ind);
                        }}
                      />
                    ) : null}
                    {!["heading", "text"].includes(item.type) && (
                      <RiDeleteBin5Line
                        onClick={() => {
                          deleteField(titleInd, ind);
                        }}
                      />
                    )}
                  </div>
                ))
              : data.map((item, ind) =>
                  !["heading", "text"].includes(item.type) ? (
                    <div key={ind} className={styles[item.type]}>
                      <p>{item.text}</p>
                    </div>
                  ) : null
                )}
          </div>
          {editingSectionInd === titleInd && (
            <div className={styles.addField}>
              <Selector
                options={fieldOptions}
                placeholder={"Select Field Type"}
                handleChangeSelect={(e) => handleSelect(e)}
                selectClassname="selectSecondaryWhiteBg"
                selectedOption={getSelectedValue(fieldOptions, newField)}
              />
              <div
                className={styles.addSubSection}
                onClick={() => handleAddField(titleInd, newField)}
              >
                <span>+</span>
                <p>Add Field</p>
              </div>
            </div>
          )}
        </>
      )}
      {errorMsg && editingSectionInd === titleInd && (
        <p className={styles.error}>{t.common.text_all_fields_are_required}</p>
      )}
      {editingSectionInd === titleInd && (
        <div className={styles.buttons}>
          <Button
            btnClass="btnWhiteBackground"
            label={"Cancel"}
            onClick={handleCancel}
          />
          <Button
            btnClass="btnNormalCasal"
            label={"Save"}
            onClick={() => {
              handleSubmit(titleInd);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TermsContainer;
