import React, { useEffect, useState } from "react";
import styles from "./cleanedContainers.module.scss";
import cleanersImg from "../../../../_assets/icons/stars.svg";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../../_store/actions/main.actions";
import t from "../../../../_config/constants";

const CleanedContainers = () => {
  const [cleanContainers, setCleanContainers] = useState(0);
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [containerTypes, setContainerTypes] = useState([]);
  const getCleanContainerNumber = async (typeIds, id, term) => {
    if (store.auth && store.auth.user && store.auth.user.type === "cleaner") {
      let response = await dispatch(
        mainActions.run("data", "container", "count", {
          filter: { state: "cleaner", cleaner_id: store.auth.user._id },
        })
      );
      setCleanContainers(response);
    } else {
      const res = await dispatch(
        mainActions.run("availableContainers", "availableContainers", "all", {
          filter: { containerTypeId: { $in: typeIds } },
        })
      );
      let count = 0;
      res &&
        res.length > 0 &&
        res.map((item) => {
          count += item.amount;
        });
      setCleanContainers(count);
    }
  };
  useEffect(() => {
    const getContainerTypes = async (regionId) => {
      let filter = [];
      if (regionId && regionId !== "*") {
        filter.push({ regionId: regionId });
      }
      let data = await dispatch(
        mainActions.run("data", "containerType", "all", { filter })
      );
      if (data) setContainerTypes(data.map((item) => item._id));
    };
    if (store.auth.user) {
      getContainerTypes(store.auth.user.regionId);
    }
  }, [store.auth && store.auth.user]);
  useEffect(() => {
    getCleanContainerNumber(containerTypes);
  }, [containerTypes]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.currently}>
        <div>
          <h3>{cleanContainers}</h3>
          <img src={cleanersImg} alt="img" />
        </div>
        <span>{t.cleaning.text_clean_containers}</span>
      </div>
    </div>
  );
};

export default CleanedContainers;
