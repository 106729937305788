import React, { useState, useEffect, createContext } from "react";
import CreateReplenishmentForm from "../../_components/distribution/replenishment";
import Transfer from "../../_components/distribution/transfer";
import { mainActions } from "../../_store/actions/main.actions";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectMap } from "../../_utils/utils";
export const DistributionContext = createContext();

const Distribution = () => {
  const [activeTab, setActiveTab] = useState("replenishment");
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [containerTypes, setContainerTypes] = useState([]);

  useEffect(() => {
    const getAllContainerTypes = async (regionId) => {
      let filter = [];
      if (regionId && regionId !== "*") {
        filter.push({
          regionId: regionId,
        });
      }
      let data = await dispatch(
        mainActions.run("data", "containerType", "all", { filter })
      );
      setContainerTypes(data);
    };
    const getAllRestaurnts = async (regionId) => {
      let filter = [];
      if (regionId && regionId !== "*") {
        filter.push({
          regionId: regionId,
        });
      }

      let data = await dispatch(
        mainActions.run("profiles", "restaurant", "all", { filter })
      );
      setRestaurants(data);
      let options = makeSelectMap(data);
      setRestaurantOptions(options);
    };
    if (store.auth.user && store.auth.user.regionId) {
      getAllRestaurnts(store.auth.user.regionId);
      getAllContainerTypes(store.auth.user.regionId);
    }
  }, [store.auth && store.auth.user]);

  return (
    <DistributionContext.Provider
      value={[restaurants, restaurantOptions, containerTypes]}
    >
      {activeTab === "replenishment" && (
        <CreateReplenishmentForm
          onTransferClick={() => setActiveTab("transfer")}
          onReplenishmentClick={() => setActiveTab("replenishment")}
          activeTab={activeTab}
        />
      )}
      {activeTab === "transfer" && (
        <Transfer
          onTransferClick={() => setActiveTab("transfer")}
          onReplenishmentClick={() => setActiveTab("replenishment")}
          activeTab={activeTab}
        />
      )}
    </DistributionContext.Provider>
  );
};

export default Distribution;
