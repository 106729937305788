import React, { useState } from "react";
import Button from "./../../../button";
import t from "./../../../../_config/constants";
import moment from "moment";
import { getStatistics } from "../../../../_services/statistics.service";
import { exportToCSVTabs } from "../../../../_utils/exportToCSVtabs";
import toastService from "../../../../_services/toast.service";
const DownloadLogsStatistics = React.memo(({ regionId, disabled }) => {
  const [loadingCSV, setLoadingCSV] = useState(false);
  const handleDownload = async () => {
    setLoadingCSV(true);
    document.body.style.cursor = "wait";
    const fileName =
      "Store Activity Statistics - " +
      moment(new Date().getTime()).format("MMMM YYYY");

    const result = await getStatistics({
      mode: "get-store-logs-statistics",
      filter: { regionId: regionId && regionId !== "*" ? regionId : "" },
    });
    if (Object.keys(result).length) {
      exportToCSVTabs(result, fileName);
    } else {
      toastService.show("warning", "No activity logs found");
    }
    setLoadingCSV(false);
    document.body.style.cursor = "auto";
  };

  return (
    <Button
      disabled={disabled || loadingCSV}
      label={"Download Logs"}
      btnClass="btnWhiteBackground"
      onClick={handleDownload}
    />
  );
});

export default DownloadLogsStatistics;
