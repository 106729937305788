import React from "react";
import styles from "./list.module.scss";
import QrGenerator from "../../../../containers/qrCode";
import moment from "moment";
import Loading from "../../../../loading";
import t from "../../../../../_config/constants";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../_store/actions/modal.actions";
import ContainerDetails from "../../../../containers/add/list/details";

const ListPurchased = ({ data, loadingData }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.wrapper}>
      {loadingData && (
        <div className={styles.loading}>
          <Loading width={50} height={50} />
        </div>
      )}
      {!loadingData &&
        data.map((item, index) => {
          return (
            <div className={styles.listContainer} key={index}>
              <div className={styles.item}>
                <p
                  className={styles.name}
                  onClick={() =>
                    dispatch(
                      modalActions.openModal(
                        <ContainerDetails containerId={item._id} />,
                        "",
                        "xlg"
                      )
                    )
                  }
                >
                  {index + 1}. #{item.uniqueContainerId}
                </p>
                <div className={styles.qrWrapper}>
                  <p>{item.name}</p>
                  <QrGenerator
                    id={item._id}
                    uniqueId={item.uniqueContainerId}
                    batchId={item.batchId}
                    name={item.name}
                    size={item.size}
                    material={item.material}
                    volume={item.volume}
                  />
                </div>
              </div>
              <p className={styles.smallerP}>
                {t.common.text_ordered_from}{" "}
                {item.restaurantName
                  ? item.restaurantName
                  : "Unspecified Store"}{" "}
                -{" "}
                {item.orderedAt
                  ? moment(item.orderedAt).format("MM/DD/YYYY")
                  : "Unspecified Date"}
              </p>
            </div>
          );
        })}
      {!loadingData && data.length < 1 && (
        <div className={styles.noData}>
          <p>This user has no charged containers.</p>
        </div>
      )}
    </div>
  );
};

export default ListPurchased;
