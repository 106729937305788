import React from "react";
import TableOfLogs from "../containers/tableOfLogs";
import styles from "./logs.module.scss";
import t from "../../../../_config/constants";
const LogsOfActivity = ({
  onContainersClick,
  onPricingClick,
  onLogsClick,
  activeTab,
  restaurant,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p
          className={
            activeTab === "containers" ? styles.tabActive : styles.tabInactive
          }
          onClick={onContainersClick}
        >
          {" "}
          {t.restaurant.text_containers_settings}{" "}
        </p>
        <p
          className={
            activeTab === "pricing" ? styles.tabActive : styles.tabInactive
          }
          onClick={onPricingClick}
        >
          {" "}
          {t.restaurant.text_pricing_management}{" "}
        </p>
        <p
          className={
            activeTab === "logs" ? styles.tabActive : styles.tabInactive
          }
          onClick={onLogsClick}
        >
          {" "}
          {t.restaurant.text_th_logs_of_activity}{" "}
        </p>
      </div>
      <div className={styles.contentWrapper}>
        <TableOfLogs restaurant={restaurant} />
      </div>
    </div>
  );
};
export default LogsOfActivity;
