import React, { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

import Input from "./../../../input";
import Button from "../../../button";
import FormModal from "../../../modal/formModal";
import RecipientInput from "../recipientInput";

import { formatPhone } from "../../../../_utils/formatPhone";
import t from "../../../../_config/constants";

import logo from "./../../../../_assets/images/logo.svg";
import close from "./../../../../_assets/icons/close.svg";

import styles from "./replenishmentForm.module.scss";

const ReplenishmentForm = ({
  data,
  onSubmit,
  handleClose,
  secureCodeDisabled,
  handleChangeSecureCode,
  buttonDisabled,
  cancelDelivery,
  userType,
}) => {
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => state?.auth?.user);
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <FormModal
          show={showModal}
          handleClose={closeModal}
          content={
            <RecipientInput
              handleClose={closeModal}
              replenishmentData={[data]}
            />
          }
          size="md"
        />
      )}
      <div className={styles.wrapper}>
        <div className={styles.close} onClick={handleClose}>
          <img src={close} alt="x" />
        </div>
        <div className={styles.header}>
          <img src={logo} alt="logo" />
          <div>{t.distribution.text_replenishment}</div>
        </div>
        <div className={styles.content}>
          <div className={styles.restaurantInfo}>
            <span>{data.name}</span>
            <p className={styles.address}> {data.address}</p>
            <p className={styles.phone}>{formatPhone(data.phone)}</p>
          </div>
          <div className={styles.containers}>
            {data?.containers?.length > 0 &&
              data.containers.map((item) => (
                <div className={styles.info} key={item.containerTypeId}>
                  <p>{item.containerTypeName}</p>
                  <p>{item.amount}</p>
                </div>
              ))}
          </div>
          <Input
            label={t.distribution.text_deadline}
            name="deadlineDate"
            inputClass="inputForm"
            type="text"
            value={
              data.deadlineDate
                ? moment(data.deadlineDate).format("MM/DD/YYYY")
                : ""
            }
            disabled={true}
          />
          {user?.secureCodeRequired && (
            <div className={styles.secureCode}>
              <Input
                label={t.restaurant.text_input_secureCode}
                inputClass="inputBorderGray"
                type={data.isDelivered ? "password" : "text"}
                name="secureCode"
                disabled={secureCodeDisabled || data.isDelivered}
                onChange={handleChangeSecureCode}
                value={data.secureCode}
                error={!data.secureCode && !secureCodeDisabled}
              />
            </div>
          )}
          {data?.uniqueContainerIds && (
            <>
              <h3>List of Container IDs</h3>
              <div className={styles.containers}>
                {data.uniqueContainerIds.map((item, index) => (
                  <div className={styles.info} key={index}>
                    <p>
                      {++index}.{item}
                    </p>
                  </div>
                ))}
              </div>
            </>
          )}
          <p>
            {t.common.text_th_created}{" "}
            {moment(
              data.createdAt ? data.createdAt : new Date().getTime()
            ).format("MM/DD/YYYY")}
          </p>
        </div>
        <div className={styles.buttons}>
          {!secureCodeDisabled && (
            <Button
              btnClass="btnWhiteBackground"
              label="Send List via Email"
              type="btn"
              onClick={() => setShowModal(true)}
            />
          )}
          {!data.isDelivered && (
            <Button
              btnClass="btnNormalCasal"
              label={t.button.text_save}
              onClick={onSubmit}
              disabled={buttonDisabled}
            />
          )}
          {data._id && userType !== "cleaner" && (
            <Button
              btnClass="btnNormal"
              label={t.button.text_delete}
              onClick={cancelDelivery}
              disabled={buttonDisabled}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default ReplenishmentForm;
