import React, { useState } from "react";
import styles from "./billingDetails.module.scss";
import t from "../../../_config/constants";
import { useDispatch } from "react-redux";
import toastService from "../../../_services/toast.service";
import { modalActions } from "../../../_store/actions/modal.actions";
import AddCardModal from "../addCardModal";
import documentService from "../../../_services/document.service";
import EmailRecipientInput from "../../emailRecipientInput";
const BillingDetails = ({ restaurant, refreshAllRestaurants }) => {
  const dispatch = useDispatch();
  const [sending, setSending] = useState(false);

  const [recipient, setRecipient] = useState(
    restaurant.billingEmail || restaurant.email
  );
  const [tab, setTab] = useState("card");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (recipient) {
      setSending(true);
      const response = await documentService.sendViaEmail("send-email", {
        recipient: recipient,
        title: "Billing Information",
        subject: "Billing Information",
        text: `Attached is a link you can use to fill out your billing information. Please don't hesitate to reach out if you have any questions. \n https://billing.stripe.com/p/login/6oE2ap3MHddA8ve288`,
      });
      if (response) {
        toastService.show(
          "success",
          `${t.distribution.text_successfully_sent_email} ${recipient}`
        );
      }
      setSending(false);
      dispatch(modalActions.closeModal());
    }
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.tabs}>
        <div
          className={tab === "card" ? styles.tabActive : styles.tabInactive}
          onClick={() => setTab("card")}
        >
          CC Information
        </div>
        <div
          className={tab === "link" ? styles.tabActive : styles.tabInactive}
          onClick={() => setTab("link")}
        >
          Stripe Link
        </div>
      </div>
      {tab === "card" ? (
        <AddCardModal
          user={restaurant}
          refreshAllRestaurants={refreshAllRestaurants}
        />
      ) : (
        <EmailRecipientInput
          recipient={recipient}
          setRecipient={setRecipient}
          title={"Send Stripe Link via Email"}
          handleSend={handleSubmit}
          sending={sending}
        />
      )}
    </div>
  );
};
export default BillingDetails;
