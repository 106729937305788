import React, { useState, useEffect } from "react";
import Button from "../../../../button";
import styles from "./downloadData.module.scss";
import t from "../../../../../_config/constants";
import { exportToCSV } from "../../../../../_utils/exportToCSV";
import documentService from "../../../../../_services/document.service";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../../../_store/actions/main.actions";
import { qrRedirectUrl } from "../../../../../environment";
const DownloadData = ({ regionId, type, disabled }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loadingCSV, setLoadingCSV] = useState(false);

  useEffect(() => {
    if (data.length > 0) {
      const fileName =
        type === "user"
          ? "Members"
          : type === "unregistered"
          ? "Unregistered"
          : "Reuser Cards";
      exportToCSV(data, fileName);
    }
    setLoadingCSV(false);
  }, [data]);
  const handleDownload = async () => {
    if (regionId && type !== "reuserCard") {
      setLoadingCSV(true);
      const response = await documentService.getMembersCSVData({
        regionId,
        type,
      });
      setData(response);
    } else if (regionId && type === "reuserCard") {
      //get reuser card data
      const filter = {};
      if (regionId !== "*") filter.regionId = regionId;
      let reuserCards = await dispatch(
        mainActions.run("profiles", "reuserCard", "all", {
          filter: filter,
        })
      );
      let data = [];
      if (reuserCards) {
        reuserCards.forEach((item) => {
          data.push({
            URL: `${qrRedirectUrl}?reuser_id=${item._id + ""}`,
            "Unique Reuser Card Id": item.uniqueReuserCardId,
          });
        });
      }
      setData(data);
    }
  };
  return (
    <div className={styles.wrapper}>
      <Button
        disabled={disabled || loadingCSV}
        label={
          type === "reuserCard"
            ? "Download Reuser Cards Data"
            : t.user.text_download_data
        }
        btnClass="btnWhiteBackground"
        onClick={handleDownload}
      />
    </div>
  );
};
export default DownloadData;
