import React from "react";
import styles from "./list.module.scss";
import t from "../../../../_config/constants";
const List = ({
  modalData,
  list,
  handleChecked,
  openDeleteModal,
  setSelectedContainer,
  selectedContainer,
  activeTab,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h3>{t.containerManagement.create.text_created_container}</h3>
      </div>
      <div className={styles.section}>
        <p>{t.containerManagement.create.text_th_name}</p>
        <p>{t.containerManagement.create.text_th_size}(LxWxH)</p>
        <p>{t.containerManagement.create.text_th_material}</p>
      </div>
      {list.length < 1 && (
        <div className={styles.noData}>
          <span>{t.containerManagement.create.text_create_container}</span>
        </div>
      )}
      {list && list.length > 0 && (
        <div className={styles.list}>
          {list.map((item, index) => (
            <div
              className={`
              ${
                selectedContainer._id === item._id
                  ? styles.selectedItem
                  : styles.item
              }
              ${activeTab === "edit" ? styles.selectableItem : ""}
              `}
              key={index}
              onClick={
                activeTab === "new"
                  ? () => {}
                  : () => {
                      setSelectedContainer(item);
                    }
              }
            >
              <p>
                {index + 1}. {item.name}
              </p>
              <p>
                {item.size.length || 0}x{item.size.width || 0}x
                {item.size.height || 0}mm
              </p>
              <p>{item.material}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default List;
