import React, { useEffect, useState } from "react";
import styles from "./containersAtDistribution.module.scss";

import Datepicker from "../../../newDatepicker";
import Loading from "../../../loading";
import { useSelector } from "react-redux";
import { getStatistics } from "../../../../_services/statistics.service";

const ContainersAtDistribution = ({ containerTypes = [] }) => {
  const store = useSelector((state) => state);

  const [containers, setContainers] = useState([]);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getContainerAtDistribution = async (date, user) => {
      try {
        const filter = {};
        if (
          date.getFullYear() === new Date().getFullYear() &&
          date.getMonth() === new Date().getMonth() &&
          date.getDate() === new Date().getDate()
        ) {
          filter.date = new Date().getTime();
        } else {
          filter.date = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
          ).getTime();
        }
        const response = await getStatistics({
          mode: "get-distribution-statistics",
          filter: {},
        });
        if (response) {
          setContainers(response);
        }
      } catch (err) {}
      setLoading(false);
    };

    if (date && store?.auth?.user?.regionId) {
      setLoading(true);
      getContainerAtDistribution(date, store.auth.user);
    }
  }, [date, store.auth && store.auth.user]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.topBar}>
        <h3>Containers At Distribution</h3>
        <div className={styles.datepicker}>
          <Datepicker
            minDate={new Date(2021, 5, 1).getTime()}
            maxDate={new Date().getTime()}
            selected={date}
            onDatePickerChange={(val) => setDate(val)}
            name="date"
            placeholder="mm/dd/yyyy"
          />
        </div>
      </div>
      <div className={styles.inventory}>
        {!loading && containers && (
          <table>
            <thead>
              <tr>
                <th>Total</th>
                {containerTypes?.map((type) => (
                  <th key={type?._id}>{type?.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{containers.all}</td>
                {containerTypes?.map((type, ind) => (
                  <td key={ind}>{containers[type._id + ""] || 0}</td>
                ))}
              </tr>
            </tbody>
          </table>
        )}
        {loading && <Loading width={50} height={50} />}
      </div>
    </div>
  );
};
export default ContainersAtDistribution;
