import React, { useState, useEffect, useRef } from "react";

import { FaRegSmile } from "react-icons/fa";
import Picker from "emoji-picker-react";

import Textarea from "../../textarea";
import Button from "../../button";
import ProgressBar from "../../progressBar";

import userService from "../../../_services/user.service";
import toastService from "../../../_services/toast.service";
import t from "../../../_config/constants";

import styles from "./smsModal.module.scss";

const SMSModal = ({ handleClose, handleDisabled, users, handleCancel }) => {
  const [errorMsg, setErrorMsg] = useState(false);
  const [sending, setSending] = useState(false);
  const [finished, setFinished] = useState(false);
  const [data, setData] = useState("");
  const [error, setError] = useState({
    title: false,
    body: false,
  });
  const [showEmojiPicker, setShowEmojiPicker] = useState({
    title: false,
    body: false,
  });

  const refTitle = useRef();
  const refBody = useRef();
  const refIconT = useRef();
  const refIconB = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showEmojiPicker.title &&
        refTitle.current &&
        !refTitle.current.contains(e.target) &&
        !refIconT.current.contains(e.target)
      ) {
        setShowEmojiPicker((prev) => ({
          ...prev,
          title: false,
        }));
      } else if (
        showEmojiPicker.body &&
        refBody.current &&
        !refBody.current.contains(e.target) &&
        !refIconB.current.contains(e.target)
      ) {
        setShowEmojiPicker((prev) => ({
          ...prev,
          body: false,
        }));
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showEmojiPicker]);

  const handleChange = (e) => {
    setErrorMsg(false);
    const { name, value } = e.target;
    setData(value);
    setError((error) => ({ ...error, [name]: !value }));
  };

  const sendSMSHandler = async (e) => {
    e.preventDefault();
    let res;
    if (data) {
      setSending(true);
      handleDisabled(true);
      document.body.style.cursor = "wait";
      if (users.length) {
        const userNumbers = users
          .map((user) => user.phone)
          .filter((num) => !!num);
        try {
          res = await userService.sendSMS({
            numbers: userNumbers,
            message: data,
          });
        } catch (err) {}
      }
      setFinished(true);
      setTimeout(() => {
        handleClose();
        handleCancel();
        if (res && res.response) {
          toastService.show(
            "success",
            `SMS Message successfully sent to ${
              res.response.successfully
            } user/s. ${
              res.response.failed
                ? `Failed to send to ${res.response.failed} user/s`
                : ""
            }`
          );
        } else {
          toastService.show(
            "error",
            "Something went wrong while sending SMS message. Please try again."
          );
        }
        document.body.style.cursor = "auto";
        handleDisabled(false);
        setSending(false);
      }, 1500);
    } else {
      setErrorMsg(true);
      setError({ title: !data.title, body: !data.body });
    }
  };

  const onEmojiClickBody = (event, emojiObject) => {
    setData((prevVal) => `${prevVal}${emojiObject.emoji}`);
  };

  return (
    <div className={styles.wrapper}>
      <h2>
        Send SMS to {users.length} member{users.length > 1 ? "s" : ""}
      </h2>
      <div className={styles.notificationBody}>
        <div className={styles.input}>
          <Textarea
            textareaClass="textareaWhite"
            label={t.pushNotifications.text_body}
            error={error.body}
            onChange={handleChange}
            name="body"
            value={data}
            disabled={sending}
          />
          <div
            ref={refIconB}
            className={styles.smileIcon}
            onClick={() => {
              setShowEmojiPicker((prev) => ({ ...prev, body: !prev.body }));
            }}
          >
            <FaRegSmile />
          </div>
          {showEmojiPicker.body && (
            <div ref={refBody} className={styles.emojiPicker}>
              <Picker
                onEmojiClick={onEmojiClickBody}
                pickerStyle={{ width: "100%" }}
              />
            </div>
          )}
        </div>
      </div>
      <ProgressBar sending={sending} finished={finished} />
      <Button
        label={t.button.text_send}
        disabled={sending || users?.length === 0}
        btnClass="btnNormalGlacier"
        onClick={sendSMSHandler}
      />
      {errorMsg ? (
        <div className={styles.error}>
          <p>{t.common.text_all_fields_required}</p>
        </div>
      ) : null}
    </div>
  );
};
export default SMSModal;
