import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import SearchBar from "../../../searchBar";
import Button from "../../../button";

import Loading from "../../../loading";
import Pagination from "../../../pagination";

import { mainActions } from "../../../../_store/actions/main.actions";
import { termRegex } from "../../../../_utils/utils";
import useDebounce from "../../../../_utils/debounce";
import { exportToCSV } from "../../../../_utils/exportToCSV";
import t from "../../../../_config/constants";

import UsersImg from "../../../../_assets/icons/user.svg";

import styles from "./reusersList.module.scss";
import ReusersTable from "../reusersTable";
import ReusersContainers from "../reusersContainers";
import documentService from "../../../../_services/document.service";
import moment from "moment";

const ReusersList = ({}) => {
  const store = useSelector((state) => state);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const pageSize = 50;
  const [numberOfMembers, setNumberOfMembers] = useState(0);
  const [sortConfig, setSortConfig] = useState({});
  const [filterData, setFilterData] = useState({
    searchTerm: "",
    selectedValue: "",
  });

  const debouncedSearchTerm = useDebounce(filterData.searchTerm, 500);

  const dispatch = useDispatch();

  useEffect(() => {
    setPageNumber(0);
  }, [debouncedSearchTerm, store.auth && store.auth.user]);

  const getNumericTerm = (term) => {
    const num = term.replace(/[- )(/|Rr]/g, "");
    if (!num) return " ";
    return num;
  };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterData((prevState) => ({ ...prevState, [name]: value }));
  };

  const getData = async (
    term,
    regionId,
    subregionId,
    pageNumber,
    sortConfig = { createdAt: -1 }
  ) => {
    setLoadingData(true);
    let filter = [{ type: { $in: ["user", "unregistered"] } }];
    const numericTerm = getNumericTerm(term);
    if (regionId && regionId !== "*") {
      filter.push({
        regionId: regionId,
      });
    }
    if (subregionId && subregionId !== "*")
      filter.push({
        subregionId,
      });

    if (term) {
      filter.push({
        $or: [
          { fullName: { $regex: `${termRegex(term)}`, $options: "i" } },
          { phone: { $regex: `${termRegex(numericTerm)}`, $options: "i" } },
          { email: { $regex: `${termRegex(term)}`, $options: "i" } },
          { customer: { $regex: `${termRegex(term)}`, $options: "i" } },
          {
            orderingCode: {
              $regex: `${termRegex(numericTerm)}`,
              $options: "i",
            },
          },
        ],
      });
    }
    let response = await dispatch(
      mainActions.run("profiles", "user", "page-reusers", {
        filter,
        pageSize,
        pageNumber,
        sort: sortConfig,
      })
    );
    if (!response) response = { rows: [], total: 0 };
    setNumberOfMembers(response.total);
    setData(response.rows);
    setLoadingData(false);
  };

  useEffect(() => {
    if (store.auth?.user?.regionId)
      getData(
        debouncedSearchTerm,
        store.auth.user.regionId,
        store.auth.user.subregionId,
        pageNumber,
        sortConfig
      );
  }, [debouncedSearchTerm, store.auth?.user, pageNumber, sortConfig, refresh]);

  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };

  const changeSortConfig = (data) => {
    if (data) {
      const { key, direction } = data;
      setSortConfig({ [key]: direction === "ascending" ? 1 : -1 });
    }
  };

  const handleDownload = async () => {
    if (store.auth.user.subregionId) {
      setLoadingCSV(true);
      try {
        const response = await documentService.getSubregionReusersCSVData({
          subregionId: store.auth.user.subregionId,
        });
        const fileName =
          store.auth.user.subregionName +
          "-" +
          moment().format("MMM Do YYYY") +
          "-reuser_data";
        exportToCSV(response, fileName);
      } catch (err) {}

      setLoadingCSV(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.header}>
          <div className={styles.info}>
            {isNaN(numberOfMembers) || loadingData ? (
              <Loading width={50} height={50} />
            ) : (
              <span>{numberOfMembers}</span>
            )}
            <img src={UsersImg} />
          </div>
          <Button
            disabled={loadingCSV || loadingData}
            btnClass="btnWhiteBackground"
            label={"Download Data"}
            onClick={handleDownload}
          />
        </div>
        <div className={styles.searchAndFilter}>
          <SearchBar
            customClass="transactionSearch"
            placeholder={t.user.text_search_member_placeholder}
            name="searchTerm"
            value={filterData.searchTerm}
            onChange={handleChange}
            disabled={loadingData}
          />
        </div>
        <div className={styles.tableWrapper}>
          <ReusersTable
            data={data}
            changeSortConfig={changeSortConfig}
            setSelectedUser={setSelectedUser}
            selectedUser={selectedUser}
            loadingData={loadingData}
          />
        </div>
        <div className={styles.pagination}>
          <Pagination
            handlePageClick={onPageChange}
            pageCount={Math.ceil(numberOfMembers / pageSize)}
            forcePage={pageNumber}
            loading={loadingData}
          />
        </div>
      </div>
      <div className={styles.right}>
        <ReusersContainers
          userId={selectedUser}
          hanleRefresh={() => {
            setRefresh((prev) => !prev);
          }}
        />
      </div>
    </div>
  );
};
export default ReusersList;
