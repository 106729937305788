import React, { useState, useEffect } from "react";
import moment from "moment";
import styles from "./addContainer.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { deleteContainerBatch } from "../../../_services/container.service";
import { mainActions } from "../../../_store/actions/main.actions";
import { modalActions } from "../../../_store/actions/modal.actions";
import toastService from "../../../_services/toast.service";

import t from "./../../../_config/constants";
import { isObjectEmpty } from "../../../_utils/utils";
import { termRegex } from "../../../_utils/utils";
import { makeSelectMap } from "../../../_utils/utils";
import useDebounce from "../../../_utils/debounce";

import Selector from "../../selector";
import SearchBar from "../../searchBar";
import Loading from "../../loading";
import ConfirmModal from "../../modal/confirmModal";
import CreateBatchForm from "./form";
import ContainerList from "./list";
import Report from "./report";
import { AiFillEdit } from "react-icons/ai";
import EditRedirectURL from "./editURL";
import DownloadPNGs from "./downloadPNGs";
import Button from "../../button";

const AddContainer = ({
  onAddClick,
  onCreateClick,
  onEditClick,
  activeTab,
}) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);

  const [options, setOptions] = useState();
  const [update, setUpdate] = useState(1);
  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  const [selectedBatch, setSelectedBatch] = useState({});
  const [showDeleteBatch, setShowDeleteBatch] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterData, setFilterData] = useState({
    searchTerm: "",
    selectedValue: "",
  });

  const debouncedSearchTerm = useDebounce(filterData.searchTerm, 500);

  useEffect(() => {
    setListLoading(true);
    if (store.auth && store.auth.user) {
      const fetchAllContainerTypes = async (regionId, subregionId) => {
        let filter = [];
        if (regionId && regionId !== "*") {
          filter.push({ regionId: regionId });
        }
        if (subregionId && subregionId !== "*") {
          filter.push({ subregionId: subregionId });
        }
        let data = await dispatch(
          mainActions.run("data", "containerType", "all", { filter })
        );
        let arr = makeSelectMap(data);
        setOptions(arr);
        getAllContainerBatches(
          debouncedSearchTerm,
          filterData.selectedValue,
          arr
        );
        let filterOptions = [...arr];
        filterOptions.unshift({ label: "All", value: "" });
        setFilterOptions(filterOptions);
      };
      fetchAllContainerTypes(
        store.auth.user.regionId,
        store.auth.user.subregionId
      );
    }
  }, [store.auth && store.auth.user]);

  useEffect(() => {
    setListLoading(true);
    if (options) {
      getAllContainerBatches(
        debouncedSearchTerm,
        filterData.selectedValue,
        options
      );
    }
  }, [debouncedSearchTerm, filterData.selectedValue, update]);

  const onUpdate = () => {
    setUpdate((prevState) => (prevState === 1 ? 2 : 1));
  };

  const onChangeTerm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSelectFilter = (e) => {
    setFilterData((filter) => ({
      ...filter,
      selectedValue: e.value,
    }));
  };

  const containerIdTerm = (term) => {
    if (term.indexOf("/") && term.lastIndexOf("/") !== term.indexOf("/")) {
      return term.slice(0, term.lastIndexOf("/"));
    } else return term;
  };

  const getAllContainerBatches = async (
    term,
    selectValue,
    containerOptions
  ) => {
    setSelectedBatch({});
    let filter = [
      {
        containerTypeId: { $in: containerOptions.map((item) => item.value) },
      },
    ];
    term = containerIdTerm(term);
    if (term) {
      filter.push({
        $or: [
          { name: { $regex: `${termRegex(term)}`, $options: "i" } },
          { material: { $regex: `${termRegex(term)}`, $options: "i" } },
          { uniqueBatchId: { $regex: `${termRegex(term)}`, $options: "i" } },
        ],
      });
    }
    if (selectValue) {
      filter.push({
        containerTypeId: { $eq: selectValue },
      });
    }
    let data = await dispatch(
      mainActions.run("data", "containerBatch", "all", { filter })
    );
    if (data) setList(data);

    setListLoading(false);
  };

  const openDeleteBatch = () => {
    setShowDeleteBatch(true);
  };

  const deleteBatch = async (answer, data) => {
    if (answer) {
      await deleteContainerBatch(data._id);
      setSelectedBatch({});
      setShowDeleteBatch(false);
      getAllContainerBatches(
        debouncedSearchTerm,
        filterData.selectedValue,
        options
      );
      toastService.show(
        "success",
        t.containerManagement.add.text_successfully_deleted_batch
      );
    } else {
      setShowDeleteBatch(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      {showDeleteBatch && (
        <ConfirmModal
          show={showDeleteBatch}
          handleYesNoAnswer={deleteBatch}
          modalData={selectedBatch}
          question={t.containerManagement.add.text_modal_delete_batch}
        />
      )}
      <div className={styles.left}>
        <div className={styles.header}>
          <p
            className={
              activeTab === "add" ? styles.tabActive : styles.tabInactive
            }
            onClick={onAddClick}
          >
            {t.containerManagement.add.text_add_containers}
          </p>
          <p
            className={
              activeTab === "create" ? styles.tabActive : styles.tabInactive
            }
            onClick={onCreateClick}
          >
            {t.containerManagement.create.text_new_container}
          </p>
          <p
            className={
              activeTab === "edit" ? styles.tabActive : styles.tabInactive
            }
            onClick={onEditClick}
          >
            {t.containerManagement.create.text_edit_container}
          </p>
        </div>
        <CreateBatchForm
          loading={listLoading}
          options={options}
          refetch={() =>
            getAllContainerBatches(
              debouncedSearchTerm,
              filterData.selectedValue,
              options
            )
          }
          setLoading={setListLoading}
        />
        <h2>List of Batches</h2>
        <div className={styles.options}>
          <div className={styles.search}>
            <SearchBar
              customClass="search"
              placeholder={
                t.containerManagement.add.text_search_batch_placeholder
              }
              disabled={listLoading}
              name="searchTerm"
              value={filterData.searchTerm}
              onChange={onChangeTerm}
            />
          </div>

          <div className={styles.subOptions}>
            <div className={styles.select}>
              <Selector
                label=""
                placeholder={t.containerManagement.add.text_filter}
                multiselect={false}
                options={filterOptions}
                handleChangeSelect={(e) => handleSelectFilter(e)}
                selectClassname="selectSecondary"
              />
            </div>
            <p
              style={
                !isObjectEmpty(selectedBatch)
                  ? { cursor: "pointer" }
                  : { cursor: "not-allowed", color: "gray" }
              }
              className={
                !isObjectEmpty(selectedBatch) ? styles.retireActive : ""
              }
              onClick={!isObjectEmpty(selectedBatch) ? openDeleteBatch : null}
            >
              {t.containerManagement.add.text_retire_batch}
            </p>
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.tableHeader}>
            <p className={styles.blockName}>
              {t.containerManagement.add.text_th_batch_name}
            </p>
            <p className={styles.blockDate}>
              {" "}
              {t.containerManagement.add.text_th_created}
            </p>
            <p className={styles.blockAmount}>
              {t.containerManagement.add.text_th_amount}
            </p>
            <p className={styles.redirectURL}>
              {t.containerManagement.add.text_redirect_url}
            </p>
            <p></p>
          </div>
          {listLoading && <Loading width={50} height={50} />}
          {!listLoading &&
            list &&
            list?.length > 0 &&
            list?.map((item) => (
              <div
                className={`${
                  selectedBatch === item ? styles.selectedItem : styles.item
                }`}
                key={item._id}
                onClick={() => setSelectedBatch(item)}
              >
                <p className={styles.blockName}>{item.name}</p>
                <p className={styles.blockDate}>
                  {moment(item.createdAt).format("MM/DD/YYYY")}
                </p>
                <p className={styles.blockAmount}>{item.amount} pcs</p>
                <p className={styles.redirectURL}>{item.redirectURL}</p>
                <p className={styles.editIcon}>
                  <AiFillEdit
                    onClick={() => {
                      dispatch(
                        modalActions.openModal(
                          <EditRedirectURL batch={item} />,
                          "",
                          "lg"
                        )
                      );
                    }}
                  />
                </p>
              </div>
            ))}
          {!listLoading && !list.length && (
            <div>
              <p className={styles.noBatchesMsg}>
                {t.containerManagement.add.text_no_batches}
              </p>
            </div>
          )}
        </div>
        <div className={styles.buttons}>
          <Button
            label={"Download QR codes"}
            btnClass="btnWhiteBackground"
            disabled={isObjectEmpty(selectedBatch)}
            onClick={() => {
              dispatch(
                modalActions.openModal(
                  <DownloadPNGs selectedBatch={selectedBatch} />
                )
              );
            }}
          />
          <Report selectedBatch={selectedBatch} />
        </div>
      </div>
      <div className={styles.right}>
        <ContainerList selectedBatch={selectedBatch} onUpdate={onUpdate} />
      </div>
    </div>
  );
};

export default AddContainer;
