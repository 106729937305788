import React, { useState } from "react";
import styles from "./createRefund.module.scss";
import Input from "../../input";
import Button from "../../button";
import toastService from "../../../_services/toast.service";
import { refundUser } from "../../../_services/payment.service";
import t from "../../../_config/constants";
import moment from "moment";
const CreateRefund = ({
  user,
  transaction,
  refunds,
  handleClose,
  onUpdate,
}) => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [data, setData] = useState({
    user: user,
    amount: "",
    paymentIntentId: transaction.paymentIntentId,
    id: transaction.id,
  });
  const [error, setError] = useState(false);
  const onChange = (e) => {
    e.preventDefault();
    setError(false);
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleRefund = async (e) => {
    e.preventDefault();
    setBtnDisabled(true);
    if (transaction) {
      if (
        data.amount <=
        transaction.amount - (transaction.refunded ? transaction.refunded : 0)
      ) {
        let response = await refundUser(data);
        if (response) {
          toastService.show("success", t.refund.text_successfully_refunded);
          setBtnDisabled(false);
          onUpdate();
          handleClose();
        }
      } else {
        setError(true);
        setBtnDisabled(false);
      }
    } else {
      toastService.show("warning", t.refund.text_warning);
    }
  };
  const handleInputNumber = (e) => {
    const numbers = "0123456789.";
    if (!numbers.includes(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <div className={styles.wrapper}>
      <header>
        <h3>{t.refund.text_create}</h3>
      </header>
      <div className={styles.transaction}>
        {t.refund.text_transaction_amount}:{" "}
        {transaction && transaction.amount
          ? Number(transaction.amount).toFixed(2)
          : ""}
        $
      </div>
      <form className={styles.form}>
        <div className={styles.formContent}>
          <div className={styles.amount}>
            <Input
              label={t.refund.text_refund_amount}
              type="number"
              inputClass="inputForm"
              desc="$"
              name="amount"
              value={data.amount}
              onChange={onChange}
              onKeyPress={handleInputNumber}
            />
          </div>
        </div>
        <div className={styles.submit}>
          <Button
            btnClass="btnNormalCasal"
            label={!transaction.paymentIntentId ? "Pending" : "Refund"}
            onClick={handleRefund}
            disabled={
              !data.amount || btnDisabled || !transaction.paymentIntentId
            }
          />
        </div>
      </form>
      {error && (
        <p className={styles.error}>
          {t.refund.text_max_amount} $
          {Number(transaction.amount).toFixed(2) -
            (transaction.refunded ? transaction.refunded : 0)}
          *
        </p>
      )}
      {refunds && refunds.length > 0 && (
        <div className={styles.previousRefunds}>
          <h3>{t.refund.text_previous_refunds}</h3>
          <div className="custom-table">
            <table>
              <thead>
                <tr className="tb-head-th">
                  <th>{t.common.text_date}</th>
                  <th>{t.common.text_th_amount}</th>
                </tr>
              </thead>
              <tbody>
                {refunds.map((refund) => (
                  <React.Fragment key={refund._id}>
                    <tr>
                      <td>{moment(refund.createdAt).format("MM/DD/YYYY")}</td>
                      <td>{Number(refund.amount).toFixed(2)} $</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateRefund;
