import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styles from "./accountSummary.module.scss";
import SummaryDetails from "./details";
import SummaryTable from "./table";
import { mainActions } from "../../../../_store/actions/main.actions";

const AccountSummary = ({ restaurant }) => {
  const [activeTab, setActiveTab] = useState("table");
  const [transactions, setTransactions] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const getTransactions = async () => {
    let filter = [];
    filter.push({
      user_id: restaurant._id,
      transactionType: "RESTAURANT_INVOICE",
    });
    let response = await dispatch(
      mainActions.run("transactions", "transaction", "all", { filter })
    );
    setTransactions(response);
    setLoading(false);
  };
  useEffect(() => {
    if (restaurant) {
      setLoading(true);
      setActiveTab("table");
      getTransactions();
    }
  }, [restaurant]);
  const handleSelectSummary = (transaction) => {
    setActiveTab("details");
    setSelectedTransaction(transaction);
  };
  const handleCloseDetails = () => {
    setActiveTab("table");
  };
  return (
    <>
      {activeTab === "table" ? (
        <SummaryTable
          transactions={transactions}
          handleSelectSummary={handleSelectSummary}
          loading={loading}
        />
      ) : (
        <SummaryDetails
          handleClose={handleCloseDetails}
          transaction={selectedTransaction}
        />
      )}
    </>
  );
};
export default AccountSummary;
