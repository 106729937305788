import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { PublicRoute } from "./publicRoute";
import { PrivateRoute } from "./privateRoute";
import { AdminRoute } from "./adminRoute";
import { MasterAdminRoute } from "./masterAdminRoute";
import Login from "../_components/auth/login";
import ContainerManagement from "../_pages/containerManagement";
import RestaurantManagement from "../_pages/restaurantManagement";
import UserManagement from "../_pages/userManagement";
import Distribution from "../_pages/distribution";
import Cleaning from "../_pages/cleaning";
import Refund from "../_pages/refund";
import ForgotPassword from "../_components/auth/forgotPassword";
import ResetPassword from "../_components/auth/resetPassword";
import ConfirmMail from "../_components/auth/confirmEmail";
import Admin from "../_pages/admin";
import DropoffManagement from "../_pages/dropoffManagement";
import CleanersManagement from "../_pages/cleanersManagement";
import Dashboard from "../_pages/dashboard";
import Transactions from "../_pages/transactions";
import Notifications from "../_pages/notifications";
import Settings from "../_components/restaurant/settings";
import Report from "../_pages/report";
import CouponManagement from "../_pages/couponManagement";
import Reports from "../_pages/reports";
import RedirectURL from "../_pages/redirect";
import Permissions from "../_pages/permissions";
import AppSettings from "../_pages/settings";
import { SubregionAdminRoute } from "./subregionAdminRoute";
import { AdminAndCleanerRoute } from "./adminAndCleanerRoute";
import ContainerAndBinsStats from "../_pages/subregionPanel/dashboard";
import Impact from "../_pages/subregionPanel/impact";
import Usage from "../_pages/subregionPanel/usage";
import Reusers from "../_pages/subregionPanel/reusers";
import OperatorPortalNotifications from "../_pages/subregionPanel/notifications";
const Routes = () => {
  return (
    <Switch>
      <PublicRoute path="/login" component={Login} />
      <PublicRoute
        path="/forgot-pass"
        component={ForgotPassword}
        key="ForgotPassword"
      />
      <PublicRoute
        path="/reset/:token/:email"
        component={ResetPassword}
        key="ResetPassword"
      />
      <PublicRoute
        path="/confirm-email/:token/:email/:newEmail"
        component={ConfirmMail}
        key="ConfirmEmail"
      />
      <PublicRoute path="/redirect" component={RedirectURL} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <SubregionAdminRoute
        exact
        path="/containers-stats"
        component={ContainerAndBinsStats}
      />
      <SubregionAdminRoute exact path="/op-reusers" component={Reusers} />
      <SubregionAdminRoute
        exact
        path="/op-notifications"
        component={OperatorPortalNotifications}
      />
      <SubregionAdminRoute exact path="/impact" component={Impact} />
      <SubregionAdminRoute exact path="/container-usage" component={Usage} />
      <AdminRoute
        exact
        path="/container-management"
        component={ContainerManagement}
      />
      <AdminRoute
        exact
        path="/dropoff-management"
        component={DropoffManagement}
      />
      <AdminRoute
        exact
        path="/store-management"
        component={RestaurantManagement}
      />
      <AdminRoute path="/store-management/settings" component={Settings} />
      <AdminRoute
        exact
        path="/coupon-management"
        component={CouponManagement}
      />
      <AdminRoute
        exact
        path="/cleaners-management"
        component={CleanersManagement}
      />
      <AdminRoute exact path="/user-management" component={UserManagement} />
      <AdminRoute exact path="/transactions" component={Transactions} />
      <AdminAndCleanerRoute
        exact
        path="/distribution"
        component={Distribution}
      />
      <AdminRoute exact path="/reports" component={Reports} />
      <AdminAndCleanerRoute exact path="/cleaning" component={Cleaning} />
      <AdminAndCleanerRoute
        exact
        path="/notifications"
        component={Notifications}
      />
      <AdminRoute exact path="/refund" component={Refund} />
      <AdminRoute exact path="/report" component={Report} />

      <MasterAdminRoute exact path="/admin" component={Admin} />
      <MasterAdminRoute exact path="/permissions" component={Permissions} />
      <MasterAdminRoute exact path="/settings" component={AppSettings} />

      <Redirect from="*" to="/dashboard" />
    </Switch>
  );
};

export { Routes };
