import React from "react";
import CheckoutsChart from "../../../statistics/checkoutsChart";
const Statistic = ({ restaurantId }) => {
  return (
    <div>
      <CheckoutsChart selectedStoreId={restaurantId} />
    </div>
  );
};
export default Statistic;
