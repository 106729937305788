import React from "react";
import DatePicker from "react-datepicker";
import "./datePicker.css";

const Datepicker = ({
  customInput,
  selected,
  onDatePickerChange,
  minDate,
  maxDate,
  icon,
  onInputChange,
  label,
  name,
  placeholder,
  error,
  errorMsg,
}) => {
  return (
    <>
      <span className="datepickerSpan">
        {label}
        {error ? <span style={{ color: "#e2725b" }}> *</span> : ""}
      </span>
      <DatePicker
        selected={selected}
        onChange={onDatePickerChange}
        strictParsing={true}
        placeholderText={placeholder}
        dateFormat="MM/dd/yyyy"
        minDate={minDate || new Date()}
        maxDate={maxDate || null}
        portalId="root-portal"
      />
    </>
  );
};

export default Datepicker;
