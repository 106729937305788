import React, { useEffect, useState } from "react";
import styles from "./header.module.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../../../_store/actions/modal.actions";
import { getStatistics } from "../../../_services/statistics.service";

import containersImg from "../../../_assets/icons/containers.svg";
import restaurantImg from "../../../_assets/icons/restaurantManagementImg.svg";
import membersImg from "../../../_assets/icons/user.svg";
import checkoutImg from "../../../_assets/icons/check-out.svg";
import cleanersImg from "../../../_assets/icons/stars.svg";

import t from "../../../_config/constants";
import Loading from "../../loading";
import ContainersAtStores from "./containersAtStores";
import ContainersAtDistribution from "./containersAtDistribution";
import ContainersAtLocations from "./containersAtLocations";
import ContainersAtMembers from "./containersInUse";

const Header = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [containerTypes, setContainerTypes] = useState([]);
  const [statisticsData, setStatisticsData] = useState({
    numberOfContainersAtStores: "",
    numberOfRestaurants: "",
    numberOfMembers: "",
    containersAtMembers: "",
    containersAtLocations: "",
    numberOfCleanContainers: "",
  });
  useEffect(() => {
    let mounted = true;

    const fetchData = async (regionId, subregionId) => {
      try {
        const response = await getStatistics({
          mode: "get-dashboard-statistics",
          filter: { regionId, subregionId },
        });
        if (response) {
          const {
            numberOfContainersAtStores,
            numberOfRestaurants,
            numberOfMembers,
            containersAtMembers,
            containersAtLocations,
            numberOfCleanContainers,
            containerTypes,
          } = response;
          setStatisticsData({
            numberOfContainersAtStores,
            numberOfRestaurants,
            numberOfMembers,
            containersAtMembers,
            containersAtLocations,
            numberOfCleanContainers,
          });
          setContainerTypes(containerTypes);
        }
      } catch (err) {}
      setLoading(false);
    };
    if (store.auth && store.auth.user && store.auth.user.regionId && mounted) {
      setLoading(true);
      fetchData(store.auth.user.regionId, store.auth.user.subregionId);
    }
    return function cleanup() {
      mounted = false;
    };
  }, [store.auth && store.auth.user]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div
          className={styles.item}
          onClick={() =>
            !loading &&
            dispatch(
              modalActions.openModal(
                <ContainersAtStores containerTypes={containerTypes} />,
                "",
                ""
              )
            )
          }
        >
          <div>
            {loading && (
              <div className={styles.loader}>
                <Loading height={40} width={40} />
              </div>
            )}
            {!loading && <h3>{statisticsData.numberOfContainersAtStores}</h3>}
            <img src={containersImg} alt="img" />
          </div>
          <span>{t.dashboard.text_containers_at_restaurants}</span>
        </div>
        <div
          className={styles.item}
          onClick={() => history.push("/store-management")}
        >
          <div>
            {loading && (
              <div className={styles.loader}>
                <Loading height={40} width={40} />
              </div>
            )}
            {!loading && <h3>{statisticsData.numberOfRestaurants}</h3>}
            <img src={restaurantImg} alt="img" />
          </div>
          <span>{t.dashboard.text_restaurats}</span>
        </div>
        <div
          className={styles.item}
          onClick={() => history.push("/user-management")}
        >
          <div>
            {loading && (
              <div className={styles.loader}>
                <Loading height={40} width={40} />
              </div>
            )}
            {!loading && <h3>{statisticsData.numberOfMembers}</h3>}
            <img src={membersImg} alt="img" />
          </div>
          <span>{t.dashboard.text_members}</span>
        </div>
      </div>
      <div className={styles.row}>
        <div
          className={styles.item}
          onClick={() =>
            !loading &&
            dispatch(
              modalActions.openModal(
                <ContainersAtMembers containerTypes={containerTypes} />,
                "",
                ""
              )
            )
          }
        >
          <div>
            {loading && (
              <div className={styles.loader}>
                <Loading height={40} width={40} />
              </div>
            )}
            {!loading && <h3>{statisticsData.containersAtMembers}</h3>}
            <img src={checkoutImg} alt="img" />
          </div>
          <span>{t.dashboard.text_in_use}</span>
        </div>
        <div
          className={styles.item}
          onClick={() =>
            !loading &&
            dispatch(
              modalActions.openModal(
                <ContainersAtLocations containerTypes={containerTypes} />,
                "",
                ""
              )
            )
          }
        >
          <div>
            {loading && (
              <div className={styles.loader}>
                <Loading height={40} width={40} />
              </div>
            )}
            {!loading && <h3>{statisticsData.containersAtLocations}</h3>}
            <img src={containersImg} alt="img" />
          </div>
          <span>{t.dashboard.text_containers_at_locationss}</span>
        </div>
        <div
          className={styles.item}
          onClick={() =>
            !loading &&
            dispatch(
              modalActions.openModal(
                <ContainersAtDistribution containerTypes={containerTypes} />,
                "",
                ""
              )
            )
          }
        >
          <div>
            {loading && (
              <div className={styles.loader}>
                <Loading height={40} width={40} />
              </div>
            )}
            {!loading && <h3>{statisticsData.numberOfCleanContainers}</h3>}
            <img src={cleanersImg} alt="img" />
          </div>
          <span>{t.dashboard.text_clean_containers}</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
