import React, { useEffect } from "react";
import styles from "./cleanersTable.module.scss";
import Loading from "../../loading";
import Button from "../../button";
import arrowUp from "../../../_assets/icons/arrowUp.svg";
import arrowDown from "../../../_assets/icons/arrowDown.svg";
import upDownArrow from "../../../_assets/icons/upDownArrow.svg";
import useSortableData from "../../../_utils/useSortableData";
import t from "../../../_config/constants";

const CleanersTable = ({
  selectedCleanerId,
  onSelectCleaner,
  cleanersList,
  loadingData,
  changeSortConfig,
}) => {
  const { handleSort, sortConfig } = useSortableData(cleanersList);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return upDownArrow;
    }
    return sortConfig.key === name
      ? sortConfig.direction === "ascending"
        ? arrowUp
        : arrowDown
      : upDownArrow;
  };
  useEffect(() => {
    changeSortConfig(sortConfig);
  }, [sortConfig]);

  const cleaners = cleanersList.map((item) => {
    return (
      <tr
        key={item._id}
        className={selectedCleanerId === item._id ? styles.selected : ""}
        onClick={() => onSelectCleaner(item._id)}
      >
        <td>{item.fullName ? item.fullName : t.common.text_not_available}</td>
        <td>{item.containerAmount}</td>
      </tr>
    );
  });

  const noCleanerMsg = (
    <p className={styles.noCleanerMsg}>{t.cleaner.text_no_cleaners}</p>
  );

  return (
    <div className={styles.tableWrapper}>
      <table>
        <thead>
          <tr>
            <th onClick={() => (loadingData ? null : handleSort("fullName"))}>
              {t.cleaner.text_th_name}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("fullName")}
              />
            </th>
            <th>{t.cleaner.text_th_amount}</th>
          </tr>
        </thead>
        {!loadingData && cleanersList.length > 0 && (
          <tbody>{cleaners.length > 0 && cleaners}</tbody>
        )}
      </table>
      {!loadingData && !cleanersList.length && noCleanerMsg}
      {loadingData && (
        <div className={styles.loading}>
          <Loading width={50} height={50} />
        </div>
      )}
    </div>
  );
};

export default CleanersTable;
