import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../_store/actions/main.actions";
import channelIcon from "../../_assets/images/noAvatar.png";
import styles from "./orderingChannelModal.module.scss";
import Input from "../input";
import Selector from "../selector";
import Button from "../button";
import Loading from "./../loading";
import UploadAvatar from "../uploadAvatar";
import ConfirmModal from "../modal/confirmModal";
import { amazonUrl, amazonUrlResized } from "../../environment";
import { uploadToS3 } from "../../_utils/auth/uploadPhoto";
import toastService from "../../_services/toast.service";
import t from "./../../_config/constants";
import { isObjectEmpty } from "./../../_utils/utils";
import { GrDeliver } from "react-icons/gr";
import { makeSelectMap } from "./../../_utils/utils";
const OrderingChannelModal = () => {
  const [orderingChannels, setOrderingChannels] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalData, setModalData] = useState({});
  const [regionOptions, setRegionOptions] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState({});
  const [channelData, setChannelData] = useState({
    name: "",
    avatarURL: "",
    regionId: "",
  });
  const [image, setImage] = useState({
    uri: channelData.avatarURL ? channelData.avatarURL : "",
  });
  const [disableSubmit, setDisableSubmit] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  useEffect(() => {
    if (store.auth && store.auth.user.regionId === "*") fetchRegions();
    if (store.auth.user.regionId != "*") {
      setChannelData((data) => ({
        ...data,
        regionId: store.auth.user.regionId,
      }));
    }
  }, [store.auth && store.auth.user]);
  useEffect(() => {
    const getOrderingChannels = async () => {
      let filter = [];
      if (store.auth && store.auth.user.regionId != "*") {
        filter.push({ regionId: store.auth.user.regionId });
      }
      const response = await dispatch(
        mainActions.run("data", "orderingChannel", "all", { filter })
      );
      setOrderingChannels(response);
      setLoading(false);
    };
    getOrderingChannels();
  }, [refresh]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setChannelData((channelData) => ({ ...channelData, [name]: value }));
  };
  const handleChooseImg = (e) => {
    e.preventDefault();
    let set = {
      uri: URL.createObjectURL(e.target.files[0]),
      type: e.target.files[0].type,
      file: e.target.files[0],
    };
    setImage(set);
  };
  const editChannel = (channel) => {
    setSelectedChannel(channel);
    setChannelData(channel);
    setImage({ uri: channel.avatarURL });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (channelData.name && channelData.regionId) {
      setDisableSubmit(true);
      const imgUrl = await uploadToS3(image);
      if (imgUrl) {
        channelData.avatarURL = amazonUrl + imgUrl;
        channelData.avatarURLResized = amazonUrlResized + imgUrl;
      } else {
        channelData.avatarURL = "";
        channelData.avatarURLResized = "";
      }

      let response;
      if (!channelData._id) {
        response = await dispatch(
          mainActions.run("data", "orderingChannel", "new", channelData)
        );
        if (response) {
          toastService.show(
            "success",
            `${t.restaurant.text_successfully_added_channel}`
          );
        }
      } else {
        response = await dispatch(
          mainActions.run("data", "orderingChannel", "update", channelData)
        );
        if (response) {
          toastService.show(
            "success",
            `${t.restaurant.text_successfully_edited_channel}`
          );
        }
      }

      setChannelData({ name: "", avatarURL: "" });
      setLoading(true);
      setImage({ uri: "" });
      setRefresh((prev) => !prev);
      setDisableSubmit(false);
    }
  };
  const updateRestaurant = async (restaurant, channelId) => {
    const orderingChannels = restaurant.orderingChannels;
    delete orderingChannels[channelId];
    await dispatch(
      mainActions.run("profiles", "restaurant", "update", {
        ...restaurant,
        orderingChannels,
      })
    );
  };
  const handleDelete = async (answer, data) => {
    if (answer) {
      const channelId = data._id + "";
      let name = "orderingChannels." + channelId;
      let filter = [{ [name]: { $exists: true } }];
      const restaurants = await dispatch(
        mainActions.run("profiles", "restaurant", "all", { filter })
      );
      if (restaurants && restaurants.length > 0) {
        restaurants.forEach((restaurant) =>
          updateRestaurant(restaurant, channelId)
        );
      }
      await dispatch(
        mainActions.run(
          "data",
          "orderingChannel",
          "delete",
          { _id: data._id },
          {
            message: `${t.restaurant.text_successfully_deleted_channel} ${data.name}`,
          }
        )
      );
      setShowModalDelete(false);
      setLoading(true);
      setChannelData({ name: "", avatarURL: "" });
      setSelectedChannel({});
      setModalData({});
      setImage({ uri: "" });
      setRefresh((prev) => !prev);
    } else {
      setShowModalDelete(false);
    }
  };
  const openDeleteModal = async (e, selected) => {
    e.preventDefault();
    setShowModalDelete(true);
    setModalData(selected);
  };
  const clearData = () => {
    setChannelData({ name: "", avatarURL: "" });
    setImage({ uri: "" });
    setSelectedChannel({});
  };
  const fetchRegions = async () => {
    let response = await dispatch(mainActions.run("data", "region", "all", {}));
    let arr = makeSelectMap(response);
    setRegionOptions(arr);
  };
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const handleSelect = (e) => {
    setChannelData((data) => ({
      ...data,
      regionId: e.value,
    }));
  };
  return (
    <div className={styles.wrapper}>
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={handleDelete}
          modalData={selectedChannel}
          question={t.restaurant.text_modal_delete_channel}
        />
      )}
      <h2>Ordering Channels</h2>
      {loading && <Loading width={70} />}
      <div className={styles.channels}>
        {orderingChannels?.length > 0 &&
          !loading &&
          orderingChannels?.map((channel) => {
            return (
              <div
                key={channel._id}
                className={`${styles.channel} ${
                  styles[selectedChannel === channel ? "selected" : ""]
                }`}
                onClick={() => {
                  editChannel(channel);
                }}
              >
                {channel.avatarURL ? (
                  <img
                    src={channel.avatarURL ? channel.avatarURL : channelIcon}
                    alt="Logo"
                  />
                ) : (
                  <div className={styles.icon}>
                    <GrDeliver />
                  </div>
                )}

                {channel.name}
              </div>
            );
          })}
      </div>
      <hr />
      <div className={styles.addNew}>
        <h3>Add Ordering Channel</h3>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.content}>
            <UploadAvatar uri={image.uri} setImg={handleChooseImg} />
            <div className={styles.inputs}>
              {" "}
              <Input
                label="Name"
                name="name"
                inputClass="inputForm"
                type="text"
                error={!channelData.name}
                value={channelData.name}
                placeholder="Name"
                onChange={handleChange}
              />
              {store?.auth?.user?.regionId === "*" && (
                <div className={styles.selectRegion}>
                  <Selector
                    options={regionOptions}
                    multiselect={false}
                    selectedOption={getSelectedValue(
                      regionOptions,
                      channelData.regionId
                    )}
                    handleChangeSelect={(e) => handleSelect(e)}
                    selectClassname="selectPrimary"
                    placeholder={t.common.input.text_select_region}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={styles.buttons}>
            {(channelData.name ||
              image.uri ||
              !isObjectEmpty(selectedChannel)) && (
              <Button
                btnClass="btnWhiteBackground"
                label="Cancel"
                onClick={clearData}
                disabled={disableSubmit}
              />
            )}
            <Button
              btnClass="btnNormalCasal"
              label={"Save"}
              type="submit"
              disabled={!channelData.name || disableSubmit}
            />

            {!isObjectEmpty(selectedChannel) && (
              <Button
                btnClass="btnNormal"
                label="Delete"
                onClick={(e) => {
                  openDeleteModal(e, selectedChannel);
                }}
                disabled={disableSubmit}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
export default OrderingChannelModal;
