import React, { useEffect, useState } from "react";
import styles from "./pricing.module.scss";
import queryString from "query-string";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../../_store/actions/main.actions";
import t from "../../../../_config/constants";
import TableOfDetails from "./tableOfDetails";

const Pricing = ({
  onContainersClick,
  onPricingClick,
  onLogsClick,
  activeTab,
}) => {
  const history = useHistory();
  const { id } = queryString.parse(history.location.search);
  const dispatch = useDispatch();
  const [restaurant, setRestaurant] = useState({});
  const [containerTypes, setContainerTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getRestaurant = async (id) => {
      let data = await dispatch(
        mainActions.run("profiles", "restaurant", "id", id)
      );
      setRestaurant(data);
    };
    setLoading(true);
    getRestaurant(id);
  }, []);

  useEffect(() => {
    const fetchAllContainerTypes = async (regionId) => {
      let filter = [];
      if (regionId && regionId !== "*") {
        filter.push({ regionId: regionId });
      }
      let data = await dispatch(
        mainActions.run("data", "containerType", "all", { filter })
      );
      setContainerTypes(data);

      setLoading(false);
    };
    if (restaurant.regionId) {
      fetchAllContainerTypes(restaurant.regionId);
    }
  }, [restaurant]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p
          className={
            activeTab === "containers" ? styles.tabActive : styles.tabInactive
          }
          onClick={onContainersClick}
        >
          {" "}
          {t.restaurant.text_containers_settings}{" "}
        </p>
        <p
          className={
            activeTab === "pricing" ? styles.tabActive : styles.tabInactive
          }
          onClick={onPricingClick}
        >
          {" "}
          {t.restaurant.text_pricing_management}{" "}
        </p>
        <p
          className={
            activeTab === "logs" ? styles.tabActive : styles.tabInactive
          }
          onClick={onLogsClick}
        >
          {" "}
          {t.restaurant.text_th_logs_of_activity}{" "}
        </p>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.center}>
          <TableOfDetails
            loading={loading}
            types={containerTypes}
            restaurant={restaurant}
            setRestaurant={setRestaurant}
          />
        </div>
      </div>
    </div>
  );
};

export default Pricing;
