import React, { useState, useEffect } from "react";
import styles from "./regionAdminManagement.module.scss";
import ConfirmModal from "../../modal/confirmModal";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../_store/actions/user.actions";
import { mainActions } from "../../../_store/actions/main.actions";
import Input from "../../input";
import Button from "../../button";
import toastService from "../../../_services/toast.service";
import Selector from "../../selector";
import { makeSelectMap } from "../../../_utils/utils";
import Loading from "../../loading";
import GeoInput from "../../googleMaps/geoInput";
import PhoneInput from "../../input/phoneInput";
import t from "./../../../_config/constants";
import trimObjectProps from "../../../_utils/trimObjectProps";

const RegionAdminManagement = ({
  onRegionClick,
  onSubRegionClick,
  onAdminClick,
  onNotificationsClick,
  onPostalCodesClick,
  activeTab,
}) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [errorMsg, setErrorMsg] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [regionOptions, setRegionOptions] = useState([]);
  const [subregionOptions, setSubregionOptions] = useState([]);
  const [subregions, setSubregions] = useState([]);
  const [regionsMap, setRegionsMap] = useState({});
  const [admins, setAdmins] = useState([]);
  const [onSave, setOnSave] = useState(1);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [selected, setSelected] = useState({
    _id: "",
    email: "",
    fullName: "",
    address: "",
    phone: "",
    regionId: "",
    subregionId: "",
  });
  const [error, setError] = useState({
    email: false,
    fullName: false,
    address: false,
    phone: false,
    regionId: false,
  });
  useEffect(() => {
    if (
      store.main &&
      store.main.data &&
      store.main.data.region &&
      store.main.data.region.list
    ) {
      let arr = makeSelectMap(store.main.data.region.list);
      setRegionOptions(arr);
    }
  }, [store.main.data && store.main.data.region]);
  useEffect(() => {
    const fetchSubregions = async () => {
      const response = await dispatch(
        mainActions.run("data", "subregion", "all", {})
      );
      setSubregions(response);
    };

    fetchSubregions();
  }, []);
  useEffect(() => {
    if (regionOptions && regionOptions.length > 0) {
      getRegionsMap(regionOptions);
    }
  }, [regionOptions]);
  useEffect(() => {
    if (selected.regionId) {
      const filteredSubregions = subregions.filter(
        (item) => item.regionId === selected.regionId
      );
      const arr = makeSelectMap(filteredSubregions);
      arr.length && arr.push({ label: "-", value: "" });
      setSubregionOptions(arr);
    }
  }, [subregions]);
  useEffect(() => {
    if (selected.regionId && subregions.length) {
      const filteredSubregions = subregions.filter(
        (item) => item.regionId === selected.regionId
      );
      const arr = makeSelectMap(filteredSubregions);
      arr.length && arr.push({ label: "-", value: "" });
      setSubregionOptions(arr);
      const subregionId =
        filteredSubregions.findIndex(
          (item) => item._id == selected.subregionId
        ) != -1
          ? selected.subregionId
          : "";
      setSelected((prev) => ({ ...prev, subregionId }));
    }
  }, [selected.regionId]);
  const getRegionsMap = (data) => {
    data.length > 0 &&
      data.map((item) => {
        if (!regionsMap[item._id]) {
          let obj = (regionsMap[item.value] = item.label);
          setRegionsMap((prevState) => ({
            ...prevState,
            obj,
          }));
        }
      });
  };

  const getAllAdmins = async () => {
    let response = await dispatch(
      mainActions.run("profiles", "admin", "all", {})
    );
    setAdmins(response);
    setLoading(false);
  };

  useEffect(() => {
    if (
      store.main &&
      store.main.profiles &&
      store.main.profiles.admin &&
      store.main.profiles.admin.list
    ) {
      setAdmins(store.main.profiles.admin.list);
      setLoading(false);
    }
  }, [store.main.profiles && store.main.profiles.admin]);

  const handleSelectEdit = (item) => {
    setEditing(true);
    setSelected(item);
  };

  const handleCancel = () => {
    setEditing(false);
    setErrorMsg(false);
    setSelected({
      _id: "",
      email: "",
      fullName: "",
      address: "",
      phone: "",
      regionId: "",
    });
    setError({
      email: false,
      fullName: false,
      address: false,
      phone: false,
      regionId: false,
    });
    setOnSave((prevState) => (prevState === 1 ? 2 : 1));
  };

  const handleChange = (e) => {
    e.preventDefault();
    setErrorMsg(false);
    const { name, value } = e.target;
    setSelected((selected) => ({ ...selected, [name]: value }));
    setError((error) => ({ ...error, [name]: !value }));
  };
  const onChangeAddress = (value) => {
    setErrorMsg(false);
    setError((error) => ({ ...error, address: !value }));
    setSelected((selected) => ({ ...selected, address: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setSubmitDisabled(true);
      if (editing) {
        let response = await dispatch(
          mainActions.run("profiles", "admin", "update", {
            _id: selected._id,
            email: selected.email,
            fullName: selected.fullName,
            address: selected.address,
            phone: selected.phone,
            regionId: selected.regionId,
            subregionId: selected.subregionId,
          })
        );
        if (response)
          toastService.show(
            "success",
            `${t.admin.admins.text_successfully_edited} ${selected.email}!`
          );
        getAllAdmins();
        handleCancel();
      } else {
        let data = { ...selected };
        delete data._id;
        let response = await dispatch(userActions.registerAdmin(data));
        if (response)
          toastService.show(
            "success",
            `${t.admin.admins.text_successfully_sent} ${selected.email}`
          );
        getAllAdmins();
        handleCancel();
      }
      setSubmitDisabled(false);
    } else {
      setErrorMsg(true);
      toastService.show("error", `${t.common.text_toast_all_fields_required}`);
    }
  };

  const validateForm = () => {
    trimObjectProps(selected);
    setError({
      email: !selected.email,
      regionId: !selected.regionId,
      fullName: !selected.fullName,
      address: !selected.address,
      phone: !selected.phone,
    });
    return (
      selected.email &&
      selected.regionId &&
      selected.fullName &&
      selected.address &&
      selected.phone
    );
  };

  const openDeleteModal = async (e) => {
    e.preventDefault();
    setShowModalDelete(true);
    setModalData(selected);
  };

  const handleDelete = async (answer, data) => {
    if (answer) {
      await dispatch(
        mainActions.run(
          "profiles",
          "admin",
          "delete",
          { _id: data._id },
          {
            message: `${t.admin.admins.text_successfully_deleted} ${data.email}`,
          }
        )
      );
      getAllAdmins();
      handleCancel();
      setShowModalDelete(false);
      setModalData({});
    } else {
      setShowModalDelete(false);
    }
  };

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };

  const handleSelect = (e, name) => {
    setSelected((selected) => ({
      ...selected,
      [name]: e.value,
    }));
    setError((error) => ({ ...error, [name]: false }));
    setErrorMsg(false);
  };

  return (
    <div className={styles.wrapper}>
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={handleDelete}
          modalData={modalData}
          question={t.admin.admins.text_modal_delete}
        />
      )}
      <div className={styles.left}>
        <div className={styles.header}>
          <p
            className={
              activeTab === "region" ? styles.tabActive : styles.tabInactive
            }
            onClick={onRegionClick}
          >
            {t.admin.regions.text_regions}
          </p>
          <p
            className={
              activeTab === "subregion" ? styles.tabActive : styles.tabInactive
            }
            onClick={onSubRegionClick}
          >
            {t.admin.regions.text_subregions}
          </p>
          <p
            className={
              activeTab === "codes" ? styles.tabActive : styles.tabInactive
            }
            onClick={onPostalCodesClick}
          >
            {t.admin.regions.text_region_postal_codes}
          </p>
          <p
            className={
              activeTab === "notifications"
                ? styles.tabActive
                : styles.tabInactive
            }
            onClick={onNotificationsClick}
          >
            {t.admin.regions.text_region_notifications}
          </p>
          <p
            className={
              activeTab === "admin" ? styles.tabActive : styles.tabInactive
            }
            onClick={onAdminClick}
          >
            {t.admin.admins.text_admins}
          </p>
        </div>
        <h2>{t.admin.admins.text_list_of_admins}</h2>
        {loading && <Loading width={50} height={50} />}
        {admins && admins.length > 0 && (
          <div className="custom-table">
            <table>
              <thead>
                <tr className="tb-head-th">
                  <th>{t.admin.admins.text_th_email}</th>
                  <th>{t.admin.admins.text_th_name}</th>
                  <th>{t.admin.admins.text_th_assigned_region}</th>
                </tr>
              </thead>
              <tbody>
                {admins?.map((item) => (
                  <React.Fragment key={item._id}>
                    <tr
                      onClick={() => handleSelectEdit(item)}
                      className={
                        selected._id === item._id ? styles.selected : ""
                      }
                    >
                      <td>{item.email}</td>
                      <td>
                        {item.fullName
                          ? item.fullName
                          : t.common.text_not_available}
                      </td>
                      <td>{regionsMap[item.regionId]}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {!loading && admins.length < 1 && (
          <p>{t.admin.admins.text_no_admins}</p>
        )}
      </div>
      <div className={styles.right}>
        <h2>
          {editing ? t.admin.admins.text_edit : t.admin.admins.text_create}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className={styles.content}>
            <Input
              label={t.admin.admins.text_input_email}
              error={error.email}
              name="email"
              inputClass="inputLogin"
              type="email"
              placeholder={t.admin.admins.text_input_email_placeholder}
              onChange={handleChange}
              value={selected.email.trim()}
              disabled={editing ? true : false}
            />
            <Input
              label={t.admin.admins.text_input_name}
              error={error.fullName}
              name="fullName"
              inputClass="inputLogin"
              type="text"
              placeholder={t.admin.admins.text_input_name}
              onChange={handleChange}
              value={selected.fullName}
              disabled={editing ? true : false}
            />
            <GeoInput
              error={error.address}
              onChangeLocation={onChangeAddress}
              editLocation={selected._id}
              inputVal={selected.address}
              inputClass={"inputLogin"}
              name={t.admin.admins.text_input_address}
              onSave={onSave}
            />
            <PhoneInput
              handleChange={handleChange}
              value={selected.phone}
              error={error.phone}
              inputClass="phoneInputLogin"
            />
            <div className={styles.selector}>
              <Selector
                label={t.admin.admins.text_input_region_selection}
                error={error.regionId}
                placeholder={t.admin.admins.text_input_region_placeholder}
                multiselect={false}
                options={regionOptions}
                selectedOption={getSelectedValue(
                  regionOptions,
                  selected.regionId
                )}
                handleChangeSelect={(e) => handleSelect(e, "regionId")}
                selectClassname="selectPrimary"
              />
            </div>
            <div>
              <Selector
                label={"Subregion selection"}
                options={subregionOptions}
                multiselect={false}
                selectedOption={getSelectedValue(
                  subregionOptions,
                  selected.subregionId
                )}
                handleChangeSelect={(e) => handleSelect(e, "subregionId")}
                selectClassname="selectPrimary"
                placeholder={"Assign Subregion"}
              />
            </div>
          </div>
          {errorMsg ? (
            <div className={styles.error}>
              <p>{t.common.text_all_fields_required}</p>
            </div>
          ) : null}
          <div className={styles.options}>
            <div className={styles.submit}>
              <Button
                btnClass="btnWhiteBackground"
                label={t.button.text_cancel}
                onClick={handleCancel}
                type="reset"
              />
              <Button
                btnClass="btnNormalCasal"
                label={editing ? t.button.text_save : t.button.text_create}
                disabled={submitDisabled}
                type="submit"
              />
            </div>
            {editing && (
              <div>
                {" "}
                <Button
                  btnClass="btnNormal"
                  label={t.button.text_delete}
                  onClick={(e) => openDeleteModal(e, selected)}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegionAdminManagement;
