import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";

import Input from "../../../input/index";

import { mainActions } from "../../../../_store/actions/main.actions";
import { modalActions } from "../../../../_store/actions/modal.actions";
import { isObjectEmpty } from "../../../../_utils/utils";
import t from "../../../../_config/constants";

import styles from "./../singleUser/singleUser.module.scss";
import ReuserCardQrGenerator from "../../reuserCardQRGenerator";

const SingleReuserCard = ({ selectedUser }) => {
  const [user, setUser] = useState({});
  const [step, setStep] = useState("profile");

  const dispatch = useDispatch();

  useEffect(() => {
    const getUser = async (id) => {
      let data = await dispatch(
        mainActions.run("profiles", "reuserCard", "id", id)
      );
      setUser(data);
    };

    if (selectedUser) {
      getUser(selectedUser);
    } else {
      setUser({});
    }
    dispatch(modalActions.closeModal());
  }, [selectedUser]);

  return (
    <div className={styles.wrapper}>
      {!isObjectEmpty(user) && step === "profile" && (
        <>
          <div className={styles.avatarSection}>
            <ReuserCardQrGenerator id={user._id} />
            <span className={styles.avatarName}>{user.uniqueReuserCardId}</span>
          </div>
          <h3 className={styles.profileTitle}>{t.common.text_profile_info}</h3>
          <div>
            <Input
              inputClass="inputProfileForm"
              label={"Region"}
              value={user.regionName}
              disabled={true}
            />
          </div>
          {user.subregionName && (
            <div>
              <Input
                inputClass="inputProfileForm"
                label={"Subregion"}
                value={user.subregionName}
                disabled={true}
              />
            </div>
          )}{" "}
          <h1 className={styles.containerTitle}>
            {t.common.text_container_info}
          </h1>
          <div className={styles.containerForm}>
            <div>
              <Input
                inputClass="inputProfileForm"
                label={t.user.text_container_in_possession}
                placeholder={user.inPossession}
                disabled={true}
              />
            </div>
            <div>
              <Input
                inputClass="inputProfileForm"
                label={t.user.text_overdue}
                placeholder={user.overdue}
                disabled={true}
              />
            </div>
            <div>
              <Input
                inputClass="inputProfileForm"
                label={t.user.text_th_dueDate}
                placeholder={user.dueDate}
                disabled={true}
              />
            </div>
          </div>
        </>
      )}

      {isObjectEmpty(user) && (
        <div className={styles.noData}>
          <span>{t.user.text_select_guideline_reuser_card}</span>
        </div>
      )}
    </div>
  );
};
export default SingleReuserCard;
