import React from "react";
import noAvatar from "../../_assets/images/noAvatar.png";
import Loading from "../loading";
import styles from "./avatar.module.scss";
const Avatar = ({
  avatarClass = "large",
  imgUrl,
  loadingImg,
  setLoadingImg,
}) => {
  return (
    <div className={`${styles[avatarClass]}`}>
      {loadingImg && <Loading height={50} width={50} />}
      <img
        className={loadingImg ? styles.imgHidden : null}
        onLoad={() => {
          setLoadingImg(false);
        }}
        src={imgUrl ? imgUrl : noAvatar}
        alt=""
      ></img>
    </div>
  );
};
export default Avatar;
