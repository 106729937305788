import React from "react";
import { Redirect, Route } from "react-router-dom";
import { localStorageService } from "../_services/localStorage.service";

export const SubregionAdminRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return localStorageService.isSubregionAdmin() ? (
          <section className={`content-op`}>
            <Component {...props} {...rest} />
          </section>
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};
