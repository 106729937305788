import { workingTime } from "../../../restaurant/addRestaurant/utils";
import t from "../../../../_config/constants";

export const cleaningOptions = [
  {
    label: t.dropoff.text_cleaning_by_themselves,
    value: true,
  },
  {
    label: t.dropoff.text_cleaning_service,
    value: false,
  },
];
export const containerAssignmentOptions = [
  { label: "-", value: "" },
  {
    label: "QR",
    value: "qr",
  },
  {
    label: "RFID",
    value: "rfid",
  },
  { label: "USB UHF", value: "uhf_usb" },
];

export const dropoffFormErrors = {
  name: false,
  description: false,
  location: false,
  regionId: false,
  subregionId: false,
  lat: false,
  lng: false,
};

export const dropoffFormInitialState = {
  _id: "",
  name: "",
  description: "",
  location: "",
  coordinates: {
    lat: 49.246292,
    lng: -123.116226,
  },
  workingTime,
  regionId: "",
  subregionId: "",
  visible: false,
  isDropoffService: false,
  isCleaning: false,
  isSmartBin: false,
  isSRS: false,
  maxContainersAmount: "",
  containerAssignment: "qr",
};
