import React, { useState } from "react";
import styles from "./chargeInvoiceModal.module.scss";
import moment from "moment";
import Checkbox from "../../checkbox";
import t from "./../../../_config/constants";
import Button from "../../button";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import toastService from "../../../_services/toast.service";
import { modalActions } from "../../../_store/actions/modal.actions";
import Input from "../../input";
import { chargeInvoice } from "../../../_services/payment.service";
const ChargeInvoiceModal = ({ invoice, updateList, user }) => {
  const [chargeOption, setChargeOption] = useState("stripe");
  const [disableButton, setDisableButton] = useState(false);
  const dispatch = useDispatch();
  const handleCheckboxChange = (e) => {
    setChargeOption(e.target.name);
  };
  const handleManually = async () => {
    setDisableButton(true);
    const response = await dispatch(
      mainActions.run("transactions", "transaction", "update", {
        ...invoice,
        chargedManually: true,
        chargedAt: new Date().getTime(),
      })
    );
    if (response) {
      toastService.show("success", t.transactions.text_successfully_saved);
      dispatch(modalActions.closeModal());
      updateList();
    }
    setDisableButton(false);
  };
  const chargeStripe = async () => {
    setDisableButton(true);
    if (invoice.id && invoice.amount > 1) {
      const data = {
        user: {
          email: user?.email,
          customer: user?.customer,
        },
        transactionId: invoice.id,
      };
      try {
        const response = await chargeInvoice(data);
        if (response) {
          if (response?.adminPayment?.id) {
            toastService.show(
              "success",
              t.transactions.text_successfully_charged
            );
          } else
            toastService.show(
              "error",
              `${t.common.text_something_went_wrong}! Please try again.`
            );
          dispatch(modalActions.closeModal());
          updateList();
        }
      } catch (err) {
        dispatch(modalActions.closeModal());
      }
    }
    setDisableButton(false);
  };
  return (
    <div className={styles.wrapper}>
      <h2>Charge Invoice</h2>
      <div className={styles.info}>
        <div className={styles.restaurantInfo}>
          <p>
            {user?.name ? user?.name : user?.fullName ? user?.fullName : ""}
          </p>
          <p>{user?.email}</p>
        </div>
        <div className={styles.invoiceInfo}>
          <p>
            {moment
              .utc(invoice?.createdAt)
              .subtract(1, "months")
              .format("MMMM, YYYY")}
          </p>
          <p>
            Amount: {invoice.currency?.slice(0, 2).toUpperCase()}${" "}
            {Number(invoice.amount).toFixed(2)}
          </p>
          <p></p>
        </div>
      </div>
      <div className={styles.checkbox}>
        <Checkbox
          customClass="secondaryCheckbox"
          name="manually"
          label={t.transactions.text_charged_manually}
          checked={chargeOption === "manually"}
          onChange={handleCheckboxChange}
        />
        <Checkbox
          customClass="secondaryCheckbox"
          name="stripe"
          label={t.transactions.text_charge_stripe}
          checked={chargeOption === "stripe"}
          onChange={handleCheckboxChange}
        />
      </div>
      {chargeOption === "manually" && (
        <div className={styles.manually}>
          <p>This invoce was charged through other services</p>
          <Button
            btnClass={"btnNormalCasal"}
            label={t.button.text_save}
            onClick={handleManually}
            disabled={disableButton}
          />
        </div>
      )}
      {chargeOption === "stripe" && (
        <div className={styles.viaStripe}>
          <Input
            label={"Amount"}
            inputClass="inputForm"
            type="number"
            name="amount"
            value={Number(invoice.amount).toFixed(2)}
            disabled={true}
            desc={`${invoice.currency?.slice(0, 2).toUpperCase()}$`}
          />
          {((user?.type === "restaurant" && user?.hasCard) ||
            user?.type === "user") &&
          invoice.amount >= 1 ? (
            <Button
              btnClass={"btnNormalCasal"}
              label={t.button.text_charge}
              onClick={chargeStripe}
              disabled={invoice.amount < 1 || disableButton}
            />
          ) : invoice.amount < 1 ? (
            <span className={styles.cardError}>
              Unable to charge via Stripe - Min amount $1
            </span>
          ) : (
            <span className={styles.cardError}>
              Unable to charge via Stripe - Payment method missing
            </span>
          )}
        </div>
      )}
    </div>
  );
};
export default ChargeInvoiceModal;
