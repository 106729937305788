import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { googleMapsApiKey } from "../../../environment";
import mapPin from "../../../_assets/icons/map-pin.svg";

const CustomMarker = ({}) => (
  <div>
    <img style={{ width: "35px", height: "35px" }} src={mapPin} />
  </div>
);

const Map = ({ location }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    if (location)
      setData({
        center: {
          lat: location.lat,
          lng: location.lng,
        },
        zoom: 11,
      });
  }, [location]);

  return (
    <>
      {data && data.center && (
        <div style={{ height: "100%`", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: googleMapsApiKey }}
            defaultCenter={data.center}
            defaultZoom={data.zoom}
            center={data.center}
          >
            <CustomMarker lat={data.center.lat} lng={data.center.lng} />
          </GoogleMapReact>
        </div>
      )}
    </>
  );
};

export default Map;
