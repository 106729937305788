import React, { useState } from "react";
import Input from "../../../input";
import Button from "../../../button";
import styles from "./recipientInput.module.scss";
import documentService from "../../../../_services/document.service";
import toastService from "../../../../_services/toast.service";
import t from "../../../../_config/constants";

const RecipientInput = ({ handleClose, replenishmentData }) => {
  const [sending, setSending] = useState(false);
  const [emailData, setEmailData] = useState({
    replenishmentData: replenishmentData,
    recipient: "",
  });
  const handleChange = (e) => {
    const { value } = e.target;
    setEmailData((prevState) => ({ ...prevState, recipient: value }));
  };
  const handleSendEmail = async (e) => {
    e.preventDefault();
    setSending(true);
    const response = await documentService.sendViaEmail(
      "email-replenishment",
      emailData
    );
    if (response) {
      toastService.show(
        "success",
        `${t.distribution.text_successfully_sent_email} ${emailData.recipient}`
      );
    }
    handleClose();
    setSending(false);
  };
  return (
    <div className={styles.wrapper}>
      <p>
        {replenishmentData.length > 1
          ? t.distribution.text_send_replenishments
          : t.distribution.text_send_replenishment}
      </p>
      <form onSubmit={handleSendEmail}>
        <Input
          label="Send to:"
          name="email"
          inputClass="inputForm"
          type="email"
          value={emailData.recipient.trim()}
          placeholder={t.auth.text_markup_mail}
          onChange={handleChange}
        />
        <Button
          btnClass="btnNormalCasal"
          label={t.button.text_send_via_email}
          type="submit"
          disabled={!emailData.recipient || sending}
        />
      </form>
    </div>
  );
};

export default RecipientInput;
