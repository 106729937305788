import httpService from "./http.service";

class UserService {
  async login(user) {
    return httpService.apiRequest("post", "/api/admin/public/", {
      mode: "login",
      data: user,
    });
  }
  async logout() {
    localStorage.removeItem("user");
  }

  async registerAdmin(user) {
    return httpService.apiRequest("post", "/api/admin/public/", {
      mode: "register",
      data: user,
    });
  }

  async updateUser(user) {
    return httpService.apiRequest("post", "/api/auth/public/user", {
      mode: "update",
      data: user,
    });
  }
  async deleteUser(data) {
    return httpService.apiRequest("post", "/api/auth/user", {
      mode: "delete-account",
      data,
    });
  }

  async resetLink(data) {
    return httpService.apiRequest("post", "/api/admin/public", {
      mode: "forgot-password",
      data: data.email,
    });
  }

  async forgotPassword(data) {
    return httpService.apiRequest("post", "/api/auth/public/user/", {
      mode: "change-forgot-password",
      data: data,
    });
  }

  async registerRestaurant(restaurant) {
    return httpService.apiRequest("post", "/api/restaurant/", {
      mode: "register",
      data: restaurant,
    });
  }

  async saveRestaurantDraft(restaurant) {
    return httpService.apiRequest("post", "/api/restaurant/", {
      mode: "save-draft",
      data: restaurant,
    });
  }

  async registerCleaner(cleaner) {
    return httpService.apiRequest("post", "/api/cleaner/", {
      mode: "register",
      data: cleaner,
    });
  }

  async changeEmailLink(data) {
    return httpService.apiRequest("post", "/api/auth/user", {
      mode: "change-email",
      data: data,
    });
  }

  async createReuserCards(data) {
    return httpService.apiRequest("post", "/api/auth/user", {
      mode: "create-reuser-cards",
      data: data,
    });
  }
  async confirmChangeEmail(data) {
    return httpService.apiRequest("post", "/api/auth/public/user", {
      mode: "confirm-change-email",
      data: data,
    });
  }
  async sendPushNotifications(data) {
    return httpService.apiRequest("post", "/api/notifications/users", {
      title: data.title,
      body: data.body,
      type: data.type,
      userIds: data.userIds,
    });
  }

  async sendSMS(data) {
    return httpService.apiRequest("post", "/api/notifications/sms/users", {
      data,
    });
  }
}

export default new UserService();
