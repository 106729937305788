import React, { useState, useEffect } from "react";
import styles from "./resolveReport.module.scss";
import Input from "./../../input";
import Button from "../../button";
import Selector from "./../../selector";
import { useDispatch } from "react-redux";
import { chargeUser } from "../../../_services/payment.service";
import toastService from "../../../_services/toast.service";
import { mainActions } from "../../../_store/actions/main.actions";
import Loading from "../../loading";
import t from "../../../_config/constants";
const ResolveReport = ({ container, user, overdue, handleClose }) => {
  const dispatch = useDispatch();
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    amount: overdue * 1.5 > 25 ? 25 : overdue * 1.5,
    currency: "cad",
    user: {
      userId: user?._id,
      type: "user",
      email: user?.email,
      customer: user?.customer,
      regionId: user?.regionId,
    },
  });
  const getContainerType = async (id) => {
    setLoading(true);
    let data = await dispatch(
      mainActions.run("data", "containerType", "id", id)
    );
    if (data && data.late_fee) {
      let amount = overdue * data.late_fee > 25 ? 25 : overdue * data.late_fee;
      setData((prevState) => ({ ...prevState, amount }));
    }
    setLoading(false);
  };
  useEffect(() => {
    if (container) getContainerType(container.containerTypeId);
  }, [container]);
  const handleCharge = async () => {
    if (validateData()) {
      setBtnDisable(true);
      let response = await chargeUser(data);
      if (response) {
        if (container.type === "overdueContainer") {
          await dispatch(
            mainActions.run("overdueContainers", "overdueContainer", "update", {
              ...container,
              isResolved: true,
            })
          );
        }
        handleClose();
        toastService.show("success", `Successfully charged penalties!`);
      }
      setBtnDisable(false);
    }
  };
  const validateData = () => {
    return (
      data.amount >= 1 && data.currency && data.user.email && data.user.customer
    );
  };
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const handleSelect = (e) => {
    setData((data) => ({
      ...data,
      currency: e.value,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  };
  const dummyCurrencies = [
    {
      label: "USD$",
      value: "usd",
    },
    {
      label: "CAN$",
      value: "cad",
    },
  ];
  return (
    <div className={styles.wrapper}>
      <h2>{t.report.text_resolve_report}</h2>
      <div className={styles.tableWrapper}>
        <table>
          <thead>
            <tr>
              <th>{t.report.text_user}</th>
              <th>{t.report.text_container}</th>
              <th>{t.report.text_overdue}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{user ? user.fullName : t.common.text_not_available}</td>
              <td>{container.name}</td>
              <td>{overdue ? overdue : t.report.text_on_time}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {loading && (
        <div className={styles.loading}>
          <Loading width={50} height={50} />
        </div>
      )}
      {!loading && (
        <div className={styles.input}>
          <div>
            <Input
              label={t.report.text_charge}
              inputClass="inputForm"
              type="number"
              name="amount"
              value={data.amount}
              onChange={handleChange}
            />
          </div>
          <div className={styles.selector}>
            <Selector
              multiselect={false}
              selectClassname="selectGray"
              options={dummyCurrencies}
              selectedOption={getSelectedValue(dummyCurrencies, data.currency)}
              handleChangeSelect={(e) => handleSelect(e)}
            />
          </div>
        </div>
      )}
      <Button
        btnClass="btnNormal"
        label={t.button.text_charge}
        disabled={data.amount < 1 || !user || btnDisable}
        onClick={handleCharge}
      />
    </div>
  );
};
export default ResolveReport;
