import React, { useState } from "react";
import moment from "moment";
import styles from "./overdueTable.module.scss";
import { mainActions } from "../../../_store/actions/main.actions";
import { modalActions } from "../../../_store/actions/modal.actions";
import { useDispatch } from "react-redux";
import Loading from "../../loading";
import Button from "./../../button";
import statusImage from "../../../_assets/icons/exclamation-mark.svg";
import ConfirmModal from "../../modal/confirmModal";
import toastService from "../../../_services/toast.service";
import ResolveReport from "../resolveReport";
import t from "../../../_config/constants";

const OverdueTable = ({ containers, loadingData, onUpdate }) => {
  const dispatch = useDispatch();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});

  const handleShowModal = (item) => {
    setSelectedReport(item);
    setShowModalDelete(true);
  };
  const handleDelete = async (answer) => {
    if (answer) {
      let response = await dispatch(
        mainActions.run("overdueContainers", "overdueContainer", "delete", {
          _id: selectedReport._id,
        })
      );
      if (response) {
        toastService.show("success", t.report.text_successfully_deleted);
        setSelectedReport({});
        setShowModalDelete(false);
        onUpdate();
      }
    } else {
      setShowModalDelete(false);
    }
  };
  const getOverdue = (item) => {
    let duedate = moment(item.days_to_return).startOf("day");
    let returned = moment(item.return_date).startOf("day");
    return returned.diff(duedate, "days");
  };
  const closeModal = async () => {
    await dispatch(modalActions.closeModal());
    onUpdate();
  };
  return (
    <>
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={handleDelete}
          modalData={{}}
          question={
            selectedReport.isResolved
              ? t.report.text_modal_delete
              : t.report.text_modal_delete_no_charge
          }
        />
      )}

      <div className={styles.list}>
        <div className={styles.header}>
          <p className={styles.blockUser}>{t.report.text_user}</p>
          <p className={styles.blockContainer}>{t.report.text_container}</p>
          <p className={styles.blockContainer}>{t.report.text_uniqueId}</p>
          <p className={styles.blockLocation}>{t.report.text_location}</p>
          <p className={styles.blockOverdue}>{t.report.text_overdue}</p>
          <div className={styles.blockButtons}></div>
          <div className={styles.blockStatus}></div>
        </div>
        {loadingData && (
          <div className={styles.loader}>
            <Loading width={75} height={75} />
          </div>
        )}
        {!loadingData && !containers.length && (
          <p className={styles.noContainersMsg}>
            {t.report.text_no_overdue_reports}
          </p>
        )}
        {!loadingData &&
          containers.length > 0 &&
          containers.map((item) => {
            return (
              <div className={styles.block} key={item._id}>
                <p className={styles.blockUser}>
                  {item?.user
                    ? item?.user?.fullName || t.common.text_not_available
                    : t.report.text_deleted}
                </p>
                <p className={styles.blockContainer}>{item.name}</p>
                <p className={styles.blockContainer}>
                  {item.uniqueContainerId}
                </p>
                <p className={styles.blockLocation}>{item.locationName}</p>
                <p className={styles.blockOverdue}>{getOverdue(item)} days</p>
                <div className={styles.blockButtons}>
                  <div className={item.isResolved ? styles.resolveHidden : ""}>
                    <Button
                      label={t.button.text_resolve}
                      btnClass="btnNormalCasal"
                      onClick={() =>
                        dispatch(
                          modalActions.openModal(
                            <ResolveReport
                              container={item}
                              user={item.user}
                              overdue={getOverdue(item)}
                              handleClose={closeModal}
                            />,
                            "Report",
                            "lg"
                          )
                        )
                      }
                    />
                  </div>
                  <Button
                    label={t.button.text_delete}
                    btnClass="btnNormal"
                    onClick={() => handleShowModal(item)}
                  />
                </div>
                <div className={styles.blockStatus}>
                  {!item.isResolved && <img src={statusImage} />}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
export default OverdueTable;
