import React, { useState, useEffect } from "react";
import styles from "./progressBar.module.scss";

const ProgressBar = ({ sending, finished }) => {
  const [percentage, setPercentage] = useState(0);
  const [style, setStyle] = useState({});
  useEffect(() => {
    let isMounted = true;
    if (finished && isMounted) {
      setPercentage(100);
    }
    return () => {
      isMounted = false;
    };
  }, [finished]);
  useEffect(() => {
    let isMounted = true;

    const setIntervalX = () => {
      let x = 0;
      let interval = setInterval(() => {
        setPercentage((prev) => prev + 5);

        if (++x === 16 && isMounted) {
          clearInterval(interval);
        }
      }, 1000);
    };
    if (sending && isMounted) {
      setPercentage(10);
      setIntervalX();
    }
    return () => {
      isMounted = false;
    };
  }, [sending]);
  useEffect(() => {
    let isMounted = true;
    const newStyle = {
      opacity: 1,
      width: `${percentage}%`,
      maxWidth: "100%",
    };
    const overStyle = {
      opacity: 1,
      width: `${percentage}%`,
      maxWidth: "100%",
    };
    percentage <= 100 && isMounted ? setStyle(newStyle) : setStyle(overStyle);
    return () => {
      isMounted = false;
    };
  }, [percentage]);
  return (
    <div className={styles.wrapper}>
      {sending && (
        <div className={styles.progressBar}>
          <div className={styles.progressDone} style={style}></div>
          <div className={styles.percentage}>Sending...</div>
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
