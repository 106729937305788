import httpService from "./http.service";

class DocumentService {
  async sendViaEmail(mode, data = {}) {
    return httpService.apiRequest("post", "/api/document", { mode, data });
  }
  async getMembersCSVData(data = {}) {
    return httpService.apiRequest("post", "/api/document", {
      mode: "members-csv-data",
      data,
    });
  }

  async getSubregionReusersCSVData(data = {}) {
    return httpService.apiRequest("post", "/api/document", {
      mode: "subregion-reusers-csv-data",
      data,
    });
  }
}

export default new DocumentService();
