import React, { useState, useEffect } from "react";
import styles from "./scanningContainers.module.scss";
import Button from "../../button/index";
import Checkbox from "../../checkbox/index";
import leftArrow from "../../../_assets/icons/leftArrow.svg";
import QrGenerator from "../../containers/qrCode/index";
import QrReader from "react-qr-reader";
import { mainActions } from "../../../../src/_store/actions/main.actions";
import { useDispatch, useSelector } from "react-redux";
import { isObjectEmpty } from "../../../_utils/utils";
import toastService from "../../../_services/toast.service";
import Loading from "../../loading/index";
import ConfirmModal from "../../../_components/modal/confirmModal";
import moment from "moment";
import { createCleanerBatch } from "../../../_services/container.service";
import t from "../../../_config/constants";
import { isMobile, isTablet } from "react-device-detect";

const ScanningContainers = ({ handleBack, cleanerBatch }) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [showDeleteContainers, setShowDeleteContainers] = useState(false);
  const [selectedContainers, setSelectedContainers] = useState([]);
  const [containers, setContainers] = useState([]);
  const [cancelScanning, setCancelScanning] = useState(true);
  const [duplicate, setDuplicate] = useState([]);
  const [disableScan, setDisableScan] = useState(true);
  const [waitingToScan, setWaitingToScan] = useState(true);
  const [scannedData, setScannedData] = useState({});
  const [update, setUpdate] = useState(1);
  const [newDataToScan, setNewDataToScan] = useState(1);
  const [updateScanning, setUpdateScanning] = useState(1);
  const [disabled, setDisabled] = useState(false);
  const [batch, setBatch] = useState({
    name: "",
    containerStatus: "",
  });
  const [tab, setTab] = useState(isMobile || isTablet ? "camera" : "scanner");

  const openDeleteContainer = () => {
    setShowDeleteContainers(true);
  };

  const validateQr = (obj) => {
    if (obj._id) {
      return true;
    }
    let lastChar = obj.substr(obj.length - 1);
    let firstChar = obj.charAt(0);

    if (lastChar === "}" && firstChar === "{") {
      let trueObj = JSON.parse(obj);
      let validateArr = [
        "_id",
        "uniqueContainerId",
        "name",
        "material",
        "uniqueBatchId",
        "size",
        "volume",
      ];
      let testArr = [];
      Object.keys(trueObj).forEach((key) => {
        testArr.push(key);
      });
      return JSON.stringify(validateArr) == JSON.stringify(testArr);
    } else {
      return false;
    }
  };

  const generateId = (str) => {
    let id = str.split("id@>@")[1].slice(0, 24);
    return id;
  };

  const handleDuplicates = (container) => {
    if (container.data) {
      let newObj = container.data;
      if (newObj.includes("container=")) newObj = geIdFromUrl(newObj);
      if (validateQr(newObj)) {
        const id = newObj._id || JSON.parse(newObj)._id;
        if (duplicate && !duplicate.length) {
          fetchContainers(id);
        } else if (duplicate.length > 0 && !duplicate.includes(id)) {
          fetchContainers(id);
        } else {
          toastService.show("warning", `Scanned QR code!`);
        }
      } else if (newObj && container.data.length > 150) {
        let id = generateId(newObj);
        if (duplicate && !duplicate.length) {
          fetchContainers(id);
        } else if (duplicate.length > 0 && !duplicate.includes(id)) {
          fetchContainers(id);
        } else {
          toastService.show("warning", `Scanned QR code!`);
        }
      } else {
        toastService.show("warning", `Please try again`);
      }
      setNewDataToScan(newDataToScan === 1 ? 2 : 1);
      handleScan();
    } else {
      setNewDataToScan(newDataToScan === 1 ? 2 : 1);
    }
  };

  let barcode = {
    timing: 500,
    data: "",
  };

  const barcodeReaded = () => {
    if (barcode.data.length > 1) {
      let obj = barcode;
      setScannedData(obj);
      setUpdate(update === 1 ? 2 : 1);
    }
  };

  let timeout = setTimeout(barcodeReaded, 500);

  useEffect(() => {
    if (!disableScan) {
      handleDuplicates(scannedData);
    }
  }, [update]);

  var sBrowser,
    sUsrAg = navigator.userAgent;

  let browser = "";
  if (sUsrAg.indexOf("Firefox") > -1) {
    browser = "firefox";
  } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
    browser = "firefox";
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    browser = "opera";
  } else if (sUsrAg.indexOf("Trident") > -1) {
    browser = "internetExplorer";
  } else if (sUsrAg.indexOf("Edge") > -1) {
    browser = "edge";
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    browser = "chrome";
  } else if (sUsrAg.indexOf("Safari") > -1) {
    browser = "safari";
  } else {
    sBrowser = "unknown";
  }

  useEffect(() => {
    if (browser === "chrome" || browser === "edge" || browser === "opera") {
      if (!disableScan && tab === "scanner") {
        const startScan = (e) => {
          if (barcode.data.length === 0 || e.timeStamp - barcode.timing < 200) {
            barcode.data += e.key;
            barcode.timing = e.timeStamp;
            clearTimeout(timeout);
            timeout = setTimeout(barcodeReaded, 100);
          }
        };
        document.onkeypress = startScan;
      }
    } else {
      if (isMobile || isTablet) return;
      alert(t.cleaning.text_can_not_scan);
    }
  }, [newDataToScan, updateScanning, tab]);

  const fetchContainerFromEdit = async (ids) => {
    let response = await dispatch(
      mainActions.run("data", "container", "all", {
        filter: { _id: { $in: ids } },
      })
    );
    setContainers(response);
  };

  useEffect(() => {
    if (cleanerBatch._id) {
      setDuplicate(cleanerBatch.containerIds);
      fetchContainerFromEdit(cleanerBatch.containerIds);
    }
  }, []);

  const fetchContainers = async (id) => {
    let response = await dispatch(
      mainActions.run("data", "container", "get", { filter: { _id: id } })
    );
    if (!isObjectEmpty(response)) {
      setContainers((prevState) => [...prevState, response]);
      setDuplicate((prevState) => [...prevState, response._id]);
      toastService.show("success", t.cleaning.text_successfully_scanned);
    } else {
      toastService.show("warning", t.cleaning.text_invalid_code);
    }
  };

  const handleCheckedContainers = (id) => {
    if (selectedContainers.includes(id)) {
      let filtered = selectedContainers.filter((item) => item !== id);
      setSelectedContainers(filtered);
    } else {
      setSelectedContainers((prevState) => [...prevState, id]);
    }
  };

  const deleteContainers = async (answer, data) => {
    if (answer) {
      setShowDeleteContainers(false);
      setContainers(containers.filter((x) => data.indexOf(x._id) === -1));
      setDuplicate(duplicate.filter((x) => data.indexOf(x) === -1));
      setSelectedContainers([]);
      toastService.show("success", t.cleaning.text_successfully_removed);
    } else {
      toastService.show("warning", t.common.text_something_went_wrong);
      setShowDeleteContainers(false);
    }
  };

  const handleScan = () => {
    if (disableScan) {
      setCancelScanning(false);
      setDisableScan(false);
      setUpdateScanning(updateScanning === 1 ? 2 : 1);
    }
  };

  const validateForm = () => {
    return containers.length;
  };

  const onSubmit = async () => {
    if (validateForm()) {
      setDisabled(true);
      let obj = { ...batch, ids: duplicate };
      await createCleanerBatch(obj);
      await dispatch(
        mainActions.run("data", "container", "updateMulti", {
          ids: obj.ids,
          state: "cleaner",
          restaurantName: "",
          cleaner_id: store.auth.user._id,
        })
      );
      toastService.show(
        "success",
        t.containerManagement.add.text_successfully_created_batch
      );
      handleBack();
    } else {
      toastService.show("warning", t.cleaning.text_empty_list);
    }
    setDisabled(false);
  };

  const validateEditForm = () => {
    return containers.length;
  };

  const onEdit = async () => {
    if (validateEditForm()) {
      let obj = {
        _id: cleanerBatch._id,
        cleaner_id: store.auth.user._id,
        containerIds: duplicate,
      };
      await dispatch(
        mainActions.run("data", "cleanerBatch", "update", obj, {
          _id: cleanerBatch._id,
        })
      );
      await dispatch(
        mainActions.run("data", "container", "updateMulti", {
          ids: obj.containerIds,
          state: "cleaner",
          restaurantName: "",
          cleaner_id: obj.cleaner_id,
        })
      );
      toastService.show("success", t.cleaning.text_successfully_edited_batch);
      handleBack();
    }
  };

  const onCancel = () => {
    if (!cleanerBatch._id) {
      setContainers([]);
      setDuplicate([]);
      setWaitingToScan(true);
      setCancelScanning(true);
      setDisableScan(true);
    }
    setCancelScanning(true);
    setDisableScan(true);
    setUpdate(update === 1 ? 2 : 1);
    setTab("scanner");
  };

  const handleBackButton = () => {
    handleBack();
  };

  const handleActive = (term) => {
    setTab(term);
  };

  const geIdFromUrl = (url) => {
    const arr = url.split("=")[1].split("&");
    const id = arr[0];
    return { _id: id };
  };
  const handleCameraScan = (code) => {
    if (code) {
      if (code.includes("container=")) code = geIdFromUrl(code);
      try {
        if (validateQr(code)) {
          const id = code._id || JSON.parse(code)._id;
          if (duplicate && !duplicate.length) {
            fetchContainers(id);
          } else if (duplicate.length > 0 && !duplicate.includes(id)) {
            fetchContainers(id);
          } else {
            toastService.show("warning", `Scanned QR code!`);
          }
        } else {
          toastService.show("warning", `Please try again`);
        }
        handleScan();
      } catch (e) {}
    }
  };

  const handleCameraError = (error) => {
    toastService.show("error", error?.message);
  };

  return (
    <div className={styles.wrapper}>
      {showDeleteContainers && (
        <ConfirmModal
          show={showDeleteContainers}
          handleYesNoAnswer={deleteContainers}
          modalData={selectedContainers}
          question={t.containerManagement.add.text_modal_delete_containers}
        />
      )}
      <div className={styles.left}>
        <Button
          label="Back"
          btnClass="btnBack"
          iconLeft={leftArrow}
          onClick={handleBackButton}
        />
        <div>
          <h1>{t.cleaning.text_scanning_containers}</h1>
          {cleanerBatch._id ? (
            <span className={styles.scanDate}>
              {t.cleaning.text_scan_date}:{" "}
              {moment(cleanerBatch.createdAt).format("MM/DD/YYYY")}
            </span>
          ) : (
            <span className={styles.scanDate}>
              {t.cleaning.text_scan_date}:{" "}
              {moment(new Date()).format("MM/DD/YYYY")}
            </span>
          )}
          {cleanerBatch._id ? (
            <span className={styles.numberOfScanned}>
              {" "}
              <span>
                {t.cleaning.text_number_of_scanned}:{" "}
                {cleanerBatch.containerIds.length}
              </span>
            </span>
          ) : (
            <span className={styles.numberOfScanned}>
              {" "}
              <span>
                {t.cleaning.text_number_of_scanned}: {containers.length}
              </span>
            </span>
          )}
          <div className={styles.checkboxAndButtons}>
            <div className={styles.buttonsWrapper}>
              <Button
                label={
                  disableScan && cancelScanning
                    ? t.button.text_scan
                    : t.button.text_scanning_progress
                }
                name="scan"
                btnClass="btnNormalCasal"
                onClick={handleScan}
              />
              <div className={styles.twoButtons}>
                <Button
                  label={t.button.text_cancel}
                  btnClass="btnWhiteBackground"
                  onClick={onCancel}
                />
                <Button
                  label={
                    !cleanerBatch._id
                      ? t.button.text_submit
                      : t.button.text_edit
                  }
                  btnClass="btnNormal"
                  disabled={disabled}
                  onClick={!cleanerBatch._id ? onSubmit : onEdit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div>
          {!disableScan && !cancelScanning && (
            <div className={styles.containerButtons}>
              <Button
                label={"Scanner"}
                btnClass={
                  tab === "scanner" ? "tabButtonActive" : "tabButtonInactive"
                }
                onClick={() => handleActive("scanner")}
              />
              <Button
                label={"Camera"}
                btnClass={
                  tab === "camera" ? "tabButtonActive" : "tabButtonInactive"
                }
                onClick={() => handleActive("camera")}
              />
            </div>
          )}
          {!disableScan && !cancelScanning && tab === "camera" && (
            <QrReader
              delay={1000}
              facingMode="environment"
              onScan={handleCameraScan}
              onError={handleCameraError}
              className={styles.camera}
            />
          )}
        </div>
        <div className={styles.tableHeader}>
          {cleanerBatch._id ? (
            <span> {moment(new Date()).format("MM/DD/YYYY")} </span>
          ) : (
            <span>{moment(cleanerBatch.createdAt).format("MM/DD/YYYY")}</span>
          )}
          <span>
            {cleanerBatch.uniqueCleanerBatchId
              ? cleanerBatch.uniqueCleanerBatchId
              : null}
          </span>
          <span
            style={
              selectedContainers.length > 0
                ? { cursor: "pointer" }
                : { cursor: "not-allowed" }
            }
            onClick={selectedContainers.length > 0 ? openDeleteContainer : null}
          >
            {t.cleaning.text_remove_from_list}
          </span>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.list}>
            {disableScan && !cleanerBatch._id ? (
              <p className={styles.emptyList}>{t.cleaning.text_no_scanned}</p>
            ) : null}
            {!containers.length && !disableScan ? (
              waitingToScan && (
                <div>
                  <p className={styles.noScannedCodes}>
                    {t.cleaning.text_waiting}
                  </p>
                  <Loading className={styles.loader} width={50} height={50} />
                </div>
              )
            ) : (
              <div>
                {containers.map((item, index) => (
                  <div className={styles.item} key={item._id}>
                    <p>
                      {index + 1}. {item.uniqueContainerId}
                    </p>
                    <QrGenerator
                      id={item._id}
                      uniqueId={item.uniqueContainerId}
                      name={item.name}
                      material={item.material}
                      batchId={item.uniqueBatchId}
                      size={item.size}
                      volume={item.volume}
                    />
                    <Checkbox
                      customClass="checkboxFix"
                      label=""
                      checked={selectedContainers.includes(item._id)}
                      onChange={() => handleCheckedContainers(item._id)}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanningContainers;
