import React, { useEffect, useState } from "react";
import styles from "./restaurantsList.module.scss";
import Button from "../../button/index";
import restaurantManagementImg from "../../../_assets/icons/restaurantManagementImg.svg";
import SearchBar from "../../searchBar/index";
import RestaurantsTable from "../restaurantsTable/index";
import AddRestaurant from "./../addRestaurant/index";
import FormModal from "../../modal/formModal";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import { modalActions } from "../../../_store/actions/modal.actions";
import useDebounce from "../../../_utils/debounce";
import { termRegex } from "../../../_utils/utils";
import t from "../../../_config/constants";
import DownloadData from "./downloadData";
import Pagination from "../../pagination";
import OrderingChannelModal from "../../orderingChannelModal";
import StoreTagsModal from "../../storeTagsModal";
const RestaurantsList = ({
  refreshList,
  onSelectRestaurant,
  selectedRestaurantId,
  setActiveTab,
  activeTab,
}) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [addedNewRestaurant, setAddedNewRestaurant] = useState(1);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [numberOfRestaurants, setNumberOfRestaurants] = useState(0);
  const [sortConfig, setSortConfig] = useState({});

  const [filterData, setFilterData] = useState({
    searchTerm: "",
    selectedValue: "",
  });

  const debouncedSearchTerm = useDebounce(filterData.searchTerm, 500);

  const addRestaurantHandler = () => {
    setShowAddForm(true);
  };

  const closeAddRaestaurantForm = () => {
    setShowAddForm(false);
    setAddedNewRestaurant((prev) => (prev === 1 ? 2 : 1));
  };
  const closeForm = () => {
    setShowAddForm(false);
  };
  const onChangeTerm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterData((prevState) => ({ ...prevState, [name]: value }));
  };

  const getFilteredRestaurants = async (
    term,
    selectValue,
    id,
    subregionId,
    pageNumber,
    sortConfig = { createdAt: -1 }
  ) => {
    setLoadingData(true);
    let filter = [{ archived: { $ne: true } }];

    if (id && id !== "*") {
      filter.push({
        regionId: id,
      });
    }

    if (subregionId && subregionId !== "*") {
      filter.push({
        subregionId,
      });
    }
    if (term) {
      filter.push({
        $or: [
          { name: { $regex: `${termRegex(term)}`, $options: "i" } },
          { email: { $regex: `${termRegex(term)}`, $options: "i" } },
          { fullName: { $regex: `${termRegex(term)}`, $options: "i" } },
        ],
      });
    }
    let data = await dispatch(
      mainActions.run("profiles", "restaurant", "page", {
        filter,
        pageSize,
        pageNumber,
        sort: sortConfig,
      })
    );
    if (!data) data = { rows: [], total: 0 };
    data.rows.map((restaurant) =>
      restaurant.hasCard === undefined ? (restaurant.hasCard = false) : null
    );
    setFilteredRestaurants(data.rows);
    setNumberOfRestaurants(data.total);
    setLoadingData(false);
  };
  useEffect(() => {
    setPageNumber(0);
  }, [debouncedSearchTerm, store.auth && store.auth.user]);
  useEffect(() => {
    getFilteredRestaurants(
      debouncedSearchTerm,
      filterData.selectedValue,
      store.auth.user.regionId,
      store.auth.user.subregionId,
      pageNumber,
      sortConfig
    );
  }, [
    debouncedSearchTerm,
    filterData.selectedValue,
    store.auth && store.auth.user,
    refreshList,
    addedNewRestaurant,
    pageNumber,
    sortConfig,
  ]);

  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };
  const changeSortConfig = (data) => {
    if (data) {
      const { key, direction } = data;
      setSortConfig({ [key]: direction === "ascending" ? 1 : -1 });
    }
  };
  return (
    <div className={styles.wrapper}>
      {showAddForm && (
        <FormModal
          show={showAddForm}
          handleClose={closeForm}
          content={<AddRestaurant handleClose={closeAddRaestaurantForm} />}
          size="xxlg"
        />
      )}
      <div className={styles.tabs}>
        <p
          className={
            activeTab === "single" ? styles.tabActive : styles.tabInactive
          }
          onClick={() => setActiveTab("single")}
        >
          {t.restaurant.text_restaurats}
        </p>
        <p
          className={
            activeTab === "groups" ? styles.tabActive : styles.tabInactive
          }
          onClick={() => setActiveTab("groups")}
        >
          {t.restaurant.text_groups}
        </p>
        <p
          className={
            activeTab === "archived" ? styles.tabActive : styles.tabInactive
          }
          onClick={() => setActiveTab("archived")}
        >
          {t.restaurant.text_archived_stores}
        </p>
      </div>
      <div className={styles.header}>
        <div className={styles.buttons}>
          <Button
            label={t.button.text_add_restaurant}
            btnClass="btnNormalCasal"
            disabled={false}
            onClick={addRestaurantHandler}
          />
          <Button
            label={t.button.text_add_ordering}
            btnClass="btnNormalCasalLight"
            disabled={false}
            onClick={() =>
              dispatch(
                modalActions.openModal(<OrderingChannelModal />, "", "lg")
              )
            }
          />
          <Button
            label={t.button.text_add_tag}
            btnClass="btnNormalCasalLight"
            disabled={false}
            onClick={() =>
              dispatch(modalActions.openModal(<StoreTagsModal />, "", "lg"))
            }
          />
        </div>

        <div className={styles.restaurantsNumber}>
          <div>
            <span>{numberOfRestaurants}</span>
            <img src={restaurantManagementImg} />
          </div>
          <span>{t.restaurant.text_restaurats}</span>
        </div>
      </div>
      <h2>{t.restaurant.text_list_of_restaurants}</h2>
      <div className={styles.searchAndFilter}>
        <SearchBar
          customClass="restaurantSearch"
          placeholder={t.restaurant.text_search_restaurants}
          name="searchTerm"
          onChange={onChangeTerm}
          disabled={loadingData}
        />
        <DownloadData
          disabled={!numberOfRestaurants}
          regionId={store.auth.user.regionId}
        />
      </div>
      <div className={styles.tableWrapper}>
        <RestaurantsTable
          restaurants={filteredRestaurants}
          onSelectRestaurant={onSelectRestaurant}
          selectedRestaurantId={selectedRestaurantId}
          loadingData={loadingData}
          changeSortConfig={changeSortConfig}
        />
      </div>
      <div className={styles.pagination}>
        <Pagination
          handlePageClick={onPageChange}
          pageCount={Math.ceil(numberOfRestaurants / pageSize)}
          forcePage={pageNumber}
          loading={loadingData}
        />
      </div>
    </div>
  );
};

export default RestaurantsList;
