import React, { useEffect, useState } from "react";
import styles from "./cleanersList.module.scss";
import CleanersTable from "../cleanersTable/index";
import Button from "../../button/index";
import cleaningImg from "../../../_assets/icons/cleaning.svg";
import SearchBar from "../../searchBar/index";
import AddCleaner from "./../addCleaner/index";
import FormModal from "../../modal/formModal";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import useDebounce from "../../../_utils/debounce";
import { termRegex } from "../../../_utils/utils";
import Pagination from "../../pagination";
import t from "../../../_config/constants";
const CleanersList = (props) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [addedNewCleaner, setAddedNewCleaner] = useState(1);
  const [filteredCleaners, setFilteredCleaners] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [update, setUpdate] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [numberOfCleaners, setNumberOfCleaners] = useState(0);
  const [sortConfig, setSortConfig] = useState({});

  const [filterData, setFilterData] = useState({
    searchTerm: "",
    selectedValue: "",
  });

  const debouncedSearchTerm = useDebounce(filterData.searchTerm, 500);

  const addCleanerHandler = () => {
    setShowAddForm(true);
  };

  const closeAddCleanerForm = () => {
    setShowAddForm(false);
    setAddedNewCleaner((prev) => (prev === 1 ? 2 : 1));
  };
  const closeForm = () => {
    setShowAddForm(false);
  };

  const onChangeTerm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterData((prevState) => ({ ...prevState, [name]: value }));
  };

  const getCount = async (item) => {
    let result = await dispatch(
      mainActions.run("data", "container", "count", {
        filter: { state: "cleaner", cleaner_id: item._id },
      })
    );
    let list = [...filteredCleaners];
    list.find((el) => el === item).containerAmount = result;
    setFilteredCleaners([...list]);
  };

  const getFilteredCleaners = async (
    term,
    selectValue,
    id,
    pageNumber,
    sortConfig = { createdAt: -1 }
  ) => {
    setLoadingData(true);
    let filter = [];
    if (id && id !== "*") {
      filter.push({
        regionId: id,
      });
    }

    if (term) {
      filter.push({
        $or: [
          { email: { $regex: `${termRegex(term)}`, $options: "i" } },
          { fullName: { $regex: `${termRegex(term)}`, $options: "i" } },
        ],
      });
    }

    let data = await dispatch(
      mainActions.run("profiles", "cleaner", "page", {
        filter,
        pageSize,
        pageNumber,
        sort: sortConfig,
      })
    );
    if (!data) data = { rows: [], total: 0 };

    setFilteredCleaners(data.rows);
    setNumberOfCleaners(data.total);
    setLoadingData(false);
    setUpdate(update === 1 ? 2 : 1);
  };
  useEffect(() => {
    setPageNumber(0);
  }, [debouncedSearchTerm, store.auth && store.auth.user]);
  useEffect(() => {
    filteredCleaners.map((el) => getCount(el));
  }, [update]);
  useEffect(() => {
    setLoadingData(true);
    getFilteredCleaners(
      debouncedSearchTerm,
      filterData.selectedValue,
      store.auth.user.regionId,
      pageNumber,
      sortConfig
    );
  }, [
    debouncedSearchTerm,
    filterData.selectedValue,
    store.auth && store.auth.user,
    props.cleanerIsDeleted,
    props.refreshList,
    addedNewCleaner,
    pageNumber,
    sortConfig,
  ]);
  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };
  const onSelectCleaner = (id) => {
    props.onSelectCleaner(id);
  };
  const changeSortConfig = (data) => {
    if (data) {
      const { key, direction } = data;
      setSortConfig({ [key]: direction === "ascending" ? 1 : -1 });
    }
  };
  return (
    <div className={styles.wrapper}>
      {showAddForm && (
        <FormModal
          show={showAddForm}
          handleClose={closeForm}
          content={<AddCleaner handleClose={closeAddCleanerForm} />}
          size="xlg"
        />
      )}
      <div className={styles.header}>
        <Button
          label={t.button.text_add_cleaner}
          btnClass="btnNormalCasal"
          disabled={false}
          onClick={addCleanerHandler}
        />
        <div className={styles.icon}>
          <div>
            <span>{numberOfCleaners}</span>
            <img src={cleaningImg} alt="Cleaning img" />
          </div>
          <span>{t.cleaner.text_cleaners}</span>
        </div>
      </div>
      <h2>{t.cleaner.text_list_of_cleaners}</h2>
      <div className={styles.searchAndFilter}>
        <SearchBar
          customClass="restaurantSearch"
          placeholder={t.cleaner.text_search_cleaner}
          name="searchTerm"
          onChange={onChangeTerm}
          disabled={loadingData}
        />
      </div>
      <div className={styles.tableWrapper}>
        <CleanersTable
          cleanersList={filteredCleaners}
          onSelectCleaner={onSelectCleaner}
          selectedCleanerId={props.selectedCleanerId}
          loadingData={loadingData}
          changeSortConfig={changeSortConfig}
        />
      </div>
      <div className={styles.pagination}>
        <Pagination
          handlePageClick={onPageChange}
          pageCount={Math.ceil(numberOfCleaners / pageSize)}
          forcePage={pageNumber}
          loading={loadingData}
        />
      </div>
    </div>
  );
};

export default CleanersList;
