import React, { useState } from "react";
import ReturnedList from "../../_components/report/returnedList";
import OverdueList from "../../_components/report/overdueList";
import styles from "./report.module.scss";

const Report = () => {
  const [activeTab, setActiveTab] = useState("returned");
  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.tabs}>
          <p
            className={
              activeTab === "returned" ? styles.tabActive : styles.tabInactive
            }
            onClick={() => {
              setActiveTab("returned");
            }}
          >
            Containers at Locations
          </p>
          <p
            className={
              activeTab === "overdue" ? styles.tabActive : styles.tabInactive
            }
            onClick={() => {
              setActiveTab("overdue");
            }}
          >
            Overdue Containers
          </p>
          <p
            className={
              activeTab === "charged" ? styles.tabActive : styles.tabInactive
            }
            onClick={() => {
              setActiveTab("charged");
            }}
          >
            Charged Containers
          </p>
        </div>
        <div className={styles.content}>
          {activeTab === "returned" ? (
            <ReturnedList />
          ) : activeTab === "overdue" ? (
            <OverdueList type="overdueContainer" />
          ) : (
            <OverdueList type="chargedOverdueContainer" />
          )}
        </div>
      </div>
    </div>
  );
};
export default Report;
