import React from "react";
import styles from "./report.module.scss";
import Button from "../../button";
import { isObjectEmpty } from "../../../_utils/utils";
import t from "../../../_config/constants";
import { qrToPNG } from "../qrCode/qrToPng";
const Report = ({ dropoffLocation }) => {
  const downloadPng = () => {
    qrToPNG(
      dropoffLocation._id,
      dropoffLocation.name,
      dropoffLocation.location,
      dropoffLocation.coordinates
    );
  };
  return (
    <div className={styles.download}>
      <Button
        disabled={!isObjectEmpty(dropoffLocation) ? false : true}
        label={t.common.text_download_qr_code}
        btnClass="btnWhiteBackground"
        onClick={(e) => downloadPng()}
      />
    </div>
  );
};
export default Report;
