import React, { useState, useEffect } from "react";
import styles from "./regionPostalCodes.module.scss";
import Button from "../../button";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import Loading from "../../loading";
import t from "../../../_config/constants";
import { isObjectEmpty } from "../../../_utils/utils";
import Input from "../../input";
const RegionPostalCodes = ({
  onRegionClick,
  onSubRegionClick,
  onAdminClick,
  onNotificationsClick,
  onPostalCodesClick,
  activeTab,
}) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [selected, setSelected] = useState({});
  const [selectedCode, setSelectedCode] = useState("");
  const [newPostalCode, setNewPostalCode] = useState("");
  const [loading, setLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [allPostalCodes, setAllPostalCodes] = useState([]);
  useEffect(() => {
    if (
      store.main &&
      store.main.data &&
      store.main.data.region &&
      store.main.data.region.list
    ) {
      setRegions(store.main.data.region.list);
    }
    setLoading(false);
  }, [store.main.data && store.main.data.region]);
  useEffect(() => {
    const allPostalCodes = [];
    regions.forEach((region) => {
      if (region.postal_codes) allPostalCodes.push(...region.postal_codes);
    });
    setAllPostalCodes(allPostalCodes);
  }, [regions]);
  const getAllRegions = async () => {
    let response = await dispatch(mainActions.run("data", "region", "all", {}));
    setRegions(response);

    setLoading(false);
  };
  const handleChange = (e) => {
    e.preventDefault();
    let { value } = e.target;
    setError(false);
    setNewPostalCode(value.trim());
  };

  const handleCancel = () => {
    setNewPostalCode("");
    setSelectedCode("");
    setError(false);
  };
  const validateData = (data) => {
    let index = allPostalCodes.findIndex((code) => code === data.toUpperCase());
    if (index !== -1) {
      setError(true);
      return false;
    } else {
      return true;
    }
  };
  const handleDelete = async () => {
    setDisabled(true);
    let codes = selected.postal_codes.filter((code) => code !== newPostalCode);
    let response = await dispatch(
      mainActions.run("data", "region", "update", {
        _id: selected._id,
        postal_codes: codes,
      })
    );
    if (response) setSelected(response);

    getAllRegions();
    handleCancel();
    setDisabled(false);
  };
  const handleSubmit = async () => {
    if (validateData(newPostalCode)) {
      setDisabled(true);
      const postal_codes = selected.postal_codes ?? [];
      let index = postal_codes.findIndex((code) => code === newPostalCode);
      if (index !== -1) setDisabled = true;
      let response = await dispatch(
        mainActions.run("data", "region", "update", {
          _id: selected._id,
          postal_codes: [...postal_codes, newPostalCode.toUpperCase()],
        })
      );
      if (response) setSelected(response);

      getAllRegions();
      handleCancel();
      setDisabled(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.header}>
          <p
            className={
              activeTab === "region" ? styles.tabActive : styles.tabInactive
            }
            onClick={onRegionClick}
          >
            {t.admin.regions.text_regions}
          </p>
          <p
            className={
              activeTab === "subregion" ? styles.tabActive : styles.tabInactive
            }
            onClick={onSubRegionClick}
          >
            {t.admin.regions.text_subregions}
          </p>
          <p
            className={
              activeTab === "codes" ? styles.tabActive : styles.tabInactive
            }
            onClick={onPostalCodesClick}
          >
            {t.admin.regions.text_region_postal_codes}
          </p>
          <p
            className={
              activeTab === "notifications"
                ? styles.tabActive
                : styles.tabInactive
            }
            onClick={onNotificationsClick}
          >
            {t.admin.regions.text_region_notifications}
          </p>
          <p
            className={
              activeTab === "admin" ? styles.tabActive : styles.tabInactive
            }
            onClick={onAdminClick}
          >
            {t.admin.admins.text_admins}
          </p>
        </div>
        <h2>{t.admin.regions.text_list_of_regions}</h2>
        {loading && <Loading width={50} height={50} />}
        {regions && regions.length > 0 && (
          <div className="custom-table">
            <table>
              <thead>
                <tr className="tb-head-th">
                  <th>Name</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {regions?.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <tr
                      onClick={() => {
                        setSelected(item);
                        handleCancel();
                      }}
                      className={
                        selected._id === item._id ? styles.selected : ""
                      }
                    >
                      <td>{item.name}</td>
                      <td>{item.description}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {!loading && regions.length < 1 && (
          <p>{t.admin.regions.text_no_regions}</p>
        )}
      </div>
      <div className={styles.right}>
        {!isObjectEmpty(selected) ? (
          <>
            <h2>
              {selected && selected.country === "United States"
                ? "Zip code"
                : t.admin.regions.text_region_postal_codes}
            </h2>
            {selected && selected.country === "United States" && (
              <h3>(Ranges)</h3>
            )}
            {selected?.postal_codes?.length > 0 ? (
              selected?.country === "United States" ? (
                <div className={styles.usaContent}>
                  {selected?.postal_codes?.map((item, ind) => (
                    <div key={ind} className={styles.code}>
                      {ind + 1}. {item.from}-{item.to}
                    </div>
                  ))}
                </div>
              ) : (
                <div className={styles.content}>
                  {selected?.postal_codes?.map((item, ind) => (
                    <div
                      key={ind}
                      className={
                        styles[selectedCode === item ? "activeCode" : "code"]
                      }
                      onClick={() => {
                        setNewPostalCode(item);
                        setSelectedCode(item);
                      }}
                    >
                      {ind + 1}. {item}
                    </div>
                  ))}
                </div>
              )
            ) : (
              <p>
                {selected && selected.country === "United States"
                  ? t.admin.regions.text_no_zip_codes
                  : t.admin.regions.text_no_postal_codes}
              </p>
            )}

            {selected && selected.country !== "United States" && (
              <>
                <h3>{t.admin.regions.text_region_add_postal_code}</h3>
                <div>
                  <Input
                    label={"New Postal Code (FSA)"}
                    error={error}
                    name="newPostalCode"
                    inputClass="inputLogin"
                    type="text"
                    onChange={handleChange}
                    errorMsg={"Postal code already exists"}
                    value={newPostalCode.trim()}
                    disabled={selectedCode}
                  />
                </div>
                <div className={styles.options}>
                  {!selectedCode ? (
                    <Button
                      btnClass="btnNormalCasal"
                      label={t.button.text_add}
                      onClick={handleSubmit}
                      disabled={
                        disabled ||
                        (selected?.country === "Canada" &&
                          newPostalCode.length !== 3) ||
                        selectedCode
                      }
                    />
                  ) : (
                    <Button
                      btnClass="btnNormal"
                      label={t.button.text_delete}
                      onClick={handleDelete}
                      disabled={!selectedCode || disabled}
                    />
                  )}
                  <Button
                    btnClass="btnWhiteBackground"
                    label={t.button.text_cancel}
                    onClick={handleCancel}
                    disabled={(!newPostalCode && !selectedCode) || disabled}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <div className={styles.noData}>
            <span>{t.admin.notifications.text_select_guideline} </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegionPostalCodes;
