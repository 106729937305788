import React, { useEffect, useState } from "react";
import Button from "../../button";
import Input from "../../input";
import styles from "./singleGroup.module.scss";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import ConfirmModal from "../../modal/confirmModal";
import toastService from "../../../_services/toast.service";
import t from "../../../_config/constants";
import { isObjectEmpty } from "../../../_utils/utils";
import { modalActions } from "../../../_store/actions/modal.actions";
import AddGroup from "../addGroup";
import Avatar from "../../avatar";
const SingleGroup = ({ groupId, refreshList, refreshGroup }) => {
  const [group, setGroup] = useState({});
  const [showModalDelete, setShowModalDelete] = useState(false);
  const dispatch = useDispatch();
  const [loadingImg, setLoadingImg] = useState(true);

  const getSelectedGroup = async (id) => {
    const response = await dispatch(
      mainActions.run("data", "restaurantGroup", "id", id)
    );
    setGroup(response);
    if (!response.avatarURL) setLoadingImg(false);
  };

  useEffect(() => {
    if (groupId) {
      getSelectedGroup(groupId);
      setLoadingImg(true);
    }
  }, [groupId, refreshGroup]);

  const openDeleteModal = () => {
    setShowModalDelete(true);
  };

  const handleDelete = async (answer) => {
    if (answer) {
      let response = await dispatch(
        mainActions.run("data", "restaurantGroup", "delete", {
          _id: groupId,
        })
      );

      if (response) {
        toastService.show(
          "success",
          t.restaurant.text_successfully_deleted_group
        );
        refreshList();
        setGroup({});
        setShowModalDelete(false);
      }
    } else {
      setShowModalDelete(false);
    }
  };

  return (
    <>
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={handleDelete}
          modalData={{}}
          question={t.restaurant.text_modal_delete_group}
        />
      )}
      {isObjectEmpty(group) && (
        <div className={styles.noData}>
          <span>{t.restaurant.text_select_guideline_group}</span>
        </div>
      )}

      {!isObjectEmpty(group) && (
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Button
              label={t.button.text_edit}
              btnClass="btnWhiteBackground"
              disabled={false}
              onClick={() => {
                dispatch(
                  modalActions.openModal(
                    <AddGroup
                      editing={true}
                      group={group}
                      refreshTableData={refreshList}
                    />
                  )
                );
              }}
            />
            <Button
              label={t.button.text_delete}
              btnClass="btnWhiteBackground"
              disabled={false}
              onClick={openDeleteModal}
            />
          </div>
          <div className={styles.restaurantInfos}>
            <Avatar
              imgUrl={group.avatarURL}
              loadingImg={loadingImg}
              setLoadingImg={setLoadingImg}
            />
            <h2>{group.name || ""}</h2>
            <form>
              <div className={styles.inputGroup}>
                <Input
                  label={"Contact Name"}
                  name="contactName"
                  inputClass="inputForm"
                  type="text"
                  value={group.contactName}
                  disabled={true}
                />
              </div>{" "}
              <div className={styles.inputGroup}>
                <Input
                  label={t.common.input.text_input_email_address}
                  name="email"
                  inputClass="inputForm"
                  type="email"
                  value={group?.email?.trim()}
                  disabled={true}
                />
              </div>
              {group?.parentRestaurant?.name && (
                <div className={styles.inputGroup}>
                  <Input
                    label={"Parent Store"}
                    inputClass="inputForm"
                    type="text"
                    value={group?.parentRestaurant?.name}
                    disabled={true}
                  />
                </div>
              )}
              <div className={styles.inputGroup}>
                <Input
                  label={t.admin.regions.text_charging}
                  inputClass="inputForm"
                  type="text"
                  value={
                    group.chargingOption === "chargeParent"
                      ? "Charge parent store"
                      : "Charge stores separately"
                  }
                  disabled={true}
                />
              </div>{" "}
            </form>
            <h3>Stores in group</h3>
            <div className="custom-table">
              <table>
                <thead>
                  <tr className="tb-head-th">
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {group.restaurants.map((item, index) => (
                    <React.Fragment key={item._id}>
                      <tr>
                        <td>
                          <span> {index + 1 + ". "}</span>
                          {item.name}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleGroup;
