import React from 'react';
import Input from '../input';
import Button from '../button';
import styles from './emailRecipientInput.module.scss'
import t from '../../_config/constants'

const EmailRecipientInput = ({ sending, handleSend, title, recipient, setRecipient }) => {
    const handleChange = e => {
        const { value } = e.target;
        setRecipient(value);
    }
    return (
        <div className={styles.wrapper}>
            <p>{title}</p>
            <form onSubmit={handleSend}>
                <Input
                    label="Send to:"
                    inputClass="inputForm"
                    type="email"
                    value={recipient.trim()}
                    placeholder={t.auth.text_markup_mail}
                    onChange={handleChange}
                />
                <Button btnClass="btnNormalCasal" label={t.button.text_send_via_email} type="submit" disabled={!recipient || sending} />
            </form>
        </div>
    )
}

export default EmailRecipientInput