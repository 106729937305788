import { phone as phoneValidator } from "phone";
export const formatPhone = (number) => {
  const { isValid, phoneNumber } = phoneValidator(number);
  if (isValid) {
    return phoneNumber;
  }
  return number;
};
export const validatePhoneNumber = (number) => {
  const { isValid } = phoneValidator(number);
  return isValid;
};
