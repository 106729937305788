import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../../../_store/actions/main.actions";
import ListPurchased from "../list/listPurchased";
const PurchasedList = ({ id }) => {
  const [currentData, setCurrentData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const dispatch = useDispatch();
  const getCurrentData = async (id) => {
    let filter = [];
    if (id) {
      filter.push({
        state: { $eq: "purchased" },
        user_id: { $eq: id },
      });
    }
    let response = await dispatch(
      mainActions.run("data", "container", "all", { filter })
    );
    setCurrentData(response);
    setLoadingData(false);
  };
  useEffect(() => {
    if (id) {
      getCurrentData(id);
    }
  }, [id]);

  return (
    <>
      <ListPurchased data={currentData} loadingData={loadingData} />
    </>
  );
};
export default PurchasedList;
