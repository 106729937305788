import React, { useState, useEffect } from "react";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import CustomSelect from "./customSelect";
import ReplenishmentForm from "./form";
import ReplenishmentHistory from "./history";
import Button from "./../../button";
import Selector from "./../../selector";
import Input from "../../input";
import DatePickerComp from "../../datePicker";
import ConfirmModal from "../../modal/confirmModal";

import { makeSelectMap } from "../../../_utils/utils";
import { mainActions } from "../../../_store/actions/main.actions";
import { checkAvailable } from "../../../_services/container.service";
import toastService from "../../../_services/toast.service";
import t from "../../../_config/constants";

import addIcon from "./../../../_assets/icons/add.svg";
import close from "./../../../_assets/icons/close.svg";

import styles from "./createReplenishmentForm.module.scss";

const CreateReplenishmentForm = ({
  onTransferClick,
  onReplenishmentClick,
  activeTab,
}) => {
  const [regionId, setRegionId] = useState("");
  const [subregionId, setSubregionId] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [hideLeftSide, setHideLeftSide] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedContainers, setSelectedContainers] = useState([]);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [containerOptions, setContainerOptions] = useState([]);
  const [update, setUpdate] = useState(1);
  const [errorMsg, setErrorMsg] = useState(false);
  const [countError, setCountError] = useState(false);
  const [secureCodeDisabled, setSecureCodeDisabled] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [templateForm, setTemplateForm] = useState();
  const [restaurantIds, setRestaurantIds] = useState();
  const [error, setError] = useState({
    restaurant: false,
    date: false,
    containers: false,
  });
  const [data, setData] = useState({
    restaurantId: "",
    name: "",
    phone: "",
    address: "",
    deadlineDate: "",
    parsedDate: "",
    containers: [],
    secureCode: "",
    template: "new",
  });
  useEffect(() => {
    const fetchTemplate = async () => {
      let previousForm = await dispatch(
        mainActions.run("data", "replenishmentForm", "get-latest", {
          filter: { restaurantId: data.restaurantId },
        })
      );
      setTemplateForm(previousForm);
    };
    if (data.restaurantId) fetchTemplate();
  }, [data.restaurantId]);
  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  const templatesOptions = [
    { value: "new", label: "Create a new form" },
    { value: "repeat", label: "Repeat previous form" },
  ];
  const getAllContainerTypes = async (regionId, subregionId) => {
    let filter = [];
    if (regionId && regionId !== "*") {
      filter.push({
        regionId,
      });
    }
    if (subregionId && subregionId !== "*") {
      filter.push({
        subregionId,
      });
    }
    let data = await dispatch(
      mainActions.run("data", "containerType", "all", { filter })
    );
    let arr = makeSelectMap(data);
    setContainerOptions(arr);
  };

  useEffect(() => {
    const getAllRestaurnts = async (id, subregionId) => {
      let filter = [];
      if (id && id !== "*") {
        filter.push({
          regionId: id,
        });
      }
      if (subregionId && subregionId !== "*") {
        filter.push({
          subregionId,
        });
      }
      let data = await dispatch(
        mainActions.run("profiles", "restaurant", "all", { filter })
      );
      setRestaurants(data);
      let options = makeSelectMap(data);
      let restaurantIds = options.map((item) => item.value);
      setRestaurantIds(restaurantIds);
      setRestaurantOptions(options);
    };
    clearData();
    getAllRestaurnts(store.auth.user.regionId, store.auth.user.subregionId);
  }, [store.auth && store.auth.user]);

  useEffect(() => {
    if (showForm) {
      if (regionId !== "*")
        getAllContainerTypes(store.auth.user.regionId, subregionId);
    }
  }, [showForm]);

  useEffect(() => {
    if (regionId && regionId !== "*") {
      getAllContainerTypes(regionId, subregionId);
    } else {
      setContainerOptions([]);
    }
  }, [regionId, subregionId]);

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };

  const handleChangeDate = (e) => {
    setErrorMsg(false);
    setError((prev) => ({ ...prev, date: false }));
    let date = e.getTime();
    setData((data) => ({ ...data, deadlineDate: date }));
    setData((data) => ({
      ...data,
      parsedDate: moment(date).format("MM/DD/YYYY"),
    }));
  };

  const handleSelectRestaurant = (e) => {
    if (!showForm || !secureCodeDisabled) {
      setShowForm(true);
      setSecureCodeDisabled(true);
    }
    setErrorMsg(false);
    setError((prev) => ({ ...prev, restaurant: false }));
    let restaurant = restaurants.find((item) => item._id === e.value);

    if (
      regionId !== restaurant.regionId ||
      subregionId !== restaurant.subregionId
    ) {
      setSelectedContainers([]);
      setRegionId(restaurant.regionId);
      setSubregionId(restaurant.subregionId);
      setData((data) => ({
        ...data,
        restaurantId: restaurant._id,
        name: restaurant.name,
        phone: restaurant.phone,
        address: restaurant.location,
        containers: [],
        template: "new",
      }));
    } else {
      setData((data) => ({
        ...data,
        restaurantId: restaurant._id,
        name: restaurant.name,
        phone: restaurant.phone,
        address: restaurant.location,
        template: "new",
      }));

      if (containerOptions.length === 0) {
        getAllContainerTypes(regionId, subregionId);
      }
    }
  };

  const handleSelectTemplate = async (e) => {
    if (e.value === "repeat" && data.restaurantId) {
      if (templateForm.containers) {
        const containerList = [];
        templateForm.containers.forEach((item) => {
          const containerOption = containerOptions.find(
            (option) => option.value + "" === item.containerTypeId
          );
          if (containerOption) {
            containerList.push({
              containerType: containerOption,
              amount: item.amount,
              validate: false,
            });
          }
        });
        setSelectedContainers(containerList);
      }
    }
    setData((prev) => ({ ...prev, template: e.value }));
  };
  const handleAddContainerType = (containerType) => {
    setErrorMsg(false);
    setError((prev) => ({ ...prev, containers: false }));
    if (
      !selectedContainers.find((item) => item.containerType === containerType)
    ) {
      setSelectedContainers((data) => [
        ...data,
        {
          containerType: containerType,
          amount: "",
          validate: false,
          containerIds: [],
        },
      ]);
    }
  };

  const handleChange = (e) => {
    setErrorMsg(false);
    setCountError(false);
    const { name, value } = e.target;
    let containersList = [...selectedContainers];

    let container = containersList.find(
      (item) => item.containerType.value === name
    );
    container.amount = value;
    container.validate = false;
    setSelectedContainers(containersList);
  };

  const handleChangeSecureCode = (e) => {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  const handleClose = (item) => {
    setErrorMsg(false);
    setCountError(false);

    let selected = selectedContainers.filter((container) => container !== item);
    setSelectedContainers(selected);
    let containersData = data.containers.filter(
      (container) => container.containerTypeId !== item.containerType.value
    );
    setData((data) => ({ ...data, containers: containersData }));
  };

  const clearData = () => {
    setData({
      restaurantId: "",
      name: "",
      phone: "",
      address: "",
      deadlineDate: "",
      parsedDate: "",
      containers: [],
      secureCode: "",
      template: "new",
    });
    setError({
      restaurant: false,
      date: false,
      containers: false,
    });
    setShowForm(false);
    setSelectedContainers([]);
    setRegionId(store.auth.user.regionId);
    setSubregionId(store.auth.user.subregionId);
    setUpdate((prevState) => (prevState === 1 ? 2 : 1));
  };

  const validateAmount = async (item) => {
    setCountError(false);
    setErrorMsg(false);
    setError((prev) => ({ ...prev, containers: false }));
    const dataVal = {
      containerTypeId: item.containerType.value,
      name: item.containerType.label,
      amount: parseInt(item.amount),
    };
    let response = await checkAvailable(dataVal);

    if (response.result) {
      let containersList = [...selectedContainers];
      let container = containersList.find((listItem) => listItem === item);
      container.validate = true;
      container.containerIds = response.ids;

      setSelectedContainers(containersList);
      let containersData = selectedContainers.map((item) => ({
        containerTypeId: item.containerType.value,
        containerTypeName: item.containerType.label,
        amount: item.amount,
        ids: item.containerIds,
      }));
      setData((data) => ({ ...data, containers: containersData }));
    } else {
      toastService.show("error", response.message);
    }
  };

  const validateForm = () => {
    setError({
      restaurant: !data.restaurantId,
      date: !data.deadlineDate,
      containers: !data.containers.length,
    });
    if (selectedContainers.find((item) => item.validate === false)) {
      setCountError(true);
      return false;
    }
    return data.restaurantId && data.deadlineDate && data.containers.length > 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (secureCodeDisabled) {
      if (validateForm()) {
        delete data.template;
        setButtonDisabled(true);
        let response = await dispatch(
          mainActions.run("data", "replenishmentForm", "new", data)
        );
        if (response) {
          toastService.show("success", t.distribution.text_successfully_added);
          clearData();
          setButtonDisabled(false);
        }
      } else {
        toastService.show(
          "error",
          `${t.common.text_toast_all_fields_required}`
        );
        setErrorMsg(true);
        setButtonDisabled(false);
      }
    } else {
      if (
        (["admin", "master"].includes(store?.auth?.user?.type) &&
          !store?.auth?.user?.secureCodeRequired) ||
        data.secureCode
      ) {
        setButtonDisabled(true);
        let response = await dispatch(
          mainActions.run("data", "replenishmentForm", "update", {
            ...data,
            isDelivered: true,
          })
        );
        if (response) {
          toastService.show("success", t.distribution.text_successfully_added);
          clearData();
          setHideLeftSide(false);
        }
        setButtonDisabled(false);
      } else {
        toastService.show("warning", "Required Secure Code");
      }
    }
  };

  const handleView = (replenishmentData) => {
    setSecureCodeDisabled(false);
    setData(replenishmentData);
    setShowForm(true);
    setHideLeftSide(true);
  };

  const handleSubmitFromTable = async (replenishmentData) => {
    const response = await dispatch(
      mainActions.run("data", "replenishmentForm", "update", {
        ...replenishmentData,
        isDelivered: true,
      })
    );
    if (response) {
      toastService.show("success", t.distribution.text_successfully_added);
      clearData();
    }
    setUpdate((prevState) => (prevState === 1 ? 2 : 1));
  };
  const handleCloseForm = () => {
    clearData();
    setErrorMsg(false);
    setCountError(false);
    setHideLeftSide(false);
    setContainerOptions([]);
    setRegionId(store.auth.user.regionId);
    setSubregionId(store.auth.user.subregionId);
  };

  const openDeleteModal = () => {
    setShowModalDelete(true);
  };

  const handleDelete = async (answer) => {
    if (answer) {
      let response = await dispatch(
        mainActions.run("data", "replenishmentForm", "delete", {
          _id: data._id,
        })
      );
      if (response) {
        toastService.show("success", t.distribution.text_successfully_deleted);
        clearData();
        setButtonDisabled(false);
        setHideLeftSide(false);
        setShowModalDelete(false);
      }
    } else {
      setShowModalDelete(false);
    }
  };

  const handleInputNumber = (e) => {
    const numbers = "0123456789";
    if (!numbers.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className={styles.wrapper}>
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={handleDelete}
          modalData={{}}
          question={t.distribution.text_cancel_delivery_modal}
        />
      )}
      {!hideLeftSide && store?.auth?.user?.type !== "cleaner" && (
        <div className={styles.left}>
          <div className={styles.header}>
            <p
              className={
                activeTab === "replenishment"
                  ? styles.tabActive
                  : styles.tabInactive
              }
              onClick={onReplenishmentClick}
            >
              {t.distribution.text_replenishment_title}
            </p>
            <p
              className={
                activeTab === "transfer" ? styles.tabActive : styles.tabInactive
              }
              onClick={() => {
                onTransferClick();
              }}
            >
              Transfer Containers
            </p>
          </div>
          <div className={styles.options}>
            <Selector
              label={t.distribution.text_find_restaurant}
              placeholder={t.distribution.text_search_restaurant}
              options={restaurantOptions}
              multiselect={false}
              selectedOption={getSelectedValue(
                restaurantOptions,
                data.restaurantId
              )}
              handleChangeSelect={(e) => handleSelectRestaurant(e)}
              selectClassname="selectSecondaryWhite"
              error={error.restaurant}
            />
            <Selector
              label={t.distribution.templates}
              options={templatesOptions}
              multiselect={false}
              selectedOption={getSelectedValue(templatesOptions, data.template)}
              handleChangeSelect={(e) => handleSelectTemplate(e)}
              selectClassname="selectSecondaryWhite"
              disabled={!data.restaurantId}
            />
            <div className={styles.buttons}>
              <CustomSelect
                error={error.containers}
                imgLeft={addIcon}
                label={t.button.text_add_container_type}
                options={containerOptions}
                selectContainer={handleAddContainerType}
                disabled={!showForm || !containerOptions.length}
              />
              <div className={styles.pickDate}>
                <DatePickerComp
                  onChangeDate={(e) => handleChangeDate(e)}
                  imgLeft={addIcon}
                  disabled={!showForm}
                />
                {error.date && <span className={styles.dateError}>*</span>}
              </div>
            </div>
          </div>
          <div className={styles.containers}>
            {selectedContainers &&
              selectedContainers.length > 0 &&
              selectedContainers.map((item) => (
                <div key={item.containerType.value} className={styles.row}>
                  <div className={styles.label}>
                    {item.containerType.label}
                    <img src={close} onClick={() => handleClose(item)}></img>
                  </div>
                  <div className={item.validate ? styles.inputGreen : ""}>
                    <Input
                      placeholder={t.common.text_th_amount}
                      name={item.containerType.value}
                      inputClass="inputBorder"
                      type="number"
                      onChange={handleChange}
                      onKeyPress={handleInputNumber}
                      value={item.amount}
                    />
                  </div>
                  <Button
                    btnClass={
                      item.validate ? "btnWhiteBackground" : "btnNormal"
                    }
                    label="Validate"
                    onClick={item.validate ? null : () => validateAmount(item)}
                  />
                </div>
              ))}
          </div>
          {errorMsg ? (
            <div className={styles.error}>
              <p>{t.common.text_all_fields_required}</p>
            </div>
          ) : null}
          {countError ? (
            <div className={styles.error}>
              <p>{t.distribution.text_validate_amount}</p>
            </div>
          ) : null}
        </div>
      )}
      <div
        className={
          hideLeftSide || store?.auth?.user?.type === "cleaner"
            ? styles.fullScreen
            : styles.right
        }
      >
        {showForm && (
          <ReplenishmentForm
            data={data}
            onSubmit={handleSubmit}
            handleClose={handleCloseForm}
            secureCodeDisabled={secureCodeDisabled}
            handleChangeSecureCode={handleChangeSecureCode}
            buttonDisabled={buttonDisabled}
            cancelDelivery={openDeleteModal}
            userType={store?.auth?.user?.type}
          />
        )}
        {!showForm && (
          <ReplenishmentHistory
            update={update}
            handleView={handleView}
            ids={restaurantIds}
            handleSubmit={handleSubmitFromTable}
          />
        )}
      </div>
    </div>
  );
};

export default CreateReplenishmentForm;
