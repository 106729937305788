import React, { useEffect, useState } from "react";
import styles from "./tableOfDetails.module.scss";
import Loading from "../../../../loading";
import t from "../../../../../_config/constants";
import { FcCheckmark } from "react-icons/fc";
import Input from "../../../../input";
import Button from "../../../../button";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../../../_store/actions/main.actions";
import toastService from "../../../../../_services/toast.service";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";
const TableOfDetails = ({ loading, types, restaurant, setRestaurant }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("membership");
  const [data, setData] = useState({
    pricingWithCleaning: "",
    pricingBasic: "",
    reuseCreditsBasic: "",
    reuseCreditsWithCleaning: "",
  });
  const [containerPricingSettings, setContainerPricingSettings] = useState({});
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setDefaultData(restaurant || {}, types || []);
    setEditing(false);
  }, [activeTab, restaurant, types]);
  const setDefaultData = (restaurant = {}, types = []) => {
    const newObj = {};

    setData({
      pricingBasic: Number(restaurant?.membershipPricing?.basic || 95).toFixed(
        2
      ),
      pricingWithCleaning: Number(
        restaurant?.membershipPricing?.withCleaning || 125
      ).toFixed(2),
      reuseCreditsBasic: Number(
        restaurant?.membershipPricing?.reuseCreditsBasic || 250
      ).toFixed(),
      reuseCreditsWithCleaning: Number(
        restaurant?.membershipPricing?.reuseCreditsWithCleaning || 250
      ).toFixed(),
    });
    if (restaurant.containerSettings) {
      types.forEach((type) => {
        newObj[type._id] = {
          pricing: Number(
            (restaurant.containerSettings[type._id] &&
              restaurant.containerSettings[type._id].pricing) ||
              type.pricing
          ).toFixed(2),
          name: type.name,
        };
      });
    } else {
      types.forEach((type) => {
        newObj[type._id] = { pricing: type.pricing, name: type.name };
      });
    }
    setContainerPricingSettings(newObj);
  };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  };
  const handleChangePricing = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setContainerPricingSettings((prev) => ({
      ...prev,
      [name]: { ...prev[name], pricing: value },
    }));
  };
  const handleInputNumber = (e, wholeNumbers = false) => {
    let numbers = "0123456789";
    !wholeNumbers && (numbers += ".");
    if (!numbers.includes(e.key)) {
      e.preventDefault();
    }
  };
  const updateContainer = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (restaurant._id) {
        setSaving(true);
        const updateData = {};
        if (activeTab === "membership") {
          updateData.membershipPricing = {
            basic: Number(data.pricingBasic).toFixed(2),
            withCleaning: Number(data.pricingWithCleaning).toFixed(2),
            reuseCreditsBasic: Number(data.reuseCreditsBasic).toFixed(),
            reuseCreditsWithCleaning: Number(
              data.reuseCreditsWithCleaning
            ).toFixed(),
          };
        } else {
          const containerSettings = restaurant.containerSettings || {};
          for (const [key, value] of Object.entries(containerPricingSettings)) {
            containerSettings[key] = {
              ...containerSettings[key],
              name: value.name,
              pricing: value.pricing,
            };
          }
          updateData.containerSettings = containerSettings;
        }

        let response = await dispatch(
          mainActions.run("profiles", "restaurant", "update", {
            _id: restaurant._id,
            ...updateData,
          })
        );
        if (response) {
          toastService.show(
            "success",
            t.restaurant.text_successfully_edited_price
          );
          setRestaurant(response);
        }
        setSaving(false);
      }
    }
  };

  const validateForm = () => {
    if (activeTab === "membership") {
      return (
        data.pricingWithCleaning &&
        data.pricingBasic &&
        data.reuseCreditsBasic &&
        data.reuseCreditsWithCleaning
      );
    } else {
      let retVal = true;
      for (const [key, value] of Object.entries(containerPricingSettings)) {
        if (value.pricing !== 0 && !value.pricing) {
          retVal = false;
          break;
        }
      }

      return retVal;
    }
  };

  const handleCancel = (e) => {
    e && e.preventDefault();
    setDefaultData(restaurant || {}, types || []);
    setEditing(false);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <table>
          <thead>
            <tr className={styles.topBar}>
              <th
                colSpan={
                  activeTab === "membership" &&
                  restaurant?.chargingOption === "limitedMembership"
                    ? 2
                    : 1
                }
                className={`${styles.tab} ${
                  activeTab === "membership"
                    ? styles.activeTab
                    : styles.inactiveTab
                }`}
                onClick={() => setActiveTab("membership")}
              >
                Membership Plan
                {activeTab === "membership" && (
                  <div
                    className={styles.lock}
                    onClick={() => {
                      setEditing((prev) => !prev);
                    }}
                  >
                    {editing ? <AiFillUnlock /> : <AiFillLock />}
                  </div>
                )}
                {(restaurant?.chargingOption === "limitedMembership" ||
                  restaurant?.chargingOption === "unlimitedMembership") && (
                  <div className={styles.checkmark}>
                    <FcCheckmark />
                  </div>
                )}
              </th>
              <th
                colSpan={1}
                className={`${styles.tab} ${
                  activeTab === "perUse" ? styles.activeTab : styles.inactiveTab
                }`}
                onClick={() => setActiveTab("perUse")}
              >
                Pricing Per Use
                {activeTab === "perUse" && (
                  <div
                    className={styles.lock}
                    onClick={() => {
                      setEditing((prev) => !prev);
                    }}
                  >
                    {editing ? <AiFillUnlock /> : <AiFillLock />}
                  </div>
                )}
                {restaurant?.chargingOption !== "unlimitedMembership" && (
                  <div className={styles.checkmark}>
                    <FcCheckmark />
                  </div>
                )}
              </th>
            </tr>
            <tr>
              <th>Type</th>
              <th>Price</th>
              {activeTab === "membership" &&
                restaurant?.chargingOption === "limitedMembership" && (
                  <th>Reuse Credits</th>
                )}
            </tr>
          </thead>
          {activeTab === "membership" && !loading && (
            <tbody>
              <tr>
                <td>Cleaning themselves</td>
                <td>
                  <Input
                    name="pricingBasic"
                    inputClass="inputTable"
                    type="number"
                    value={data.pricingBasic}
                    onChange={handleChange}
                    onKeyPress={handleInputNumber}
                    disabled={!editing || saving}
                  />
                </td>
                {activeTab === "membership" &&
                  restaurant?.chargingOption === "limitedMembership" && (
                    <td>
                      <Input
                        name="reuseCreditsBasic"
                        inputClass="inputTable"
                        type="number"
                        value={data.reuseCreditsBasic}
                        onChange={handleChange}
                        onKeyPress={(e) => {
                          handleInputNumber(e, true);
                        }}
                        disabled={!editing || saving}
                      />
                    </td>
                  )}
              </tr>
              <tr>
                <td>Using cleaning service</td>
                <td>
                  <Input
                    name="pricingWithCleaning"
                    inputClass="inputTable"
                    type="number"
                    value={data.pricingWithCleaning}
                    onChange={handleChange}
                    disabled={!editing || saving}
                    onKeyPress={handleInputNumber}
                  />
                </td>
                {activeTab === "membership" &&
                  restaurant?.chargingOption === "limitedMembership" && (
                    <td>
                      <Input
                        name="reuseCreditsWithCleaning"
                        inputClass="inputTable"
                        type="number"
                        value={data.reuseCreditsWithCleaning}
                        onChange={handleChange}
                        disabled={!editing || saving}
                        onKeyPress={(e) => {
                          handleInputNumber(e, true);
                        }}
                      />
                    </td>
                  )}
              </tr>
            </tbody>
          )}
          {activeTab === "perUse" && !loading && (
            <tbody>
              {!loading &&
                types &&
                types.length > 0 &&
                types.map((item) => (
                  <tr key={item._id}>
                    <td>{item.name}</td>
                    <td>
                      <Input
                        name={item._id}
                        inputClass="inputTable"
                        type="number"
                        value={containerPricingSettings[item._id].pricing}
                        onChange={handleChangePricing}
                        onKeyPress={handleInputNumber}
                        disabled={!editing || saving}
                        error={!containerPricingSettings[item._id].pricing}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </table>

        {loading && (
          <div className={styles.loading}>
            <Loading width={50} height={50} />
          </div>
        )}
      </div>
      {editing && (
        <div className={styles.options}>
          <Button
            label={t.button.text_cancel}
            btnClass="btnWhiteBackground"
            type="btn"
            onClick={handleCancel}
            disabled={saving || loading}
          />
          <Button
            btnClass="btnNormalCasal"
            label={t.button.text_update}
            type="submit"
            onClick={updateContainer}
            disabled={saving || loading}
          />
        </div>
      )}
    </div>
  );
};

export default TableOfDetails;
