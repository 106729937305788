import React, { useState, useEffect, useRef } from "react";

import Picker from "emoji-picker-react";
import { FaRegSmile } from "react-icons/fa";

import Input from "../../input";
import Textarea from "../../textarea";
import Button from "../../button";
import ProgressBar from "../../progressBar";

import userService from "../../../_services/user.service";
import toastService from "../../../_services/toast.service";
import t from "../../../_config/constants";

import styles from "./notificationsModal.module.scss";

const NotificationsModal = ({
  handleClose,
  handleDisabled,
  users,
  handleCancel,
}) => {
  const [errorMsg, setErrorMsg] = useState(false);
  const [sending, setSending] = useState(false);
  const [finished, setFinished] = useState(false);
  const [data, setData] = useState({
    title: "",
    body: "",
    type: "adminPN",
  });
  const [error, setError] = useState({
    title: false,
    body: false,
  });
  const [showEmojiPicker, setShowEmojiPicker] = useState({
    title: false,
    body: false,
  });

  const refTitle = useRef();
  const refBody = useRef();
  const refIconT = useRef();
  const refIconB = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showEmojiPicker.title &&
        refTitle.current &&
        !refTitle.current.contains(e.target) &&
        !refIconT.current.contains(e.target)
      ) {
        setShowEmojiPicker((prev) => ({
          ...prev,
          title: false,
        }));
      } else if (
        showEmojiPicker.body &&
        refBody.current &&
        !refBody.current.contains(e.target) &&
        !refIconB.current.contains(e.target)
      ) {
        setShowEmojiPicker((prev) => ({
          ...prev,
          body: false,
        }));
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showEmojiPicker]);

  const handleChange = (e) => {
    setErrorMsg(false);
    const { name, value } = e.target;
    setData((prevVal) => ({ ...prevVal, [name]: value }));
    setError((error) => ({ ...error, [name]: !value }));
  };

  const sendNotification = async (e) => {
    e.preventDefault();
    let response;
    if (data.title && data.body) {
      setSending(true);

      handleDisabled(true);
      document.body.style.cursor = "wait";
      if (users.length) {
        const userIds = users.map((user) => user._id);
        response = await userService.sendPushNotifications({
          ...data,
          userIds,
        });
      }
      setFinished(true);
      setTimeout(() => {
        handleClose();
        handleCancel();
        if (response) {
          toastService.show(
            "success",
            t.pushNotifications.text_successfully_sent
          );
        }
        document.body.style.cursor = "auto";
        handleDisabled(false);
        setSending(false);
      }, 1500);
    } else {
      setErrorMsg(true);
      setError({ title: !data.title, body: !data.body });
    }
  };

  const onEmojiClickTitle = (event, emojiObject) => {
    setData((prevVal) => ({
      ...prevVal,
      title: `${prevVal.title}${emojiObject.emoji}`,
    }));
  };

  const onEmojiClickBody = (event, emojiObject) => {
    setData((prevVal) => ({
      ...prevVal,
      body: `${prevVal.body}${emojiObject.emoji}`,
    }));
  };

  return (
    <div className={styles.wrapper}>
      <h2>
        {t.pushNotifications.text_send_notification}
        {users.length === 1 ? users[0].fullName : `${users.length} Members`}
      </h2>
      <div className={styles.notificationBody}>
        <div className={styles.input}>
          <Input
            label={t.pushNotifications.text_title}
            inputClass="inputForm"
            error={error.title}
            onChange={handleChange}
            name="title"
            value={data.title}
            disabled={sending}
          />
          <div
            ref={refIconT}
            className={styles.smileIcon}
            onClick={() => {
              setShowEmojiPicker((prev) => ({ ...prev, title: !prev.title }));
            }}
          >
            <FaRegSmile />
          </div>

          {showEmojiPicker.title && (
            <div ref={refTitle} className={styles.emojiPicker}>
              <Picker
                onEmojiClick={onEmojiClickTitle}
                pickerStyle={{ width: "100%" }}
              />
            </div>
          )}
        </div>
        <div className={styles.input}>
          <Textarea
            textareaClass="textareaWhite"
            label={t.pushNotifications.text_body}
            error={error.body}
            onChange={handleChange}
            name="body"
            value={data.body}
            disabled={sending}
          />
          <div
            ref={refIconB}
            className={styles.smileIcon}
            onClick={() => {
              setShowEmojiPicker((prev) => ({ ...prev, body: !prev.body }));
            }}
          >
            <FaRegSmile />
          </div>
          {showEmojiPicker.body && (
            <div ref={refBody} className={styles.emojiPicker}>
              <Picker
                onEmojiClick={onEmojiClickBody}
                pickerStyle={{ width: "100%" }}
              />
            </div>
          )}
        </div>
      </div>
      <ProgressBar sending={sending} finished={finished} />
      <Button
        label={t.button.text_send}
        disabled={sending || users?.length === 0}
        btnClass="btnNormalGlacier"
        onClick={sendNotification}
      />
      {errorMsg ? (
        <div className={styles.error}>
          <p>{t.common.text_all_fields_required}</p>
        </div>
      ) : null}
    </div>
  );
};
export default NotificationsModal;
