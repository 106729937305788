import React from "react";
import ReactPaginate from "react-paginate";
import styles from "./pagination.module.scss";
const Pagination = ({
  handlePageClick = null,
  pageCount = 100,
  forcePage,
  loading,
}) => {
  return (
    <div className={loading ? styles.unclickable : styles.wrapper}>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        pageCount={Math.ceil(pageCount)}
        activeClassName={styles.active}
        previousLabel="<"
        renderOnZeroPageCount={null}
        forcePage={forcePage}
      />
    </div>
  );
};
export default Pagination;
