import React, { useContext } from "react";
import styles from "./couponList.module.scss";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../_store/actions/modal.actions";
import { CouponContext } from "../../../_pages/couponManagement";

import CouponTable from "../couponTable/index";
import Button from "../../button/index";
import SearchBar from "../../searchBar/index";
import AddCoupon from "../addCoupon/index";
import Pagination from "../../pagination";
import { ReactComponent as CouponImg } from "../../../_assets/icons/Promo.svg";

import t from "../../../_config/constants";

const CouponList = () => {
  const [
    ,
    ,
    ,
    setSearchTerm,
    loadingData,
    closeModalAndRefresh,
    ,
    numberOfCoupons,
    pageNumber,
    setPageNumber,
  ] = useContext(CouponContext);

  const dispatch = useDispatch();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Button
          label="Add coupon"
          btnClass="btnNormalCasal"
          disabled={false}
          onClick={() =>
            dispatch(
              modalActions.openModal(
                <AddCoupon handleClose={closeModalAndRefresh} />,
                "Coupon",
                "xlg"
              )
            )
          }
        />
        <div className={styles.icon}>
          <div>
            <span>{numberOfCoupons}</span>
            <CouponImg />
          </div>
          <span>Coupons</span>
        </div>
      </div>
      <h2>{t.coupon.text_list_of_coupons}</h2>
      <div className={styles.searchAndFilter}>
        <SearchBar
          customClass="restaurantSearch"
          placeholder={t.coupon.text_search_coupons}
          name="searchTerm"
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          disabled={loadingData}
        />
      </div>
      <div className={styles.tableWrapper}>
        <CouponTable />
      </div>
      <div className={styles.pagination}>
        <Pagination
          handlePageClick={(page) => setPageNumber(page.selected)}
          pageCount={Math.ceil(numberOfCoupons / 20)}
          forcePage={pageNumber}
          loading={loadingData}
        />
      </div>
    </div>
  );
};

export default CouponList;
