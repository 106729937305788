import QRCode from "qrcode";
import * as FileSaver from "file-saver";
import Axios from "axios";
import { firebaseApiKey } from "../../../environment";

const generateQr = async (redirectUrl) => {
  const FIREBASE_DYNAMIC_LINK_ENDPOINT = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseApiKey}`;
  try {
    const requestConfig = {
      method: "POST",
      url: FIREBASE_DYNAMIC_LINK_ENDPOINT,
      headers: {
        "content-type": "application/json",
      },
      data: {
        dynamicLinkInfo: {
          domainUriPrefix: "https://reusables.page.link",
          link: redirectUrl,
          androidInfo: {
            androidPackageName: "com.circular.reusables",
            androidFallbackLink:
              "https://play.google.com/store/apps/details?id=com.circular.reusables",
          },
          iosInfo: {
            iosBundleId: "com.circular.reusables",
            iosFallbackLink:
              "https://apps.apple.com/app/reusables/id1570782074",
          },
          navigationInfo: {
            enableForcedRedirect: true,
          },
          socialMetaTagInfo: {
            socialTitle: "Reusables.com | Zero Waste Packaging Platform",
            socialDescription:
              "Get your favourite food and drinks in Reusables and help reduce single-use packaging from our environment. We help food businesses reduce packaging waste by providing tech enabled reusables that makes it easy for customers to reuse!",
            socialImageLink:
              "https://cdn.shopify.com/s/files/1/0503/5963/2036/files/Reusables-All-Logo-vector_reusables-logo-tall_1200x1200.png",
          },
        },
        suffix: {
          option: "UNGUESSABLE",
        },
      },
    };

    const { shortLink } = await Axios(requestConfig);
    const response = await QRCode.toDataURL(shortLink);
    return response;
  } catch (e) {
    console.log(e.message);
  }
};

export const qrToPNG = async (id, name) => {
  let response = "";
  try {
    response = await generateQr(`https://reusables.com/?location=${id}`);
  } catch (error) {
    console.log(error);
  }

  function dataURItoBlob(dataURI) {
    let byteString = atob(dataURI.split(",")[1]);
    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([ab], { type: mimeString });
    return blob;
  }
  function resizedataURL(datas, wantedWidth, wantedHeight) {
    return new Promise(async function (resolve, reject) {
      let img = document.createElement("img");
      img.onload = function () {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
        let dataURI = canvas.toDataURL();
        resolve(dataURI);
      };
      img.src = datas;
    });
  }
  let newDataURI = await resizedataURL(response, 350, 350);
  FileSaver.saveAs(dataURItoBlob(newDataURI), name + ".png");
};
