import React, { useEffect } from "react";
import styles from "./groupTable.module.scss";
import statusImage from "../../../_assets/icons/exclamation-mark.svg";
import Loading from "../../loading";
import Button from "../../button";
import arrowUp from "../../../_assets/icons/arrowUp.svg";
import arrowDown from "../../../_assets/icons/arrowDown.svg";
import upDownArrow from "../../../_assets/icons/upDownArrow.svg";
import useSortableData from "../../../_utils/useSortableData";
import t from "../../../_config/constants";
const GroupTable = ({
  groups = [],
  onSelectGroup,
  selectedGroupId,
  loadingData,
  changeSortConfig,
}) => {
  const { items, handleSort, sortConfig } = useSortableData(groups);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return upDownArrow;
    }
    return sortConfig.key === name
      ? sortConfig.direction === "ascending"
        ? arrowUp
        : arrowDown
      : upDownArrow;
  };
  useEffect(() => {
    changeSortConfig(sortConfig);
  }, [sortConfig]);

  const noGroupssMsg = (
    <p className={styles.noRestaurantsMsg}>{t.restaurant.text_no_groups}</p>
  );
  return (
    <div className={styles.tableWrapper}>
      <table>
        <thead>
          <tr>
            <th onClick={() => (loadingData ? null : handleSort("name"))}>
              {t.common.text_th_name}
              <Button btnClass="btnBack" iconLeft={getClassNamesFor("name")} />
            </th>
            <th
              onClick={() => (loadingData ? null : handleSort("totalStores"))}
            >
              {t.restaurant.text_th_total_stores}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("totalStores")}
              />
            </th>
            <th
              onClick={() =>
                loadingData ? null : handleSort("chargingOption")
              }
            >
              {t.admin.regions.text_charging}
              <Button
                btnClass="btnBack"
                iconLeft={getClassNamesFor("chargingOption")}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {!loadingData &&
            groups.length > 0 &&
            items.map((item) => {
              return (
                <tr
                  key={item._id}
                  className={
                    selectedGroupId === item._id ? styles.selected : ""
                  }
                  onClick={() => onSelectGroup(item._id)}
                >
                  <td>{item.name}</td>
                  <td>{item.totalStores ? item.totalStores : 0}</td>
                  <td>
                    {item.chargingOption === "chargeParent"
                      ? "Charge parent store"
                      : "Charge stores separately"}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {!loadingData && !groups.length && noGroupssMsg}
      {loadingData && (
        <div className={styles.loading}>
          <Loading width={50} height={50} />
        </div>
      )}
    </div>
  );
};

export default GroupTable;
