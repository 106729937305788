import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";

export default function useIsCleaner() {
  const history = useHistory();
  const store = useSelector((state) => state);

  const isCleaner = () => {
    if (store.auth && store.auth.user && store.auth.user.type === "cleaner") {
      history.push("/cleaning");
    }
  };
  return isCleaner;
}
