import React, { useEffect, useState } from "react";
import Button from "../../button";
import Input from "../../input";
import FormModal from "../../modal/formModal";
import AddRestaurant from "../addRestaurant";
import styles from "./singleRestaurant.module.scss";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import ConfirmModal from "../../modal/confirmModal";
import toastService from "../../../_services/toast.service";
import MonthlySummary from "../monthlySummary";
import { Link } from "react-router-dom";
import { formatPhone } from "../../../_utils/formatPhone";
import t from "../../../_config/constants";
import Avatar from "../../avatar";
import { modalActions } from "../../../_store/actions/modal.actions";
import EditEmail from "../editEmail";
import { deleteStore } from "../../../_services/restaurant.service";
import BillingDetails from "../billingDetails";
const SingleRestaurant = ({ restaurantId, getAllRestaurants }) => {
  const [restaurant, setRestaurant] = useState("");
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalArchive, setShowModalArchive] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [step, setStep] = useState("profile");
  const [loadingImg, setLoadingImg] = useState(true);
  const dispatch = useDispatch();

  const getSelectedRestaurant = async (id) => {
    const response = await dispatch(
      mainActions.run("profiles", "restaurant", "id", id)
    );
    setRestaurant(response);
    if (!response.avatarURL) setLoadingImg(false);
  };

  useEffect(() => {
    if (restaurantId) {
      getSelectedRestaurant(restaurantId);
      setLoadingImg(true);
    }
  }, [restaurantId]);

  const openDeleteModal = () => {
    setShowModalDelete(true);
  };
  const handleDelete = async (answer) => {
    if (answer) {
      try {
        let response = await deleteStore({
          _id: restaurant._id,
          email: restaurant.email,
          customer: restaurant.customer,
        });
        if (response) {
          toastService.show("success", t.restaurant.text_successfully_deleted);
          getAllRestaurants();
          setRestaurant("");
          setShowModalDelete(false);
        }
      } catch (err) {
        setShowModalDelete(false);
      }
    } else {
      setShowModalDelete(false);
    }
  };

  const handleArchive = async (answer) => {
    if (answer) {
      try {
        const response = await dispatch(
          mainActions.run("profiles", "restaurant", "update", {
            _id: restaurantId,
            archived: true,
            visible: false,
            active: false,
          })
        );

        if (response) {
          toastService.show("success", t.restaurant.text_successfully_archived);
          getAllRestaurants();
          setRestaurant("");
          setShowModalArchive(false);
        }
      } catch (err) {
        setShowModalArchive(false);
      }
    } else {
      setShowModalArchive(false);
    }
  };

  const editButtonHandler = () => {
    setShowEditForm(true);
  };
  const closeAddRaestaurantForm = () => {
    setShowEditForm(false);
    getSelectedRestaurant(restaurantId);
    getAllRestaurants();
  };
  const closeForm = () => {
    setShowEditForm(false);
  };
  const handleStep = (term) => {
    setStep(term);
  };

  return (
    <>
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={handleDelete}
          modalData={{}}
          question={t.restaurant.text_modal_delete_restaurant}
        />
      )}
      {showModalArchive && (
        <ConfirmModal
          show={showModalArchive}
          handleYesNoAnswer={handleArchive}
          question={t.restaurant.text_modal_archive_restaurant}
          confirmLabel="Yes"
        />
      )}
      {!restaurant && (
        <div className={styles.noData}>
          <span>{t.restaurant.text_select_guideline}</span>
        </div>
      )}
      {showEditForm && (
        <FormModal
          show={showEditForm}
          handleClose={closeForm}
          content={
            <AddRestaurant
              editing={true}
              editRestaurant={restaurant}
              handleClose={closeAddRaestaurantForm}
            />
          }
          size="xxlg"
        />
      )}
      {restaurant && step === "profile" && (
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Button
              label={t.button.text_edit}
              btnClass="btnWhiteBackground"
              disabled={false}
              onClick={editButtonHandler}
            />
            <Button
              btnClass="btnWhiteBackground"
              label="Deactivate"
              type="btn"
              onClick={() => {
                setShowModalArchive(true);
              }}
            />
            <Button
              label={t.button.text_delete}
              btnClass="btnWhiteBackground"
              disabled={false}
              onClick={openDeleteModal}
            />
          </div>

          <div className={styles.restaurantInfos}>
            <Avatar
              imgUrl={restaurant.avatarURL}
              loadingImg={loadingImg}
              setLoadingImg={setLoadingImg}
            />
            <h2>
              {restaurant.isDraft && (
                <div className={styles.draft}>[Draft]</div>
              )}
              {restaurant.name || ""}
            </h2>
            <p>{t.common.text_profile_info}</p>

            <form>
              <div className={styles.inputGroup}>
                <Input
                  label={t.common.input.text_input_email_address}
                  name="email"
                  inputClass="inputForm"
                  type="text"
                  value={restaurant.email || ""}
                  disabled={true}
                />{" "}
                <Input
                  label={t.common.input.text_input_phone_number}
                  name="phone"
                  inputClass="inputForm"
                  type="text"
                  value={formatPhone(restaurant.phone) || ""}
                  disabled={true}
                />
              </div>
              {restaurant.billingEmail && (
                <div className={styles.inputGroup}>
                  <Input
                    label={"Billing Email Address"}
                    name="billingEmail"
                    inputClass="inputForm"
                    type="text"
                    value={restaurant.billingEmail || ""}
                    disabled={true}
                  />{" "}
                </div>
              )}
              <div className={styles.inputGroup}>
                <Input
                  label={t.restaurant.text_input_owner}
                  name="fullName"
                  inputClass="inputForm"
                  type="text"
                  value={restaurant.fullName || ""}
                  disabled={true}
                />
              </div>
              <div className={styles.inputGroup}>
                <Input
                  label={t.common.input.text_input_address}
                  name="location"
                  inputClass="inputForm"
                  type="text"
                  value={restaurant.location || ""}
                  disabled={true}
                />
              </div>
              <div className={styles.inputGroup}>
                <Input
                  label={t.restaurant.text_input_secureCode}
                  name="secureCode"
                  inputClass="inputForm"
                  type="text"
                  value={restaurant.secureCode || "N/A"}
                  disabled={true}
                  showCopyToClipboard={true}
                />
              </div>
            </form>
          </div>
          {!restaurant.isDraft && (
            <div className={styles.footer}>
              <Button
                onClick={() => {
                  dispatch(
                    modalActions.openModal(
                      <EditEmail currentEmail={restaurant.email} />
                    )
                  );
                }}
                label={"Edit Email"}
                btnClass="btnWhiteBackground"
              />
              <Button
                onClick={() => {
                  dispatch(
                    modalActions.openModal(
                      <BillingDetails
                        restaurant={restaurant}
                        refreshAllRestaurants={getAllRestaurants}
                      />
                    )
                  );
                }}
                label={"Billing Details"}
                btnClass="btnNormalGlacier"
              />
              <Button
                label={t.button.text_monthly_summary}
                btnClass="btnDarkGlacier"
                disabled={false}
                onClick={() => handleStep("summary")}
              />
              <Link to={`/store-management/settings?id=${restaurantId}`}>
                <Button
                  label={t.button.text_settings}
                  btnClass="btnNormalCasal"
                />
              </Link>
            </div>
          )}
        </div>
      )}
      {restaurant && step === "summary" && (
        <MonthlySummary
          handleClose={() => handleStep("profile")}
          restaurant={restaurant}
        />
      )}
    </>
  );
};

export default SingleRestaurant;
