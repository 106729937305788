import React, { useState, useEffect } from "react";
import styles from "./dropoffList.module.scss";
import Button from "../../../button";
import SearchBar from "../../../searchBar";
import dropoff from "../../../../_assets/icons/dropoff.svg";
import DropoffTable from "./../dropoffTable";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../../_store/actions/main.actions";
import useDebounce from "../../../../_utils/debounce";
import FormModal from "../../../modal/formModal";
import DropoffForm from "./../../single/form";
import { termRegex } from "../../../../_utils/utils";
import t from "../../../../_config/constants";
import Pagination from "../../../pagination";
import { modalActions } from "../../../../_store/actions/modal.actions";
import StatisticsModal from "../statisticsModal";
const DropoffList = ({ handleSelectLocation, update, selectedLocationId }) => {
  const [filteredLocations, setFilteredLocations] = useState([]);
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [showAddForm, setShowAddForm] = useState(false);
  const [updateList, setUpdateList] = useState(1);
  const [loadingData, setLoadingData] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sortConfig, setSortConfig] = useState({});
  const [numberOfLocations, setNumberOfLocations] = useState(0);
  const [filterData, setFilterData] = useState({
    searchTerm: "",
    selectedValue: "",
  });

  const debouncedSearchTerm = useDebounce(filterData.searchTerm, 500);
  const addLocationHandler = () => {
    setShowAddForm(true);
  };
  const closeAddDropoffForm = () => {
    setShowAddForm(false);
    setUpdateList((prev) => (prev === 1 ? 2 : 1));
  };
  const closeForm = () => {
    setShowAddForm(false);
  };

  const onChangeTerm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterData((prevState) => ({ ...prevState, [name]: value }));
  };

  const getFilteredLocation = async (
    term,
    selectValue,
    id,
    subregionId,
    pageNumber,
    sortConfig = { createdAt: -1 }
  ) => {
    let filter = [];
    setLoadingData(true);
    if (id && id !== "*") {
      filter.push({
        regionId: id,
      });
    }
    if (subregionId && subregionId !== "*") {
      filter.push({
        subregionId,
      });
    }
    if (term) {
      filter.push({
        $or: [
          { name: { $regex: `${termRegex(term)}`, $options: "i" } },
          { description: { $regex: `${termRegex(term)}`, $options: "i" } },
        ],
      });
    }
    let locations = await dispatch(
      mainActions.run("data", "location", "page", {
        filter,
        pageSize,
        pageNumber,
        sort: sortConfig,
      })
    );
    if (!locations) locations = { rows: [], total: 0 };
    locations.rows.map((location) =>
      location.isRestaurant === undefined
        ? (location.isRestaurant = false)
        : null
    );
    setFilteredLocations(locations.rows);
    setNumberOfLocations(locations.total);
    setLoadingData(false);
  };

  useEffect(() => {
    setPageNumber(0);
  }, [debouncedSearchTerm, store.auth && store.auth.user]);

  useEffect(() => {
    getFilteredLocation(
      debouncedSearchTerm,
      filterData.selectedValue,
      store.auth.user.regionId,
      store.auth.user.subregionId,
      pageNumber,
      sortConfig
    );
  }, [
    debouncedSearchTerm,
    filterData.selectedValue,
    store.auth && store.auth.user,
    update,
    updateList,
    pageNumber,
    sortConfig,
  ]);

  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };
  const changeSortConfig = (data) => {
    if (data) {
      const { key, direction } = data;
      setSortConfig({ [key]: direction === "ascending" ? 1 : -1 });
    }
  };
  return (
    <div className={styles.wrapper}>
      {showAddForm && (
        <FormModal
          show={showAddForm}
          handleClose={closeForm}
          content={<DropoffForm handleClose={closeAddDropoffForm} />}
          size="xlg"
        />
      )}
      <div className={styles.header}>
        <div className={styles.buttons}>
          <Button
            label={t.button.text_add_location}
            btnClass="btnNormalCasal"
            onClick={addLocationHandler}
          />
          <Button
            label={"Statistics"}
            btnClass="btnNormalCasalLight"
            onClick={() =>
              dispatch(
                modalActions.openModal(
                  <StatisticsModal
                    title="Locations Checkout/Drop Off Ratio"
                    mode="ratio"
                  />,
                  "",
                  ""
                )
              )
            }
          />
        </div>

        <div className={styles.icon}>
          <div>
            <span>{numberOfLocations}</span>
            <img src={dropoff} />
          </div>
          <span>{t.dropoff.text_dropoff_locations}</span>
        </div>
      </div>

      <h2>{t.dropoff.text_dropoff_locations_list}</h2>

      <div className={styles.searchAndFilter}>
        <SearchBar
          customClass="restaurantSearch"
          placeholder={t.dropoff.text_search_locations}
          name="searchTerm"
          onChange={onChangeTerm}
          disabled={loadingData}
        />
        <Button
          label={"Rank by Popularity"}
          btnClass="btnWhiteBackground"
          onClick={() =>
            dispatch(
              modalActions.openModal(
                <StatisticsModal
                  title="Locations Ranked By Popularity"
                  mode="rank"
                />,
                "",
                ""
              )
            )
          }
        />
      </div>
      <div className={styles.tableWrapper}>
        <DropoffTable
          locations={filteredLocations}
          onSelectLocation={handleSelectLocation}
          selectedLocationId={selectedLocationId}
          loadingData={loadingData}
          changeSortConfig={changeSortConfig}
        />
      </div>
      <div className={styles.pagination}>
        <Pagination
          handlePageClick={onPageChange}
          pageCount={Math.ceil(numberOfLocations / pageSize)}
          forcePage={pageNumber}
          loading={loadingData}
        />
      </div>
    </div>
  );
};

export default DropoffList;
