import React from "react";
import styles from "./table.module.scss";
import Button from "../../../../button";
import moment from "moment";
import Loading from "./../../../../loading";
import t from "../../../../../_config/constants";
const SummaryTable = ({ handleSelectSummary, transactions, loading }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.list}>
        {loading && (
          <div>
            <Loading width={50} height={50} />
          </div>
        )}
        {!loading &&
          transactions.length > 0 &&
          transactions.map((transaction, index) => (
            <div className={styles.block} key={transaction._id}>
              <p className={styles.blockId}>{++index}</p>
              <p className={styles.blockUser}>
                {moment
                  .utc(transaction.createdAt)
                  .subtract(1, "months")
                  .format("MMMM")}
              </p>

              <p style={{ color: "#929292" }} className={styles.blockMsg}>
                {transaction.paymentIntentId || transaction.chargedManually
                  ? t.restaurant.text_charged_automaticly
                  : ""}
              </p>
              <div className={styles.blockButton}>
                <Button
                  label="View"
                  btnClass="btnNormalGlacier"
                  onClick={() => {
                    handleSelectSummary(transaction);
                  }}
                />
              </div>
            </div>
          ))}
        {!loading && !transactions.length && (
          <div className={styles.noDataMsg}>
            {t.restaurant.text_no_transactions}
          </div>
        )}
      </div>
    </div>
  );
};
export default SummaryTable;
