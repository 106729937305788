import React from "react";
import styles from "./checkbox.module.scss";

const Checkbox = ({
  name,
  value,
  checked,
  onChange,
  label,
  customClass,
  desc,
  disabled = false,
  description,
}) => {
  return (
    <>
      {desc && <small className={styles.desc}>{desc}</small>}
      <label className={styles[customClass]}>
        {description && <p className={styles.hidden}>{description}</p>}
        <input
          name={name}
          value={value}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <span className={styles.checkmark} />
        <span className={styles.spanLabel}> {label} </span>
      </label>
    </>
  );
};

export default Checkbox;
