import React from "react";
import styles from "./permissions.module.scss";
import t from "../../_config/constants";
import DistributionPermissions from "../../_components/permissions/distribution";

const Permissions = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.header}>
          <h2>{t.permissions.permissions}</h2>
        </div>
        <div className={styles.content}>
          <div className={styles.sideMenu}>
            <div className={styles.listItem}>{t.sidebar.text_distribution}</div>
          </div>
          <div className={styles.settings}>
            <DistributionPermissions />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Permissions;
