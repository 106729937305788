import * as XLSX from "xlsx";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import toastService from "../_services/toast.service";

export const exportToCSVTabs = async (csvMapData, fileName) => {
  let zip = new JSZip();
  try {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const sheetNames = Object.keys(csvMapData);
    const shortSheetNames = sheetNames.map((item) => item.substring(0, 31));
    const wb = { Sheets: {}, SheetNames: shortSheetNames };
    sheetNames.forEach((sheetName) => {
      let shortSheetName = sheetName.substring(0, 31);
      const csvData = csvMapData[sheetName];
      const ws = XLSX.utils.json_to_sheet(csvData);
      let objectMaxLength = [];
      csvData.forEach((obj) => {
        let i = 0;
        for (const [key, value] of Object.entries(obj)) {
          let maxLen =
            key.length >= String(value)?.length
              ? key.length
              : String(value).length;
          objectMaxLength[i] =
            objectMaxLength[i] >= maxLen ? objectMaxLength[i] : maxLen;
          i++;
        }
      });
      objectMaxLength = objectMaxLength.map((item) => ({ wch: item }));
      ws["!cols"] = objectMaxLength;
      wb.Sheets[shortSheetName] = ws;
    });

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    zip.file(fileName + fileExtension, data);
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, fileName + ".zip");
  } catch (err) {
    toastService.show("error", "Something went wrong. Try again.");
  }
};
