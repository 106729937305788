import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../_store/actions/user.actions";
import { Redirect } from "react-router-dom";
import styles from "../auth.module.scss";
import Input from "../../input";
import Button from "../../button";
import logo from "../../../_assets/images/logo.svg";
import logoAuth from "../../../_assets/images/logoAuth.svg";
import toastService from "../../../_services/toast.service";
import { isObjectEmpty } from "../../../_utils/utils";
import { Link } from "react-router-dom";
import t from "../../../_config/constants";
import trimObjectProps from "../../../_utils/trimObjectProps";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [email, setEmail] = useState({ email: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setEmail({
      ...email,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail()) {
      await dispatch(userActions.resetLink({ email }));
    }
  };

  const validateEmail = () => {
    trimObjectProps(email);
    if (!isObjectEmpty(email)) {
      return email;
    } else {
      toastService.show("warning", t.auth.text_email_required);
    }
  };

  if (store.auth.user) return <Redirect to="/" />;
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img src={logoAuth} alt="Logo" />
      </div>
      <div className={styles.container}>
        <div>
          <div className={styles.logo}>
            <img src={logo} alt="Logo" />
          </div>
          <div className={styles.info}>{t.auth.text_forgot_password}</div>
          <div className={styles.mailInfo}>
            {t.auth.text_forgot_password_guideline}
          </div>
          <div>
            <form onSubmit={handleSubmit} className={styles.form}>
              <section>
                <div>
                  <Input
                    inputClass="oneInput"
                    type="email"
                    name="email"
                    placeholder={t.auth.text_markup_mail}
                    onChange={handleChange}
                    value={email.email.trim()}
                  />
                </div>
              </section>
              <div className={styles.backToLoginDiv}>
                <div className={styles.backToLogin}>
                  <Link to="/login">{t.auth.text_back_to_login}</Link>
                </div>
              </div>
              <Button
                label={t.auth.text_send_reset_instruction}
                btnClass="btnLogin"
                type="submit"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
