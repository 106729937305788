import React, { useEffect, useState } from "react";
import styles from "./bins.module.scss";
import { useSelector } from "react-redux";
import LineChart from "../../../charts/srbLineChart";
import { getStatistics } from "../../../../_services/statistics.service";
import moment from "moment";
const BinsStats = ({ binsData, binsCapacity }) => {
  const store = useSelector((state) => state);
  const [selectedBin, setSelectedBin] = useState();
  const [dataForBinChart, setDataForBinChart] = useState([]);
  const [lastEmptiedAt, setLastEmptiedAt] = useState();
  useEffect(() => {
    const getData = async (subregionId, locationId) => {
      let filter = {};
      filter.subregionId = subregionId;
      filter.locationId = locationId;
      filter.period = "hourly";
      filter.year = new Date().getFullYear();
      filter.month = new Date().getMonth();
      filter.day = new Date().getDate();

      const response = await getStatistics({
        mode: "get-subregion-return-statistics",
        filter: filter,
      });
      if (response) {
        setDataForBinChart(response.array || {});
        setLastEmptiedAt(response.lastEmptiedAt);
      }
    };
    if (selectedBin) {
      getData(store.auth.user.subregionId, selectedBin._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBin]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.chartWrapper}>
        <h2>Smart Return Bins</h2>
        <div className={styles.chart}>
          <div className={styles.column}>
            {Array.from(
              { length: Math.ceil(binsCapacity / 10) },
              (_, index) => binsCapacity - index * 10
            ).map((i) => (
              <span key={i}>{i}</span>
            ))}

            <span></span>
          </div>
          <div className={styles.chartRow}>
            {binsData?.map((bin) => {
              return (
                <div
                  key={bin?.name}
                  className={styles.bin}
                  onClick={() => setSelectedBin(bin)}
                >
                  <div
                    className={`${styles.bar} ${
                      selectedBin == bin._id && styles.selectedBin
                    }`}
                    style={{
                      height: `${
                        (bin.fullPercentage * bin.maxContainersAmount) /
                        binsCapacity
                      }%`,
                    }}
                  ></div>
                  <div className={styles.name}>
                    <span>{bin.name}</span>
                    <p>{bin.fullPercentage}%</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {selectedBin && (
        <div className={styles.info}>
          <div className={styles.binInfo}>
            <h2>Activity Timeline</h2>
            <h3>{selectedBin.name}</h3>
            <span>{selectedBin.infoDescription}</span>
            {lastEmptiedAt && (
              <p>
                Last emptied:{" "}
                <span className={styles.bold}>
                  {moment(lastEmptiedAt).format("MMMM Do")}
                </span>{" "}
                at
                <span className={styles.bold}>
                  {" "}
                  {moment(lastEmptiedAt).format("h:mm a")}
                </span>
              </p>
            )}
            <div className={styles.percentage}>
              {selectedBin.fullPercentage}%
            </div>
          </div>
          <div className={styles.lineChart}>
            <LineChart dataForLineChart={dataForBinChart} />
          </div>
        </div>
      )}
    </div>
  );
};

export default BinsStats;
