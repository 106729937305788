import React, { useEffect, useState } from "react";
import Selector from "../selector";
import styles from "./userAppInteractionsReport.module.scss";
import {
  eventOptionsBeforeRenting,
  eventOptionsBeforeReturning,
  groupOptions,
  userActionOptions,
  mapReportsForChart,
} from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../_store/actions/main.actions";
import {
  getDropdownListMonths,
  getDropdownListYears,
} from "../../_utils/getReportsDateOptions";
import LineChart from "../charts/line";
import { capitalizeWord } from "../../_utils/utils";
import t from "../../_config/constants";
const UserAppInteractionsReport = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  const [data, setData] = useState({ display: "number", action: "" });
  const [selectedYear, setSelectedYear] = useState();
  const [yearsOptions, setYearsOptions] = useState([]);
  const [monthsOptions, setMonthsOptions] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState();
  const [lineChartData, setLineChartData] = useState([]);
  const [reports, setReports] = useState();
  useEffect(() => {
    const arr = getDropdownListMonths(selectedYear);
    setSelectedMonth(arr[0].value);
    setMonthsOptions(arr);
  }, [selectedYear]);
  useEffect(() => {
    const arr = getDropdownListYears();
    setSelectedYear(arr[0].value);
    setYearsOptions(arr);
  }, []);

  useEffect(() => {
    const fetchReports = async (regionId, date) => {
      const filter = [
        {
          createdAt: {
            $gte: date.getTime(),
            $lte: new Date(date.getFullYear(), date.getMonth() + 1).getTime(),
          },
        },
      ];
      regionId && regionId !== "*" && filter.push({ regionId: regionId });

      const response = await dispatch(
        mainActions.run("reports", "userAppInteraction", "all", { filter })
      );
      setReports(response);
    };
    if (selectedMonth && store.auth.user && data.action) {
      fetchReports(store.auth.user.regionId, selectedMonth);
    }
  }, [selectedMonth, store.auth && store.auth.user, data.action]);
  useEffect(() => {
    if (reports && data.display && data.action && selectedMonth) {
      const array = mapReportsForChart(
        reports,
        data.action,
        data.display,
        selectedMonth
      );
      setLineChartData(array);
    }
  }, [data.display, data.action, reports]);
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const handleSelect = (e, name) => {
    setData((data) => ({
      ...data,
      [name]: e.value,
    }));
  };
  return (
    <div className={styles.wrapper}>
      <h3>{t.reports.user_app_usage_behavior}</h3>
      <div className={styles.filterOptions}>
        <div className={styles.selector}>
          <Selector
            label={"Audience / Group"}
            multiselect={false}
            options={groupOptions}
            selectedOption={getSelectedValue(groupOptions, data.display)}
            handleChangeSelect={(e) => handleSelect(e, "display")}
            selectClassname="selectSecondaryWhiteBg"
          />
        </div>
        <div className={styles.selector}>
          <Selector
            label={"User Action"}
            selectClassname="selectSecondaryWhiteBg"
            options={userActionOptions}
            selectedOption={getSelectedValue(userActionOptions, data.action)}
            handleChangeSelect={(e) => handleSelect(e, "action")}
          />
        </div>
        <div className={styles.selector}>
          <Selector
            label={"Event"}
            selectClassname="selectSecondaryWhiteBg"
            disabled={data.action ? false : true}
            options={
              data.action === "interactionBeforeRenting"
                ? eventOptionsBeforeRenting
                : eventOptionsBeforeReturning
            }
            selectedOption={getSelectedValue(
              data.action === "interactionBeforeRenting"
                ? eventOptionsBeforeRenting
                : eventOptionsBeforeReturning,
              data.action
            )}
            placeholder={""}
          />
        </div>
        <div className={styles.selectDate}>
          <div className={styles.selectMonth}>
            <Selector
              label=""
              multiselect={false}
              selectClassname="selectSecondaryWhiteBg"
              selectedOption={getSelectedValue(monthsOptions, selectedMonth)}
              handleChangeSelect={(e) => setSelectedMonth(e.value)}
              options={monthsOptions}
            />
          </div>
          <div className={styles.selectYear}>
            <Selector
              label=""
              multiselect={false}
              selectClassname="selectSecondaryWhiteBg"
              selectedOption={getSelectedValue(yearsOptions, selectedYear)}
              handleChangeSelect={(e) => setSelectedYear(e.value)}
              options={yearsOptions}
            />
          </div>
        </div>
      </div>
      <div className={styles.report}>
        {selectedMonth && data.action && (
          <div className={styles.graph}>
            <LineChart arrayForLineChart={lineChartData} />
            <p className={styles.xLabel}></p>
            <p className={styles.yLabel}>
              {capitalizeWord(data.display)} of Reusers
            </p>
          </div>
        )}
        {!selectedMonth ||
          (!data.action && <p>Select User Action to view Report</p>)}
      </div>
    </div>
  );
};
export default UserAppInteractionsReport;
