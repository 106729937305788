import FileManager from "../../_services/file.manager";

const sendFile = (presignedurl, file) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener(
      "progress",
      (e) => {
        // handle notifications about upload progress: e.loaded / e.total
        // onProgress(e); //TODO add code here if you want progress bar for upload
      },
      false
    );
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(file.preview);
        } else {
          reject(file.preview);
        }
      }
    };
    xhr.open("PUT", presignedurl);
    xhr.setRequestHeader("Content-Type", file.type);
    xhr.send(file);
  });
};

export const uploadToS3 = async (image) => {
  if (image.uri !== "") {
    try {
      const findExt = image?.file?.name.lastIndexOf(".") || 0;
      const ext = image?.file?.name.substring(findExt) || "";
      const response = await FileManager.getSignedURL(image.type, ext);
      const { url, key } = response;
      await sendFile(url, image.file);
      return `/${key}`;
    } catch (err) {}
  }
};
