import React from "react";
import styles from "./rented.module.scss";

import CheckoutsChart from "../../../statistics/checkoutsChart";

const UsageRentedChart = () => {
  return (
    <div className={styles.wrapper}>
      <CheckoutsChart />
    </div>
  );
};

export default UsageRentedChart;
