import React from "react";
import styles from "./list.module.scss";
import QrGenerator from "../../../../containers/qrCode";
import moment from "moment";
import Loading from "../../../../loading";
import t from "../../../../../_config/constants";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../_store/actions/modal.actions";
import ContainerDetails from "../../../../containers/add/list/details";

const ListReturned = ({ data, activeTab, loadingData }) => {
  const dispatch = useDispatch();
  return (
    <div className={styles.wrapper}>
      {loadingData && (
        <div className={styles.loading}>
          <Loading width={50} height={50} />
        </div>
      )}
      {!loadingData &&
        data.map((item, index) => {
          return (
            <div className={styles.listContainer} key={index}>
              <div className={styles.item}>
                <p
                  className={styles.name}
                  title={"Show history"}
                  onClick={() =>
                    dispatch(
                      modalActions.openModal(
                        <ContainerDetails containerId={item?.oldData?._id} />,
                        "",
                        "xlg"
                      )
                    )
                  }
                >
                  {index + 1}. #{item?.oldData?.uniqueContainerId}
                </p>
                <div className={styles.qrWrapper}>
                  <p>{item?.oldData?.name}</p>
                  <QrGenerator
                    id={item?.oldData?._id}
                    uniqueId={item?.oldData?.uniqueContainerId}
                    batchId={item?.oldData?.batchId}
                    name={item?.oldData?.name}
                    size={item?.oldData?.size}
                    material={item?.oldData?.material}
                    volume={item?.oldData?.volume}
                  />
                </div>
              </div>
              <p className={styles.smallerP}>
                {t.common.text_ordered_from}{" "}
                {item?.oldData?.restaurantName
                  ? item?.oldData?.restaurantName
                  : "Unspecified Store"}
                -{" "}
                {item?.oldData?.orderedAt
                  ? moment(item?.oldData?.orderedAt).format("MM/DD/YYYY")
                  : "Unspecified Date"}
              </p>
              <p className={styles.smallerP}>
                {item?.assignedTo?.name
                  ? `${t.common.text_returned_to} ${item?.assignedTo?.name}`
                  : `Received by Cleaners`}
                -{" "}
                {item.timestamp
                  ? moment(item.timestamp).format("MM/DD/YYYY")
                  : "Unspecified Date"}{" "}
              </p>
            </div>
          );
        })}
      {!loadingData && data.length < 1 && (
        <div className={styles.noData}>
          <p>This user has no {activeTab} containers</p>
        </div>
      )}
    </div>
  );
};

export default ListReturned;
