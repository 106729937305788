import React, { useState, useEffect } from "react";
import styles from "./form.module.scss";

import { useDispatch, useSelector } from "react-redux";

import Input from "../../../input";
import Button from "../../../button";
import Textarea from "../../../textarea";
import Selector from "../../../selector";
import GeoInput from "../../../googleMaps/geoInput";
import Checkbox from "../../../checkbox";
import WorkingDay from "../../../workingDay";
import { SelectLocationMap } from "../../../googleMaps/selectLocation";

import { mainActions } from "../../../../_store/actions/main.actions";
import { days, makeSelectMap } from "../../../../_utils/utils";
import toastService from "../../../../_services/toast.service";
import trimObjectProps from "../../../../_utils/trimObjectProps";
import {
  cleaningOptions,
  dropoffFormErrors,
  dropoffFormInitialState,
  containerAssignmentOptions,
} from "./utils";
import t from "../../../../_config/constants";

const DropoffForm = ({ editLocation, handleClose }) => {
  const [user, setUser] = useState({});
  const [errorMsg, setErrorMsg] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [regionOptions, setRegionOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [allWorkingHours, setAllWorkingHours] = useState(false);
  const [locationCoordinates, setLocationCoordinates] = useState(
    editLocation
      ? editLocation.coordinates
      : {
          lat: 49.246292,
          lng: -123.116226,
        }
  );
  const [dropoffLocation, setDropoffLocation] = useState(
    editLocation || dropoffFormInitialState
  );
  const [error, setError] = useState(dropoffFormErrors);
  const [subregionOptions, setSubregionOptions] = useState([]);
  const [subregions, setSubregions] = useState([]);

  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSubregions = async () => {
      const response = await dispatch(
        mainActions.run("data", "subregion", "all", {})
      );
      setSubregions(response);
    };
    setUser(store.auth.user);
    fetchRegions();
    fetchSubregions();

    if (store.auth.user.regionId !== "*") {
      setDropoffLocation((prev) => ({
        ...prev,
        regionId: store.auth.user.regionId,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.auth, store.auth.user]);
  useEffect(() => {
    if (dropoffLocation.regionId) {
      const filteredSubregions = subregions.filter(
        (item) => item.regionId === dropoffLocation.regionId
      );
      const arr = makeSelectMap(filteredSubregions);
      arr.length && arr.push({ label: "-", value: "" });
      setSubregionOptions(arr);
    }
  }, [subregions]);

  useEffect(() => {
    const fetchStores = async (regionId) => {
      let response = await dispatch(
        mainActions.run("profiles", "restaurant", "all", {
          filter: { regionId, archived: { $ne: true } },
        })
      );
      const arr = makeSelectMap(response);
      arr.length && arr.push({ label: "-", value: "" });

      setStoreOptions(arr);
    };
    if (dropoffLocation.regionId && subregions.length) {
      const filteredSubregions = subregions.filter(
        (item) => item.regionId === dropoffLocation.regionId
      );
      const arr = makeSelectMap(filteredSubregions);
      arr.length && arr.push({ label: "-", value: "" });
      setSubregionOptions(arr);
      setDropoffLocation((prev) => ({ ...prev, subregionId: "" }));
    }
    if (dropoffLocation.regionId) {
      fetchStores(dropoffLocation.regionId);
    }
  }, [dropoffLocation.regionId]);

  useEffect(() => {
    if (allWorkingHours) {
      const workingTime = { ...dropoffLocation?.workingTime };
      days.forEach((day) => {
        workingTime[day] = dropoffLocation?.workingTime?.monday
          ? dropoffLocation.workingTime.monday
          : {
              from: "",
              to: "",
            };
      });
      setDropoffLocation((data) => ({
        ...data,
        workingTime: workingTime,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allWorkingHours]);

  useEffect(() => {
    setLocationCoordinates(dropoffLocation.coordinates);
  }, [dropoffLocation.coordinates]);

  useEffect(() => {
    setError((prev) => ({ ...prev, lat: false, lng: false }));
  }, [locationCoordinates]);

  const fetchRegions = async () => {
    let response = await dispatch(mainActions.run("data", "region", "all", {}));
    let arr = makeSelectMap(response);
    setRegionOptions(arr);
  };

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };

  const handleChange = (e) => {
    setErrorMsg(false);
    const { name, value } = e.target;
    if (name === "lat" || name === "lng") {
      setLocationCoordinates((prev) => ({ ...prev, [name]: Number(value) }));
    } else {
      setDropoffLocation((dropoffLocation) => ({
        ...dropoffLocation,
        [name]: value,
      }));
    }

    setError((error) => ({ ...error, [name]: !value }));
  };

  const handleChangeWorktime = (e) => {
    setErrorMsg(false);
    const { name, value } = e.target;
    const [nameVal, fromTo] = name.split(".");
    if (allWorkingHours && nameVal === "monday") {
      const workingTime = { ...dropoffLocation?.workingTime };
      days.forEach((day) => {
        workingTime[day] = { ...workingTime[day], [fromTo]: value };
      });
      setDropoffLocation((data) => ({
        ...data,
        workingTime: workingTime,
      }));
    } else {
      setAllWorkingHours(false);
      setDropoffLocation((dropoffLocation) => ({
        ...dropoffLocation,
        workingTime: {
          ...dropoffLocation.workingTime,
          [nameVal]: {
            ...dropoffLocation.workingTime[nameVal],
            [fromTo]: value,
          },
        },
      }));
    }
  };

  const handleSelectCleaning = (e) => {
    setDropoffLocation((dropoffLocation) => ({
      ...dropoffLocation,
      isCleaning: e.value,
    }));
  };

  const handleSelect = (e, type) => {
    if (type == "restaurant_id") {
      setDropoffLocation((data) => ({
        ...data,
        restaurant_id: e.value,
        isRestaurant: !!e.value,
      }));
    } else if (type) {
      setDropoffLocation((data) => ({ ...data, [type]: e.value }));
    } else {
      setDropoffLocation((data) => ({
        ...data,
        regionId: e.value,
        restaurant_id: "",
        isRestaurant: false,
      }));
      setError((error) => ({ ...error, regionId: false }));
    }
    setErrorMsg(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setSubmitDisabled(true);
      if (editLocation) {
        const dataForSubmit = {
          ...dropoffLocation,
          coordinates: locationCoordinates,
          isRestaurant: !!dropoffLocation.restaurant_id,
          isCleaning: dropoffLocation.restaurant_id
            ? dropoffLocation.isCleaning
            : false,
        };
        if (dropoffLocation.isDraft) {
          dataForSubmit.isDraft = false;
        }
        const response = await dispatch(
          mainActions.run("data", "location", "update", dataForSubmit)
        );
        if (response)
          toastService.show("success", t.toast.text_successfully_submited);
      } else {
        delete dropoffLocation._id;
        const response = await dispatch(
          mainActions.run("data", "location", "new", {
            ...dropoffLocation,
            totalContainers: 0,
            coordinates: locationCoordinates,
            isRestaurant: !!dropoffLocation.restaurant_id,
            isCleaning: dropoffLocation.restaurant_id
              ? dropoffLocation.isCleaning
              : false,
          })
        );
        if (response)
          toastService.show("success", t.toast.text_successfully_submited);
      }
      handleClose();
    } else {
      toastService.show("error", `${t.common.text_toast_all_fields_required}`);
      setErrorMsg(true);
    }
    setSubmitDisabled(false);
  };

  const handleSaveDraft = async () => {
    if (editLocation) {
      const response = await dispatch(
        mainActions.run("data", "location", "update", {
          ...dropoffLocation,
          coordinates: locationCoordinates,
          visible: false,
          isDraft: true,
        })
      );
      if (response)
        toastService.show("success", t.toast.text_successfully_submited);
    } else {
      delete dropoffLocation._id;
      const response = await dispatch(
        mainActions.run("data", "location", "new", {
          ...dropoffLocation,
          totalContainers: 0,
          coordinates: locationCoordinates,
          visible: false,
          isDraft: true,
        })
      );
      if (response)
        toastService.show("success", t.toast.text_successfully_submited);
    }
    handleClose();
  };

  const validateForm = () => {
    trimObjectProps(dropoffLocation);
    setError({
      name: !dropoffLocation.name,
      description: !dropoffLocation.description,
      location: !dropoffLocation.location,
      regionId: !dropoffLocation.regionId,
      subregionId: !dropoffLocation.subregionId,
      lat: !locationCoordinates?.lat,
      lng: !locationCoordinates.lng,
    });
    return (
      dropoffLocation.name &&
      dropoffLocation.description &&
      dropoffLocation.location &&
      dropoffLocation.regionId &&
      dropoffLocation.subregionId &&
      locationCoordinates?.lat &&
      locationCoordinates?.lng
    );
  };

  const onChangeLocation = (value, coordinates) => {
    let obj = { ...dropoffLocation };
    obj["location"] = value;
    obj["coordinates"] = coordinates;
    setDropoffLocation(obj);
    setError((error) => ({
      ...error,
      location: !value,
      lat: !value,
      lng: !value,
    }));
    setErrorMsg(false);
  };

  const handleCheckVisible = () => {
    setDropoffLocation((prevState) => ({
      ...prevState,
      visible: !prevState.visible,
    }));
  };

  const handleCheckSmartBin = () => {
    setDropoffLocation((prevState) => ({
      ...prevState,
      isSmartBin: !prevState.isSmartBin,
    }));
  };

  const handleClosedDay = (day) => {
    setAllWorkingHours(false);
    setDropoffLocation((data) => ({
      ...data,
      workingTime: {
        ...data?.workingTime,
        [day]: {
          from: "",
          to: "",
        },
      },
    }));
  };
  const handleInputNumber = (e) => {
    const numbers = "0123456789";
    if (!numbers.includes(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <div className={styles.wrapper}>
      <h2>{editLocation ? "Edit" : "Add"} Dropoff Location</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.content}>
          <div className={styles.inputFields}>
            <Input
              label={t.dropoff.text_input_name}
              error={error.name}
              name="name"
              inputClass="inputForm"
              type="text"
              value={dropoffLocation.name}
              placeholder={t.dropoff.text_input_name}
              onChange={handleChange}
            />
            <div>
              <Textarea
                label={t.dropoff.text_input_description}
                error={error.description}
                name="description"
                textareaClass="textareaWhite"
                type="text"
                placeholder={t.dropoff.text_input_description}
                onChange={handleChange}
                value={dropoffLocation.description}
              />
            </div>
            {(user.regionId === "*" || editLocation) && (
              <div className={styles.selectRegion}>
                <Selector
                  label={"Region"}
                  options={regionOptions}
                  multiselect={false}
                  error={error.regionId}
                  selectedOption={getSelectedValue(
                    regionOptions,
                    dropoffLocation.regionId
                  )}
                  handleChangeSelect={(e) => handleSelect(e)}
                  selectClassname="selectGray"
                  placeholder={""}
                />
              </div>
            )}
            <div className={styles.selectRegion}>
              <Selector
                options={subregionOptions}
                label={"Subregion"}
                multiselect={false}
                selectedOption={getSelectedValue(
                  subregionOptions,
                  dropoffLocation.subregionId
                )}
                error={error.subregionId}
                handleChangeSelect={(e) => handleSelect(e, "subregionId")}
                selectClassname="selectGray"
                placeholder={""}
              />
            </div>
            <div className={styles.selectRegion}>
              <Selector
                options={storeOptions}
                label={"Connected to Store"}
                multiselect={false}
                selectedOption={getSelectedValue(
                  storeOptions,
                  dropoffLocation.restaurant_id
                )}
                handleChangeSelect={(e) => handleSelect(e, "restaurant_id")}
                selectClassname="selectGray"
                placeholder={""}
                disabled={!dropoffLocation.regionId}
              />
            </div>
            <div className={styles.location}>
              <GeoInput
                error={error.location}
                onChangeLocation={onChangeLocation}
                editLocation={editLocation}
                name={t.dropoff.text_input_address}
                inputVal={dropoffLocation.location}
              />
            </div>
            <div className={styles.map}>
              <span>Return Bin Location</span>
              <div className={styles.coords}>
                <Input
                  label={"Latitude"}
                  error={error.lat}
                  name="lat"
                  inputClass="inputForm"
                  type="number"
                  value={locationCoordinates?.lat}
                  onChange={handleChange}
                />
                <Input
                  label={"Longitude"}
                  error={error.lng}
                  name="lng"
                  inputClass="inputForm"
                  type="number"
                  value={locationCoordinates?.lng}
                  onChange={handleChange}
                />
              </div>
              <SelectLocationMap
                location={locationCoordinates}
                setLocation={setLocationCoordinates}
              />
            </div>{" "}
          </div>
          <div className={styles.right}>
            <div className={styles.workingTime}>
              <p>{t.dropoff.text_working_hours}</p>
              <hr />
              <Checkbox
                label={"Set the same working hours Mon-Sun"}
                customClass="checkbox"
                onChange={() => {
                  setAllWorkingHours((state) => !state);
                }}
                checked={allWorkingHours}
              />

              {days.map((day) => (
                <WorkingDay
                  key={day}
                  day={day}
                  handleChangeWorktime={handleChangeWorktime}
                  workingTime={dropoffLocation?.workingTime}
                  handleClosedDay={handleClosedDay}
                />
              ))}
              <hr />
            </div>
            <Input
              label={"Container Limit"}
              name="maxContainersAmount"
              inputClass="inputForm"
              type="number"
              value={dropoffLocation.maxContainersAmount}
              onChange={handleChange}
              onKeyPress={handleInputNumber}
            />

            <div className={styles.checkbox}>
              <Checkbox
                customClass="checkbox"
                label={t.dropoff.text_is_visible}
                checked={dropoffLocation.visible}
                onChange={handleCheckVisible}
              />
              <Checkbox
                customClass="checkbox"
                label={"Is this location Smart Bin?"}
                checked={dropoffLocation.isSmartBin}
                onChange={handleCheckSmartBin}
              />
              <Checkbox
                customClass="checkbox"
                label={"Is this location Smart Return Station?"}
                checked={dropoffLocation.isSRS}
                onChange={() => {
                  setDropoffLocation((prevState) => ({
                    ...prevState,
                    isSRS: !prevState.isSRS,
                    containerAssignment: "",
                  }));
                }}
              />
              {dropoffLocation.isSRS && (
                <div className={styles.selectCleaning}>
                  <Selector
                    options={containerAssignmentOptions}
                    multiselect={false}
                    selectedOption={getSelectedValue(
                      containerAssignmentOptions,
                      dropoffLocation.containerAssignment
                    )}
                    handleChangeSelect={(e) =>
                      handleSelect(e, "containerAssignment")
                    }
                    selectClassname="selectGray"
                    label={"Container Scan"}
                  />
                </div>
              )}
            </div>
            {dropoffLocation.isRestaurant && (
              <div className={styles.selectCleaning}>
                <Selector
                  options={cleaningOptions}
                  multiselect={false}
                  selectedOption={getSelectedValue(
                    cleaningOptions,
                    dropoffLocation.isCleaning
                  )}
                  handleChangeSelect={(e) => handleSelectCleaning(e)}
                  selectClassname="selectGray"
                  placeholder={t.dropoff.text_select_cleaning_option}
                />
              </div>
            )}
            <div className={styles.options}>
              {(!editLocation || (editLocation && dropoffLocation.isDraft)) && (
                <Button
                  btnClass="btnNormalCasalLight"
                  label="Save Draft"
                  type="button"
                  onClick={handleSaveDraft}
                />
              )}
              <Button
                btnClass="btnNormal"
                label={
                  !editLocation || (editLocation && dropoffLocation.isDraft)
                    ? "Save and Publish"
                    : t.button.text_save
                }
                type="submit"
                onClick={handleSubmit}
                disabled={submitDisabled}
              />
            </div>
          </div>{" "}
        </div>
      </form>
      {errorMsg ? (
        <div className={styles.error}>
          <p>{t.common.text_all_fields_required}</p>
        </div>
      ) : null}
    </div>
  );
};

export default DropoffForm;
