import React, { useState, useEffect } from "react";
import styles from "../regionManagement/regionManagement.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import toastService from "../../../_services/toast.service";

import Button from "../../button";
import Input from "../../input";
import Textarea from "../../textarea";
import Loading from "../../loading";
import ConfirmModal from "../../modal/confirmModal";
import Selector from "./../../selector";
import { IoMdCopy } from "react-icons/io";
import UploadAvatar from "../../uploadAvatar";

import t from "./../../../_config/constants";
import trimObjectProps from "../../../_utils/trimObjectProps";
import { makeSelectMap, validateURL } from "../../../_utils/utils";
import { userAssignmentOptions } from "../../restaurant/addRestaurant/utils";
import { availableLanguages } from "../utils";
import { amazonUrl, amazonUrlResized, webAppUrl } from "../../../environment";
import GeoInput from "../../googleMaps/geoInput";
import Checkbox from "../../checkbox";
import { uploadToS3 } from "../../../_utils/auth/uploadPhoto";
const SubRegionManagement = ({
  onRegionClick,
  onSubRegionClick,
  onAdminClick,
  onNotificationsClick,
  onPostalCodesClick,
  activeTab,
}) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [loading, setLoading] = useState(true);
  const [subregions, setSubegions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);

  const [error, setError] = useState({
    name: false,
    shortName: false,
    description: false,
    days_to_return: false,
    regionId: false,
    checkoutType: false,
    location: false,
  });
  const [errorMsg, setErrorMsg] = useState(false);
  const [editing, setEditing] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalData, setModalData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [copied, setCopied] = useState(false);
  const getAllSubRegions = async () => {
    let response = await dispatch(
      mainActions.run("data", "subregion", "all", {})
    );
    setSubegions(response);
    setLoading(false);
  };
  useEffect(() => {
    getAllSubRegions();
  }, []);

  useEffect(() => {
    if (
      store.main &&
      store.main.data &&
      store.main.data.region &&
      store.main.data.region.list
    ) {
      let arr = makeSelectMap(store.main.data.region.list);
      setRegionOptions(arr);
    }
    setLoading(false);
  }, [store.main.data && store.main.data.region]);

  const [selected, setSelected] = useState({
    name: "",
    shortName: "",
    description: "",
    days_to_return: "",
    regionId: "",
    location: "",
    checkoutType: [],
    openNetwork: false,
    institution: false,
    defaultLanguage: [],
    avatarURL: "",
    avatarURLResized: "",
    nubpnSMSLink: "",
  });
  const [image, setImage] = useState({
    uri: selected.avatarURL ? selected.avatarURL : "",
  });
  const handleChange = (e) => {
    e.preventDefault();
    setError((error) => ({ ...error, [name]: !value }));
    setErrorMsg(false);
    let { name, value } = e.target;
    if (name === "days_to_return" && value.slice(0, 1) === "0") {
      value = value.slice(1);
    }
    setSelected((selected) => ({ ...selected, [name]: value }));
  };

  const onChangeLocation = (value, coordinates) => {
    const obj = { ...selected };
    obj["location"] = value;
    obj["coordinates"] = coordinates;
    setSelected(obj);
    setError((error) => ({ ...error, location: !value }));
  };
  const handleSelectEdit = (item) => {
    setEditing(true);
    setSelected(item);
    setImage({ uri: item.avatarURL || "" });
  };

  const handleCancel = () => {
    setEditing(false);
    setError({
      name: false,
      description: false,
      days_to_return: false,
      regionId: false,
      shortName: false,
      location: false,
    });
    setSelected({
      name: "",
      shortName: "",
      location: "",
      description: "",
      days_to_return: "",
      regionId: "",
      openNetwork: false,
      institution: false,
      checkoutType: [],
      defaultLanguage: [],
      avatarURL: "",
      avatarURLResized: "",
      nubpnSMSLink: "",
    });
    setImage({ uri: "" });
    setErrorMsg(false);
  };

  const handleSubmit = async () => {
    const validForm = await validateForm();
    if (validForm) {
      setDisabled(true);
      const imgUrl = await uploadToS3(image);

      let response = await dispatch(
        mainActions.run("data", "subregion", editing ? "update" : "new", {
          ...selected,
          avatarURL: imgUrl ? amazonUrl + imgUrl : selected.avatarURL,
          avatarURLResized: imgUrl
            ? amazonUrlResized + imgUrl
            : selected.avatarURLResized,
        })
      );
      if (response)
        toastService.show("success", t.toast.text_successfully_submited);
      getAllSubRegions();
      handleCancel();
    } else {
      toastService.show("error", `${t.common.text_toast_all_fields_required}`);
      setErrorMsg(true);
    }
    setDisabled(false);
  };

  const validateForm = async () => {
    trimObjectProps(selected);
    const err = {
      name: !selected.name,
      shortName: !selected.shortName,
      location: !selected.location,
      description: !selected.description,
      days_to_return: !selected.days_to_return,
      regionId: !selected.regionId,
      checkoutType: !selected.checkoutType,
    };
    const nameExists = await dispatch(
      mainActions.run("data", "subregion", "get", {
        filter: [{ _id: { $ne: selected._id } }, { name: selected.name }],
      })
    );
    const shortNameExists = await dispatch(
      mainActions.run("data", "subregion", "get", {
        filter: [
          { _id: { $ne: selected._id } },
          { shortName: selected.shortName },
        ],
      })
    );
    if (nameExists._id || shortNameExists._id) {
      toastService.show(
        "error",
        `Subregion name and/or short name already in use. Please choose a different name.`
      );
      setError({
        ...err,
        shortName: !!shortNameExists._id,
        name: !!nameExists._id,
      });
      return false;
    }
    setError(err);

    return (
      selected.name &&
      selected.shortName &&
      selected.location &&
      selected.description &&
      selected.days_to_return &&
      selected.regionId &&
      selected.checkoutType?.length &&
      selected.defaultLanguage?.length &&
      (!selected.nubpnSMSLink || validateURL(selected.nubpnSMSLink))
    );
  };

  const openDeleteModal = async (e) => {
    e.preventDefault();
    setShowModalDelete(true);
    setModalData(selected);
  };

  const handleDelete = async (answer, data) => {
    if (answer) {
      await dispatch(
        mainActions.run(
          "data",
          "subregion",
          "delete",
          { _id: data._id, type: "subregion" },
          { message: "Successfully deleted subregion" }
        )
      );
      getAllSubRegions();
      handleCancel();
      setShowModalDelete(false);
      setModalData({});
    } else {
      setShowModalDelete(false);
    }
  };
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const handleSelect = (e, name) => {
    if (name === "checkoutType" && e.length === 0) {
      setSelected((data) => ({
        ...data,
        checkoutType: e,
      }));
    } else if (name === "checkoutType") {
      const val = e.map((obj) => obj.value);
      setSelected((data) => ({
        ...data,
        checkoutType: val,
      }));
    } else if (name === "defaultLanguage") {
      const val = e.map((obj) => obj.value);
      setSelected((data) => ({
        ...data,
        defaultLanguage: val,
      }));
    } else {
      setError((error) => ({ ...error, [name]: false }));
      setSelected((data) => ({
        ...data,
        [name]: e.value,
      }));
    }
    setErrorMsg(false);
  };

  const getMultiSelectedValues = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (value?.findIndex((item) => item === obj.value) !== -1) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const handleInputNumber = (e) => {
    const numbers = "0123456789";
    if (!numbers.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleChooseImg = (e) => {
    e.preventDefault();
    let set = {
      uri: URL.createObjectURL(e.target.files[0]),
      type: e.target.files[0].type,
      file: e.target.files[0],
    };
    setImage(set);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 650);
  };

  return (
    <div className={styles.wrapper}>
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={handleDelete}
          modalData={modalData}
          question={t.admin.regions.text_modal_delete}
        />
      )}
      <div className={styles.left}>
        <div className={styles.header}>
          <p
            className={
              activeTab === "region" ? styles.tabActive : styles.tabInactive
            }
            onClick={onRegionClick}
          >
            {t.admin.regions.text_regions}
          </p>
          <p
            className={
              activeTab === "subregion" ? styles.tabActive : styles.tabInactive
            }
            onClick={onSubRegionClick}
          >
            {t.admin.regions.text_subregions}
          </p>
          <p
            className={
              activeTab === "codes" ? styles.tabActive : styles.tabInactive
            }
            onClick={onPostalCodesClick}
          >
            {t.admin.regions.text_region_postal_codes}
          </p>
          <p
            className={
              activeTab === "notifications"
                ? styles.tabActive
                : styles.tabInactive
            }
            onClick={onNotificationsClick}
          >
            {t.admin.regions.text_region_notifications}
          </p>
          <p
            className={
              activeTab === "admin" ? styles.tabActive : styles.tabInactive
            }
            onClick={onAdminClick}
          >
            {t.admin.admins.text_admins}
          </p>
        </div>
        <h2>{t.admin.regions.text_list_of_subregions}</h2>
        {loading && <Loading width={50} height={50} />}
        {subregions && subregions.length > 0 && (
          <div className="custom-table">
            <table>
              <thead>
                <tr className="tb-head-th">
                  <th>{t.admin.regions.text_th_name}</th>{" "}
                  <th>{t.admin.regions.text_input_short_name}</th>{" "}
                  <th>{t.admin.regions.text_th_region}</th>{" "}
                  <th>{t.admin.regions.text_th_description}</th>
                  <th>{t.admin.regions.text_th_return_days}</th>
                  <th>{t.admin.regions.text_web_app_link}</th>{" "}
                </tr>
              </thead>
              <tbody>
                {subregions?.map((item) => (
                  <React.Fragment key={item._id}>
                    <tr
                      onClick={() => handleSelectEdit(item)}
                      className={
                        selected._id === item._id ? styles.selected : ""
                      }
                    >
                      <td>{item.name}</td>
                      <td>{item.shortName}</td>
                      <td>{item.parentRegionName}</td>
                      <td>{item.description}</td>
                      <td>{item.days_to_return}</td>{" "}
                      <td
                        onClick={() => {
                          handleCopy(
                            `${webAppUrl}?regionId=${item.regionId}&subregion=${item._id}`
                          );
                        }}
                        title="Copy to clipboard"
                        className={styles.copyToClipboard}
                      >
                        <IoMdCopy
                          color={
                            copied && selected._id == item._id
                              ? "#00C853"
                              : "#000000"
                          }
                        />
                        {copied && selected._id == item._id
                          ? "Copied!"
                          : `${webAppUrl}?regionId=${item.regionId}&subregion=${item._id}`}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {!loading && subregions.length < 1 && (
          <p>{t.admin.regions.text_no_subregions}</p>
        )}
      </div>
      <div className={styles.right}>
        <h2>
          {editing
            ? t.admin.regions.text_edit_subregion
            : t.admin.regions.text_create_new_subregion}
        </h2>
        <div className={styles.content}>
          <Input
            label={t.admin.regions.text_input_name_subregion}
            error={error.name}
            name="name"
            inputClass="inputLogin"
            type="text"
            errorMsg={"Must be unique."}
            placeholder={t.admin.regions.text_input_name_subregion}
            onChange={handleChange}
            value={selected.name}
          />
          <Input
            label={t.admin.regions.text_input_short_name}
            error={error.shortName}
            name="shortName"
            inputClass="inputLogin"
            type="text"
            errorMsg={"Must be unique."}
            placeholder={t.admin.regions.text_input_short_name}
            onChange={handleChange}
            value={selected.shortName}
          />
          <div className={styles.selector}>
            <Selector
              label={t.admin.admins.text_input_region_selection}
              error={error.regionId}
              placeholder={t.admin.admins.text_input_region_placeholder}
              multiselect={false}
              options={regionOptions}
              selectedOption={getSelectedValue(
                regionOptions,
                selected.regionId
              )}
              handleChangeSelect={(e) => handleSelect(e, "regionId")}
              selectClassname="selectPrimary"
            />
          </div>
          <div className={styles.geoInput}>
            <GeoInput
              error={error.location}
              onChangeLocation={onChangeLocation}
              editLocation={selected._id}
              inputVal={selected.location}
              name={"Default map center"}
            />
          </div>
          <div className={styles.selector}>
            <Selector
              label={t.admin.admins.text_default_langiage}
              error={!selected?.defaultLanguage?.length}
              placeholder={"Select..."}
              multiselect={true}
              options={availableLanguages}
              selectedOption={getMultiSelectedValues(
                availableLanguages,
                selected.defaultLanguage
              )}
              handleChangeSelect={(e) => handleSelect(e, "defaultLanguage")}
              selectClassname="selectPrimary"
            />
          </div>
          <Textarea
            label={t.admin.regions.text_input_description_subregion}
            error={error.description}
            name="description"
            textareaClass="textareaDefault"
            type="text"
            placeholder={t.admin.regions.text_input_description_subregion}
            onChange={handleChange}
            value={selected.description}
          />
          <Input
            label={t.admin.regions.text_input_days_to_return_subregion}
            error={error.days_to_return}
            name="days_to_return"
            inputClass="inputLogin"
            type="number"
            value={selected.days_to_return}
            onChange={handleChange}
            onKeyPress={handleInputNumber}
          />
          <Input
            label={t.admin.regions.text_input_default_price}
            error={error.defaultContainerPrice}
            name="defaultContainerPrice"
            inputClass="inputLoginDesc"
            desc="%"
            type="text"
            value={selected.defaultContainerPrice}
            onChange={handleChange}
          />
          <Input
            label={t.admin.regions.text_input_nubpn_sms_link}
            error={selected.nubpnSMSLink && !validateURL(selected.nubpnSMSLink)}
            name="nubpnSMSLink"
            inputClass="inputLogin"
            type="text"
            value={selected.nubpnSMSLink}
            onChange={handleChange}
            errorMsg={"Please enter valid URL"}
          />
          <div className={styles.selector}>
            <Selector
              label={t.admin.admins.text_checkout_type}
              error={!selected.checkoutType?.length}
              placeholder={"Select..."}
              multiselect={true}
              options={userAssignmentOptions}
              selectedOption={getMultiSelectedValues(
                userAssignmentOptions,
                selected.checkoutType
              )}
              handleChangeSelect={(e) => handleSelect(e, "checkoutType")}
              selectClassname="selectPrimary"
            />
          </div>
          <div className={styles.institution}>
            <div className={styles.checkbox}>
              <Checkbox
                customClass="checkbox"
                name="institution"
                label={"Institution"}
                onChange={() => {
                  setSelected((data) => ({
                    ...data,
                    institution: !data.institution,
                  }));
                }}
                checked={selected.institution}
              />
            </div>
            {selected.institution && (
              <UploadAvatar
                uri={image.uri}
                setImg={handleChooseImg}
                type="logo"
              />
            )}
          </div>
        </div>
        {errorMsg ? (
          <div className={styles.error}>
            <p>{t.admin.regions.text_all_fields_required}</p>
          </div>
        ) : null}
        <div className={styles.options}>
          <div className={styles.submit}>
            <Button
              btnClass="btnWhiteBackground"
              label={t.button.text_cancel}
              onClick={handleCancel}
            />
            <Button
              btnClass="btnNormalCasal"
              label={editing ? t.button.text_save : t.button.text_create}
              onClick={handleSubmit}
              disabled={disabled}
            />
          </div>
          {editing && (
            <div>
              <Button
                btnClass="btnNormal"
                label={t.button.text_delete}
                onClick={(e) => openDeleteModal(e, selected)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubRegionManagement;
