import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../_store/actions/user.actions";
import styles from "../auth.module.scss";
import Input from "../../input";
import Checkbox from "../../checkbox";
import Button from "../../button";
import logo from "../../../_assets/images/logo.svg";
import logoAuth from "../../../_assets/images/logoAuth.svg";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import t from "../../../_config/constants";
import trimObjectProps from "../../../_utils/trimObjectProps";
import restaurantLogo from "../../../_assets/images/reusablesRestaurantAppLogo.jpg";
import userLogo from "../../../_assets/images/reusablesAppLogo.jpg";
const Login = () => {
  const store = useSelector((state) => state);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const [inputType, setInputType] = useState("password");
  const handleChange = (e) => {
    setError(false);
    const { name, value } = e.target;
    setUserData((userData) => ({ ...userData, [name]: value }));
  };

  const onCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setUserData((userData) => ({ ...userData, [name]: checked }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(userActions.login(userData));
    } else {
      setError(true);
    }
  };

  const validateForm = () => {
    trimObjectProps(userData);
    return userData.email && userData.password;
  };
  const onClickEyeIcon = () => {
    setInputType((prevState) =>
      prevState === "password" ? "text" : "password"
    );
  };
  const getOperatingSystem = (window) => {
    let operatingSystem = "Not known";
    if (window.navigator.appVersion.indexOf("Mac") !== -1) {
      operatingSystem = "MacOS";
    } else {
      operatingSystem = "other";
    }
    return operatingSystem;
  };
  const os = getOperatingSystem(window);
  if (store.auth.user) return <Redirect to="/" />;
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img src={logoAuth} alt="Logo" />
      </div>
      <div className={styles.container}>
        <div className={styles.c}>
          <div className={styles.logo}>
            <img src={logo} alt="Logo" />
          </div>
          <div className={styles.info}>{t.auth.text_welcome}</div>
          <form onSubmit={handleSubmit} className={styles.form}>
            <h2>{t.auth.text_login} </h2>
            <section>
              <div>
                <Input
                  name="email"
                  inputClass="inputLogin"
                  type="email"
                  placeholder={t.auth.text_markup_mail}
                  onChange={handleChange}
                  value={userData.email.trim()}
                />
              </div>
              <div>
                <Input
                  name="password"
                  inputClass="inputLogin"
                  type={inputType}
                  onChange={handleChange}
                  value={userData.password}
                  placeholder={t.auth.text_password_placeholder}
                  showEyeIcon={true}
                  onClickEyeIcon={onClickEyeIcon}
                />
              </div>
              <div className={styles.forgotPassword}>
                <div>
                  <Checkbox
                    customClass="checkbox"
                    name="rememberMe"
                    label={t.auth.text_save_password}
                    onChange={onCheckboxChange}
                    value={userData.rememberMe}
                  />
                </div>
                <div className={styles.forgotPasswordLink}>
                  <Link to="/forgot-pass">{t.auth.text_forgot_password}</Link>
                </div>
              </div>
              {error ? (
                <div className="message-alert">
                  <p>{t.auth.text_all_fields_required}</p>
                </div>
              ) : null}
            </section>
            <Button
              label={t.auth.text_login}
              btnClass="btnLogin"
              type="submit"
            />
          </form>
          <div className={styles.links}>
            <h4>Reusables Apps are just one click away!</h4>
            <div>
              <div className={styles.link}>
                <a
                  href={
                    os === "other"
                      ? "https://play.google.com/store/apps/details?id=com.circular.reusables.restaurant"
                      : "https://apps.apple.com/au/app/reusables-restaurant/id1570782295"
                  }
                >
                  <img src={restaurantLogo} alt={""}></img>
                </a>
              </div>
              <div className={styles.link}>
                <a
                  href={
                    os === "other"
                      ? "https://play.google.com/store/apps/details?id=com.circular.reusables"
                      : "https://apps.apple.com/us/app/reusables/id1570782074"
                  }
                >
                  <img src={userLogo} alt={""}></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
