import { authConstants } from '../constants';
const user = JSON.parse(localStorage.getItem('user'));
const initialUser = user ? user : null;
const initialState = {
  user: initialUser,
  forgotPassword: false,
  showConfirm: false,
}
export const auth = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case authConstants.LOGIN_FAILURE:
      return {
        ...state
      };
    case authConstants.UPDATE_USER_REQUEST:
      return {
        ...state
      };
    case authConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.user
      };
    case authConstants.UPDATE_USER_FAILURE:
      return {
        ...state
      };
    case authConstants.ADMIN_REGISTER_SUCCESS:
      return {
        ...state
      };
    case authConstants.RESET_LINK_REQUEST:
      return {
        ...state,
        forgotPassword: true
      };
    case authConstants.RESET_LINK_SUCCESS:
      return {
        ...state,
        forgotPassword: false,
        showConfirm: true
      };
    case authConstants.RESET_LINK_FAILURE:
      return {
        ...state,
        forgotPassword: false
      };
    case authConstants.RESET_FORGOT_PASSWORD:
      return {
        ...state,
        showConfirm: false
      };
    case authConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state
      };

    case authConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        user: action.user
      };

    case authConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state
      };

    case authConstants.LOGOUT:
      return {
        ...state,
        user: null
      };
    case authConstants.RESTAURANT_REGISTER_SUCCESS:
      return {
        ...state
      };
    case authConstants.CLEANER_REGISTER_SUCCESS:
      return {
        ...state
      };
    default:
      return state
  }
}