import React, { useEffect, useState } from "react";
import styles from "./impact.module.scss";
import { useSelector } from "react-redux";
import { getStatistics } from "../../../_services/statistics.service";
import { ReactComponent as WaterImg } from "../../../_assets/icons/water.svg";
import { ReactComponent as CO2Img } from "../../../_assets/icons/co2.svg";
import { ReactComponent as TrashImg } from "../../../_assets/icons/icon _trash.svg";
import { ReactComponent as HelpIcon } from "../../../_assets/icons/icon _help.svg";
import Loading from "../../../_components/loading";
const Impact = () => {
  const store = useSelector((state) => state);
  const [impactData, setImpactData] = useState([]);
  const [hoveredTooltipId, setHoveredTooltipId] = useState(null);
  useEffect(() => {
    const getStats = async (subregionId) => {
      try {
        const response = await getStatistics({
          mode: "get-subregion-impact",
          filter: { subregionId },
        });
        if (response) {
          setImpactData(response);
        }
      } catch (err) {}
    };

    if (store?.auth?.user?.subregionId) {
      getStats(store.auth.user.subregionId);
    }
  }, [store.auth && store.auth.user]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.wideBox}>
          <p>Total Reuses</p>
          {impactData.reusedContainers ? (
            <p className={styles.large}> {impactData.reusedContainers}</p>
          ) : (
            <div className={styles.loader}>
              <Loading width={10} height={10} />
            </div>
          )}
        </div>
        <div className={styles.wideBox}>
          <p>Return Rate</p>
          {impactData.returnRate ? (
            <p className={styles.large}>{impactData.returnRate}%</p>
          ) : (
            <div className={styles.loader}>
              <Loading width={10} height={10} />
            </div>
          )}
        </div>
      </div>
      <h2>Environmental Impact Overview</h2>
      <div className={styles.row}>
        <div className={styles.imgBox}>
          <TrashImg />
          <div className={styles.p}>
            Disposable Waste Diversion
            <div
              className={styles.help}
              onMouseEnter={() => setHoveredTooltipId(1)}
              onMouseLeave={() => setHoveredTooltipId(null)}
            >
              <HelpIcon />
            </div>
            {hoveredTooltipId == 1 && (
              <div className={`${styles.tooltip}`}>
                Waste diversion impact is based on the weight of equivalent
                single-use containers that are being replaced by Reusables. For
                example, most single-use food containers weigh 50 grams and
                coffee cups - 30 grams. For more precise information, contact
                the team at Reusables.
              </div>
            )}
          </div>

          {impactData.wasteAvoided ? (
            <p className={styles.large}> {impactData.wasteAvoided} tons</p>
          ) : (
            <div className={styles.loader}>
              <Loading width={10} height={10} />
            </div>
          )}
        </div>
        <div className={styles.imgBox}>
          <CO2Img />
          <div className={styles.p}>
            Carbon Emissions Saved
            <div
              className={styles.help}
              onMouseEnter={() => setHoveredTooltipId(2)}
              onMouseLeave={() => setHoveredTooltipId(null)}
            >
              <HelpIcon />
            </div>
            {hoveredTooltipId == 2 && (
              <div className={`${styles.tooltip}`}>
                Carbon emissions impact is based on many factors that affect the
                reuse system like the container material, return rate,
                cleanliness of the local electricity grid, distance between the
                cleaning facility and where the containers are being used, as
                well as other factors. To get the specific information on the
                LCA for your reuse system, contact the team at Reusables.
              </div>
            )}
          </div>
          {impactData.emissionsAvoided ? (
            <p className={styles.large}> {impactData.emissionsAvoided} tons</p>
          ) : (
            <div className={styles.loader}>
              <Loading width={10} height={10} />
            </div>
          )}
        </div>
        <div className={styles.imgBox}>
          <WaterImg />
          <div className={styles.p}>
            Water Saved
            <div
              className={styles.help}
              onMouseEnter={() => setHoveredTooltipId(3)}
              onMouseLeave={() => setHoveredTooltipId(null)}
            >
              <HelpIcon />
            </div>
            {hoveredTooltipId == 3 && (
              <div className={`${styles.tooltip_left}`}>
                Water savings are based on the amount of water saved with every
                reuse compared to single-use packaging. Production of single-use
                containers is highly water-intensive because it's being used
                throughout the production process: first in raw material
                extraction, then in plastic resin production, and lastly in
                molding and shaping. It is hard to believe but even with all the
                dishwashing involved in reuse, reuse still saves water compared
                to single-use plastics. For more precise information, contact
                the team at Reusables.
              </div>
            )}
          </div>
          {impactData.waterSaved ? (
            <p className={styles.large}> {impactData.waterSaved} litres</p>
          ) : (
            <div className={styles.loader}>
              <Loading width={10} height={10} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Impact;
