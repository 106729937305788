import React, { useState, useEffect } from "react";
import styles from "./restaurantManagement.module.scss";
import RestaurantsList from "../../_components/restaurant/restaurantsList/index";
import SingleRestaurant from "../../_components/restaurant/singleRestaurant";
import queryString from "query-string";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useIsCleaner from "../../_utils/isCleaner";
import SingleGroup from "../../_components/restaurantGroups/singleGroup";
import GroupList from "../../_components/restaurantGroups/groupList";
import ArchivedStoresList from "../../_components/archivedRestaurants/archivedList";
import SingleArchivedStore from "../../_components/archivedRestaurants/singleArchivedStore";

const RestaurantManagement = () => {
  const history = useHistory();
  const { id } = queryString.parse(history.location.search);
  const isCleaner = useIsCleaner();
  useEffect(() => {
    isCleaner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [activeTab, setActiveTab] = useState("single");

  const [selectedRestaurantId, setSelectedRestaurantId] = useState(
    id ? id : ""
  );
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [selectedArchivedRestaurantId, setSelectedArchivedRestaurantId] =
    useState("");

  const [refreshList, setRefreshList] = useState(1);

  const selectRestaurantHandler = (id) => {
    setSelectedRestaurantId(id);
  };
  const refreshRestaurantList = () => {
    setRefreshList((prev) => (prev === 1 ? 2 : 1));
  };

  return (
    <div className={styles.wrapper}>
      {activeTab === "single" ? (
        <>
          <div className={styles.left}>
            <RestaurantsList
              selectedRestaurantId={selectedRestaurantId}
              onSelectRestaurant={selectRestaurantHandler}
              refreshList={refreshList}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          </div>
          <div className={styles.right}>
            <SingleRestaurant
              restaurantId={selectedRestaurantId}
              getAllRestaurants={refreshRestaurantList}
            />
          </div>
        </>
      ) : activeTab === "groups" ? (
        <>
          <div className={styles.left}>
            <GroupList
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              refreshList={refreshList}
              selectedGroupId={selectedGroupId}
              onSelectGroup={(id) => {
                setSelectedGroupId(id);
              }}
              handleRefreshList={refreshRestaurantList}
            />
          </div>
          <div className={styles.right}>
            <SingleGroup
              groupId={selectedGroupId}
              refreshList={refreshRestaurantList}
              refreshGroup={refreshList}
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.left}>
            <ArchivedStoresList
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              handleRefreshList={refreshRestaurantList}
              onSelectRestaurant={(id) => {
                setSelectedArchivedRestaurantId(id);
              }}
              refreshList={refreshList}
              selectedRestaurantId={selectedArchivedRestaurantId}
            />
          </div>
          <div className={styles.right}>
            <SingleArchivedStore
              getAllRestaurants={refreshRestaurantList}
              restaurantId={selectedArchivedRestaurantId}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default RestaurantManagement;
