import React, { useState, useEffect } from "react";
import styles from "./../resolveReport/resolveReport.module.scss";
import Input from "./../../input";
import Button from "../../button";
import { useDispatch } from "react-redux";
import { refundUser } from "../../../_services/payment.service";
import toastService from "../../../_services/toast.service";
import { mainActions } from "../../../_store/actions/main.actions";
import Loading from "../../loading";
import t from "../../../_config/constants";
const RefundReport = ({ report, user, overdue, handleClose }) => {
  const dispatch = useDispatch();
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    user: user,
    amount: "",
    paymentIntentId: "",
    id: "",
  });

  useEffect(() => {
    const getTransaction = async (report) => {
      setLoading(true);
      let filter = [
        {
          transactionType: "CHARGE_CONTAINER",
          "container._id": report.containerId,
          "container.days_to_return": report.days_to_return,
          "user._id": report.user_id,
        },
      ];
      let transaction = await dispatch(
        mainActions.run("transactions", "transaction", "get", { filter })
      );
      setData((prevState) => ({
        ...prevState,
        amount: transaction?.amount,
        paymentIntentId: transaction?.paymentIntentId,
        id: transaction?.id,
      }));
      setLoading(false);
    };
    if (report) getTransaction(report);
  }, [report]);
  const handleRefund = async () => {
    if (validateData()) {
      setBtnDisable(true);
      let response = await refundUser(data);
      if (response) {
        await dispatch(
          mainActions.run(
            "overdueContainers",
            "chargedOverdueContainer",
            "update",
            {
              ...report,
              isResolved: true,
            }
          )
        );

        handleClose();
        toastService.show("success", t.refund.text_successfully_refunded);
      }
      setBtnDisable(false);
    }
  };
  const validateData = () => {
    return (
      data.amount >= 1 &&
      data.paymentIntentId &&
      data.id &&
      data.user.email &&
      data.user.customer
    );
  };

  return (
    <div className={styles.wrapper}>
      <h2>{t.report.text_resolve_report}</h2>
      <div className={styles.tableWrapper}>
        <table>
          <thead>
            <tr>
              <th>{t.report.text_user}</th>
              <th>{t.report.text_container}</th>
              <th>{t.report.text_overdue}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{user ? user.fullName : t.common.text_not_available}</td>
              <td>{report.name}</td>
              <td>{overdue ? overdue : t.report.text_on_time}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {loading && (
        <div className={styles.loading}>
          <Loading width={50} height={50} />
        </div>
      )}
      {!loading && (
        <div className={styles.input}>
          <div>
            <Input
              label="Amount charged"
              inputClass="inputForm"
              type="number"
              name="amount"
              value={data.amount}
              disabled={true}
              desc={"$"}
            />
          </div>
        </div>
      )}
      <Button
        btnClass="btnNormalCasal"
        label="Refund"
        disabled={!data.amount || data.amount < 1 || !user || btnDisable}
        onClick={handleRefund}
      />
    </div>
  );
};
export default RefundReport;
