import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getHoursInADay } from "../../../_utils/utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ dataForBarChart, getTodaysStats }) => {
  const [barLabels, setLineLabels] = useState([]);
  const [barData, setLineData] = useState([]);
  const [emptiedBinData, setEmptiedBinData] = useState([]);
  useEffect(() => {
    const dataMap = {};
    const emptiedFlag = {};
    const lastHour = getTodaysStats ? new Date().getHours() : 23;
    for (const [key, value] of Object.entries(dataForBarChart)) {
      if (key <= lastHour) {
        dataMap[key] = value.quantity;
        emptiedFlag[key] = value.emptied;
      } else {
        dataMap[key] = 0;
        emptiedFlag[key] = 0;
      }
    }
    const valueArr = Object.values(dataMap);
    const labelsArr = getHoursInADay();
    setLineData(valueArr);
    setLineLabels(labelsArr);
    setEmptiedBinData(Object.values(emptiedFlag));
  }, [dataForBarChart]);

  const data = {
    labels: barLabels,
    datasets: [
      {
        label: "Number of containers",
        data: barData,
        backgroundColor: "#D5E5EB",
        borderColor: "#D5E5EB",
        borderWidth: 1,
      },
      {
        label: "Bin Emptied",
        data: emptiedBinData,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};
export default BarChart;
