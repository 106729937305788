import httpService from "./http.service";
class FileService {
  async getSignedURL(type, ext) {
    return httpService.apiRequest(
      "get",
      `/api/file/document/signedURL?type=${type}&ext=${ext}`
    );
  }
}

export default new FileService();
