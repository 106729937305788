import React, { useEffect, useState } from "react";
import Button from "../../button";
import Input from "../../input";
import FormModal from "../../modal/formModal";
import AddCleaner from "../addCleaner";
import styles from "./singleCleaner.module.scss";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import ConfirmModal from "../../modal/confirmModal";
import toastService from "../../../_services/toast.service";
import t from "../../../_config/constants";
import Avatar from "../../avatar";
import { modalActions } from "../../../_store/actions/modal.actions";
import EditEmail from "../../restaurant/editEmail";

const SingleCleaner = (props) => {
  const [cleaner, setCleaner] = useState("");
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [step, setStep] = useState("profile");
  const [loadingImg, setLoadingImg] = useState(true);

  const dispatch = useDispatch();

  const getSelectedCleaner = async (id) => {
    const response = await dispatch(
      mainActions.run("profiles", "cleaner", "id", id)
    );
    setCleaner(response);
    if (!response.avatarURL) setLoadingImg(false);
  };

  useEffect(() => {
    if (props.cleanerId) {
      getSelectedCleaner(props.cleanerId);
      setLoadingImg(true);
    }
  }, [props.cleanerId]);

  const openDeleteModal = () => {
    setShowModalDelete(true);
  };

  const handleDelete = async (answer) => {
    if (answer) {
      let response = await dispatch(
        mainActions.run("profiles", "cleaner", "delete", {
          _id: props.cleanerId,
        })
      );

      if (response) {
        toastService.show("success", t.cleaner.text_successfully_deleted);
        props.onDelete();
        setCleaner("");
        setShowModalDelete(false);
      }
    } else {
      setShowModalDelete(false);
    }
  };

  const editButtonHandler = () => {
    setShowEditForm(true);
  };
  const closeAddCleanerForm = () => {
    setShowEditForm(false);
    getSelectedCleaner(props.cleanerId);
    props.getAllCleaners();
  };
  const closeForm = () => {
    setShowEditForm(false);
  };
  return (
    <>
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={handleDelete}
          modalData={{}}
          question={t.cleaner.text_modal_delete}
        />
      )}
      {!cleaner && (
        <div className={styles.noData}>
          <span>{t.cleaner.text_select_guideline}</span>
        </div>
      )}
      {showEditForm && (
        <FormModal
          show={showEditForm}
          handleClose={closeForm}
          content={
            <AddCleaner
              editing={true}
              editCleaner={cleaner}
              handleClose={closeAddCleanerForm}
            />
          }
          size="xlg"
        />
      )}
      {cleaner && step === "profile" && (
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Button
              label={t.button.text_edit}
              btnClass="btnWhiteBackground"
              disabled={false}
              onClick={editButtonHandler}
            />
            <Button
              label={t.button.text_delete}
              btnClass="btnWhiteBackground"
              disabled={false}
              onClick={openDeleteModal}
            />
          </div>
          <div className={styles.cleanerInfos}>
            <Avatar
              imgUrl={cleaner.avatarURL}
              setLoadingImg={setLoadingImg}
              loadingImg={loadingImg}
            />
            <h2>{cleaner.fullName || ""}</h2>
            <p>{t.common.text_profile_info}</p>
            <form>
              <div className={styles.inputGroup}>
                <Input
                  label={t.common.input.text_input_email_address}
                  name="email"
                  inputClass="inputForm"
                  type="text"
                  value={cleaner.email || ""}
                  disabled={true}
                />
                <Input
                  label={t.common.input.text_input_phone_number}
                  name="phone"
                  inputClass="inputForm"
                  type="text"
                  value={cleaner.phone || ""}
                  disabled={true}
                />
              </div>
              <div className={styles.inputGroup}>
                <Input
                  label={t.common.input.text_input_full_name}
                  name="fullName"
                  inputClass="inputForm"
                  type="text"
                  value={cleaner.fullName || ""}
                  disabled={true}
                />
              </div>
              <div className={styles.inputGroup}>
                <Input
                  label={t.common.input.text_input_address}
                  name="address"
                  inputClass="inputForm"
                  type="text"
                  value={cleaner.address || ""}
                  disabled={true}
                />
              </div>
            </form>
          </div>
          <div className={styles.footer}>
            <Button
              onClick={() => {
                dispatch(
                  modalActions.openModal(
                    <EditEmail currentEmail={cleaner.email} />
                  )
                );
              }}
              label={"Edit Email"}
              btnClass="btnWhiteBackground"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SingleCleaner;
