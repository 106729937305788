import React, { useEffect, useState } from "react";
import Button from "../../../button";
import styles from "./containersAtStores.module.scss";
import { exportToCSV } from "../../../../_utils/exportToCSV";
import moment from "moment";
import { useSelector } from "react-redux";
import { getStatistics } from "../../../../_services/statistics.service";
import Datepicker from "../../../newDatepicker";

const ContainersAtStores = ({ containerTypes = [] }) => {
  const store = useSelector((state) => state);
  const [date, setDate] = useState(new Date());
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [restaurantsInventory, setRestaurantsInventory] = useState([]);
  const [totalContainers, setTotalContainers] = useState({});
  const [csvData, setCsvData] = useState([]);
  useEffect(() => {
    const getContainerAtStores = async (date, regionId) => {
      try {
        const filter = {};
        regionId !== "*" && (filter.regionId = regionId);
        if (
          date.getFullYear() === new Date().getFullYear() &&
          date.getMonth() === new Date().getMonth() &&
          date.getDate() === new Date().getDate()
        ) {
          filter.date = new Date().getTime();
        } else {
          filter.date = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
          ).getTime();
        }
        const response = await getStatistics({
          mode: "get-store-containers-statistics",
          filter: filter,
        });
        if (response) {
          const { csvInventory, restaurantsInventory, totalContainers } =
            response;
          setRestaurantsInventory(restaurantsInventory);
          setTotalContainers(totalContainers);
          setCsvData(csvInventory);
        }
      } catch (err) {}
    };

    if (date && store?.auth?.user?.regionId) {
      getContainerAtStores(date, store.auth.user.regionId);
    }
  }, [date, store.auth && store.auth.user]);
  const downloadData = () => {
    setLoadingCSV(true);
    if (csvData && csvData.length) {
      exportToCSV(
        csvData,
        `Stores Inventory - ${moment(date).format("MMM DD, YYYY")}`
      );
    }
    setLoadingCSV(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.topBar}>
        <h3>Stores Inventory</h3>
        <div className={styles.datepicker}>
          <Datepicker
            minDate={new Date(2021, 5, 1).getTime()}
            maxDate={new Date().getTime()}
            selected={date}
            onDatePickerChange={(val) => setDate(val)}
            name="date"
            placeholder="mm/dd/yyyy"
          />
        </div>
        <div
          className={
            loadingCSV || csvData.length === 0 ? styles.btnDisabled : ""
          }
        >
          <Button
            label={"Download"}
            onClick={downloadData}
            disabled={loadingCSV || csvData.length === 0}
          />
        </div>
      </div>
      <div className={styles.inventory}>
        <table>
          <thead>
            <tr>
              <th>Store</th>
              <th>Total</th>
              {containerTypes?.map((type) => (
                <th key={type?._id}>{type?.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={styles.firstTd}>All Stores</td>
              <td className={styles.firstTd}>{totalContainers?.total}</td>
              {containerTypes?.map((type, ind) => (
                <td key={ind} className={styles.firstTd}>
                  {totalContainers[type.name]}
                </td>
              ))}
            </tr>
            {restaurantsInventory?.map((restaurantInventory) => (
              <tr key={restaurantInventory?.restaurant_id}>
                <td>{restaurantInventory?.restaurant_name}</td>
                <td>{restaurantInventory?.inventory.total}</td>

                {containerTypes?.map((type, ind) => (
                  <td key={ind}>{restaurantInventory?.inventory[type.name]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ContainersAtStores;
