import React, { useEffect, useState } from "react";
import styles from "./refund.module.scss";
import RefundHistory from "./../../_components/refund/refundHistory";
import useIsCleaner from "../../_utils/isCleaner";
import TransactionsHistory from "../../_components/refund/transactions";
const Refund = () => {
  const [step, setStep] = useState(1);
  const [user, setUser] = useState({});
  const [showRestaurants, setShowRestaurants] = useState(true);
  const [showUsers, setShowUsers] = useState(false);
  const isCleaner = useIsCleaner();
  useEffect(() => {
    isCleaner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCheckboxChange = (e) => {
    const name = e.target.name;
    if (name === "restaurants") {
      setShowRestaurants(true);
      setShowUsers(false);
    } else {
      setShowRestaurants(false);
      setShowUsers(true);
    }
  };
  const handleView = (value) => {
    setUser(value);
    setStep(2);
  };
  const handleBack = () => {
    setStep(1);
    setUser({});
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.center}>
        {step === 1 && (
          <RefundHistory
            handleView={handleView}
            showRestaurants={showRestaurants}
            showUsers={showUsers}
            onChange={handleCheckboxChange}
          />
        )}
        {step === 2 && (
          <TransactionsHistory handleBack={handleBack} user={user} />
        )}
      </div>
    </div>
  );
};

export default Refund;
