import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import styles from "./createContainer.module.scss";
import toastService from "../../../_services/toast.service";
import ConfirmModal from "../../../_components/modal/confirmModal";
import List from "./list";
import CreateTypeForm from "./form";
import t from "./../../../_config/constants";

const CreateContainer = ({
  onAddClick,
  onCreateClick,
  onEditClick,
  activeTab,
}) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState([]);
  const [list, setList] = useState([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [regionId, setRegionId] = useState("");
  const [subregionId, setSubregionId] = useState("");

  const [selectedContainer, setSelectedContainer] = useState({});
  useEffect(() => {
    if (store.auth && store.auth.user) {
      setRegionId(store.auth.user.regionId);
      setSubregionId(store.auth.user.subregionId);
      getAllContainers(store.auth.user.regionId, store.auth.user.subregionId);
    }
  }, [store.auth && store.auth.user]);
  const getAllContainers = async (regionId, subregionId) => {
    let filter = [];
    if (regionId && regionId !== "*") {
      filter.push({ regionId: regionId });
    }
    if (subregionId && subregionId !== "*") {
      filter.push({ subregionId: subregionId });
    }
    let response = await dispatch(
      mainActions.run("data", "containerType", "all", { filter })
    );
    if (response) setList(response);
  };
  const handleChecked = (id) => {
    if (modalData.includes(id)) {
      let filtered = modalData.filter((item) => item !== id);
      setModalData(filtered);
    } else {
      setModalData((prevState) => [...prevState, id]);
    }
  };

  const deleteContainers = async (answer, data) => {
    if (answer) {
      await dispatch(
        mainActions.run("data", "containerType", "deleteMulti", {
          ids: data,
          type: "containerType",
        })
      );
      setModalData([]);
      setShowModalDelete(false);
      getAllContainers(regionId, subregionId);
      toastService.show(
        "success",
        t.containerManagement.create.text_successfully_deleted_types
      );
    } else {
      setShowModalDelete(false);
    }
  };

  const openDeleteModal = () => {
    setShowModalDelete(true);
  };

  return (
    <div className={styles.wrapper}>
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={deleteContainers}
          modalData={modalData}
          question={t.containerManagement.create.text_modal_delete_type}
        />
      )}
      <div className={styles.left}>
        <div className={styles.header}>
          <p
            className={
              activeTab === "add" ? styles.tabActive : styles.tabInactive
            }
            onClick={onAddClick}
          >
            {" "}
            {t.containerManagement.add.text_add_containers}{" "}
          </p>
          <p
            className={
              activeTab === "new" ? styles.tabActive : styles.tabInactive
            }
            onClick={onCreateClick}
          >
            {" "}
            {t.containerManagement.create.text_new_container}{" "}
          </p>
          <p
            className={
              activeTab === "edit" ? styles.tabActive : styles.tabInactive
            }
            onClick={onEditClick}
          >
            {t.containerManagement.create.text_edit_container}
          </p>
        </div>
        <CreateTypeForm
          refetch={getAllContainers}
          regionId={regionId}
          selectedContainer={selectedContainer}
          activeTab={activeTab}
          setSelectedContainer={setSelectedContainer}
        />
      </div>
      <div className={styles.right}>
        <List
          modalData={modalData}
          list={list}
          handleChecked={handleChecked}
          openDeleteModal={openDeleteModal}
          selectedContainer={selectedContainer}
          setSelectedContainer={setSelectedContainer}
          activeTab={activeTab}
        />
      </div>
    </div>
  );
};

export default CreateContainer;
