import React, { useState, useEffect } from "react";
import styles from "./overdueList.module.scss";
import OverdueTable from "./../overdueTable";
import { mainActions } from "../../../_store/actions/main.actions";
import SearchBar from "../../searchBar";
import useDebounce from "./../../../_utils/debounce";
import { useDispatch, useSelector } from "react-redux";
import { termRegex } from "../../../_utils/utils";
import Pagination from "../../pagination";
import t from "../../../_config/constants";
import PurchasedTable from "../purchasedTable";
const OverdueList = ({ type }) => {
  const store = useSelector((state) => state);
  const [containers, setContainers] = useState([]);
  const dispatch = useDispatch();
  const [loadingData, setLoadingData] = useState(true);
  const [update, setUpdate] = useState(1);
  const [containerTypes, setContainerTypes] = useState([]);
  const [filterData, setFilterData] = useState({
    searchTerm: "",
    selectedValue: "",
  });
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [numberOfReports, setNumberOfReports] = useState(0);
  const [sortConfig, setSortConfig] = useState({});
  const debouncedSearchTerm = useDebounce(filterData.searchTerm, 500);
  useEffect(() => {
    setPageNumber(0);
    const fetchAllContainerTypes = async (regionId) => {
      let filter = [];
      if (regionId && regionId !== "*") {
        filter.push({ regionId: regionId });
      }
      let data = await dispatch(
        mainActions.run("data", "containerType", "all", { filter })
      );
      if (data) setContainerTypes(data);
    };
    if (store.auth && store.auth.user && store.auth.user.regionId) {
      fetchAllContainerTypes(store.auth.user.regionId);
    }
  }, [store.auth && store.auth.user]);
  const onUpdate = () => setUpdate(update === 1 ? 2 : 1);
  const getContainers = async (
    term,
    ids,
    pageNumber,
    type,
    sortConfig = { createdAt: -1 }
  ) => {
    setLoadingData(true);
    let filter = [{ containerTypeId: { $in: ids } }];
    if (term) {
      filter.push({
        $or: [{ name: { $regex: `${termRegex(term)}`, $options: "i" } }],
      });
    }
    let data = await dispatch(
      mainActions.run("overdueContainers", type, "page", {
        filter,
        pageSize,
        pageNumber,
        sort: sortConfig,
      })
    );
    if (!data) data = { rows: [], total: 0 };

    setContainers(data.rows);
    setNumberOfReports(data.total);
    setLoadingData(false);
  };
  useEffect(() => {
    setPageNumber(0);
  }, [debouncedSearchTerm]);
  useEffect(() => {
    setPageNumber(0);
  }, [type]);
  useEffect(() => {
    if (containerTypes) {
      let ids = containerTypes.map((item) => item._id);
      getContainers(debouncedSearchTerm, ids, pageNumber, type);
    }
  }, [debouncedSearchTerm, update, containerTypes, pageNumber, type]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterData((prevState) => ({ ...prevState, [name]: value }));
  };
  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div></div>
        <SearchBar
          customClass="transactionSearch"
          placeholder={t.report.text_search_container}
          name="searchTerm"
          value={filterData.searchTerm}
          onChange={handleChange}
          disabled={loadingData}
        />
      </div>
      {type === "overdueContainer" ? (
        <OverdueTable
          containers={containers}
          loadingData={loadingData}
          onUpdate={onUpdate}
        />
      ) : (
        <PurchasedTable
          containerReports={containers}
          loadingData={loadingData}
          onUpdate={onUpdate}
        />
      )}
      <div className={styles.pagination}>
        <Pagination
          handlePageClick={onPageChange}
          pageCount={Math.ceil(numberOfReports / pageSize)}
          forcePage={pageNumber}
          loading={loadingData}
        />
      </div>
    </div>
  );
};
export default OverdueList;
