import React, { useEffect, useState } from "react";
import styles from "./distribution.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";

import { RiSettings5Fill } from "react-icons/ri";
import t from "../../../_config/constants";

import Button from "../../button";
import Toggle from "../../toggle";
import Loading from "../../loading";
import toastService from "../../../_services/toast.service";
import { userActions } from "../../../_store/actions/user.actions";
const DistributionPermissions = () => {
  const dispatch = useDispatch();
  const [admins, setAdmins] = useState();
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [update, setUpdate] = useState(true);
  const store = useSelector((state) => state);
  useEffect(() => {
    const fetchAdmins = async () => {
      let response = await dispatch(
        mainActions.run("profiles", "admin", "all", {})
      );
      const masterAdmin = store?.auth?.user || {};
      setAdmins([masterAdmin, ...response]);
    };
    fetchAdmins();
  }, [update]);
  useEffect(() => {
    if (admins?.length) {
      admins.forEach((admin) => {
        updateData[admin._id + ""] = admin.secureCodeRequired;
      });
    }
  }, [admins]);
  const handleSubmit = async (e) => {
    e && e.preventDefault();
    setSaving(true);

    for (let i = 0; i < admins.length; i++) {
      const admin = admins[i];
      if (admin.secureCodeRequired !== updateData[admin._id + ""]) {
        admin.type === "admin"
          ? await dispatch(
              mainActions.run("profiles", "admin", "update", {
                _id: admin._id,
                secureCodeRequired: updateData[admin._id + ""],
              })
            )
          : await dispatch(
              userActions.updateUser({
                _id: admin._id,
                secureCodeRequired: updateData[admin._id + ""],
              })
            );
      }
    }
    toastService.show("success", t.toast.text_successfully_submited);
    setSaving(false);
    setEditing(false);
    setUpdateData({});
    setUpdate((prev) => !prev);
  };
  const handleCancel = (e) => {
    e && e.preventDefault();
    setEditing(false);
  };
  const handleChange = (e, item) => {
    const value = e?.target?.checked || false;
    setUpdateData((prev) => ({ ...prev, [item._id + ""]: value }));
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <div className={styles.header}>
          <h3>Secure code requirements</h3>
          <RiSettings5Fill
            onClick={() => {
              setEditing(true);
            }}
          />
        </div>
        <div className={styles.content}>
          {admins && admins.length && (
            <div className={styles.table}>
              <div className="custom-table">
                <table>
                  <thead>
                    <tr className="tb-head-th">
                      <th>{t.admin.admins.text_th_name}</th>
                      <th>{t.admin.admins.text_th_email}</th>
                      <th>{t.admin.admins.text_th_assigned_region}</th>
                      <th>{t.permissions.secure_code_required}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {admins?.map((item) => (
                      <React.Fragment key={item._id}>
                        <tr>
                          <td>
                            {item.fullName || t.common.text_not_available}
                          </td>
                          <td>{item.email}</td>
                          <td>
                            {item.regionName || t.common.text_not_available}
                          </td>
                          {editing ? (
                            <td>
                              <Toggle
                                checked={updateData[item._id + ""]}
                                onChange={(e) => {
                                  handleChange(e, item);
                                }}
                                disabled={saving}
                              />
                            </td>
                          ) : (
                            <td>{item.secureCodeRequired ? "Yes" : "No"}</td>
                          )}
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              {saving && (
                <div className={styles.loading}>
                  <Loading height={70} width={70} />
                </div>
              )}
            </div>
          )}
          {editing && (
            <div className={styles.options}>
              <Button
                label={t.button.text_cancel}
                btnClass="btnWhiteBackground"
                type="btn"
                onClick={handleCancel}
                disabled={saving}
              />
              <Button
                btnClass="btnNormalCasal"
                label={t.button.text_save}
                type="submit"
                onClick={handleSubmit}
                disabled={saving}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default DistributionPermissions;
