import React, { useEffect, useState } from "react";
import styles from "./paymentHistory.module.scss";
import Button from "../../../button/index";
import leftArrow from "../../../../_assets/icons/leftArrow.svg";
import MonthlyPayment from "../monthlyPayment";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../../_store/actions/main.actions";
import Loading from "../../../loading";
import { capitalizeWord, getTransactionsType } from "../../../../_utils/utils";
import moment from "moment";
import t from "../../../../_config/constants";

const PaymentHistory = ({ id, goBack }) => {
  const dispatch = useDispatch();
  const [param, setParam] = useState("");
  const [transaction, setTransaction] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getTransactions(id);
    }
  }, [id]);

  const getTransactions = async () => {
    let filter = [];
    filter.push({
      user_id: id,
      transactionType: { $nin: ["SUBSCRIPTION"] },
      amount: { $ne: 0 },
    });
    let response = await dispatch(
      mainActions.run("transactions", "transaction", "all", { filter })
    );
    setTransactions(response);
    setParam("");
    setLoading(false);
  };

  const handleView = (value) => {
    setTransaction(value);
    setParam(getTransactionsType(value.transactionType));
  };
  const handleSelect = (param) => {
    setParam(param);
  };

  const mainReturn = () => {
    setParam("");
    goBack();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonsWrapper}>
        <Button
          label={t.button.text_backz}
          btnClass="btnBack"
          iconLeft={leftArrow}
          onClick={mainReturn}
        />
      </div>
      <h1 className={styles.paymentTitle}>{t.user.text_payment_history}</h1>
      {!param && (
        <>
          <div className={styles.list}>
            <div className={styles.block}>
              <div className={styles.transactionTypeBlock}>
                {t.common.text_transaction}
              </div>
              <div className={styles.transactionStatus}>
                {t.common.text_th_status}
              </div>
              <div className={styles.transactionDateBlock}>
                {t.common.text_date}
              </div>
              <div className={styles.blockButton}></div>
            </div>
            {loading && (
              <div className={styles.loading}>
                <Loading width={50} height={50} />
              </div>
            )}
            {!loading && !transactions.length && (
              <div className={styles.noTransactionsMsg}>
                {t.common.text_no_transactions}
              </div>
            )}
            {!loading &&
              transactions.length > 0 &&
              transactions.map((item) => (
                <div key={item._id} className={styles.block}>
                  <div className={styles.transactionTypeBlock}>
                    {getTransactionsType(item.transactionType)}
                  </div>
                  <div className={styles.transactionStatus}>
                    {capitalizeWord(item.status)}
                  </div>
                  <div className={styles.transactionDateBlock}>
                    {moment(item.createdAt).format("MM/DD/YYYY")}
                  </div>
                  <div className={styles.blockButton}>
                    <Button
                      label={t.button.text_view}
                      btnClass="btnNormalGlacier"
                      onClick={() => handleView(item)}
                    />
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
      {param && (
        <MonthlyPayment
          transaction={transaction}
          param={param}
          handleExit={() => handleSelect("")}
        />
      )}
    </div>
  );
};
export default PaymentHistory;
