import React, { useEffect, useState } from "react";
import styles from "./tableOfLogs.module.scss";
import Loading from "../../../../loading";
import Button from "../../../../button";
import t from "../../../../../_config/constants";
import arrowUp from "../../../../../_assets/icons/arrowUp.svg";
import arrowDown from "../../../../../_assets/icons/arrowDown.svg";
import upDownArrow from "../../../../../_assets/icons/upDownArrow.svg";
import useSortableData from "../../../../../_utils/useSortableData";

import { getLogs } from "../../../../../_services/restaurant.service";
import moment from "moment";
import { Link } from "react-router-dom";
import Datepicker from "../../../../newDatepicker";

const TableOfLogs = ({ restaurant }) => {
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  const [date, setDate] = useState(new Date());

  const { handleSort, sortConfig } = useSortableData(logs);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return upDownArrow;
    }
    return sortConfig.key === name
      ? sortConfig.direction === "ascending"
        ? arrowUp
        : arrowDown
      : upDownArrow;
  };

  useEffect(() => {
    const getRestaurantLogs = async (restaurant_id, date, sortConfig) => {
      setLoading(true);
      let fromDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      ).getTime();

      const filter = {};
      const dateTo = new Date(fromDate).setDate(
        new Date(fromDate).getDate() + 1
      );
      filter.timestamp = { $gte: fromDate, $lte: dateTo };
      const response = await getLogs({
        restaurant_id,
        filter,
        sortConfig,
      });
      setLogs(response);
      setLoading(false);
    };
    if (restaurant && restaurant._id) {
      getRestaurantLogs(restaurant._id, date, sortConfig);
    }
  }, [restaurant, date, sortConfig]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.options}>
        <Datepicker
          minDate={new Date(2021, 5, 1).getTime()}
          maxDate={new Date().getTime()}
          selected={date}
          onDatePickerChange={(val) => setDate(val)}
          name="date"
          placeholder="mm/dd/yyyy"
        />
      </div>
      <div className={styles.table}>
        <table>
          <thead>
            <tr className={styles.topBar}>
              <th colSpan="5">{t.restaurant.text_th_logs_of_activity}</th>
            </tr>
            <tr>
              <th onClick={() => (loading ? null : handleSort("date"))}>
                {t.common.text_date}
                <Button
                  btnClass="btnBack"
                  iconLeft={getClassNamesFor("date")}
                />
              </th>
              <th
                onClick={() => (loading ? null : handleSort("containerType"))}
              >
                {t.restaurant.text_th_container_type}
                <Button
                  btnClass="btnBack"
                  iconLeft={getClassNamesFor("containerType")}
                />
              </th>
              <th
                onClick={() =>
                  loading ? null : handleSort("numberOfContainers")
                }
              >
                {t.restaurant.text_th_number_of_containers}

                <Button
                  btnClass="btnBack"
                  iconLeft={getClassNamesFor("numberOfContainers")}
                />
              </th>
              <th onClick={() => (loading ? null : handleSort("userName"))}>
                {t.user.text_member}
                <Button
                  btnClass="btnBack"
                  iconLeft={getClassNamesFor("userName")}
                />
              </th>
              <th onClick={() => (loading ? null : handleSort("activity"))}>
                {t.restaurant.text_th_activity}
                <Button
                  btnClass="btnBack"
                  iconLeft={getClassNamesFor("activity")}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              logs &&
              logs.length > 0 &&
              logs.map((item, ind) => (
                <tr key={ind}>
                  <td>
                    {moment(
                      item.date ? item.date : new Date().getTime()
                    ).format("MMM D, YYYY HH:mm")}
                  </td>
                  <td>
                    <span>{item.containerType}</span>
                  </td>
                  <td>
                    <span>{item.numberOfContainers}</span>
                  </td>

                  <td>
                    {item.user_id && item.userName ? (
                      <Link to={`/user-management?id=${item.user_id}`}>
                        <span title="Show Member">{item.userName}</span>
                      </Link>
                    ) : (
                      <span>
                        {item.userName ? item.userName : "Deleted Member"}
                      </span>
                    )}
                  </td>
                  <td>
                    <span>{item.activity}</span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {loading && (
          <div className={styles.loading}>
            <Loading width={50} height={50} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TableOfLogs;
