import React, { useEffect, useState } from "react";
import RegionManagement from "../../_components/admin/regionManagement";
import RegionAdminManagement from "../../_components/admin/regionAdminManagement";
import { useDispatch } from "react-redux";
import { mainActions } from "../../_store/actions/main.actions";
import RegionNotifications from "../../_components/admin/regionNotifications";
import RegionPostalCodes from "../../_components/admin/regionPostalCodes";
import SubRegionManagement from "../../_components/admin/subRegions";

const Admin = () => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("region");

  const fetchAdmins = async () => {
    await dispatch(mainActions.run("profiles", "admin", "all", {}));
  };
  const fetchRegionNotifications = async () => {
    await dispatch(mainActions.run("data", "regionNotifications", "all", {}));
  };
  useEffect(() => {
    fetchAdmins();
    fetchRegionNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {activeTab === "region" && (
        <RegionManagement
          onRegionClick={() => setActiveTab("region")}
          onSubRegionClick={() => setActiveTab("subregion")}
          onAdminClick={() => setActiveTab("admin")}
          onNotificationsClick={() => setActiveTab("notifications")}
          onPostalCodesClick={() => setActiveTab("codes")}
          activeTab={activeTab}
        />
      )}
      {activeTab === "subregion" && (
        <SubRegionManagement
          onRegionClick={() => setActiveTab("region")}
          onSubRegionClick={() => setActiveTab("subregion")}
          onAdminClick={() => setActiveTab("admin")}
          onNotificationsClick={() => setActiveTab("notifications")}
          onPostalCodesClick={() => setActiveTab("codes")}
          activeTab={activeTab}
        />
      )}
      {activeTab === "notifications" && (
        <RegionNotifications
          onRegionClick={() => setActiveTab("region")}
          onSubRegionClick={() => setActiveTab("subregion")}
          onAdminClick={() => setActiveTab("admin")}
          onNotificationsClick={() => setActiveTab("notifications")}
          onPostalCodesClick={() => setActiveTab("codes")}
          activeTab={activeTab}
        />
      )}
      {activeTab === "codes" && (
        <RegionPostalCodes
          onRegionClick={() => setActiveTab("region")}
          onSubRegionClick={() => setActiveTab("subregion")}
          onAdminClick={() => setActiveTab("admin")}
          onNotificationsClick={() => setActiveTab("notifications")}
          onPostalCodesClick={() => setActiveTab("codes")}
          activeTab={activeTab}
        />
      )}
      {activeTab === "admin" && (
        <RegionAdminManagement
          onRegionClick={() => setActiveTab("region")}
          onSubRegionClick={() => setActiveTab("subregion")}
          onAdminClick={() => setActiveTab("admin")}
          onNotificationsClick={() => setActiveTab("notifications")}
          onPostalCodesClick={() => setActiveTab("codes")}
          activeTab={activeTab}
        />
      )}
    </>
  );
};

export default Admin;
