import React, { useState, useEffect } from "react";
import styles from "./dropoffManagement.module.scss";
import DropoffList from "../../_components/dropoff/list/dropoffList";
import SingleLocation from "../../_components/dropoff/single/singleLocation";
import useIsCleaner from "../../_utils/isCleaner";

const Dropoff = () => {
  const [selectedLocation, setSelectedLocation] = useState("");
  const [update, setUpdate] = useState(1);
  const isCleaner = useIsCleaner();
  useEffect(() => {
    isCleaner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectLocation = (id) => {
    setSelectedLocation(id);
  };

  const onUpdate = () => {
    setUpdate((prevState) => (prevState === 1 ? 2 : 1));
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <DropoffList
          handleSelectLocation={handleSelectLocation}
          selectedLocationId={selectedLocation}
          update={update}
        />
      </div>
      <div className={styles.right}>
        <SingleLocation
          selectedLocationId={selectedLocation}
          onUpdate={onUpdate}
        />
      </div>
    </div>
  );
};

export default Dropoff;
