import React, { useState, useEffect } from "react";
import styles from "./userManagement.module.scss";
import UsersList from "../../_components/userManagement/list/usersList";
import SingleUser from "../../_components/userManagement/single/singleUser";
import queryString from "query-string";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useIsCleaner from "../../_utils/isCleaner";
import SingleReuserCard from "../../_components/userManagement/single/singleReuserCard";

const UserManagement = () => {
  const history = useHistory();
  const isCleaner = useIsCleaner();
  useEffect(() => {
    isCleaner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { id } = queryString.parse(history.location.search);
  const [isUser, setIsUser] = useState(true);
  const [selectedUser, setSelectedUser] = useState(id ? id : "");
  const [update, setUpdate] = useState(1);
  const [hideSingle, setHideSingle] = useState(false);
  const handleSelectUser = (id) => {
    setSelectedUser(id);
  };

  const onUpdate = () => {
    setUpdate((prevState) => (prevState === 1 ? 2 : 1));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <UsersList
          handleSelectUser={handleSelectUser}
          selectedUser={selectedUser}
          update={update}
          setHideSingle={setHideSingle}
          setIsUser={setIsUser}
          setSelectedUser={setSelectedUser}
        />
      </div>
      <div className={styles.right}>
        {isUser ? (
          <SingleUser
            selectedUser={selectedUser}
            onUpdate={onUpdate}
            hideSingleOptions={hideSingle}
          />
        ) : (
          <SingleReuserCard onUpdate={onUpdate} selectedUser={selectedUser} />
        )}
      </div>
    </div>
  );
};

export default UserManagement;
