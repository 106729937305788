import React, { useState } from "react";
import userService from "../../../_services/user.service";
import Button from "../../button";
import Input from "../../input";
import styles from "./editEmail.module.scss";
import t from "../../../_config/constants";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import { isObjectEmpty } from "../../../_utils/utils";
import toastService from "../../../_services/toast.service";
import { modalActions } from "../../../_store/actions/modal.actions";
const EditEmail = ({ currentEmail }) => {
  const dispatch = useDispatch();
  const [newEmail, setNewEmail] = useState("");
  const [disabled, setDisabled] = useState(false);
  const handleChange = (e) => {
    const { value } = e.target;
    setNewEmail(value);
  };
  const validateEmail = async () => {
    let isValid = true;
    const registeredUser = await dispatch(
      mainActions.run("profiles", "user", "get", {
        filter: [{ email: newEmail }],
      })
    );
    const registeredStore = await dispatch(
      mainActions.run("profiles", "restaurant", "get", {
        filter: [{ email: newEmail }],
      })
    );
    const registeredCleaner = await dispatch(
      mainActions.run("profiles", "cleaner", "get", {
        filter: [{ email: newEmail }],
      })
    );
    const registeredAdmin = await dispatch(
      mainActions.run("profiles", "admin", "get", {
        filter: [{ email: newEmail }],
      })
    );
    if (
      !isObjectEmpty(registeredUser) ||
      !isObjectEmpty(registeredStore) ||
      !isObjectEmpty(registeredCleaner) ||
      !isObjectEmpty(registeredAdmin)
    ) {
      isValid = false;
    }

    return isValid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    const isValid = await validateEmail();
    if (isValid) {
      const res = await userService.changeEmailLink({
        email: currentEmail,
        newEmail: newEmail,
      });
      if (res) {
        toastService.show(
          "success",
          `Successfully sent confirmation link to ${newEmail}`
        );
        dispatch(modalActions.closeModal());
      }
    } else {
      toastService.show("error", "Email already registered.");
    }
    setDisabled(false);
  };

  return (
    <div className={styles.wrapper}>
      <h2>Change Email Address</h2>
      <form onSubmit={handleSubmit}>
        <Input
          label={"Current Email Address"}
          name="email"
          inputClass="inputForm"
          type="email"
          value={currentEmail}
          disabled={true}
        />
        <Input
          label={"New Email Address"}
          name="email"
          inputClass="inputForm"
          type="email"
          value={newEmail.trim()}
          placeholder={t.common.input.text_input_email_address}
          onChange={handleChange}
        />
        <Button
          btnClass="btnNormal"
          label={t.button.text_save}
          type="submit"
          disabled={!newEmail || disabled}
        />
      </form>
    </div>
  );
};
export default EditEmail;
