import React, { useState } from 'react';
import Statistics from '../../_components/cleaning/statistics';
import ScanningContainers from '../../_components/cleaning/scanningContainers/index';
import styles from './cleaning.module.scss';

const Cleaning = () => {
  const [step, setStep] = useState(1);
  const [cleanerBatch, setCleanerBatch] = useState({})
  const handleNext = () => {
    if (step < 2) {
      let nextStep = step + 1;
      setStep(nextStep);
      setCleanerBatch({})
    }
  };

  const handleEdit = (obj) => {
    if (step < 2) {
      let nextStep = step + 1;
      setStep(nextStep);
      setCleanerBatch(obj)
    }
  }

  const handleBack = () => {
    if (step > 1) {
      let nextStep = step - 1;
      setStep(nextStep);
    }
  };

  return (
    <div className={styles.wrapper}>
      {step === 1 && <Statistics handleBack={handleBack} handleNext={handleNext} handleEdit={handleEdit} />}
      {step === 2 && <ScanningContainers handleBack={handleBack} cleanerBatch={cleanerBatch} />}
    </div>
  )
}

export default Cleaning;